import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';

// const firebaseConfig = {
//   apiKey: "AIzaSyCW7tLO10TUngkWDxaZqyXRMPDwXIEhid4",
//   authDomain: "recycling-33b80.firebaseapp.com",
//   projectId: "recycling-33b80",
//   storageBucket: "recycling-33b80.appspot.com",
//   messagingSenderId: "638393445867",
//   appId: "1:638393445867:web:547f6cb1fe36b55116bdae",
// };

const firebaseConfig = {
  apiKey: "AIzaSyAk3-o9xpyRytvTo6nlpvFdP8BXBJdRPMA",
  authDomain: "bigscrappersegyptchattest.firebaseapp.com",
  projectId: "bigscrappersegyptchattest",
  storageBucket: "bigscrappersegyptchattest.appspot.com",
  messagingSenderId: "963307070264",
  appId: "1:963307070264:web:50c8cd30203fad338857cd"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export const FBStorage = getStorage(app);

export default db;