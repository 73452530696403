import { useEffect, useRef, useState } from "react";
import { Button, Input, Space, DatePicker } from "antd";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from 'moment';
import { getSalesRequestsReturns } from "../../apis/Network";
import { toastError } from "../../helpers/toasters";

// components
import GridTable from "../SubComponents/GridTable/GridTable";
import SubHeader from "../SubComponents/SubHeader";
import MainHeader from "../SubComponents/MainHeader";
import ReturnModal from './ReturnModal';
import DeleteRequestModal from "./DeleteRequestModal";

const SalesRequestsReturns = () => {
  const [dataSource, setDataSource] = useState([]);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [rowData, setRowData] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isReturnModalOpen, setIsReturnModalOpen] = useState(false);
  const [selectedRange, setSelectedRange] = useState([]);
  const [renderMe, setRenderMe] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const { RangePicker } = DatePicker;

  const handleCancelModal = () => {
    setIsReturnModalOpen(false);
    setOpenDeleteModal(false);
  };
  const handleSubmit = () => {
    handleCancelModal();
    setRenderMe(!renderMe);
  };
  const handleDateChange = (dates) => {
    setSelectedRange(dates);
    if (dates) {
      const startDate = dates[0].format("DD MMM, YYYY");
      const endDate = dates[1].format("DD MMM, YYYY");
      const dataWithingRange = dataSource.filter((ele) => {
        const comparisonDate = moment(ele.date, "DD MMMM YYYY");
        return (
          comparisonDate.isSameOrAfter(startDate) &&
          comparisonDate.isSameOrBefore(endDate)
        );
      });
      setFilteredDataSource(dataWithingRange);
    } else {
      setFilteredDataSource(dataSource);
    }
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          ></Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text || text === 0 ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const getColumnDateProps = () => ({
    filterDropdown: () => (
      <div
        style={{
          padding: 8,
        }}
        className="range-picker-holder"
        onKeyDown={(e) => e.stopPropagation()}
      >
        <RangePicker
          value={selectedRange}
          allowClear
          popupClassName="main-date-picker"
          inputReadOnly={false}
          onChange={handleDateChange}
          onKeyDown={(e) => e.preventDefault()}
        />
      </div>
    ),
    filterIcon: (filtered) => <FilterOutlined style={selectedRange?.length > 0 ? { color: "blue" } : {}} />,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });
  const columns = [
    {
      key: "merchant",
      title: "MERCHANT",
      dataIndex: "merchant",
      ...getColumnSearchProps("merchant"),
    },
    {
      key: "typeName",
      title: "TYPE",
      dataIndex: "typeName",
      ...getColumnSearchProps("typeName"),
    },
    {
      key: "quantity",
      title: "QUANTITY",
      dataIndex: "quantity",
      ...getColumnSearchProps("quantity"),
    },

    {
      key: "perKiloPrice",
      title: "PRICE PER KILO",
      dataIndex: "perKiloPrice",
      ...getColumnSearchProps("perKiloPrice"),
    },
    {
      key: "note",
      title: "NOTE",
      dataIndex: "note",
      ...getColumnSearchProps("note"),
      render: (_, row) => {
        return (
          <span
            title={row.note?.length > 20 ? row.note : ""}
            className="text-truncate text--max-20ch d-block"
          >
            {row.note}
          </span>
        );
      }
    },
    {
      key: "date",
      title: "CREATION DATE",
      dataIndex: "date",
      ...getColumnDateProps("date"),
    },
    {
      title: "ACTION",
      render: (_, row) => {
        return (
          <Button
            className="border-0"
            onClick={() => {
              setRowData(row)
              setOpenDeleteModal(true)
            }}
            style={
              row.requestStatusId === 3
                ? {}
                : { backgroundColor: "#E72121", color: "white" }
            }
          >
            Delete
          </Button>
        );
      }
    },
  ];
  useEffect(() => {
    getSalesRequestsReturns(
      (res) => {
        setLoading(false);
        if (res.success) {
          setDataSource(res.data);
          setFilteredDataSource(res.data);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setLoading(false);
        toastError(res.message);
      }
    );
  }, [renderMe]);

  return (
    <section className="users couriers">
      <div className="users__all-contents">
        <div className="users__content">
          <div className="users__usersData">
            <div className="pb-3 mb-3">
              <MainHeader
                title="Sales Requests Returns"
                buttonName={"Add New Return"}
                onButtonClick={() => setIsReturnModalOpen(true)}
              />
            </div>
            <div className="users__table grid-table mrg-y-30">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={filteredDataSource}
                rowId={"id"}
                title={"Sales Requests Returns"}
              />
            </div>
          </div>
        </div>
      </div>
      {isReturnModalOpen && (
        <ReturnModal
          open={isReturnModalOpen}
          onCancel={handleCancelModal}
          onSubmit={handleSubmit}
          SubHeader={
            <SubHeader
              bgColor="#589559"
              title={`Add Request Return`}
              closeButton
              onCancel={handleCancelModal}
            />
          }
        />
      )}
      {openDeleteModal && (
        <DeleteRequestModal
          rowData={rowData}
          open={openDeleteModal}
          SubHeader={
            <SubHeader
              bgColor="#589559"
              title={`Delete Return`}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          onCancel={handleCancelModal}
          onSubmit={handleSubmit}
        />
      )}
    </section>
  );
};

export default SalesRequestsReturns;
