import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import Routes from "../Routes/Routes";

import WebsiteTitle from "../../helpers/WebsiteTitle";
import SideBar from "../SideBar/SideBar";
import NoNetwork from "../NoNetwork";

const MenuOutlinedStyle = {
  fontSize: "35px",
  color: "white",
  marginBlockEnd: "10px",
  marginInlineStart: "20px",
};

function General() {
  const [isConnectedToInternet, setIsConnectedToInternet] = useState(
    navigator.onLine
  );
  const [isSidebar, setIsSidebar] = useState(true);
  const { authorization } = useSelector((state) => state?.auth);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const notHaveNavSide = ["/"];

  useEffect(() => {
    if (!authorization.token) {
      navigate("/", { replace: true });
    }
  }, [navigate, authorization]);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsConnectedToInternet(navigator.onLine);
    };

    handleOnlineStatusChange();

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);

  return (
    <section className={"general-section align-self-stretch"}>
      {isConnectedToInternet ? (
        <>
          <WebsiteTitle />
          {!notHaveNavSide.includes(pathname) && (
            <div
              className={`${notHaveNavSide.includes(pathname) || !isSidebar
                ? "d-none"
                : "side-bar position-relative"
                }`}
            >
              <SideBar />
            </div>
          )}
          <div
            className={`${notHaveNavSide.includes(pathname) || !isSidebar
              ? "content-full-width"
              : " content-fixed-width"
              } all-content flex-fill align-self-stretch d-flex flex-column`}
          >
            {!notHaveNavSide.includes(pathname) && (
              <ShowHideSidebar
                isSidebar={isSidebar}
                setIsSidebar={setIsSidebar}
              />
            )}
            <div className="component-wrapper flex-fill">
              <Routes />
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      <NoNetwork show={!isConnectedToInternet} />
    </section>
  );
}

export default General;

const ShowHideSidebar = ({ isSidebar, setIsSidebar }) => {
  return (
    <div className="d-flex pt-2">
      {isSidebar ? (
        <MenuFoldOutlined
          style={MenuOutlinedStyle}
          onClick={() => setIsSidebar(false)}
          role="button"
        />
      ) : (
        <MenuUnfoldOutlined
          style={MenuOutlinedStyle}
          onClick={() => setIsSidebar(true)}
          role="button"
        />
      )}
    </div>
  );
};
