// images
import illustration from "../../assets/images/no-network.png";
import retryIcon from "../../assets/icons/reload.svg";

const NoNetwork = ({ show }) => {
  return (
    <div
      style={!show ? { display: "none", width: 0, height: 0, padding: 0 } : {}}
      className={
        show
          ? "no-network bg--white flex-fill d-flex justify-content-center align-items-center"
          : ""
      }
    >
      <div className="no-network__container d-flex flex-column">
        <img
          src={illustration}
          alt="No Network"
          className={show ? "illustration-image d-block img-fluid mx-auto" : ""}
          style={!show ? { display: "none", width: 0, height: 0 } : {}}
        />
        <h1 className="text-center mb-0">
          Something went wrong!
        </h1>
        <p className="text-center mx-auto mb-0">
          Oops! Looks like our connection got lost. Sorry, it looks like you're off the grid.
        </p>
        {/* <div className="d-flex justify-content-center">
          <button
            type="button"
            className="no-network__retry-btn d-flex justify-content-center align-items-center gap-2 outline-none shadow-none border-0 fw-bold"
          >
            <img src={retryIcon} alt="" className="d-block img-fluid" />
            <span>Reload Page</span>
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default NoNetwork;
