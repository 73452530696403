import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, Form, InputNumber, Row, Select } from "antd";
import { handleAllowNumbersOnly } from "../../helpers/helpfulFunctions";

const FormItem = ({
  data = {},
  onDelete,
  periods,
  allItems,
  onUpdateItem,
  order
}) => {
  const validateDays = (_, value) => {
    let alreadySelected = false;
    for (let i = 0; i < allItems.length; i++) {
      if (i === order) continue;
      if (allItems[i].deliveryPeriodId === value) {
        alreadySelected = true
      }
    }

    if (alreadySelected) {
      return Promise.reject("This period was selected before");
    } else if (!value) {
      return Promise.reject("Please, Select the Receiving Period");
    } else {
      return Promise.resolve();
    }
  };

  const validatePricePerKilo = (_, value) => {
    if (!value && value !== 0) {
      return Promise.reject("Please, enter the price");
    } else if (value <= 0) {
      return Promise.reject("Minimum value should be greater than 0");
    } else if (value > 10000) {
      return Promise.reject("Maximum value is 10000");
    } else {
      return Promise.resolve();
    }
  };

  return (
    <div
      style={{
        border: "solid #076128 2px",
        padding: "10px",
        borderRadius: "12px",
      }}
    >
      <div className="d-flex justify-content-end align-items-center mb-2">
        <Button
          type="button"
          onClick={() => onDelete(data?.id)}
          className="d-block p-0 border-0 outline-none bg-transparent shadow-none"
        >
          <CloseOutlined style={{ color: "#f22626", fontSize: "16px" }} />
        </Button>
      </div>
      <Row>
        <Col span={24}>
          <div className="lang-name wd-100 flex">
            <label className="text--secondary-1 mb-2 fw-semibold text-start" htmlFor="">
              Period
            </label>
          </div>
          <Form.Item
            className="new-category__form-item w-100"
            name={`deliveryPeriodId-${data?.id}`}
            rules={[{ validator: validateDays }]}
            dependencies={allItems.filter((ele) => ele.id !== data?.id).map((ele) => `deliveryPeriodId-${ele.id}`)}
          >
            <Select
              name={`deliveryPeriodId-${data?.id}`}
              className="new-category__form-item"
              placeholder="Select Period"
              loading={periods ? false : true}
              options={periods ? periods : []}
              getPopupContainer={trigger => trigger.parentElement}
              onChange={(value) => onUpdateItem(data?.id, "deliveryPeriodId", value)}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <div className="lang-name wd-100 flex">
            <label className="text--secondary-1 mb-2 fw-semibold" htmlFor="">
              Price per kilo
            </label>
          </div>
          <Form.Item
            className="new-category__form-item w-100"
            name={`pricePerKilo-${data?.id}`}
            rules={[{ validator: validatePricePerKilo }]}
          >
            <InputNumber
              className="w-100 d-block search__searchField"
              placeholder="Enter price per kilo"
              onKeyDown={(e) => handleAllowNumbersOnly(e, true)}
              controls={false}
              keyboard={false}
              value={data.pricePerKilo}
              onChange={(value) =>
                onUpdateItem(data?.id, "pricePerKilo", value)
              }
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default FormItem;
