const StoresIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4731_29005)">
                <path d="M297.2 146.88C297.2 155.664 300.69 164.088 306.901 170.299C313.112 176.511 321.536 180 330.32 180C339.104 180 347.528 176.511 353.74 170.299C359.951 164.088 363.44 155.664 363.44 146.88V134.48H297.2V146.88Z" />
                <path d="M116.64 33.3601H72.32L4 126.32H67.76L116.64 33.3601Z" />
                <path d="M33.04 180C41.824 180 50.2482 176.511 56.4594 170.299C62.6706 164.088 66.16 155.664 66.16 146.88V134.48H0V146.88C0.0210157 155.644 3.50622 164.044 9.69559 170.248C15.885 176.452 24.2764 179.958 33.04 180ZM74.32 146.88C74.0996 151.361 74.7918 155.84 76.3548 160.045C77.9177 164.251 80.3187 168.095 83.4122 171.344C86.5056 174.593 90.227 177.18 94.3505 178.948C98.474 180.716 102.914 181.627 107.4 181.627C111.886 181.627 116.326 180.716 120.45 178.948C124.573 177.18 128.294 174.593 131.388 171.344C134.481 168.095 136.882 164.251 138.445 160.045C140.008 155.84 140.7 151.361 140.48 146.88V134.48H74.32V146.88Z" />
                <path d="M141.6 126.32L170.8 33.3601H125.84L76.96 126.32H141.6Z" />
                <path d="M215.2 126.32L224.96 33.3601H179.36L150.08 126.32H215.2Z" />
                <path d="M148.64 146.88C148.42 151.361 149.112 155.84 150.675 160.045C152.238 164.251 154.639 168.095 157.732 171.344C160.826 174.593 164.547 177.18 168.671 178.948C172.794 180.716 177.234 181.627 181.72 181.627C186.206 181.627 190.646 180.716 194.77 178.948C198.893 177.18 202.614 174.593 205.708 171.344C208.801 168.095 211.202 164.251 212.765 160.045C214.328 155.84 215.021 151.361 214.8 146.88V134.48H148.64V146.88ZM222.96 146.88C222.96 155.643 226.441 164.047 232.637 170.243C238.833 176.439 247.237 179.92 256 179.92C264.763 179.92 273.167 176.439 279.363 170.243C285.559 164.047 289.04 155.643 289.04 146.88V134.48H222.96V146.88Z" />
                <path d="M288.64 126.32L278.96 33.3601H233.12L223.44 126.32H288.64Z" />
                <path d="M361.92 126.32L332.64 33.3601H287.04L296.8 126.32H361.92Z" />
                <path d="M435.04 126.32L386.24 33.3601H341.2L370.4 126.32H435.04Z" />
                <path d="M508 126.32L439.76 33.3601H395.36L444.24 126.32H508Z" />
                <path d="M371.6 146.88C371.6 155.643 375.081 164.047 381.277 170.243C387.473 176.439 395.877 179.92 404.64 179.92C413.403 179.92 421.807 176.439 428.003 170.243C434.199 164.047 437.68 155.643 437.68 146.88V134.48H371.6V146.88ZM445.84 134.48V146.88C445.62 151.361 446.312 155.84 447.875 160.045C449.438 164.251 451.839 168.095 454.932 171.344C458.026 174.593 461.747 177.18 465.871 178.948C469.994 180.716 474.434 181.627 478.92 181.627C483.407 181.627 487.846 180.716 491.97 178.948C496.093 177.18 499.814 174.593 502.908 171.344C506.001 168.095 508.402 164.251 509.965 160.045C511.528 155.84 512.221 151.361 512 146.88V134.48H445.84Z" />
                <path d="M488 186.96C485.044 187.702 482.008 188.078 478.96 188.08C471.12 188.025 463.458 185.739 456.871 181.489C450.283 177.238 445.042 171.2 441.76 164.08C438.464 171.145 433.221 177.123 426.645 181.311C420.07 185.5 412.436 187.725 404.64 187.725C396.844 187.725 389.21 185.5 382.635 181.311C376.059 177.123 370.816 171.145 367.52 164.08C364.22 171.163 358.966 177.157 352.376 181.356C345.786 185.556 338.134 187.787 330.32 187.787C322.506 187.787 314.854 185.556 308.264 181.356C301.674 177.157 296.42 171.163 293.12 164.08C289.824 171.145 284.581 177.123 278.005 181.311C271.43 185.5 263.796 187.725 256 187.725C248.204 187.725 240.57 185.5 233.995 181.311C227.419 177.123 222.176 171.145 218.88 164.08C215.594 171.167 210.35 177.167 203.765 181.372C197.181 185.577 189.532 187.811 181.72 187.811C173.908 187.811 166.259 185.577 159.675 181.372C153.09 177.167 147.846 171.167 144.56 164.08C141.274 171.167 136.03 177.167 129.445 181.372C122.861 185.577 115.212 187.811 107.4 187.811C99.5879 187.811 91.9387 185.577 85.3546 181.372C78.7705 177.167 73.5261 171.167 70.24 164.08C66.9584 171.2 61.7171 177.238 55.1293 181.489C48.5415 185.739 40.8796 188.025 33.04 188.08C29.9926 188.072 26.9572 187.696 24 186.96V456H0V478.72H512V456H488V186.96ZM145.76 376.48H69.92V314.48H145.76V376.48ZM145.76 236.96V303.04H69.92V236.96H145.76ZM233.12 376.48H157.2V314.48H233.12V376.48ZM233.12 303.04H157.2V236.96H233.12V303.04ZM437.68 456H305.04V236.96H437.68V456Z" />
                <path d="M422.8 355.36C424.094 355.36 425.335 354.846 426.251 353.931C427.166 353.016 427.68 351.774 427.68 350.48V336.96C427.68 335.666 427.166 334.425 426.251 333.509C425.335 332.594 424.094 332.08 422.8 332.08C421.506 332.08 420.264 332.594 419.349 333.509C418.434 334.425 417.92 335.666 417.92 336.96V350.48C417.92 351.774 418.434 353.016 419.349 353.931C420.264 354.846 421.506 355.36 422.8 355.36Z" />
            </g>
            <defs>
                <clipPath id="clip0_4731_29005">
                    <rect width="512" height="512" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
}

export default StoresIcon;