import { useState, useEffect } from 'react';
import { Row, Space, Modal, Form, Col, Button } from 'antd';
import { toastError, toastSuccess } from '../../../helpers/toasters';
import { addSupplyTypesToCansSalesRequest, getQuantityUnits } from '../../../apis/Network';

// components
import PrimaryButton from "../../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../../SubComponents/Buttons/SecondaryButton";
import FormItem from '../AddRequestModal/FormItem';

const AddRequestSupplyType = ({
    open,
    onCancel,
    SubHeader,
    rowData,
    onSubmit,
    activeSupplyingTypes,
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [quantityUnits, setQuantityUnits] = useState(null);
    const [emptyItemsError, setEmptyItemsError] = useState("");
    const [itemsCounter, setItemsCounter] = useState(0);
    const [createdItems, setCreatedItems] = useState([
        {
            id: 0,
            supplyTypeId: "",
            deductionPercentage: 0,
            unitId: 1,
            quantity: "",
        },
    ]);
    const [form] = Form.useForm();
    const initialValues = {
        supplyTypeId: "",
        unitId: 1,
        quantity: "",
        "deductionPercentage-0": 0,
    }

    const handleAddNewItem = () => {
        const itemId = itemsCounter + 1;
        setItemsCounter(itemId);
        const newItem = {
            id: itemId,
            supplyTypeId: "",
            unitId: quantityUnits ? quantityUnits[0]?.value : "",
            quantity: "",
            deductionPercentage: 0,
        };
        form.setFieldValue(`deductionPercentage-${itemId}`, 0)
        const createdItemsCopy = structuredClone(createdItems);
        createdItemsCopy.push(newItem);
        setCreatedItems(createdItemsCopy);
        if (emptyItemsError) {
            setEmptyItemsError(false);
        }
    };

    const handleDeleteItem = (id) => {
        const updatedCreatedItems = createdItems.filter((ele) => ele.id !== id);
        setCreatedItems(updatedCreatedItems);
    };

    const handleUpdateItem = (id, property, value) => {
        const createdItemsCopy = structuredClone(createdItems);
        const itemIndex = createdItemsCopy.findIndex((ele) => ele.id === id);
        createdItemsCopy[itemIndex][property] = value;
        setCreatedItems(createdItemsCopy);
    };

    const validateItems = () => {
        if (createdItems.length === 0) {
            setEmptyItemsError(true);
            return;
        }
    };

    const handleOnFinish = (values) => {
        if (submitting) return;
        setSubmitting(true);

        const requests = createdItems.map((item) => {
            return {
                supplyTypeId: item.supplyTypeId,
                unitId: item.unitId,
                quantity: item.quantity,
                deductionPercentage: item.deductionPercentage,
            };
        });
        const payload = {
            requestIdentifier: rowData.requestId,
            requestss: requests,
        };
        addSupplyTypesToCansSalesRequest(
            payload,
            (res) => {
                setSubmitting(false)
                if (res.success) {
                    toastSuccess(res.message);
                    onSubmit();
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setSubmitting(false)
                toastError(res.message);
            }
        )
    }

    useEffect(() => {
        getQuantityUnits(
            (res) => {
                if (res.success) {
                    const options = res.data.map((unit) => {
                        return { label: unit.name, value: unit.id };
                    });
                    setQuantityUnits(options);
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                toastError(res.message);
            }
        );
    }, []);

    return (
        <Modal
            title={SubHeader}
            open={open}
            className="msg-modal"
            footer={false}
            closable={false}
            centered
            destroyOnClose
            onCancel={onCancel}
        >
            <Form
                form={form}
                className="edit-actor__content pt-3"
                onFinish={handleOnFinish}
                initialValues={initialValues}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <div className="mb-3">
                            <div className="d-flex flex-column gap-3">
                                {createdItems.map((ele, indx) => (
                                    <FormItem
                                        key={ele.id}
                                        data={ele}
                                        onDelete={handleDeleteItem}
                                        onUpdateItem={handleUpdateItem}
                                        supplyingTypes={activeSupplyingTypes}
                                        quantityUnits={quantityUnits}
                                        form={form}
                                        order={indx}
                                    />
                                ))}
                            </div>
                            {emptyItemsError ? (
                                <div className="ant-form-item-explain ant-form-item-explain-connected">
                                    <p className="ant-form-item-explain-error">
                                        Please, add items
                                    </p>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="d-flex justify-content-end">
                            <Button
                                className="cans-sales-requests__add-request-form__add-request-btn text-white border-0 outline-none shadow-none"
                                onClick={handleAddNewItem}
                            >
                                +
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row className="mrg-ys-20 mrg-x-auto mt-2 justify-content-center">
                    <Space size={20}>
                        <SecondaryButton
                            buttonName={"Cancel"}
                            onClick={onCancel}
                        />
                        <PrimaryButton
                            buttonName={"Save"}
                            onClick={validateItems}
                            loading={submitting}
                            type="submit"
                        />
                    </Space>
                </Row>
            </Form>
        </Modal>
    );
}

export default AddRequestSupplyType;