import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { logger } from "redux-logger";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import authorizationSlice from "./authorization";
import dynamicDataSlice from "./dynamicData";
import langSlice from "./lang";
import staticDataSlice from "./staticData";
import chatSlice from "./chatSlice";

const persistConfig = {
  key: "Scraption-Admin",
  version: 1,
  storage,
};

const reducer = {
  auth: authorizationSlice,
  lang: langSlice,
  staticData: staticDataSlice,
  dynamicData: dynamicDataSlice,
  chat: chatSlice
};

const rootReducer = combineReducers(reducer);
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(logger),
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
