import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, Form, InputNumber, Radio, Row, Select } from "antd";
import { handleAllowNumbersOnly } from "../../../helpers/helpfulFunctions";

const FormItem = ({
    data,
    onUpdateItem,
    onDelete,
    supplyingTypes,
    quantityUnits,
    order,
}) => {
    const validateQuantity = (_, value) => {
        if (!value && value !== 0) {
            return Promise.reject("Please, enter the quantity")
        } else if (value <= 0) {
            return Promise.reject("Please, enter a valid number")
        } else {
            return Promise.resolve()
        }
    }

    const validateDeductionPercentage = (_, value) => {
        if (!value && value !== 0) {
            return Promise.reject("Please, enter the deduction percentage")
        } else if (value < 0 || value > 100) {
            return Promise.reject("Please, enter a valid number")
        } else {
            return Promise.resolve()
        }
    }

    return (
        <div className="cans-sales-requests__add-request-form__item">
            {order > 0 ? (
                <div className="d-flex justify-content-end align-items-center mb-3">
                    <Button
                        type="button"
                        onClick={() => onDelete(data?.id)}
                        className="d-block p-0 border-0 outline-none bg-transparent shadow-none"
                    >
                        <CloseOutlined style={{ color: "#f22626", fontSize: "16px" }} />
                    </Button>
                </div>
            ) : (
                ""
            )}
            <Row>
                <Col span={24}>
                    <div className="lang-name wd-100 flex">
                        <label className="text--secondary-1 mb-2 fw-semibold" htmlFor="">
                            Supplying Type
                        </label>
                    </div>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Please, select a supply type",
                            },
                        ]}
                        name={`supplyTypeId-${data?.id}`}
                        className="new-category__form-item"
                    >
                        <Select
                            showSearch
                            optionFilterProp="label"
                            name={`supplyTypeId-${data?.id}`}
                            className="new-category__form-item"
                            placeholder="Select supplying type"
                            onChange={(value) => onUpdateItem(data?.id, "supplyTypeId", value)}
                            loading={supplyingTypes ? false : true}
                            options={supplyingTypes ? supplyingTypes : []}
                            getPopupContainer={trigger => trigger.parentElement}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <div className="lang-name wd-100 flex">
                        <label className="text--secondary-1 mb-0 fw-semibold" htmlFor="">
                            Enter quantity by
                        </label>
                    </div>
                    <div className="mb-3">
                        <Radio.Group
                            options={quantityUnits}
                            className="cans-sales-requests__add-request-form__item__units-radio"
                            value={data?.unitId}
                            onChange={(e) => onUpdateItem(data?.id, "unitId", e.target.value)}
                        />
                    </div>
                </Col>
                <Col span={24}>
                    <div className="lang-name wd-100 flex">
                        <label className="text--secondary-1 mb-2 fw-semibold" htmlFor="">
                            Quantity
                        </label>
                    </div>
                    <Form.Item
                        className="new-category__form-item w-100"
                        name={`quantity-${data?.id}`}
                        rules={[
                            { validator: validateQuantity }
                        ]}
                    >
                        <InputNumber
                            className="w-100 d-block search__searchField"
                            placeholder="Enter quantity"
                            onKeyDown={(e) => handleAllowNumbersOnly(e, true)}
                            controls={false}
                            keyboard={false}
                            maxLength={8}
                            value={data?.quantity}
                            onChange={(value) => onUpdateItem(data?.id, "quantity", value)}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <div className="lang-name wd-100 flex">
                        <label className="text--secondary-1 mb-2 fw-semibold" htmlFor="">
                            Deduction Percentage
                        </label>
                    </div>
                    <Form.Item
                        className="new-category__form-item w-100"
                        name={`deductionPercentage-${data?.id}`}
                        rules={[
                            { validator: validateDeductionPercentage }
                        ]}
                    >
                        <InputNumber
                            className="w-100 d-block search__searchField"
                            placeholder="Enter deduction percentage"
                            onKeyDown={(e) => handleAllowNumbersOnly(e, true)}
                            controls={false}
                            keyboard={false}
                            value={data?.deductionPercentage}
                            onChange={(value) => onUpdateItem(data?.id, "deductionPercentage", value)}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}

export default FormItem;