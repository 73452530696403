const BannerIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48" >
            <g>
                <path d="M29.36 11.08A3 3 0 1 0 26.38 14a2.98 2.98 0 0 0 2.98 -2.92Z" strokeWidth="1"></path>
                <path d="M22.24 25.8a0.38 0.38 0 0 0 0.34 0.2h18a0.42 0.42 0 0 0 0.34 -0.2 0.38 0.38 0 0 0 0 -0.38l-6.46 -10.34a0.7 0.7 0 0 0 -0.62 -0.36 0.76 0.76 0 0 0 -0.64 0.36l-3.62 5.78a0.34 0.34 0 0 1 -0.32 0.18 0.32 0.32 0 0 1 -0.3 -0.18l-1.3 -2a0.74 0.74 0 0 0 -0.64 -0.34 0.74 0.74 0 0 0 -0.62 0.34l-4.14 6.62a0.38 0.38 0 0 0 -0.02 0.32Z" strokeWidth="1"></path>
                <path d="M48 8.82A4 4 0 0 0 46.82 6L42 1.18A4 4 0 0 0 39.18 0H16a4 4 0 0 0 -4 4v15.74a0.5 0.5 0 0 0 0.66 0.46 5.08 5.08 0 0 1 2.68 0A0.5 0.5 0 0 0 16 19.74V5a1 1 0 0 1 1 -1h21.76a0.94 0.94 0 0 1 0.7 0.3l4.24 4.24a0.94 0.94 0 0 1 0.3 0.7V36a1 1 0 0 1 -1 1h-10.68c-0.44 0 -0.56 0.48 -0.62 0.72A4.36 4.36 0 0 1 30 40c-0.4 0.32 -0.48 0.96 0.52 0.96H44a4 4 0 0 0 4 -4Z" strokeWidth="1"></path>
                <path d="M14.64 24.24a0.98 0.98 0 0 0 -1.28 0l-13 11a1 1 0 0 0 0.64 1.76h2.5a0.5 0.5 0 0 1 0.5 0.5v9.5a1 1 0 0 0 1 1H10a1 1 0 0 0 1 -1v-4.5a3 3 0 0 1 6 0v4.5a1 1 0 0 0 1 1h5a1 1 0 0 0 1 -1v-9.5a0.5 0.5 0 0 1 0.5 -0.5h2.5a1 1 0 0 0 0.64 -1.76Z" strokeWidth="1"></path>
            </g>
        </svg>
    );
}

export default BannerIcon;