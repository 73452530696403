import { useEffect, useState } from "react";
import { Col, Form, Input, Modal, Row, Space, Switch, Upload, Select } from "antd";
import ImgCrop from "antd-img-crop";
import { toastError, toastSuccess } from "../../helpers/toasters";
import { addStoreCategory, editStoreCategory, getAllPartnersWithoutPagination, getPartnersForList } from "../../apis/Network";
import { baseUrl } from "../../apis/services";
import { arabicNameValidation } from "../../constants/RegexValidations";

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";

const StoreCategoryForm = ({
    open,
    mode,
    rowData = {},
    SubHeader,
    primaryBtnName,
    secondaryBtnName,
    onSubmit,
    onCancel
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [categoryImage, setCategoryImage] = useState("");
    const [partnersList, setPartnersList] = useState(null);
    const [allActiveDeactivePartners, setAllActiveDeactivePartners] = useState(null);
    const [partnersListLoading, setPartnersListLoading] = useState(true);
    const [invalidImage, setInvalidImage] = useState(false)
    const [previewImage, setPreviewImage] = useState("");
    const [form] = Form.useForm();
    const initialValues = {
        nameEn: "",
        nameAr: "",
        image: "",
        isActive: true,
        partners: undefined
    }
    const props = {
        name: 'image',
        accept: ".png,.jpg,.jpeg,.bmp,.gif,.svg",
        maxCount: 1,
        showUploadList: false,
        onChange(info) {
            if (!info.file.type.startsWith("image") || info.file.type.endsWith("gif")) {
                handleRemoveImage();
                form.setFields([
                    {
                        name: "image",
                        errors: ["Unsupported image format. Please upload another image"],
                        value: "",
                    }
                ])
                return;
            }
            handlePreview(info.file)
        },
    };

    const handleBeforeCrop = (file) => {
        if (!file.type.startsWith("image") || file.type.endsWith("gif")) {
            handleRemoveImage();
            return false;
        } else {
            return true
        }
    }

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handlePreview = async (file) => {
        setCategoryImage(file.originFileObj);
        form.setFields([{ name: "image", value: file.originFileObj, errors: [] }])
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
    };

    const handleRemoveImage = () => {
        setPreviewImage("");
        setCategoryImage("");
        setInvalidImage(true)
    };

    const addCategory = (payload) => {
        addStoreCategory(
            payload,
            (res) => {
                setSubmitting(false);
                if (res.success) {
                    toastSuccess(res.message);
                    onSubmit();
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setSubmitting(false);
                toastError(res.message);
            }
        );
    }

    const editCategory = (payload) => {
        editStoreCategory(
            payload,
            (success) => {
                setSubmitting(false);
                if (success.success) {
                    toastSuccess(success.message);
                    onSubmit();
                } else {
                    toastError(success.message);
                }
            },
            (fail) => {
                setSubmitting(false);
                toastError(fail.message);
            }
        );
    }

    const handleOnFinish = (values) => {
        if (submitting) return;
        setSubmitting(true);

        const formData = new FormData();
        const payload = {
            Name: values.nameEn.trim(),
            NameAr: values.nameAr.trim(),
            IsActive: values.isActive,
            partners: values.partners || []
        }
        if (mode === "edit") {
            payload.Id = rowData.id;
            const selectedPartners = values.partners?.map((ele) => {
                let partnerOfName = allActiveDeactivePartners.find((partner) => partner.nameEn === ele)
                if (partnerOfName) {
                    return partnerOfName.id
                } else {
                    return ele
                }
            })
            payload.partners = selectedPartners || []
        }
        if (categoryImage) {
            payload.Image = categoryImage
        }
        for (const key in payload) {
            if (payload.hasOwnProperty(key)) {
                if (key === "partners") {
                    payload[key].forEach((partnerId, indx) => {
                        formData.append(`partners[${indx}]`, partnerId);
                    })
                } else {
                    formData.append(key, payload[key]);
                }
            }
        }
        if (mode === "add") {
            addCategory(formData)
        } else {
            editCategory(formData)
        }
    };

    useEffect(() => {
        getPartnersForList(
            (res) => {
                setPartnersListLoading(false)
                if (res.success) {
                    const options = res.data.map((partner) => {
                        return { value: partner.id, label: partner.name };
                    });
                    setPartnersList(options)
                } else {
                    toastError(res.message)
                }
            },
            (res) => {
                setPartnersListLoading(false)
                toastError(res.message)
            }
        )
    }, [])

    useEffect(() => {
        if (rowData && mode === "edit") {
            form.setFieldsValue({
                nameEn: rowData.nameEn,
                nameAr: rowData.nameAr,
                image: rowData.image,
                isActive: rowData.isActive,
            });
        }
    }, [rowData, mode, form]);

    useEffect(() => {
        if (!rowData || !partnersList || mode !== "edit") return;
        getAllPartnersWithoutPagination(
            (res) => {
                if (res.success) {
                    setAllActiveDeactivePartners(res.data)
                    const selectedPartners = rowData.partners.map((partId) => {
                        const isPartnerActive = partnersList.find((partner) => partner.value === partId);
                        if (isPartnerActive) {
                            return partId
                        } else {
                            const partnerName = res.data.find((partner) => partner.id === partId).nameEn
                            return partnerName
                        }
                    })
                    form.setFieldValue("partners", selectedPartners)
                } else {
                    toastError(res.message)
                }
            },
            (res) => {
                toastError(res?.data?.error)
            }
        )
    }, [rowData, partnersList])

    return (
        <Modal
            title={SubHeader}
            open={open}
            className="msg-modal"
            footer={false}
            closable={false}
            centered
            destroyOnClose
        >
            <Form
                form={form}
                className="edit-actor__content store-items__item-form pt-3"
                onFinish={handleOnFinish}
                initialValues={initialValues}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <div className="lang-name wd-100 flex ">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Category Image
                                </label>
                            </div>
                            {previewImage ? (
                                <img src={previewImage} alt="" className="store-items__item-form__item-preview-img d-block img-fluid mb-2" />
                            ) : mode === "edit" && !invalidImage ? (
                                <img src={baseUrl + rowData?.image} alt="" className="store-items__item-form__item-preview-img d-block img-fluid mb-2" />
                            ) : (
                                ""
                            )}
                            <Form.Item
                                name="image"
                                rules={[
                                    { required: true, message: "Please, upload an image for the category" }
                                ]}
                                className="w-100"
                            >
                                <ImgCrop beforeCrop={handleBeforeCrop} fillColor="transparent" quality={1} cropperProps={{ restrictPosition: false }} minZoom={0.5} rotationSlider aspectSlider>
                                    <Upload {...props}>
                                        Upload Image +
                                    </Upload>
                                </ImgCrop>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2" htmlFor="">
                                Name (EN)
                            </label>
                            <Form.Item
                                className="new-category__form-item"
                                name="nameEn"
                                validateFirst
                                rules={[
                                    {
                                        required: true,
                                        message: "Please, enter category name in English",
                                    },
                                    {
                                        min: 3,
                                        message: "Minimum length is 3 characters"
                                    },
                                    {
                                        pattern: /^[a-z][a-z\s\d$!@#%^&*()_+-=\[\]/{}\\|;':",./<>?~`]*[a-z\d$!@#%^&*()_+-=\[\]/{}\\|;':",./<>?~`]$/i,
                                        message: "Please, enter category name in English",
                                    },
                                    {
                                        max: 50,
                                        message: "Maximum length is 50 characters"
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    className="search__searchField py-2"
                                    placeholder="Enter Name in English"
                                    maxLength={50}
                                    showCount
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2" htmlFor="">
                                Name (AR)
                            </label>
                            <Form.Item
                                className="new-category__form-item"
                                name="nameAr"
                                validateFirst
                                rules={[
                                    {
                                        required: true,
                                        message: "Please, enter category name in Arabic",
                                    },
                                    {
                                        min: 3,
                                        message: "Minimum length is 3 characters"
                                    },
                                    {
                                        pattern: arabicNameValidation,
                                        message: "Please, enter category name in Arabic",
                                    },
                                    {
                                        max: 50,
                                        message: "Maximum length is 50 characters"
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    className="search__searchField py-2"
                                    placeholder="Enter Name in Arabic"
                                    maxLength={50}
                                    dir="rtl"
                                    showCount
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2" htmlFor="">
                                Partners
                            </label>
                            <Form.Item
                                className="new-category__form-item"
                                name="partners"
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    mode="multiple"
                                    optionFilterProp="label"
                                    placeholder="Select partners"
                                    loading={partnersListLoading}
                                    options={partnersList ? partnersList : []}
                                    getPopupContainer={(trigger) => trigger.parentElement}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name mb-4 row mx-0 align-items-center">
                            <div className="col-4 px-0 text-start">
                                <label className="users__parent-info-label">Active</label>
                            </div>
                            <div className="col-8 px-0 d-flex">
                                <Form.Item
                                    name="isActive"
                                    valuePropName="checked"
                                    className="mb-0"
                                >
                                    <Switch />
                                </Form.Item>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mrg-ys-20 mrg-x-auto justify-content-center">
                    <Space size={20}>
                        <SecondaryButton
                            buttonName={secondaryBtnName}
                            onClick={onCancel}
                        />
                        <PrimaryButton
                            buttonName={primaryBtnName}
                            loading={submitting}
                            type="submit"
                            disableMe={partnersListLoading}
                        />
                    </Space>
                </Row>
            </Form>
        </Modal >
    )
}

export default StoreCategoryForm;