// components
import MapPicker from "../../../../Stores/MapPicker";

const MessageLocation = ({ data }) => {
    return (
        <a
            className="chat__chat-holder__body__message__holder__location d-block"
            href={`https://maps.google.com?q=${data?.latitude},${data?.longitude}`}
        >
            <MapPicker
                initialCoords={{ lat: data?.latitude, lng: data?.longitude }}
                disableControllers
            />
        </a>
    );
}

export default MessageLocation;