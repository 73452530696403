import { useEffect, useRef, useState } from "react";
import { Button, Input, Space, DatePicker } from "antd";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { getCollectionTypes, getCourierReport } from "../../apis/Network";
import { toastError } from "../../helpers/toasters";
import { exportDataAsExcel, handleAllowNumbersOnly } from "../../helpers/helpfulFunctions";

// components
import GridTable from "../SubComponents/GridTable/GridTable";
import MainHeader from "../SubComponents/MainHeader";

const CourierReport = () => {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isExporting, setIsExporting] = useState(false);
  const [selectedRange, setSelectedRange] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [collectionTypes, setCollectionTypes] = useState([]);
  const [searchValues, setSearchValues] = useState({
    perPage: 10,
    userName: "",
    userMobile: "",
    totalQuantity: "",
    totalPounds: "",
    totalPoints: "",
    supplyType: "",
    courierName: "",
    courierMobile: "",
    collectionType: null,
    cashToUser: "",
    from: "",
    to: "",
    acceptedBy: "",
    deductionPercentage: ""
  })
  const { RangePicker } = DatePicker;
  const searchInput = useRef(null);

  const handlePageSizeChange = (current, newSize) => {
    setLoading(true)
    setPageSize(newSize)
  }

  const updateSearchValues = (property, newValue, otherProperty) => {
    const copyOfSearchObj = { ...searchValues }
    copyOfSearchObj[property] = newValue;
    if (otherProperty) {
      copyOfSearchObj[otherProperty.property] = otherProperty.value
    }
    setSearchValues(copyOfSearchObj)
  }
  const handleTableFilters = (_, filters,) => {
    if (filters.requestType) {
      if (searchValues.collectionType) {
        const filterArrToStr = [...filters.requestType].sort((x, y) => x - y).join("")
        const currentArrToStr = [...searchValues.collectionType].sort((x, y) => x - y).join("")
        if (filterArrToStr !== currentArrToStr) {
          updateSearchValues("collectionType", filters.requestType)
        }
      } else {
        updateSearchValues("collectionType", filters.requestType)
      }
    } else if (searchValues.collectionType) {
      updateSearchValues("collectionType", null)
    }
  }
  const handleDateChange = (dates, dateStr) => {
    setSelectedRange(dates);
    if (dates) {
      const startDate = dates[0].format("YYYY-MM-DD");
      const endDate = dates[1].format("YYYY-MM-DD");
      updateSearchValues("from", startDate, { property: "to", value: endDate })
    } else {
      updateSearchValues("from", "", { property: "to", value: "" })
    }
  };
  const handlePageChange = (page) => {
    setLoading(true)
    setCurrentPage(page)
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    const value = selectedKeys[0]?.trim() || ""
    switch (dataIndex) {
      case "userName":
        updateSearchValues("userName", value)
        break;
      case "userPhone":
        updateSearchValues("userMobile", value)
        break;
      case "totalQuantityInGrams":
        updateSearchValues("totalQuantity", value)
        break;
      case "totalPounds":
        updateSearchValues("totalPounds", value)
        break;
      case "totalPoints":
        updateSearchValues("totalPoints", value)
        break;
      case "type":
        updateSearchValues("supplyType", value)
        break;
      case "courierName":
        updateSearchValues("courierName", value)
        break;
      case "courierPhoneNumber":
        updateSearchValues("courierMobile", value)
        break;
      case "requestType":
        updateSearchValues("collectionType", value)
        break;
      case "cashAmount":
        updateSearchValues("cashToUser", value)
        break;
      case "acceptedBy":
        updateSearchValues("acceptedBy", value)
        break;
      case "deductionPercentage":
        updateSearchValues("deductionPercentage", value)
        break;
      default:
        break;
    }
    confirm();
  };
  const handleReset = (clearFilters, dataIndex, confirm) => {
    // setSearchText("");
    setLoading(true);
    switch (dataIndex) {
      case "userName":
        updateSearchValues("userName", "")
        break;
      case "userPhone":
        updateSearchValues("userMobile", "")
        break;
      case "totalQuantityInGrams":
        updateSearchValues("totalQuantity", "")
        break;
      case "totalPounds":
        updateSearchValues("totalPounds", "")
        break;
      case "totalPoints":
        updateSearchValues("totalPoints", "")
        break;
      case "type":
        updateSearchValues("supplyType", "")
        break;
      case "courierName":
        updateSearchValues("courierName", "")
        break;
      case "courierPhoneNumber":
        updateSearchValues("courierMobile", "")
        break;
      case "requestType":
        updateSearchValues("collectionType", "")
        break;
      case "cashAmount":
        updateSearchValues("cashToUser", "")
        break;
      case "acceptedBy":
        updateSearchValues("acceptedBy", "")
        break;
      case "deductionPercentage":
        updateSearchValues("deductionPercentage", "")
        break;
      default:
        break;
    }
    clearFilters();
    confirm();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onKeyDown={(e) => {
            const acceptsNumbersOnly = [
              "deductionPercentage",
              "cashAmount",
              "totalPoints",
              "totalPounds",
              "totalQuantityInGrams",
              "courierPhoneNumber",
              "userPhone",
            ]
            if (acceptsNumbersOnly.includes(dataIndex)) {
              handleAllowNumbersOnly(e, true)
            }
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, dataIndex, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          ></Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => true,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: () => (
      <div
        style={{
          padding: 8,
        }}
        className="range-picker-holder"
        onKeyDown={(e) => e.stopPropagation()}
      >
        <RangePicker
          value={selectedRange}
          allowClear
          popupClassName="main-date-picker"
          inputReadOnly={false}
          onChange={(dates, datesStr) => {
            setCurrentPage(1)
            handleDateChange(dates, datesStr)
          }}
          onKeyDown={(e) => e.preventDefault()}
        />
      </div>
    ),
    filterIcon: (filtered) => <FilterOutlined style={selectedRange?.length > 0 ? { color: "blue" } : {}} />,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });
  const columns = [
    {
      key: "courierName",
      title: "COURIER NAME",
      dataIndex: "courierName",
      fixed: "left",
      ...getColumnSearchProps("courierName"),
    },
    {
      key: "courierPhoneNumber",
      title: "COURIER MOBILE",
      dataIndex: "courierPhoneNumber",
      fixed: "left",
      ...getColumnSearchProps("courierPhoneNumber"),
    },
    {
      key: "userName",
      title: "USER NAME",
      dataIndex: "userName",
      ...getColumnSearchProps("userName"),
    },
    {
      key: "userPhone",
      title: "USER MOBILE",
      dataIndex: "userPhone",
      ...getColumnSearchProps("userPhone"),
    },
    {
      key: "type",
      title: "SUPPLY TYPE",
      dataIndex: "type",
      ...getColumnSearchProps("type"),
    },
    {
      key: "requestType",
      title: "COLLECTION TYPE",
      dataIndex: "requestType",
      filters: collectionTypes,
      onFilter: (value, record) => true,
    },
    {
      key: "totalQuantityInGrams",
      title: "QUANTITY IN GRAMS",
      dataIndex: "totalQuantityInGrams",
      ...getColumnSearchProps("totalQuantityInGrams"),
    },
    {
      key: "totalPoints",
      title: "MARKETPLACE POINTS",
      dataIndex: "totalPoints",
      ...getColumnSearchProps("totalPoints"),
    },
    {
      key: "totalPounds",
      title: "MARKETPLACE PRICE",
      dataIndex: "totalPounds",
      ...getColumnSearchProps("totalPounds"),
    },
    {
      key: "cashAmount",
      title: "CASH VALUE",
      dataIndex: "cashAmount",
      ...getColumnSearchProps("cashAmount"),
    },
    {
      key: "deductionPercentage",
      title: "DEDUCTION PERCENTAGE",
      dataIndex: "deductionPercentage",
      ...getColumnSearchProps("deductionPercentage"),
      render: (_, row) => {
        return <span>{row.deductionPercentage}%</span>;
      },
    },
    {
      key: "date",
      title: "REQUEST DATE",
      dataIndex: "date",
      ...getColumnDateProps("date"),
    },
    {
      key: "acceptedBy",
      title: "ACCEPTED BY",
      dataIndex: "acceptedBy",
      ...getColumnSearchProps("acceptedBy"),
    },
  ];

  const handleExportDataToExcel = () => {
    if (isExporting) return;
    setIsExporting(true)
    const baseProps = [
      { title: "Courier Name", prop: "courierName" },
      { title: "Courier Mobile", prop: "courierPhoneNumber" },
      { title: "User Name", prop: "userName" },
      { title: "User Mobile", prop: "userPhone" },
      { title: "Supply Type", prop: "type" },
      { title: "Collection Type", prop: "requestType" },
      { title: "Quantity In Grams", prop: "totalQuantityInGrams" },
      { title: "Marketplace Points", prop: "totalPoints" },
      { title: "Marketplace Price", prop: "totalPounds" },
      { title: "Cash Value", prop: "cashAmount" },
      { title: "Deduction Percentage", prop: "deductionPercentage" },
      { title: "Request Date", prop: "date" },
      { title: "Accepted By", prop: "acceptedBy" },
    ]
    const payload = {
      isExport: true,
      ...searchValues,
    };
    for (let property in payload) {
      if (!payload[property] && payload[property] !== 0) {
        delete payload[property];
      }
    }
    getCourierReport(
      payload,
      (res) => {
        if (res.success) {
          exportDataAsExcel(res.data.response, { baseProps, tab: "Courier Report" })
          setIsExporting(false)
        }
      },
      (res) => {
        toastError(res?.data?.error);
        setIsExporting(false)
      }
    );
  }

  useEffect(() => {
    const payload = {
      pageNumber: currentPage,
      perPage: pageSize,
      ...searchValues
    }
    for (let property in payload) {
      if (!payload[property] && payload[property] !== 0) {
        delete payload[property]
      }
    }
    getCourierReport(
      payload,
      (res) => {
        setLoading(false);
        if (res.success) {
          setDataSource(res.data.response);
          setTotalCount(res.data.totalCount);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setLoading(false);
        toastError(res.message);
      }
    );
  }, [searchValues, currentPage]);

  useEffect(() => {
    getCollectionTypes(
      (res) => {
        const filterArr = res.data.map((obj) => (
          { text: obj.name, value: obj.id }
        ))
        setCollectionTypes(filterArr)
      },
      (res) => {
        toastError(res?.data?.error)
      })
  }, [])

  return (
    <section className="users">
      <div className="users__all-contents">
        <div className="users__content">
          <div className="users__usersData">
            <div className="pb-3 mb-3">
              <MainHeader title="Courier Report" />
            </div>
            <div className="users__table grid-table mrg-y-30">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                rowId={"requestId"}
                title={"Courier Report"}
                allowExport
                isExporting={isExporting}
                onExport={handleExportDataToExcel}
                currentPage={currentPage}
                totalCount={totalCount}
                onPageChange={handlePageChange}
                onChange={handleTableFilters}
                pageSize={pageSize}
                onPageSizeChange={handlePageSizeChange}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CourierReport;
