import { useEffect, useState } from "react";
import { Col, Form, Modal, Row, Space, Switch, InputNumber } from "antd";
import { toastError, toastSuccess } from "../../helpers/toasters";
import { addMerchantPeriods, editMerchantPeriods } from "../../apis/Network";
import { handleAllowNumbersOnly } from "../../helpers/helpfulFunctions";

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";

const PeriodModal = ({
    open,
    mode,
    rowData = {},
    SubHeader,
    onSubmit,
    onCancel,
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm();
    const initialValues = {
        period: "",
        isActive: true,
    };

    const handleAddPeriod = (payload) => {
        addMerchantPeriods(
            payload,
            (res) => {
                setSubmitting(false);
                if (res.success) {
                    toastSuccess(res.message);
                    onSubmit();
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setSubmitting(false);
                toastError(res.message);
            }
        );
    };

    const handleEditPeriod = (payload) => {
        editMerchantPeriods(
            payload,
            (res) => {
                setSubmitting(false);
                if (res.success) {
                    toastSuccess(res.message);
                    onSubmit();
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setSubmitting(false);
                toastError(res.message);
            }
        );
    };

    const handleOnFinish = (values) => {
        if (submitting) return;
        setSubmitting(true);

        const payload = {
            days: values.period,
            isActive: values.isActive,
        };
        if (mode === "add") {
            handleAddPeriod(payload);
        } else {
            payload.Id = rowData.id;
            handleEditPeriod(payload);
        }
    };

    const handlePeriodValidation = (_, value) => {
        if (!value && value !== 0) {
            return Promise.reject(new Error("Please, Enter the period"))
        } else if (value < 0 || parseInt(value) !== value) {
            return Promise.reject(new Error(`Please, Enter a valid period`));
        } else if (value > 365) {
            return Promise.reject(new Error(`Maximum value should be less than or equal to 365 days`));
        } else {
            return Promise.resolve()
        }
    }

    useEffect(() => {
        if (rowData && mode === "edit") {
            form.setFieldsValue({
                period: rowData.days,
                isActive: rowData.isActive,
            });
        }
    }, [rowData, mode, form]);

    return (
        <Modal
            title={SubHeader}
            open={open}
            className="msg-modal"
            footer={false}
            closable={false}
            centered
            destroyOnClose
        >
            <Form
                form={form}
                className="edit-actor__content store-items__item-form"
                onFinish={handleOnFinish}
                initialValues={initialValues}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <div className="lang-name wd-100 flex ">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Period
                                </label>
                            </div>
                            <Form.Item
                                className="new-category__form-item"
                                name="period"
                                rules={[
                                    { validator: handlePeriodValidation }
                                ]}
                            >
                                <InputNumber
                                    className="w-100 d-block search__searchField py-1"
                                    placeholder="Enter Period"
                                    onKeyDown={handleAllowNumbersOnly}
                                    controls={false}
                                    keyboard={false}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name mb-4 row mx-0 align-items-center">
                            <div className="col-4 px-0 text-start">
                                <label className="users__parent-info-label">Active</label>
                            </div>
                            <div className="col-8 px-0 d-flex">
                                <Form.Item
                                    name="isActive"
                                    valuePropName="checked"
                                    className="mb-0"
                                >
                                    <Switch />
                                </Form.Item>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mrg-ys-20 mrg-x-auto justify-content-center">
                    <Space size={20}>
                        <SecondaryButton buttonName={"Cancel"} onClick={onCancel} />
                        <PrimaryButton
                            buttonName={mode === "add" ? "Add" : "Save"}
                            loading={submitting}
                            type="submit"
                        />
                    </Space>
                </Row>
            </Form>
        </Modal>
    );
}

export default PeriodModal;