import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { theFirstEncounteredLetterIsArabic } from "helpers/RegexPatterns";

// components
import MessageImg from "./MessageImgVideo";
import MessageDocument from "./MessageDocument";
import MessageLocation from "./MessageLocation";
import MessageAudio from "./MessageAudio";
import MessageTimestamp from "./MessageTimestamp";
import Highlighter from "react-highlight-words";

const ChatMessage = ({
    data,
    firstMessageOfSequence,
    userName,
    playingAudio,
    onPlayingAudio,
    searchValue
}) => {
    const [messageOfCurrentUser, setMessageOfCurrentUser] = useState(false);
    const [messageDir, setMessageDir] = useState("ltr");
    const [timeOfMsg, setTimeOfMsg] = useState("");
    const { authorization } = useSelector((state) => state.auth);

    useEffect(() => {
        if (data && authorization) {
            setTimeOfMsg(dayjs(data.date).format("HH:mm"))
            if (data.adminId === authorization.id) {
                setMessageOfCurrentUser(true)
            } else {
                setMessageOfCurrentUser(false)
            }
            // set last message direction 
            if (theFirstEncounteredLetterIsArabic.test(data.message)) {
                setMessageDir("rtl");
            }
        }
    }, [data, authorization])

    return (
        <>
            {firstMessageOfSequence ? (
                <span
                    dir={data?.adminName ? "rtl" : "ltr"}
                    className="text--primary-1 fw-bold"
                >
                    {messageOfCurrentUser ? "You" : data.adminName || userName}
                </span>
            ) : (
                ""
            )}
            <div className={`chat__chat-holder__body__message d-flex ${data?.adminId ? "justify-content-end" : "gap-2"}`}>
                <div className={`chat__chat-holder__body__message__holder ${data?.adminId ? "chat__chat-holder__body__message__holder--sent-by-current" : ""}`}>
                    {data?.type === 1 ? (
                        <p dir={messageDir} className="chat__chat-holder__body__message__holder__message mb-1 fw-bold fsize-11">
                            <Highlighter
                                highlightStyle={{
                                    backgroundColor: "#ffc069",
                                    padding: 0,
                                }}
                                searchWords={[searchValue]}
                                autoEscape
                                textToHighlight={data?.message}
                            />
                        </p>
                    ) : data?.type === 2 || data?.type === 5 ? (
                        <MessageImg data={data} />
                    ) : data?.type === 3 ? (
                        <MessageAudio
                            data={data}
                            active={data?.message === playingAudio}
                            onPlaying={onPlayingAudio}
                            fromUser={!data?.adminId}
                            timeOfMsg={timeOfMsg}
                        />
                    ) : data?.type === 4 ? (
                        <MessageDocument data={data} searchValue={searchValue} />
                    ) : data?.type === 6 ? (
                        <MessageLocation data={data} />
                    ) : (
                        ""
                    )}
                    {data?.type !== 3 ? (
                        <MessageTimestamp type={data?.type} time={timeOfMsg} />
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </>
    );
}

export default ChatMessage;