const CourierIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4731_28958)">
                <path d="M52 9C54.2091 9 56 7.20914 56 5C56 2.79086 54.2091 1 52 1C49.7909 1 48 2.79086 48 5C48 7.20914 49.7909 9 52 9Z" />
                <path d="M28 18H7C5.34315 18 4 19.3431 4 21V22C4 23.6569 5.34315 25 7 25H28C29.6569 25 31 23.6569 31 22V21C31 19.3431 29.6569 18 28 18Z" />
                <path d="M16 45C20.4183 45 24 41.4183 24 37C24 32.5817 20.4183 29 16 29C11.5817 29 8 32.5817 8 37C8 41.4183 11.5817 45 16 45Z" />
                <path d="M53 45C57.4183 45 61 41.4183 61 37C61 32.5817 57.4183 29 53 29C48.5817 29 45 32.5817 45 37C45 41.4183 48.5817 45 53 45Z" />
                <path d="M53 41C55.2091 41 57 39.2091 57 37C57 34.7909 55.2091 33 53 33C50.7909 33 49 34.7909 49 37C49 39.2091 50.7909 41 53 41Z" />
                <path d="M45 6H39C38.7348 6 38.4804 5.89464 38.2929 5.70711C38.1054 5.51957 38 5.26522 38 5C38 4.73478 38.1054 4.48043 38.2929 4.29289C38.4804 4.10536 38.7348 4 39 4H45C45.2652 4 45.5196 4.10536 45.7071 4.29289C45.8946 4.48043 46 4.73478 46 5C46 5.26522 45.8946 5.51957 45.7071 5.70711C45.5196 5.89464 45.2652 6 45 6Z" />
                <path d="M14 2H2C0.89543 2 0 2.89543 0 4V16C0 17.1046 0.89543 18 2 18H14C15.1046 18 16 17.1046 16 16V4C16 2.89543 15.1046 2 14 2Z" />
                <path d="M16 4V6H0V4C0 3.46957 0.210714 2.96086 0.585786 2.58579C0.960859 2.21071 1.46957 2 2 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4Z" />
                <path d="M53.34 24.23L52.81 24.13L48.63 10H52C52.2652 10 52.5196 9.89464 52.7071 9.70711C52.8946 9.51957 53 9.26522 53 9V1C53 0.734784 52.8946 0.48043 52.7071 0.292893C52.5196 0.105357 52.2652 0 52 0L45 0C43.8524 0.00135042 42.7401 0.397459 41.85 1.12184C40.9598 1.84621 40.3459 2.85476 40.1114 3.97819C39.8769 5.10161 40.0359 6.27152 40.5619 7.29154C41.0879 8.31156 41.9488 9.11959 43 9.58V31H29.91C29.5136 27.5821 27.8271 24.4446 25.195 22.2284C22.563 20.0122 19.184 18.8847 15.7485 19.0762C12.3131 19.2677 9.08037 20.7638 6.71091 23.2587C4.34146 25.7537 3.01409 29.0592 3 32.5V37C3 37.2652 3.10536 37.5196 3.29289 37.7071C3.48043 37.8946 3.73478 38 4 38H63C63.2652 38 63.5196 37.8946 63.7071 37.7071C63.8946 37.5196 64 37.2652 64 37C63.9959 33.9611 62.9273 31.0197 60.9798 28.6868C59.0324 26.3539 56.3293 24.777 53.34 24.23Z" />
                <path d="M24 35H8V33C8 30.8783 8.84285 28.8434 10.3431 27.3431C11.8434 25.8429 13.8783 25 16 25C18.1217 25 20.1566 25.8429 21.6569 27.3431C23.1571 28.8434 24 30.8783 24 33V35Z" />
                <path d="M5 8C4.73478 8 4.48043 7.89464 4.29289 7.70711C4.10536 7.51957 4 7.26522 4 7V5C4 4.73478 4.10536 4.48043 4.29289 4.29289C4.48043 4.10536 4.73478 4 5 4C5.26522 4 5.51957 4.10536 5.70711 4.29289C5.89464 4.48043 6 4.73478 6 5V7C6 7.26522 5.89464 7.51957 5.70711 7.70711C5.51957 7.89464 5.26522 8 5 8ZM11 8C10.7348 8 10.4804 7.89464 10.2929 7.70711C10.1054 7.51957 10 7.26522 10 7V5C10 4.73478 10.1054 4.48043 10.2929 4.29289C10.4804 4.10536 10.7348 4 11 4C11.2652 4 11.5196 4.10536 11.7071 4.29289C11.8946 4.48043 12 4.73478 12 5V7C12 7.26522 11.8946 7.51957 11.7071 7.70711C11.5196 7.89464 11.2652 8 11 8Z" />
                <path d="M21 22C24.3137 22 27 19.3137 27 16C27 12.6863 24.3137 10 21 10C17.6863 10 15 12.6863 15 16C15 19.3137 17.6863 22 21 22Z" />
                <path d="M34.2002 34C34.2002 34 29.4202 25.39 29.3502 25.29C29.0854 24.9075 28.7352 24.5918 28.3274 24.3679C27.9195 24.144 27.4652 24.0181 27.0002 24H15.0002C14.4766 24.0171 13.9667 24.1711 13.521 24.4465C13.0754 24.722 12.7097 25.1093 12.4602 25.57L7.80025 34C7.53445 34.5145 7.47439 35.1109 7.63225 35.668C7.79012 36.2252 8.15407 36.7014 8.65025 37L14.2602 40.27C13.9302 47.59 14.0002 42.84 14.0002 62C13.9671 62.5304 14.146 63.0523 14.4976 63.4508C14.8493 63.8493 15.3448 64.0918 15.8752 64.125C16.4057 64.1582 16.9276 63.9792 17.3261 63.6276C17.7246 63.276 17.9671 62.7804 18.0002 62.25C18.0002 62.25 20.0002 46.06 20.0002 46H22.0002C22.0002 46.06 24.0002 62.25 24.0002 62.25C24.0334 62.7804 24.2759 63.276 24.6744 63.6276C25.0729 63.9792 25.5948 64.1582 26.1252 64.125C26.6557 64.0918 27.1512 63.8493 27.5028 63.4508C27.8545 63.0523 28.0334 62.5304 28.0002 62C28.0002 42.78 28.0602 47.42 27.7402 40.29L33.3502 37C33.8464 36.7014 34.2104 36.2252 34.3682 35.668C34.5261 35.1109 34.466 34.5145 34.2002 34ZM13.3402 34.52L14.6002 32.8L14.4802 35.38L13.3402 34.52ZM27.5202 35.38L27.4002 32.8L28.6602 34.52L27.5202 35.38Z" />
                <path d="M28 35H14C13.4477 35 13 35.4477 13 36V38C13 38.5523 13.4477 39 14 39H28C28.5523 39 29 38.5523 29 38V36C29 35.4477 28.5523 35 28 35Z" />
                <path d="M28 31H14C13.4477 31 13 31.4477 13 32V34C13 34.5523 13.4477 35 14 35H28C28.5523 35 29 34.5523 29 34V32C29 31.4477 28.5523 31 28 31Z" />
                <path d="M33.3504 37L27.1104 40.64C26.6703 40.9002 26.1473 40.9819 25.6488 40.8685C25.1503 40.7551 24.7142 40.455 24.43 40.0301C24.1459 39.6051 24.0352 39.0874 24.1208 38.5834C24.2064 38.0794 24.4818 37.6273 24.8904 37.32L28.0004 35H29.0004V35.38L33.3504 37ZM8.65039 37L14.8904 40.64C15.3305 40.9002 15.8535 40.9819 16.352 40.8685C16.8505 40.7551 17.2866 40.455 17.5708 40.0301C17.8549 39.6051 17.9656 39.0874 17.88 38.5834C17.7943 38.0794 17.519 37.6273 17.1104 37.32L14.0004 35H13.0004V35.38L8.65039 37Z" />
            </g>
            <defs>
                <clipPath id="clip0_4731_28958">
                    <rect width="64" height="64" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default CourierIcon;