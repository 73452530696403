import { useRef, useState } from "react";
import { useSelector } from 'react-redux';
import { Button, Input, Modal, Space, Image } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { baseUrl } from "../../apis/services";

// comonents
import GridTable from "../SubComponents/GridTable/GridTable";
import SubHeader from "modules/SubComponents/SubHeader";
import ConfirmRefundModal from "./ConfirmRefundModal";

const ViewStoreItem = ({ open, subHeader, onCancel, onSubmit, rowData }) => {
  const [itemData, setItemData] = useState("");
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchText, setSearchText] = useState("");
  const { roles } = useSelector((state) => state.auth.authorization);
  const searchInput = useRef(null);

  const handleCancelModal = () => {
    setIsRefundModalOpen(false)
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          ></Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text, record) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      key: "name",
      title: "NAME",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },
    {
      key: "image",
      title: "PHOTO",
      dataIndex: "image",
      render: (_, row) => {
        return (
          <Image
            src={baseUrl + row?.image}
            width={70}
            height={70}
            preview={{ src: baseUrl + row?.image }}
            className="redeem-points__view-details-modal__item-img img-fluid d-block mx-auto mb-4"
          />
        )
      }
    },
    {
      key: "quantity",
      title: "QUANTITY",
      dataIndex: "quantity",
      ...getColumnSearchProps("quantity"),
    },
    {
      key: "price",
      title: "UNIT PRICE",
      dataIndex: "price",
      ...getColumnSearchProps("price"),
    },
    {
      key: "totalPrice",
      title: "TOTAL PRICE",
      dataIndex: "totalPrice",
      ...getColumnSearchProps("totalPrice"),
    },
    {
      key: "link",
      title: "URL",
      dataIndex: "link",
      render: (_, row) => {
        return (
          <a
            target="_blank"
            rel="noreferrer"
            href={row?.link}
            className="dealers__balance-details-modal__total-details__value dealers__balance-details-modal__total-details__value--link"
          >
            Click here
          </a>
        )
      }
    },
    {
      key: "id",
      title: "ACTIONS",
      hidden: roles.includes(12) || roles.includes(15),
      render: (_, row) => {
        return (
          <div>
            <Button
              onClick={() => {
                setItemData(row);
                setIsRefundModalOpen(true);
              }}
              className="w-100 border-0"
              style={{
                backgroundColor: "rgb(233 98 44)",
                color: "white",
              }}
            >
              Refund
            </Button>
          </div >
        );
      },
    },
  ]

  return (
    <>
      <Modal
        title={subHeader}
        open={open}
        width={window.innerWidth >= 992 ? "66%" : "90%"}
        className="msg-modal redeem-points__view-details-modal cans-sales-requests__view-details"
        footer={false}
        closable={false}
        centered
        destroyOnClose
        onCancel={onCancel}
      >
        {rowData?.length ? (
          <GridTable
            columns={columns}
            dataSource={rowData}
            rowId={"itemId"}
          />
        ) : (
          <p className="mb-0 text-center py-5 fsize-4">All items are refunded</p>
        )}
      </Modal>
      {isRefundModalOpen && (
        <ConfirmRefundModal
          request={itemData}
          open={isRefundModalOpen}
          type={2}
          subHeader={
            <SubHeader
              bgColor="#589559"
              title={`Refund A Redeemed Product`}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          onSubmit={() => {
            handleCancelModal()
            onSubmit()
          }}
          onCancel={handleCancelModal}
        />
      )}
    </>
  );
};

export default ViewStoreItem;