import { useEffect, useState } from "react";
import { Col, Form, Input, Modal, Row, Space, Spin, Switch } from "antd";
import { toastError, toastSuccess } from "../../helpers/toasters";
import { addStore, editStore, getAddressFromGeolocation } from "../../apis/Network";

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";
import MapPicker from "./MapPicker";

const StoreModal = ({
    mode,
    rowData,
    SubHeader,
    onCancel,
    open,
    onSubmit
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedCoords, setSelectedCoords] = useState(null);
    const [loadingAddress, setLoadingAddress] = useState(false)
    const [physicalAddress, setPhysicalAddress] = useState("");
    const [form] = Form.useForm();
    const initialValues = {
        nameEn: "",
        nameAr: "",
        descriptionEn: "",
        descriptionAr: "",
        isActive: true,
    }

    const handleValidationAfterTrimming = (_, value) => {
        if (value && value.trim().length < 2) {
            return Promise.reject(new Error("Minimum length is 2 characters"))
        } else {
            return Promise.resolve()
        }
    }

    const handleAddressChange = (coords) => {
        setLoadingAddress(true)
        setSelectedCoords(coords)
        getAddressFromGeolocation(
            coords,
            (res) => {
                setLoadingAddress(false)
                if (res.address.country !== "Egypt") {
                    toastError("Outside Egypt")
                    setPhysicalAddress("")
                } else {
                    setPhysicalAddress(res.display_name)
                }
            },
            (res) => {
                setLoadingAddress(false)
                setPhysicalAddress("")
                if (res.status === 404) {
                    toastError("Please, select a valid location")
                } else {
                    toastError(res?.data?.error)
                }
            }
        )
    }

    const handleFinish = (values) => {
        if (isSubmitting || !physicalAddress || !selectedCoords) return;
        setIsSubmitting(true);

        const payload = {
            name: values.nameEn.trim(),
            nameAr: values.nameAr.trim(),
            isActive: values.isActive,
            description: values.descriptionEn.trim(),
            descriptionAr: values.descriptionAr.trim(),
            address: physicalAddress,
            latitude: `${selectedCoords.lat}`,
            longitude: `${selectedCoords.lng}`,
        }

        if (mode === "add") {
            addStore(
                payload,
                (res) => {
                    setIsSubmitting(false);
                    if (res.success) {
                        toastSuccess(res.message)
                        onSubmit();
                    } else {
                        toastError(res.message)
                    }
                },
                (res) => {
                    setIsSubmitting(false);
                    toastError(res.message);
                }
            )
        } else {
            payload.id = rowData.id;
            editStore(
                payload,
                (res) => {
                    setIsSubmitting(false);
                    if (res.success) {
                        toastSuccess(res.message)
                        onSubmit();
                    } else {
                        toastError(res.message)
                    }
                },
                (res) => {
                    setIsSubmitting(false);
                    toastError(res.message);
                }
            )
        }
    }

    useEffect(() => {
        if (rowData && mode === "edit") {
            form.setFieldsValue({
                nameEn: rowData.name,
                nameAr: rowData.nameAr,
                descriptionEn: rowData.description,
                descriptionAr: rowData.descriptionAr,
                address: rowData.address,
                isActive: rowData.isActive,
            })
            setPhysicalAddress(rowData.address)
            setSelectedCoords({ lat: rowData.latitude, lng: rowData.longitude })
        }
    }, [rowData, mode])

    return (
        <Modal
            title={SubHeader}
            open={open}
            className="msg-modal store-modal"
            footer={false}
            closable={false}
            centered
            destroyOnClose
        >
            <Form
                form={form}
                className="edit-actor__content"
                initialValues={initialValues}
                onFinish={handleFinish}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2" htmlFor="">
                                Name (EN)
                            </label>
                            <Form.Item
                                className=" new-category__form-item"
                                name="nameEn"
                                validateFirst
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: "Please, enter the store name",
                                    },
                                    {
                                        pattern: /^[\w\s$!@#%^&*()_+-=[\]/{}\\|;':",./<>?~`]+$/,
                                        message: "Please, enter the name in English",
                                    },
                                    {
                                        min: 2,
                                        message: "Minimum length is 2 characters"
                                    },
                                    {
                                        max: 30,
                                        message: "Maximum length is 30 characters"
                                    },
                                    { validator: handleValidationAfterTrimming }
                                ]}
                            >
                                <Input
                                    type="text"
                                    className="search__searchField py-2"
                                    placeholder="Enter name in English"
                                    maxLength={30}
                                    showCount
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2" htmlFor="">
                                Name (AR)
                            </label>
                            <Form.Item
                                className="new-category__form-item"
                                name="nameAr"
                                validateFirst
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: "Please, enter the store name",
                                    },
                                    {
                                        pattern: /^[\u0600-\u06FF\s\d$!@#%^&*()_+-=[\]/\\{}|;':",./<>؟~`]+$/,
                                        message: "Please, enter the name in Arabic",
                                    },
                                    {
                                        min: 2,
                                        message: "Minimum length is 2 characters"
                                    },
                                    {
                                        max: 30,
                                        message: "Maximum length is 30 characters"
                                    },
                                    { validator: handleValidationAfterTrimming }
                                ]}
                            >
                                <Input
                                    type="text"
                                    className="search__searchField py-2"
                                    placeholder="Enter name in Arabic"
                                    maxLength={30}
                                    showCount
                                    dir="rtl"
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2" htmlFor="">
                                Description (EN)
                            </label>
                            <Form.Item
                                className=" new-category__form-item"
                                name="descriptionEn"
                                validateFirst
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: "Please, enter the store description",
                                    },
                                    {
                                        pattern: /^[\w\s$!@#%^&*()_+-=[\]/{}\\|;':",./<>?~`]+$/,
                                        message: "Please, enter the description in English",
                                    },
                                    {
                                        min: 2,
                                        message: "Minimum length is 2 characters"
                                    },
                                    {
                                        max: 70,
                                        message: "Maximum length is 70 characters"
                                    },
                                    { validator: handleValidationAfterTrimming }
                                ]}
                            >
                                <Input.TextArea
                                    type="text"
                                    className="search__searchField py-2"
                                    placeholder="Enter description in English"
                                    maxLength={70}
                                    showCount
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2" htmlFor="">
                                Description (AR)
                            </label>
                            <Form.Item
                                className="new-category__form-item"
                                name="descriptionAr"
                                validateFirst
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: "Please, enter the store description",
                                    },
                                    {
                                        pattern: /^[\u0600-\u06FF\s\d$!@#%^&*()_+-=[\]/\\{}|;':",./<>؟~`]+$/,
                                        message: "Please, enter the description in Arabic",
                                    },
                                    {
                                        min: 2,
                                        message: "Minimum length is 2 characters"
                                    },
                                    {
                                        max: 70,
                                        message: "Maximum length is 70 characters"
                                    },
                                    { validator: handleValidationAfterTrimming }
                                ]}
                            >
                                <Input.TextArea
                                    type="text"
                                    className="search__searchField py-2"
                                    placeholder="Enter description in Arabic"
                                    maxLength={70}
                                    showCount
                                    dir="rtl"
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div id="map" className="w-100 mb-3 mt-3">
                            <MapPicker
                                mode={mode}
                                initialCoords={mode === "edit" ? selectedCoords : null}
                                onAddressChange={handleAddressChange}
                            />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2" htmlFor="">
                                Store address
                            </label>
                            {loadingAddress ? (
                                <div className="d-flex justify-content-center w-100">
                                    <Spin />
                                </div>
                            ) : (
                                <p className="text-start w-100">
                                    {physicalAddress}
                                </p>
                            )}
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name d-flex align-items-center gap-3 mb-5">
                            <label className="users__parent-info-label mb-0" htmlFor="">
                                Status
                            </label>
                            <Form.Item
                                className="mb-0"
                                name="isActive"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row className="mrg-ys-20 mrg-x-auto justify-content-center">
                    <Space size={20}>
                        <SecondaryButton
                            buttonName={"Cancel"}
                            onClick={onCancel}
                        />
                        <PrimaryButton
                            type="submit"
                            disableMe={!physicalAddress || !selectedCoords || loadingAddress}
                            loading={isSubmitting}
                            buttonName={mode === "add" ? "Add" : "Edit"}
                        />
                    </Space>
                </Row>
            </Form>
        </Modal>
    );
}

export default StoreModal;