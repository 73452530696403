import { useState, useEffect, useRef } from "react";
import { Button, Space, Input, Image, DatePicker, Table } from "antd";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from 'moment';
import { toastError } from "../../helpers/toasters";
import { getStoreItems } from "../../apis/Network";
import { baseUrl } from '../../apis/services';

// components
import GridTable from "../SubComponents/GridTable/GridTable";
import SubHeader from "../SubComponents/SubHeader";
import MainHeader from "../SubComponents/MainHeader";
import AddEditStoreProduct from "./AddEditStoreProduct";
import ChangeStatusModal from "./ChangeStatusModal";

const StoreProducts = () => {
    const [loading, setLoading] = useState(true);
    const [mode, setMode] = useState("add");
    const [rowData, setRowData] = useState("");
    const [dataSource, setDataSource] = useState([]);
    const [filteredDataSource, setFilteredDataSource] = useState([]);
    const [selectedRange, setSelectedRange] = useState([]);
    const [isItemModalVisible, setIsItemModalVisible] = useState(false);
    const [renderMe, setRenderMe] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [subcategoryOptions, setSubcategoryOptions] = useState([]);
    const [searchedColumn, setSearchedColumn] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);
    const searchInput = useRef(null);
    const { RangePicker } = DatePicker;
    const rowSelection = {
        type: "checkbox",
        selectedRowKeys,
        onChange: (tableSelectedRowKeys, tableSelectedRows) => {
            let copyOfRows = structuredClone(selectedRows);
            if (tableSelectedRowKeys.length < copyOfRows.length) {
                copyOfRows = copyOfRows.filter((row) =>
                    tableSelectedRowKeys.includes(row.requestId)
                );
            } else {
                tableSelectedRows.forEach((ele, indx) => {
                    const alreadyExists = copyOfRows.find(
                        (row) => row.requestId === ele.requestId
                    );
                    if (!alreadyExists) {
                        const newRow = {
                            requestId: tableSelectedRows[indx].requestId,
                            note: tableSelectedRows[indx].note || "",
                            assiginingDate: tableSelectedRows[indx].assigningDate || "",
                            courierId: tableSelectedRows[indx].courierId || "",
                        };
                        copyOfRows.push(newRow);
                    }
                });
            }
            setSelectedRowKeys(tableSelectedRowKeys);
            setSelectedRows(copyOfRows);
        },
        getCheckboxProps: (record) => ({
            // Column configuration not to be checked
            name: record.name,
        }),
        selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
    };

    const handleDateChange = (dates) => {
        setSelectedRange(dates);
        if (dates) {
            const startDate = dates[0].format("DD MMM, YYYY");
            const endDate = dates[1].format("DD MMM, YYYY");
            const dataWithingRange = dataSource.filter((ele) => {
                const comparisonDate = moment(ele.creationDate, "DD MMM, YYYY");
                return (
                    comparisonDate.isSameOrAfter(startDate) &&
                    comparisonDate.isSameOrBefore(endDate)
                );
            });
            setFilteredDataSource(dataWithingRange);
        } else {
            setFilteredDataSource(dataSource);
        }
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text || text === 0 ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });
    const getColumnDateProps = () => ({
        filterDropdown: () => (
            <div
                style={{
                    padding: 8,
                }}
                className="range-picker-holder"
                onKeyDown={(e) => e.stopPropagation()}
            >
                <RangePicker
                    value={selectedRange}
                    allowClear
                    popupClassName="main-date-picker"
                    inputReadOnly={false}
                    onChange={handleDateChange}
                    onKeyDown={(e) => e.preventDefault()}
                />
            </div>
        ),
        filterIcon: (filtered) => <FilterOutlined style={selectedRange?.length > 0 ? { color: "blue" } : {}} />,
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text,
    });

    const columns = [
        {
            key: "image",
            title: "IMAGE",
            dataIndex: "image",
            render: (_, row) => (
                <Image
                    width={90}
                    height={70}
                    src={baseUrl + row.image}
                    className="d-block img-fluid object-fit-cover"
                    alt=""
                />
            ),
        },
        {
            key: "name",
            title: "NAME (EN)",
            dataIndex: "name",
            ...getColumnSearchProps("name"),
        },
        {
            key: "nameAr",
            title: "NAME (AR)",
            dataIndex: "nameAr",
            ...getColumnSearchProps("nameAr"),
        },
        {
            key: "description",
            title: "DESCRIPTION (EN)",
            dataIndex: "description",
            ...getColumnSearchProps("description"),
            render: (_, row) => {
                return (
                    <span className="store-items__data-table__description d-block">
                        {row.description}
                    </span>
                )
            }
        },
        {
            key: "descriptionAr",
            title: "DESCRIPTION (AR)",
            dataIndex: "descriptionAr",
            ...getColumnSearchProps("descriptionAr"),
            render: (_, row) => {
                return (
                    <span className="store-items__data-table__description d-block">
                        {row.descriptionAr}
                    </span>
                )
            }
        },
        {
            key: "price",
            title: "PRICE",
            dataIndex: "price",
            ...getColumnSearchProps("price"),
        },
        {
            key: "discountPercentage",
            title: "DISCOUNT PERCENTAGE",
            dataIndex: "discountPercentage",
            ...getColumnSearchProps("discountPercentage"),
            render: (_, row) => {
                return <span>{row.discountPercentage}%</span>;
            },
        },
        {
            key: "quantity",
            title: "AVAILABLE QUANTITY",
            dataIndex: "quantity",
            ...getColumnSearchProps("quantity"),
        },
        {
            key: "link",
            title: "LINK",
            dataIndex: "link",
            ...getColumnSearchProps("link"),
            render: (_, row) => {
                return (
                    <a
                        href={row.link}
                        className="store-items__data-table__link text-center mx-auto d-block text-decoration-underline"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {row.link}
                    </a>
                )
            }
        },
        {
            key: "categoryId",
            title: "CATEGORY",
            dataIndex: "categoryId",
            filters: categoryOptions,
            onFilter: (value, record) => value === record.categoryId,
            render: (_, row) => {
                return (
                    <span className="">
                        {row.categoryName}
                    </span>
                )
            }
        },
        {
            key: "subCategoryName",
            title: "SUBCATEGORY",
            dataIndex: "subCategoryName",
            filters: subcategoryOptions,
            onFilter: (value, record) => value === record.subCategoryId,
        },
        {
            key: "isActive",
            title: "STATUS",
            dataIndex: "isActive",
            filters: [
                {
                    text: 'Active',
                    value: true,
                },
                {
                    text: 'Deactive',
                    value: false,
                }
            ],
            onFilter: (value, record) => value === record.isActive,
            render: (_, row) => (
                <span>{row.isActive ? "Active" : "Deactive"}</span>
            ),
        },
        {
            key: "creationDate",
            title: "CREATION DATE",
            dataIndex: "creationDate",
            ...getColumnDateProps("creationDate"),
        },
        {
            key: "itemId",
            title: "ACTIONS",
            dataIndex: "action",
            render: (_, row) => {
                return (
                    <Space size={5}>
                        <Button
                            className="border-0"
                            onClick={() => handleEditBtn(row)}
                            style={{ backgroundColor: "#589559", color: "white" }}
                        >
                            Edit
                        </Button>
                    </Space>
                );
            },
        },
    ];

    const handleEditBtn = (row) => {
        setMode("edit");
        setRowData(row);
        setIsItemModalVisible(true);
    };

    const handleAddItems = () => {
        setIsItemModalVisible(true);
        setMode("add");
    };

    const handleModalCancel = () => {
        setIsItemModalVisible(false);
        setMode("add");
        setRowData(null)
    };

    useEffect(() => {
        setLoading(true)
        getStoreItems(
            (res) => {
                setLoading(false);
                if (res.success) {
                    setDataSource(res.data);
                    setFilteredDataSource(res.data);
                    // set category options
                    const categoriesOptions = [];
                    const subcategoriesOptions = [];
                    res.data.forEach((item) => {
                        // set categories
                        const categoryAlreadyInArr = categoriesOptions.find((ele) => ele.value === item.categoryId);
                        if (!categoryAlreadyInArr) {
                            const obj = {
                                value: item.categoryId,
                                text: item.categoryName
                            }
                            categoriesOptions.push(obj);
                        }
                        // set subcategories
                        const subAlreadyInArr = subcategoriesOptions.find((ele) => ele.value === item.subCategoryId);
                        if (item.subCategoryId && !subAlreadyInArr) {
                            const obj = {
                                value: item.subCategoryId,
                                text: item.subCategoryName
                            }
                            subcategoriesOptions.push(obj);
                        }
                    })
                    setCategoryOptions(categoriesOptions);
                    setSubcategoryOptions(subcategoriesOptions);
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setLoading(false);
                if (res.data?.error) {
                    toastError(res.message);
                }
            }
        );
    }, [renderMe]);

    return (
        <section className="users store-items">
            <div className="users__all-contents">
                <div className="users__content">
                    <div className="users__usersData">
                        <div className="pb-3 mb-3">
                            <MainHeader
                                title="Marketplace Products"
                                buttonName={"Add Product"}
                                onButtonClick={handleAddItems}
                            />
                        </div>
                        <div className="users__table store-items__data-table grid-table mrg-ye-50 ">
                            <GridTable
                                loading={loading}
                                columns={columns}
                                dataSource={filteredDataSource}
                                rowId={"itemId"}
                                rowSelection={rowSelection}
                                showMarketplaceBtns={{
                                    disableBtn: selectedRows.length === 0,
                                    onOpenChangeStatusModal: () => {
                                        setOpenChangeStatusModal(true)
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
                {isItemModalVisible && (
                    <AddEditStoreProduct
                        isItemModalVisible={isItemModalVisible}
                        mode={mode}
                        rowData={rowData}
                        SubHeader={
                            <SubHeader
                                bgColor="#F58234"
                                title={`${mode === "add" ? "Add Product" : "Edit Product"}`}
                                closeButton
                                onCancel={handleModalCancel}
                            />
                        }
                        onSubmit={() => {
                            setRenderMe(!renderMe)
                            handleModalCancel()
                        }}
                        onCancel={handleModalCancel}
                        primaryBtnName={`${mode === "add" ? "Add" : "Edit"}`}
                        secondaryBtnName={"Cancel"}
                    />
                )}
                {openChangeStatusModal && (
                    <ChangeStatusModal
                        open={openChangeStatusModal}
                        selectedKeys={selectedRowKeys}
                        onSubmit={() => {
                            setRenderMe(!renderMe)
                            setOpenChangeStatusModal(false)
                            setSelectedRowKeys([])
                            setSelectedRows([])
                        }}
                        onCancel={() => {
                            setOpenChangeStatusModal(false)
                        }}
                    />
                )}
            </div>
        </section>
    );
}

export default StoreProducts;