import { Form, Row, Modal, Space } from 'antd';
import { useState } from 'react';
import { updateMerchantStatusOfScrapper } from '../../apis/Network';
import { toastError, toastSuccess } from '../../helpers/toasters';

// components
import SecondaryButton from '../SubComponents/Buttons/SecondaryButton';
import PrimaryButton from '../SubComponents/Buttons/PrimaryButton';

const ConfirmTogglingMerchantStatusModal = ({
    open,
    SubHeader,
    onCancel,
    onSubmit,
    rowData
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm();

    const handleFinish = (values) => {
        if (submitting) return;
        setSubmitting(true);

        const payload = {
            dealerId: rowData.dealerId,
            isMerchant: !rowData.isMerchant
        }

        updateMerchantStatusOfScrapper(
            payload,
            (res) => {
                setSubmitting(false)
                if (res.success) {
                    toastSuccess(res.message)
                    onSubmit()
                } else {
                    toastError(res.message)
                }
            },
            (res) => {
                setSubmitting(false)
                toastError(res?.data?.error)
            }
        )
    }

    return (
        <Modal
            title={SubHeader}
            open={open}
            className="msg-modal store-modal"
            footer={false}
            closable={false}
            centered
            destroyOnClose
        >
            <Form
                form={form}
                className="edit-actor__content"
                onFinish={handleFinish}
            >
                <p className='text-center py-3'>
                    Are you sure you want to change this {rowData?.isMerchant ? "merchant" : "scrapper"} to a {rowData?.isMerchant ? "scrapper" : "merchant"}?
                </p>
                <Row className="mrg-ys-20 mrg-x-auto justify-content-center">
                    <Space size={20}>
                        <SecondaryButton
                            buttonName={"Cancel"}
                            onClick={onCancel}
                        />
                        <PrimaryButton
                            type="submit"
                            loading={submitting}
                            buttonName={"Confirm"}
                        />
                    </Space>
                </Row>
            </Form>
        </Modal>
    );
}

export default ConfirmTogglingMerchantStatusModal;