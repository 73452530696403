import { useState } from "react";
import { Form, Modal, Radio, Row, Space } from "antd";
import { changeStatusOfStoreItemsList } from "../../apis/Network";
import { toastError, toastSuccess } from "../../helpers/toasters";

// components
import SubHeader from "../SubComponents/SubHeader";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";

const ChangeStatusModal = ({
    open,
    selectedKeys,
    onCancel,
    onSubmit,
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm();
    const initialValues = {
        active: true
    }

    const handleFinish = (values) => {
        if (submitting) return;
        setSubmitting(true);

        const payload = {
            isActive: values.active,
            storeItemIds: selectedKeys
        }
        changeStatusOfStoreItemsList(
            payload,
            (res) => {
                setSubmitting(false)
                if (res.success) {
                    toastSuccess(res.message)
                    onSubmit()
                } else {
                    toastError(res.message)
                }
            },
            (res) => {
                setSubmitting(false)
                toastError(res?.data?.error)
            })
    }

    return (
        <Modal
            title={
                <SubHeader
                    bgColor="#F58234"
                    title={"Change Status"}
                    closeButton
                    onCancel={onCancel}
                />
            }
            open={open}
            className="msg-modal"
            footer={false}
            closable={false}
            centered
            destroyOnClose
            onCancel={onCancel}
        >
            <Form
                form={form}
                className="edit-actor__content store-items__item-form"
                onFinish={handleFinish}
                initialValues={initialValues}
            >
                <Form.Item name="active" className="pt-2">
                    <Radio.Group className="d-flex justify-content-center">
                        <Radio value={true}>Activate</Radio>
                        <Radio value={false}>Deactivate</Radio>
                    </Radio.Group>
                </Form.Item>
                <Row className="mrg-ys-20 mrg-x-auto justify-content-center">
                    <Space size={20}>
                        <SecondaryButton
                            buttonName={"Cancel"}
                            onClick={onCancel}
                        />
                        <PrimaryButton
                            buttonName={"Change"}
                            loading={submitting}
                            type="submit"
                        />
                    </Space>
                </Row>
            </Form>
        </Modal>
    );
}

export default ChangeStatusModal;