import { useRef, useState } from "react";
import { Button, Image, Skeleton, Space } from "antd";
import {
    DownloadOutlined,
    ZoomOutOutlined,
    ZoomInOutlined,
    LoadingOutlined
} from '@ant-design/icons';
import { getBlob, ref } from "firebase/storage";
import { FBStorage } from "apis/firebase";
import { downloadFileFromBlob, getFilePathFromDownloadURL } from "helpers/helpfulFunctions";

// assets
import videoPlayIcon from "assets/icons/video-play.png"

const MessageImg = ({ data }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isDownloading, setIsDownloading] = useState(false);
    const [previewVisible, setPreviewVisible] = useState(false);
    const videoRef = useRef();

    const onDownload = () => {
        if (isDownloading) return;
        setIsDownloading(true)
        const filePath = getFilePathFromDownloadURL(data.message);
        const storageRef = ref(FBStorage, filePath);
        getBlob(storageRef)
            .then((blob) => {
                downloadFileFromBlob(blob, data.mediaName)
                setIsDownloading(false)
            })
            .catch((error) => {
                setIsDownloading(false)
                console.log('error downloading file: ', error)
            })
    };

    return (
        <>
            {isLoading ? (
                <Skeleton.Image active={isLoading} />
            ) : (
                ""
            )}
            {data?.type === 5 ? (
                <div className={`position-relative chat__chat-holder__body__message__holder__image--video-thumbnail ${isLoading ? "d-none" : ""}`}>
                    <Button
                        onClick={() => setPreviewVisible(true)}
                        className="d-flex align-items-center justify-content-center w-100 h-100 position-absolute start-0 top-0 border-0 outline-none p-0"
                    >
                        <img
                            src={videoPlayIcon}
                            alt=""
                            className="d-block img-fluid"
                        />
                    </Button>
                    <video
                        className="d-block img-fluid"
                        src={data?.message}
                        ref={videoRef}
                        onLoadedData={() => setIsLoading(false)}
                    />
                </div>
            ) : (
                ""
            )}
            {data?.type === 2 || (data?.type === 5 && previewVisible) ? (
                <Image
                    src={data?.message}
                    alt=""
                    rootClassName="chat__chat-holder__body__message__holder__image__preview-holder"
                    preview={{
                        visible: previewVisible,
                        imageRender: (node) => (
                            data?.type === 5 ? (
                                <video
                                    controls
                                    className="d-block img-fluid"
                                    src={data?.message}
                                    ref={videoRef}
                                />
                            ) : (
                                node
                            )
                        ),
                        toolbarRender: (
                            _,
                            {
                                transform: { scale },
                                actions: { onZoomOut, onZoomIn },
                            },
                        ) => (
                            <Space size={12} className="toolbar-wrapper">
                                <Button
                                    disabled={isDownloading}
                                    onClick={onDownload}
                                    className="chat__chat-holder__body__message__holder__image__preview-holder__icon-holder border-0 outline-none shadow-none"
                                >
                                    {isDownloading ? <LoadingOutlined /> : <DownloadOutlined />}
                                </Button>
                                {data?.type === 2 ? (
                                    <>
                                        <Button
                                            disabled={scale === 1}
                                            onClick={onZoomOut}
                                            className="chat__chat-holder__body__message__holder__image__preview-holder__icon-holder border-0 outline-none shadow-none"
                                        >
                                            <ZoomOutOutlined />
                                        </Button>
                                        <Button
                                            disabled={scale === 10}
                                            onClick={onZoomIn}
                                            className="chat__chat-holder__body__message__holder__image__preview-holder__icon-holder border-0 outline-none shadow-none"
                                        >
                                            <ZoomInOutlined />
                                        </Button>
                                    </>
                                ) : (
                                    ""
                                )}
                            </Space>
                        ),
                        onVisibleChange: (value) => {
                            setPreviewVisible(value)
                            if (!value) {
                                videoRef?.current?.pause()
                            }
                        }
                    }}
                    onLoad={() => setIsLoading(false)}
                    className={`chat__chat-holder__body__message__holder__image img-fluid d-block ${isLoading || (data?.type === 5 && !previewVisible) ? "d-none" : ""}`}
                />
            ) : (
                ""
            )}
        </>
    );
}

export default MessageImg;