import { useState } from "react";
import { Form, Modal, Row, Space } from "antd";
import { refundRedeemedOrder, refundRedeemedProduct } from "../../apis/Network";
import { toastError, toastSuccess } from "../../helpers/toasters";

// components
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";

const ConfirmRefundModal = ({
  request,
  open,
  subHeader,
  onCancel,
  onSubmit,
  type, // 1 --> Order, 2 --> Single Product
}) => {
  const [submitting, setSubmitting] = useState(false);

  const handleFinish = () => {
    if (submitting) return;
    setSubmitting(true);
    if (type === 1) {
      refundRedeemedOrder(
        { orderId: request.id },
        (res) => {
          setSubmitting(false);
          if (res.success) {
            toastSuccess(res.message);
            onSubmit();
          } else {
            toastError(res.message);
          }
        },
        (res) => {
          setSubmitting(false);
          toastError(res?.data?.error);
        }
      );
    } else {
      refundRedeemedProduct(
        { orderDetailsId: request.orderDetailsId },
        (res) => {
          setSubmitting(false);
          if (res.success) {
            toastSuccess(res.message);
            onSubmit();
          } else {
            toastError(res.message);
          }
        },
        (res) => {
          setSubmitting(false);
          toastError(res?.data?.error);
        }
      );
    }
  };

  return (
    <Modal
      title={subHeader}
      open={open}
      className="msg-modal"
      footer={false}
      closable={false}
      centered
      destroyOnClose
      onCancel={onCancel}
    >
      <p className="text-center pt-3 mb-3">
        You are about to refund this {type === 1 ? "request" : "product"}!
      </p>
      <Form className="edit-actor__content" onFinish={handleFinish}>
        <Row className="mrg-ys-20 mrg-x-auto mt-2 justify-content-center">
          <Space size={20}>
            <SecondaryButton buttonName={"Cancel"} onClick={onCancel} />
            <PrimaryButton
              buttonName={"Confirm"}
              loading={submitting}
              type="submit"
            />
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};

export default ConfirmRefundModal;
