import { Helmet } from "react-helmet";

const WebsiteTitle = ({ title }) => {
  return (
    <Helmet>
      <title>{title?.length ? "Big Scrappers | " + title : "Big Scrappers Admin Panel"}</title>
    </Helmet>
  );
};

export default WebsiteTitle;
