import { createSlice } from "@reduxjs/toolkit";

export const chatSlice = createSlice({
  name: "chat",
  initialState: {
    notifications: 0,
  },
  reducers: {
    updateNotifications: (state, action) => {
      state.notifications = action.payload;
    },
  },
});

const { reducer, actions } = chatSlice;
export const { updateNotifications } = actions;
export default reducer;
