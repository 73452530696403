import axios from "axios";
import { checkAdminStatus } from "./Network";
import store from "../store/index";
import { handleLogout } from "../helpers/helpfulFunctions";

export const baseUrl = process.env.REACT_APP_BASE_URL;

const getToken = () => {
  const state = store.getState();
  const { authorization } = state.auth;
  return authorization.token;
}

export function getResource(path, onSuccess, onFail, reqAuth = true, useBaseUrl = true) {
  let requestData = {
    method: "get",
    url: useBaseUrl ? baseUrl + path : path,
    headers: {
      "Accept-Language": "en-US",
    },
  };

  if (reqAuth) {
    requestData.headers = {
      "Accept-Language": "en-US",
      Authorization: "Bearer " + getToken(),
    };
  }

  axios(requestData)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((fail) => {
      if (fail?.response && (fail.response.status === 401 || fail.response.status === 403)) {
        handleLogout();
      } else {
        onFail(fail.response);
      }
    });
}

export function postResource(
  path,
  data,
  onSuccess,
  onFail,
  reqAuth = true,
  multiPart = false
) {
  const token = getToken();
  if (token) {
    checkAdminStatus((res) => {
      if (res.success && !res.data) {
        handleLogout()
      }
    }, (res) => { })
  }

  let requestData = {
    method: "post",
    url: baseUrl + path,
    headers: {
      "Accept-Language": "en-US",
    },
    data,
  };
  if (token && reqAuth) {
    requestData.headers = {
      ...requestData.headers,
      Authorization: "Bearer " + token,
    };
  }

  if (multiPart) {
    requestData.headers = {
      ...requestData.headers,
      "content-type": "multipart/form-data",
    };
  }
  axios(requestData)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((fail) => {
      if (fail?.response && (fail.response.status === 401 || fail.response.status === 403)) {
        handleLogout();
      } else {
        onFail(fail.response);
      }
    });
}

export function putResource(
  path,
  data,
  onSuccess,
  onFail,
  reqAuth = true,
  multipart = false
) {
  const token = getToken();
  checkAdminStatus((res) => {
    if (res.success && !res.data) {
      handleLogout()
    }
  }, (res) => { })

  let requestData = {
    method: "put",
    url: baseUrl + path,
    headers: {
      "Accept-Language": "en-US",
    },
    data,
  };

  if (token && reqAuth) {
    requestData.headers = {
      ...requestData.headers,
      Authorization: "Bearer " + token,
    };
  }

  if (multipart) {
    requestData.headers = {
      ...requestData.headers,
      "content-type": "multipart/form-data",
    };
  }

  axios(requestData).then(
    (res) => {
      onSuccess(res.data);
    },
    (fail) => {
      if (fail?.response && (fail.response.status === 401 || fail.response.status === 403)) {
        handleLogout();
      } else {
        onFail(fail.response);
      }
    }
  );
}

export function patchResource(
  path,
  data,
  onSuccess,
  onFail,
  reqAuth = true,
  multipart = false
) {
  const token = getToken();
  checkAdminStatus((res) => {
    if (res.success && !res.data) {
      handleLogout()
    }
  }, (res) => { })

  let requestData = {
    method: "patch",
    url: baseUrl + path,
    headers: {
      "Accept-Language": "en-US",
    },
    data,
  };

  if (token && reqAuth) {
    requestData.headers = {
      ...requestData.headers,
      Authorization: "Bearer " + token,
    };
  }

  if (multipart) {
    requestData.headers = {
      ...requestData.headers,
      "content-type": "multipart/form-data",
    };
  }

  axios(requestData).then(
    (res) => {
      onSuccess(res.data);
    },
    (fail) => {
      if (fail?.response && (fail.response.status === 401 || fail.response.status === 403)) {
        handleLogout();
      } else {
        onFail(fail.response);
      }
    }
  );
}

export function deleteResource(path, onSuccess, onFail, reqAuth = true) {
  const token = getToken();
  checkAdminStatus((res) => {
    if (res.success && !res.data) {
      handleLogout()
    }
  }, (res) => { })

  let requestData = {
    method: "delete",
    url: baseUrl + path,
    headers: {
      "Accept-Language": "en-US",
    },
  };

  if (token && reqAuth) {
    requestData.headers = {
      "Accept-Language": "en-US",
      Authorization: "Bearer " + token,
    };
  }

  axios(requestData).then(
    (res) => {
      onSuccess(res.data);
    },
    (fail) => {
      if (fail?.response && (fail.response.status === 401 || fail.response.status === 403)) {
        handleLogout();
      } else {
        onFail(fail.response);
      }
    }
  );
}

export function deleteResourceWithData(
  path,
  data,
  onSuccess,
  onFail,
  reqAuth = true
) {
  const token = getToken();
  checkAdminStatus((res) => {
    if (res.success && !res.data) {
      handleLogout()
    }
  }, (res) => { })

  let requestData = {
    method: "delete",
    url: baseUrl + path,
    headers: {
      "Accept-Language": "en-US",
    },
    data,
  };

  if (token && reqAuth) {
    requestData.headers = {
      "Accept-Language": "en-US",
      Authorization: "Bearer " + token,
    };
  }

  axios(requestData).then(
    (res) => {
      onSuccess(res.data);
    },
    (fail) => {
      if (fail?.response && (fail.response.status === 401 || fail.response.status === 403)) {
        handleLogout();
      } else {
        onFail(fail.response);
      }
    }
  );
}

export function uploadFileService(
  method,
  path,
  formDataType,
  data,
  onProgress,
  onSuccess,
  onFail,
  cancelToken
) {
  const token = getToken();
  checkAdminStatus((res) => {
    if (res.success && !res.data) {
      handleLogout()
    }
  }, (res) => { })

  let requestData = {
    method: method,
    url: baseUrl + path,
    formDataType,
    headers: {
      Authorization: "Bearer " + token,
      "content-type": "multipart/form-data",
      "Accept-Language": "en-US",
    },
    data,
    onUploadProgress: (progressEvent) => {
      onProgress(progressEvent);
    },
    cancelToken,
  };

  axios(requestData)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((fail) => {
      if (fail?.response && (fail.response.status === 401 || fail.response.status === 403)) {
        handleLogout();
      } else {
        onFail(fail.response);
      }
    });
}
