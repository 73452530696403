import { useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import { Button, Input, Space, DatePicker, Table } from "antd";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import { useLocation, useOutletContext } from "react-router-dom";
import Highlighter from "react-highlight-words";
import dayjs from 'dayjs';
import { getCollectionTypes, getDailyOrdersForCourier } from "../../../apis/Network";
import { toastError } from "../../../helpers/toasters";
import { exportDataAsExcel, handleAllowNumbersOnly } from "../../../helpers/helpfulFunctions";

// components
import GridTable from "../../SubComponents/GridTable/GridTable";
import MainHeader from "../../SubComponents/MainHeader";
import SubHeader from "../../SubComponents/SubHeader";
import OrderDetailsModal from "./OrderDetailsModal";

const CourierCompletedOrders = () => {
  const [loading, setLoading] = useState(true);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [rowData, setRowData] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [filterDates, setFilterDates] = useOutletContext();
  const [selectedDates, setSelectedDates] = useState();
  const [isExporting, setIsExporting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [courierData, setCourierData] = useState(null);
  const [collectionTypes, setCollectionTypes] = useState([]);
  const [searchValues, setSearchValues] = useState({
    fullName: "",
    mobile: "",
    totalQuantity: "",
    totalPounds: "",
    totalPoints: "",
    pointStatus: null,
    collectionType: null,
    pointPercentage: "",
    totalMarketplacePoints: "",
    totalMarketplacePounds: "",
    from: "",
    to: "",
    cashToUser: "",
  });
  const [columnsTotal, setColumnsTotal] = useState({
    totalQuantity: "",
    totalPounds: "",
    totalMarketplacePoints: "",
    totalMarketplacePounds: ""
  });
  const { roles } = useSelector((state) => state.auth.authorization);
  const { RangePicker } = DatePicker;
  const searchInput = useRef(null);
  const location = useLocation();
  const fieldsAcceptingNumbersOnly = [
    "pointsPercentage",
    "totalPounds",
    "totalPoints",
    "totalQuantity",
    "totalMarketplacePoundss",
    "totalMarketplacePoints",
    "cashToUser",
    "userPhone"
  ];

  const handleExportDataToExcel = () => {
    if (isExporting) return;
    setIsExporting(true)
    const baseProps = [
      { title: "User Name", prop: "userName" },
      { title: "User Mobile", prop: "userPhone" },
      { title: "Order Status", prop: "statusName" },
      { title: "Points Percentage", prop: "pointsPercentage" },
      { title: "Collection Type", prop: "collectionType" },
      { title: "Quantity In Grams", prop: "totalQuantity" },
      { title: "Total Pounds", prop: "totalPounds" },
      { title: "Total Points", prop: "totalPoints" },
      { title: "Marketplace Points", prop: "totalMarketplacePoints" },
      { title: "Marketplace Pounds", prop: "totalMarketplacePoundss" },
      { title: "Cash Value", prop: "cashToUser" },
      { title: "Creation Date", prop: "date" },
    ]
    const payload = {
      isExport: true,
      courierId: courierData.courierId,
      ...searchValues,
    };
    for (let property in payload) {
      if (!payload[property] && payload[property] !== 0) {
        delete payload[property];
      }
    }
    getDailyOrdersForCourier(
      payload,
      (res) => {
        setIsExporting(false)
        if (res.success) {
          const totalObj = {
            "User Name": "Sum",
            "Quantity In Grams": res.data.totalQuantity,
            "Total Pounds": res.data.totalPounds,
            "Total Points": res.data.totalMarketplacePoints,
            "Marketplace Points": res.data.totalMarketplacePoints,
            "Marketplace Pounds": res.data.totalMarketplacePounds
          }
          exportDataAsExcel(
            res.data.response,
            { baseProps, tab: `${courierData.courierName}'s Completed Orders Report`, totalObj },
          )
        } else {
          toastError(res.message)
        }
      },
      (res) => {
        setIsExporting(false)
        toastError(res?.data?.error);
      }
    );
  }

  const handlePageSizeChange = (current, newSize) => {
    setLoading(true);
    setPageSize(newSize);
  };

  const handlePageChange = (page) => {
    setLoading(true);
    setCurrentPage(page);
  };

  const handleViewOrderDetails = (row) => {
    setIsViewModalOpen(true);
    setRowData(row);
  };

  const handleCancelModal = () => {
    setIsViewModalOpen(false);
    setRowData(null);
  };

  const updateSearchValues = (property, newValue, otherProperty) => {
    const copyOfSearchObj = structuredClone(searchValues)
    copyOfSearchObj[property] = newValue;
    if (otherProperty) {
      copyOfSearchObj[otherProperty.property] = otherProperty.value
    }
    setSearchValues(copyOfSearchObj)
  }

  const handleDateChange = (dates) => {
    setSelectedDates(dates);
    if (dates) {
      const startDate = dayjs(dates[0]).format("YYYY-MM-DD");
      const endDate = dayjs(dates[1]).format("YYYY-MM-DD");
      updateSearchValues("from", startDate, { property: "to", value: endDate })
    } else {
      updateSearchValues("from", "", { property: "to", value: "" })
    }
  };

  const handleTableFilters = (_, filters) => {
    if (filters.collectionType) {
      if (searchValues.collectionType) {
        const filterArrToStr = [...filters.collectionType]
          .sort((x, y) => x - y)
          .join("");
        const currentArrToStr = [...searchValues.collectionType]
          .sort((x, y) => x - y)
          .join("");
        if (filterArrToStr !== currentArrToStr) {
          updateSearchValues("collectionType", filters.collectionType);
        }
      } else {
        updateSearchValues("collectionType", filters.collectionType);
      }
    } else if (searchValues.collectionType) {
      updateSearchValues("collectionType", null);
    }

    if (filters.statusId) {
      if (searchValues.pointStatus) {
        const filterArrToStr = [...filters.statusId]
          .sort((x, y) => x - y)
          .join("");
        const currentArrToStr = [...searchValues.pointStatus]
          .sort((x, y) => x - y)
          .join("");
        if (filterArrToStr !== currentArrToStr) {
          updateSearchValues("pointStatus", filters.statusId);
        }
      } else {
        updateSearchValues("pointStatus", filters.statusId);
      }
    } else if (searchValues.pointStatus) {
      updateSearchValues("pointStatus", null);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    const value = selectedKeys[0]?.trim() || "";
    confirm();
    switch (dataIndex) {
      case "userName":
        updateSearchValues("fullName", value);
        break;
      case "userPhone":
        updateSearchValues("mobile", value);
        break;
      case "totalQuantity":
        updateSearchValues("totalQuantity", value);
        break;
      case "totalPounds":
        updateSearchValues("totalPounds", value);
        break;
      case "totalPoints":
        updateSearchValues("totalPoints", value);
        break;
      case "totalMarketplacePoints":
        updateSearchValues("totalMarketplacePoints", value);
        break;
      case "totalMarketplacePoundss":
        updateSearchValues("totalMarketplacePounds", value);
        break;
      case "pointsPercentage":
        updateSearchValues("pointPercentage", value);
        break;
      case "cashToUser":
        updateSearchValues("cashToUser", value);
        break;
      default:
        break;
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    clearFilters();
    setLoading(true);
    switch (dataIndex) {
      case "userName":
        updateSearchValues("fullName", "");
        break;
      case "userPhone":
        updateSearchValues("mobile", "");
        break;
      case "totalQuantity":
        updateSearchValues("totalQuantity", "");
        break;
      case "totalPounds":
        updateSearchValues("totalPounds", "");
        break;
      case "totalPoints":
        updateSearchValues("totalPoints", "");
        break;
      case "totalMarketplacePoints":
        updateSearchValues("totalMarketplacePoints", "");
        break;
      case "totalMarketplacePoundss":
        updateSearchValues("totalMarketplacePounds", "");
        break;
      case "pointsPercentage":
        updateSearchValues("pointPercentage", "");
        break;
      case "cashToUser":
        updateSearchValues("cashToUser", "");
        break;
      default:
        break;
    }
    confirm();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onKeyDown={(e) => {
            if (fieldsAcceptingNumbersOnly.includes(dataIndex)) {
              handleAllowNumbersOnly(e, true)
            }
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, dataIndex, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          ></Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => true,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: () => (
      <div
        style={{
          padding: 8,
        }}
        className="range-picker-holder"
        onKeyDown={(e) => e.stopPropagation()}
      >
        <RangePicker
          value={selectedDates}
          allowClear
          popupClassName="main-date-picker"
          inputReadOnly={false}
          onChange={(dates, datesStr) => {
            setCurrentPage(1);
            handleDateChange(dates, datesStr);
          }}
          onKeyDown={(e) => e.preventDefault()}
        />
      </div>
    ),
    filterIcon: (filtered) => <FilterOutlined />,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });

  const columns = [
    {
      key: "userName",
      title: "USER NAME",
      dataIndex: "userName",
      ...getColumnSearchProps("userName"),
    },
    {
      key: "userPhone",
      title: "USER MOBILE",
      dataIndex: "userPhone",
      ...getColumnSearchProps("userPhone"),
    },
    {
      key: "statusId",
      title: "ORDER STATUS",
      dataIndex: "statusId",
      filters: [
        { text: "Pending", value: 1 },
        { text: "Collected", value: 2 },
        { text: "Rejected", value: 3 },
      ],
      onFilter: (value, record) => true,
      render: (_, row) => {
        return <span>{row.statusName}</span>;
      },
    },
    {
      key: "pointsPercentage",
      title: "POINTS PERCENTAGE",
      dataIndex: "pointsPercentage",
      ...getColumnSearchProps("pointsPercentage"),
      render: (_, row) => {
        return <span>{row.pointsPercentage}%</span>;
      },
    },
    {
      key: "collectionType",
      title: "COLLECTION TYPE",
      dataIndex: "collectionType",
      filters: collectionTypes,
      onFilter: (value, record) => true,
    },
    {
      key: "totalQuantity",
      title: "QUANTITY IN GRAMS",
      dataIndex: "totalQuantity",
      ...getColumnSearchProps("totalQuantity"),
    },
    {
      key: "totalPounds",
      title: "TOTAL POUNDS",
      dataIndex: "totalPounds",
      ...getColumnSearchProps("totalPounds"),
    },
    {
      key: "totalPoints",
      title: "TOTAL POINTS",
      dataIndex: "totalPoints",
      ...getColumnSearchProps("totalPoints"),
    },
    {
      key: "totalMarketplacePoints",
      title: "MARKETPLACE POINTS",
      dataIndex: "totalMarketplacePoints",
      ...getColumnSearchProps("totalMarketplacePoints"),
    },
    {
      key: "totalMarketplacePoundss",
      title: "MARKETPLACE POUNDS",
      dataIndex: "totalMarketplacePoundss",
      ...getColumnSearchProps("totalMarketplacePoundss"),
    },
    {
      key: "cashToUser",
      title: "CASH VALUE",
      dataIndex: "cashToUser",
      ...getColumnSearchProps("cashToUser"),
    },
    {
      key: "date",
      title: "CREATION DATE",
      dataIndex: "date",
      ...getColumnDateProps("date"),
    },
    {
      key: "ACTION",
      title: "ACTION",
      dataIndex: "ACTION",
      render: (_, row) => {
        return (
          <Button
            className="border-0"
            onClick={() => handleViewOrderDetails(row)}
            style={{ backgroundColor: "#589559", color: "white" }}
          >
            View Details
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    if (location) {
      setCourierData(location.state?.data)
      const datesAsDayjsObj = location.state?.selectedDate?.map((date) => dayjs(date, "YYYY-MM-DD"))
      if (datesAsDayjsObj) {
        handleDateChange(datesAsDayjsObj)
      }
    }
  }, [location])

  useEffect(() => {
    if (!courierData) return;
    setLoading(true)

    const payload = {
      pageNumber: currentPage,
      perPage: pageSize,
      courierId: courierData.courierId,
      ...searchValues,
    };
    for (let property in payload) {
      if (!payload[property] && payload[property] !== 0) {
        delete payload[property];
      }
    }
    getDailyOrdersForCourier(
      payload,
      (res) => {
        setLoading(false);
        if (res.success) {
          setDataSource(res.data.response);
          setTotalCount(res.data.totalCount);
          const totalObj = {
            totalQuantity: res.data.totalQuantity,
            totalPounds: res.data.totalPounds,
            totalMarketplacePoints: res.data.totalMarketplacePoints,
            totalMarketplacePounds: res.data.totalMarketplacePounds
          }
          setColumnsTotal(totalObj)
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setLoading(false);
        toastError(res.message);
      }
    );
  }, [courierData, pageSize, currentPage, searchValues]);

  useEffect(() => {
    if (filterDates) {
      setSelectedDates(filterDates)
    }
  }, [filterDates])

  useEffect(() => {
    getCollectionTypes(
      (res) => {
        const filterArr = res.data.map((obj) => (
          { text: obj.name, value: obj.id }
        ))
        setCollectionTypes(filterArr)
      },
      (res) => {
        toastError(res?.data?.error)
      })
  }, [])

  return (
    <section className="users">
      <div className="users__all-contents">
        <div className="users__content">
          <div className="users__usersData">
            <div className="pb-3 mb-3">
              <MainHeader title="Daily Courier Completed Orders" />
              <MainHeader
                info={{
                  courierName: courierData?.courierName,
                  courierMobile: courierData?.courierMobile,
                }}
              />
            </div>
            <div className="users__table grid-table mrg-y-30">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                rowId={"requestId"}
                title={"Today Courier Orders"}
                allowExport={!roles?.includes(14)}
                isExporting={isExporting}
                onExport={handleExportDataToExcel}
                totalCount={totalCount}
                onPageChange={handlePageChange}
                onChange={handleTableFilters}
                pageSize={pageSize}
                onPageSizeChange={handlePageSizeChange}
                summary={() => (
                  <Table.Summary>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} className="fw-bold text-center text--primary-1">Sum</Table.Summary.Cell>
                      <Table.Summary.Cell index={1}></Table.Summary.Cell>
                      <Table.Summary.Cell index={2}></Table.Summary.Cell>
                      <Table.Summary.Cell index={3}></Table.Summary.Cell>
                      <Table.Summary.Cell index={4}></Table.Summary.Cell>
                      <Table.Summary.Cell index={5} className="text-center text--primary-1">{columnsTotal.totalQuantity}</Table.Summary.Cell>
                      <Table.Summary.Cell index={6} className="text-center text--primary-1">{columnsTotal.totalPounds}</Table.Summary.Cell>
                      <Table.Summary.Cell index={7} className="text-center text--primary-1">{columnsTotal.totalMarketplacePoints}</Table.Summary.Cell>
                      <Table.Summary.Cell index={8} className="text-center text--primary-1">{columnsTotal.totalMarketplacePoints}</Table.Summary.Cell>
                      <Table.Summary.Cell index={9} className="text-center text--primary-1">{columnsTotal.totalMarketplacePounds}</Table.Summary.Cell>
                      <Table.Summary.Cell index={10}></Table.Summary.Cell>
                      <Table.Summary.Cell index={11}></Table.Summary.Cell>
                      <Table.Summary.Cell index={12}></Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                )}
              />
              {isViewModalOpen && (
                <OrderDetailsModal
                  data={rowData}
                  open={isViewModalOpen}
                  subHeader={
                    <SubHeader
                      bgColor="#589559"
                      title={`View Order details`}
                      closeButton
                      onCancel={handleCancelModal}
                    />
                  }
                  onCancel={handleCancelModal}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CourierCompletedOrders;
