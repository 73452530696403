import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Space,
  Table,
  Select,
} from "antd";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import dayjs from "dayjs";
import {
  getLogisticTeams,
  getSocialOrders,
  getUserRequestStatus,
} from "../../apis/Network";
import { toastError } from "../../helpers/toasters";
import { exportDataAsExcel, handleAllowNumbersOnly } from "../../helpers/helpfulFunctions";

// components
import GridTable from "../SubComponents/GridTable/GridTable";
import MainHeader from "../SubComponents/MainHeader";
import SubHeader from "../SubComponents/SubHeader";
import ViewDetails from "./ViewDetails";
import AddressDetails from "./AddressDetails";
import AssignToCourierModal from "./AssignToCourierModal";
import EditStatus from "./EditStatus";
import AssignLogisticTeam from "./AssignLogisticTeam";
import RatingModal from "./RatingModal";
import AssignmentLogsModal from "./AssignmentLogsModal";
import CallCenterStatusModal from "./CallCenterStatusModal";
const assignmentStatusOptions = [
  { label: "Not Assigned", value: 1 },
  { label: "Assigned Not Completed", value: 2 },
  { label: "Assigned And Completed", value: 3 },
  { label: "Postponed After Assigning", value: 4 },
  { label: "Cancel After Assigning", value: 5 },
  // { label: "Completed With Another Account", value: 6 },
];
const ratingCount = 10;
const SocialOrders = () => {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [rowData, setRowData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddressDetailsModalOpen, setIsAddressDetailsModalOpen] = useState(false);
  const [userAssignMode, setUserAssignMode] = useState("single");
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [isEditStatusModalOpen, setIsEditStatusModalOpen] = useState(false);
  const [isAssignmentStatusModalOpen, setIsAssignmentStatusModalOpen] = useState(false);
  const [renderMe, setRenderMe] = useState(false);
  const [userStatusOptions, setUserStatusOptions] = useState(null);
  const [logisticTeams, setLogisticTeams] = useState(null);
  const [logisticTeamsFilterOptions, setLogisticTeamsFilterOptions] = useState([]);
  const [ratingFilteringOptions, setRatingFilteringOptions] = useState([]);
  const [rating, setRating] = useState([]);
  const [selectedRatingValue, setSelectedRatingValue] = useState(undefined);
  const [isAssignLogisticOpen, setIsAssignLogisticOpen] = useState(false);
  const [isRatingModal, setIsRatingModal] = useState(false);
  const [selectedLogisticTeam, setSelectedLogisticTeam] = useState(undefined);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [submittable, setSubmittable] = useState(false);
  const [requestDateSelectedRange, setRequestDateSelectedRange] = useState([]);
  const [assignedDateSelectedRange, setAssignDateSelectedRange] = useState([]);
  const [isExporting, setIsExporting] = useState(false);
  const [searchValues, setSearchValues] = useState({
    userName: "",
    userMobile: "",
    government: "",
    city: "",
    courierName: "",
    note: "",
    from: "",
    to: "",
    assignDatefrom: "",
    assignDateto: "",
    requestStatus: null,
    assignedStatusId: null,
    logisticTeamId: null,
    reason: "",
    rating: "",
    anotherAccount: "",
    callCenterUserRequestStatus: null,
    callCenterName: "",
  });
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [page] = useState(1);
  const [isAssignmentLogsModalOpen, setIsAssignmentLogsModalOpen] = useState(false);
  const [isCallCenterModalOpen, setIsCallCenterModalOpen] = useState(false);
  const { roles } = useSelector((state) => state.auth.authorization);
  const { RangePicker } = DatePicker;
  const searchInput = useRef(null);
  const rowSelection = {
    type: "checkbox",
    selectedRowKeys,
    onChange: (tableSelectedRowKeys, tableSelectedRows) => {
      let copyOfRows = structuredClone(selectedRows);
      if (tableSelectedRowKeys.length < copyOfRows.length) {
        copyOfRows = copyOfRows.filter((row) =>
          tableSelectedRowKeys.includes(row.requestId)
        );
      } else {
        tableSelectedRows.forEach((ele, indx) => {
          const alreadyExists = copyOfRows.find(
            (row) => row.requestId === ele.requestId
          );
          if (!alreadyExists) {
            const newRow = {
              requestId: tableSelectedRows[indx].requestId,
              note: tableSelectedRows[indx].note || "",
              assiginingDate: tableSelectedRows[indx].assigningDate || "",
              courierId: tableSelectedRows[indx].courierId || "",
            };
            copyOfRows.push(newRow);
          }
        });
      }
      setSelectedRowKeys(tableSelectedRowKeys);
      setSelectedRows(copyOfRows);

      const isSubmittable = checkIfSubmittable(copyOfRows);
      setSubmittable(isSubmittable);
    },
    getCheckboxProps: (record) => ({
      // Column configuration not to be checked
      name: record.name,
    }),
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  // const handleUpdateRequestStatus = (payload) => {
  //   if (!payload.userRequestStatusId) {
  //     const updatedEditRows = editRows.filter(
  //       (ele) => ele.requestId !== payload.requestId
  //     );
  //     setEditRows(updatedEditRows);
  //     return;
  //   }
  //   updateUserRequestStatus(
  //     payload,
  //     (res) => {
  //       if (res.success) {
  //         toastSuccess(res.message);
  //         const updatedEditRows = editRows.filter(
  //           (ele) => ele.requestId !== payload.requestId
  //         );
  //         setEditRows(updatedEditRows);
  //         setRenderMe(!renderMe);
  //       } else {
  //         toastError(res.message);
  //       }
  //     },
  //     (res) => {
  //       toastError(res.message);
  //     }
  //   );
  // };
  // const handleUserStatusChange = (requestId, property, value) => {
  //   const copyOfEditRows = structuredClone(editRows);
  //   const requestIndx = copyOfEditRows.findIndex(
  //     (ele) => ele.requestId === requestId
  //   );
  //   copyOfEditRows[requestIndx][property] = value;
  //   setEditRows(copyOfEditRows);
  // };

  const handleDataChange = (requestId, property, value) => {
    const copyOfPrev = structuredClone(selectedRows);
    const rowIndex = copyOfPrev.findIndex((ele) => ele.requestId === requestId);
    copyOfPrev[rowIndex][property] = value;
    setSelectedRows((prev) => copyOfPrev);
    const isSubmittable = checkIfSubmittable(copyOfPrev);
    setSubmittable(isSubmittable);
  };

  const checkIfSubmittable = (rows) => {
    let submittable = true;
    for (let i = 0; i < rows.length; i++) {
      if (
        // !rows[i].assiginingDate ||
        (rows[i].note && (rows[i].note.length < 3 || rows[i].note.length > 70))
      ) {
        submittable = false;
        break;
      }
    }
    return submittable;
  };

  const handleAssigningBulk = () => {
    if (selectedRows.length < 1 || !submittable) return;
    setIsAssignModalOpen(true);
  };

  const handleCancelModal = (resetRowData = true) => {
    setIsModalOpen(false);
    setIsAssignModalOpen(false);
    setIsAddressDetailsModalOpen(false);
    setIsEditStatusModalOpen(false);
    setIsAssignmentStatusModalOpen(false);
    setIsAssignModalOpen(false);
    setIsRatingModal(false);
    setIsAssignmentLogsModalOpen(false);
    setIsCallCenterModalOpen(false)
    if (resetRowData) {
      setRowData(null);
    }
  };

  const handlePageChange = (page) => {
    setLoading(true);
    setCurrentPage(page);
    localStorage.setItem("currentPage", page);
  };

  const updateSearchValues = (property, newValue, otherProperty) => {
    const copyOfSearchObj = structuredClone(searchValues);
    copyOfSearchObj[property] = newValue;
    if (otherProperty) {
      copyOfSearchObj[otherProperty.property] = otherProperty.value;
    }
    setSearchValues(copyOfSearchObj);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    const value = selectedKeys[0]?.trim() || "";
    confirm();
    switch (dataIndex) {
      case "userName":
        updateSearchValues("userName", value);
        break;
      case "mobile":
        updateSearchValues("userMobile", value);
        break;
      case "goverment":
        updateSearchValues("government", value);
        break;
      case "district":
        updateSearchValues("city", value);
        break;
      case "courierName":
        updateSearchValues("courierName", value);
        break;
      case "note":
        updateSearchValues("note", value);
        break;
      case "userRequestStatusReason":
        updateSearchValues("reason", value);
        break;
      case "rating":
        updateSearchValues("rating", value);
        break;
      case "anotherAccount":
        updateSearchValues("anotherAccount", value);
        break;
        case "callCenterName":
          updateSearchValues("callCenterName", value);
          break;
      default:
        break;
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    clearFilters();
    setLoading(true);
    switch (dataIndex) {
      case "userName":
        updateSearchValues("userName", "");
        break;
      case "mobile":
        updateSearchValues("userMobile", "");
        break;
      case "goverment":
        updateSearchValues("government", "");
        break;
      case "district":
        updateSearchValues("city", "");
        break;
      case "courierName":
        updateSearchValues("courierName", "");
        break;
      case "note":
        updateSearchValues("note", "");
        break;
      case "userRequestStatusReason":
        updateSearchValues("reason", "");
        break;
      case "rating":
        updateSearchValues("rating", "");
        break;
      case "anotherAccount":
        updateSearchValues("anotherAccount", "");
        break;
        case "callCenterName":
          updateSearchValues("callCenterName", "");
          break;
      default:
        break;
    }
    confirm();
  };

  const handleRangeDateChange = (dates, dateStr, dataIndex) => {
    if (dataIndex === "assigningDate") {
      setAssignDateSelectedRange(dates);
    } else {
      setRequestDateSelectedRange(dates);
    }

    const startDateName = dataIndex === "assigningDate" ? "assignDatefrom" : "from";
    const endDateName = dataIndex === "assigningDate" ? "assignDateto" : "to";
    if (dates) {
      const startDate = dates[0].format("YYYY-MM-DD");
      const endDate = dates[1].format("YYYY-MM-DD");
      updateSearchValues(startDateName, startDate, {
        property: endDateName,
        value: endDate,
      });
    } else {
      updateSearchValues(startDateName, "", {
        property: endDateName,
        value: "",
      });
    }
  };

  const handlePageSizeChange = (current, newSize) => {
    setLoading(true);
    setPageSize(newSize);
  };

  const handleTableChange = (_, filters) => {
    if (filters.assignedStatusId) {
      if (searchValues.assignedStatusId) {
        const filterArrToStr = [...filters.assignedStatusId]
          .sort((x, y) => x - y)
          .join("");
        const crrentArrToStr = [...searchValues.assignedStatusId]
          .sort((x, y) => x - y)
          .join("");
        if (filterArrToStr !== crrentArrToStr) {
          updateSearchValues("assignedStatusId", filters.assignedStatusId);
        }
      } else {
        updateSearchValues("assignedStatusId", filters.assignedStatusId);
      }
    } else if (searchValues.assignedStatusId) {
      updateSearchValues("assignedStatusId", null);
    }

    if (filters.userRequestStatusId) {
      if (searchValues.requestStatus) {
        const filterArrToStr = [...filters.userRequestStatusId]
          .sort((x, y) => x - y)
          .join("");
        const crrentArrToStr = [...searchValues.requestStatus]
          .sort((x, y) => x - y)
          .join("");
        if (filterArrToStr !== crrentArrToStr) {
          updateSearchValues("requestStatus", filters.userRequestStatusId);
        }
      } else {
        updateSearchValues("requestStatus", filters.userRequestStatusId);
      }
    } else if (searchValues.requestStatus) {
      updateSearchValues("requestStatus", null);
    }

    if (filters.logisticTeamId) {
      if (searchValues.logisticTeamId) {
        const filterArrToStr = [...filters.logisticTeamId]
          .sort((x, y) => x - y)
          .join("");
        const crrentArrToStr = [...searchValues.logisticTeamId]
          .sort((x, y) => x - y)
          .join("");
        if (filterArrToStr !== crrentArrToStr) {
          updateSearchValues("logisticTeamId", filters.logisticTeamId);
        }
      } else {
        updateSearchValues("logisticTeamId", filters.logisticTeamId);
      }
    } else if (searchValues.logisticTeamId) {
      updateSearchValues("logisticTeamId", null);
    }

    if (filters.rating) {
      if (searchValues.rating) {
        const filterArrToStr = [...filters.rating]
          .sort((x, y) => x - y)
          .join("");
        const crrentArrToStr = [...searchValues.rating]
          .sort((x, y) => x - y)
          .join("");
        if (filterArrToStr !== crrentArrToStr) {
          updateSearchValues("rating", filters.rating);
        }
      } else {
        updateSearchValues("rating", filters.rating);
      }
    } else if (searchValues.rating) {
      updateSearchValues("rating", null);
    }

    if (filters.callCenterUserRequestStatusId) {
      if (searchValues.callCenterUserRequestStatus) {
        const filterArrToStr = [...filters.callCenterUserRequestStatusId]
          .sort((x, y) => x - y)
          .join("");
        const crrentArrToStr = [...searchValues.callCenterUserRequestStatus]
          .sort((x, y) => x - y)
          .join("");
        if (filterArrToStr !== crrentArrToStr) {
          updateSearchValues("callCenterUserRequestStatus", filters.callCenterUserRequestStatusId);
        }
      } else {
        updateSearchValues("callCenterUserRequestStatus", filters.callCenterUserRequestStatusId);
      }
    } else if (searchValues.callCenterUserRequestStatus) {
      updateSearchValues("callCenterUserRequestStatus", null);
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onKeyDown={(e) => {
            const acceptsNumbersOnly = ["mobile"]
            if (acceptsNumbersOnly.includes(dataIndex)) {
              handleAllowNumbersOnly(e, true)
            }
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters, dataIndex, confirm)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          ></Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => true,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getColumnRangeDateProps = (dataIndex) => ({
    filterDropdown: () => (
      <div
        style={{
          padding: 8,
        }}
        className="range-picker-holder"
        onKeyDown={(e) => e.stopPropagation()}
      >
        <RangePicker
          value={
            dataIndex === "assigningDate"
              ? assignedDateSelectedRange
              : requestDateSelectedRange
          }
          allowClear
          popupClassName="main-date-picker"
          inputReadOnly={false}
          onChange={(dates, datesStr) => {
            setCurrentPage(1);
            handleRangeDateChange(dates, datesStr, dataIndex);
          }}
          onKeyDown={(e) => e.preventDefault()}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterOutlined
        style={
          (dataIndex === "assigningDate" &&
            assignedDateSelectedRange?.length > 0) ||
            (dataIndex === "date" && requestDateSelectedRange?.length > 0)
            ? { color: "blue" }
            : {}
        }
      />
    ),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });

  const columns = [
    {
      key: "userName",
      title: "USERNAME",
      dataIndex: "userName",
      fixed: "left",
      ...getColumnSearchProps("userName"),
    },
    {
      key: "mobile",
      title: "MOBILE NUMBER",
      dataIndex: "mobile",
      ...getColumnSearchProps("mobile"),
    },
    {
      key: "goverment",
      title: "GOVERNMENT",
      dataIndex: "goverment",
      ...getColumnSearchProps("goverment"),
    },
    {
      key: "district",
      title: "AREA",
      dataIndex: "district",
      ...getColumnSearchProps("district"),
    },
    {
      key: "courierName",
      title: "COURIER NAME",
      dataIndex: "courierName",
      ...getColumnSearchProps("courierName"),
    },
    {
      key: "assignedStatusId",
      title: "ASSIGNED STATUS",
      dataIndex: "assignedStatusId",
      filters: [
        { text: "Not Assigned", value: 1 },
        { text: "Assigned Not Completed", value: 2 },
        { text: "Assigned And Completed", value: 3 },
        { text: "Postponed After Assigning", value: 4 },
        { text: "Cancel After Assigning", value: 5 },
        { text: "Completed With Another Account", value: 6 },
      ],
      onFilter: (value, record) => true,
      render: (_, row) => {
        return <span>{row.assignedStatus}</span>;
      },
    },
    {
      key: "assigningDate",
      title: "ASSIGN DATE",
      dataIndex: "assigningDate",
      ...getColumnRangeDateProps("assigningDate"),
      // render: (_, row) => {
      //   const initialValue =
      //     selectedRows.find((ele) => ele.requestId === row.requestId)
      //       ?.assiginingDate || row.assigningDate;
      //   return selectedRowKeys.includes(row.requestId) ? (
      //     <Form
      //       initialValues={{
      //         assigningDate: initialValue
      //           ? dayjs(initialValue, "D MMMM YYYY")
      //           : "",
      //       }}
      //     >
      //       <Form.Item
      //         className="new-category__form-item social-orders__date-picker-holder"
      //         name="assigningDate"
      //         rules={[
      //           {
      //             required: true,
      //             message: "Please, select a date",
      //           },
      //         ]}
      //       >
      //         <DatePicker
      //           className="w-100"
      //           // showTime={{
      //           //   defaultValue: moment("00:00:00", "HH:mm:ss"),
      //           //   format: "HH:mm"
      //           // }}
      //           showNow={false}
      //           showSecond={false}
      //           format="DD MMMM YYYY"
      //           disabledDate={(current) => moment().add(-1, "days") >= current}
      //           onKeyDown={(e) => e.preventDefault()}
      //           // getPopupContainer={(triggerNode) => {
      //           //   return triggerNode.parentNode;
      //           // }}
      //           onChange={(date) => {
      //             if (date) {
      //               handleDataChange(
      //                 row.requestId,
      //                 "assiginingDate",
      //                 date.format("D MMMM YYYY")
      //               );
      //             } else {
      //               handleDataChange(row.requestId, "assiginingDate", "");
      //             }
      //           }}
      //         />
      //       </Form.Item>
      //     </Form>
      //   ) : (
      //     <span>{row.assigningDate}</span>
      //   );
      // },
    },
    // {
    //   key: "anotherAccount",
    //   title: "ANOTHER ACCOUNT",
    //   dataIndex: "anotherAccount",
    //   ...getColumnSearchProps("anotherAccount"),
    // },
    {
      key: "note",
      title: "NOTE",
      dataIndex: "note",
      ...getColumnSearchProps("note"),
      render: (_, row) => {
        const initialValue =
          selectedRows.find((ele) => ele.requestId === row.requestId)?.note ||
          row.note;
        return selectedRowKeys.includes(row.requestId) ? (
          <Form initialValues={{ note: initialValue }}>
            <Form.Item
              className="new-category__form-item"
              name="note"
              validateFirst
              rules={[
                // {
                //   required: true,
                //   message: "Please, enter note",
                // },
                {
                  min: 3,
                  message: "Minimum is 3 characters",
                },
                {
                  max: 70,
                  message: "Maximum is 70 characters",
                },
              ]}
            >
              <Input.TextArea
                placeholder="Enter note"
                className="social-orders__note-input pb-1"
                maxLength={70}
                showCount
                onChange={(e) =>
                  handleDataChange(row.requestId, "note", e.target.value)
                }
              />
            </Form.Item>
          </Form>
        ) : (
          <span>{row.note}</span>
        );
      },
    },
    {
      key: "userRequestStatusId",
      title: "USER STATUS",
      dataIndex: "userRequestStatusId",
      filters: userStatusOptions
        ? userStatusOptions.map((ele) => {
          return { text: ele.label, value: ele.value };
        })
        : [],
      // onFilter: (value, record) => record.userRequestStatusId === value,
      render: (_, row) => {
        // const indx = editRows.findIndex(
        //   (ele) => ele.requestId === row.requestId
        // );
        // return indx !== -1 ? (
        return (
          //   <Select
          //     name="userRequestStatus"
          //     className="new-category__form-item"
          //     placeholder="Select status"
          //     onChange={(value, option) => {
          //       handleUserStatusChange(
          //         row.requestId,
          //         "userRequestStatusId",
          //         option.value
          //       );
          //     }}
          //     loading={userStatusOptions ? false : true}
          //     options={userStatusOptions ? userStatusOptions : []}
          //     value={editRows[indx].userRequestStatusId}
          //   />
          // ) : (
          <span>{row.userRequestStatus}</span>
          // )
        );
      },
    },
    {
      key: "callCenterUserRequestStatusId",
      title: "CALL CENTER ACTIVITY",
      dataIndex: "callCenterUserRequestStatusId",
      filters: [
        { text: "Pending", value: 0 },
        { text: "Unreachable", value: 1 },
        { text: "Done", value: 2 },
      ],
      onFilter: (value, record) => record.callCenterUserRequestStatusId === value,
      hidden: roles?.includes(15),
      render: (_, row) => {
        return (
          <span>{row.callCenterUserRequestStatus}</span>
        );
      },
    },
    {
      key: "callCenterName",
      title: "LOGISTICS VIEW",
      dataIndex: "callCenterName",
      hidden: roles?.includes(15),
      ...getColumnSearchProps("callCenterName"),
      render: (_, row) => {
        return (
          <span>{row.callCenterName}</span>
        );
      },
    },
    {
      key: "userRequestStatusReason",
      title: "REASON",
      dataIndex: "userRequestStatusReason",
      ...getColumnSearchProps("userRequestStatusReason"),
      render: (_, row) => {
        return (
          <p className="redeem-points__address mx-auto mb-0">
            {row.userRequestStatusReason}
          </p>
        );
      },
    },
    {
      key: "logisticDate",
      title: "LOGISTIC DATE",
      dataIndex: "logisticDate",
      render: (_, row) => (
        <span>
          {row.logisticDate ? dayjs(row?.logisticDate).format("YYYY-MM-DD") : ""}
        </span>
      ),
    },
    {
      key: "logisticTeamId",
      title: "LOGISTIC NAME",
      dataIndex: "logisticTeamId",
      filters: logisticTeamsFilterOptions,
      // onFilter: (value, record) => record.logisticTeamId === value,
      render: (_, row) => {
        return roles?.includes(12) || roles?.includes(15) ? (
          <span>{row.logisticTeam}</span>
        ) : (
          <Select
            placeholder="Select Team"
            popupMatchSelectWidth={false}
            value={
              rowData?.requestId === row.requestId && selectedLogisticTeam
                ? selectedLogisticTeam.value
                : row.logisticTeamId
                  ? row.logisticTeam
                  : undefined
            }
            loading={logisticTeams ? false : true}
            onChange={(value, option) => {
              setIsAssignLogisticOpen(true);
              setRowData(row);
              setSelectedLogisticTeam(option);
            }}
            options={logisticTeams ? logisticTeams : []}
          />
        );
      },
    },
    {
      key: "rating",
      title: "RATING",
      dataIndex: "rating",
      filters: ratingFilteringOptions,
      // onFilter: (value, record) => record.rating === value,
      render: (_, row) => {
        return roles?.includes(12) || roles?.includes(15) ? (
          <span>{row.rating}</span>
        ) : (
          <Select
            placeholder="Select Rating"
            value={
              rowData?.requestId === row.requestId && selectedRatingValue
                ? selectedRatingValue
                : row.rating
                  ? row.rating
                  : undefined
            }
            loading={rating ? false : true}
            onChange={(value, option) => {
              setRowData(row);
              setIsRatingModal(true);
              setSelectedRatingValue(value);
            }}
            options={rating ? rating : []}
          />
        );
      },
    },
    {
      key: "date",
      title: "REQUEST DATE",
      dataIndex: "date",
      ...getColumnRangeDateProps("date"),
    },
    {
      key: "requestId",
      title: "ACTIONS",
      // fixed: "right",
      render: (_, row) => {
        // const editRowData = editRows.find(
        //   (ele) => ele.requestId === row.requestId
        // );
        return (
          <div className="d-flex flex-column row-gap-2">
            <div className="d-flex gap-2">
              <Button
                className="border-0 d-block w-100"
                onClick={() => {
                  setRowData(row);
                  setIsModalOpen(true);
                }}
                style={{ backgroundColor: "#589559", color: "white" }}
              >
                Request Details
              </Button>
              <Button
                className="btn--secondary d-block w-100"
                onClick={() => {
                  setRowData(row);
                  setIsAddressDetailsModalOpen(true);
                }}
              >
                Address Details
              </Button>
            </div>
            {!roles?.includes(12) && !roles?.includes(15) ? (
              <div className="d-flex gap-2">
                <Button
                  className="d-block w-100"
                  onClick={() => {
                    setIsEditStatusModalOpen(true);
                    setRowData(row);
                    setUserAssignMode("single")
                  }}
                  style={{
                    backgroundColor: "#176b87",
                    color: "white",
                    border: "none",
                  }}
                >
                  User Status
                </Button>
                <Button
                  className="d-block w-100"
                  onClick={() => {
                    setRowData(row);
                    setIsAssignmentStatusModalOpen(true);
                  }}
                  style={{
                    backgroundColor: "white ",
                    color: "#176b87",
                    border: "solid 1px #176b87 ",
                  }}
                >
                  Assignment Status
                </Button>
              </div>
            ) : (
              ""
            )}
            <div className="d-flex gap-2">
              <Button
                className="d-block w-100"
                onClick={() => {
                  setRowData(row);
                  setIsAssignmentLogsModalOpen(true);
                }}
                style={{ backgroundColor: "rgb(233 98 44)", color: "white", border: "none" }}
              >
                Assignment Logs
              </Button>
              {roles.includes(12) ? (
                <Button
                  className="d-block w-100"
                  onClick={() => {
                    setIsCallCenterModalOpen(true);
                    setRowData(row);
                  }}
                  style={{
                    backgroundColor: "#176b87",
                    color: "white",
                    border: "none",
                  }}
                >
                  Call Center Status
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
        );
      },
    },
  ];

  const exportSocialOrdersPageByPage = (payload, dataArr, baseProps) => {
    getSocialOrders(
      payload,
      (res) => {
        if (res.success) {
          dataArr.push(...res.data.response)
          const lastPage = Math.ceil(res.data.totalCount / 500);
          if (lastPage === payload.pageNumber) {
            exportDataAsExcel(
              dataArr,
              { baseProps, tab: "Social Orders Report" },
            );
            setIsExporting(false);
          } else {
            let updatedPayload = { ...payload, pageNumber: payload.pageNumber + 1 }
            exportSocialOrdersPageByPage(updatedPayload, dataArr, baseProps)
          }
        }
      },
      (res) => {
        setIsExporting(false);
        toastError(res?.data?.error);
      }
    );
  }

  const handleExportDataToExcel = async () => {
    if (isExporting) return;
    setIsExporting(true);
    const baseProps = [
      { title: "Username", prop: "userName" },
      { title: "Mobile Number", prop: "mobile" },
      { title: "Couier Name", prop: "courierName" },
      { title: "Assigned Status", prop: "assignedStatus" },
      { title: "Assign Date", prop: "assigningDate" },
      { title: "Another Account", prop: "anotherAccount" },
      { title: "Note", prop: "note" },
      { title: "User Status", prop: "userRequestStatus" },
      { title: "Call Center Activity", prop: "callCenterUserRequestStatus" },
      { title: "Reason", prop: "userRequestStatusReason" },
      { title: "Logistic Date", prop: "logisticDate" },
      { title: "Logistic Name", prop: "logisticTeam" },
      { title: "Rating", prop: "rating" },
      { title: "Date", prop: "date" },
      { title: "Government", prop: "goverment" },
      { title: "Area", prop: "district" },
      { title: "Address", prop: "address" },
      { title: "Street", prop: "street" },
      { title: "Nearest Landmark", prop: "landmark" },
      { title: "Building", prop: "buildingNo" },
      { title: "Floor", prop: "floorNo" },
      { title: "Apartment", prop: "apartmentNo" },
      // { title: "Order Supply Types", prop: "socialOrderDetails", concatprop: "supplyType" },
    ]
    const payload = {
      isExport: false,
      perPage: 500,
      pageNumber: 1,
      ...searchValues,
    };
    for (let property in payload) {
      if (!payload[property] && payload[property] !== 0) {
        delete payload[property];
      }
    }
    exportSocialOrdersPageByPage(payload, [], baseProps)
  };

  useEffect(() => {
    setLoading(true)
    const payload = {
      pageNumber: currentPage,
      perPage: pageSize,
      ...searchValues,
    };
    for (let property in payload) {
      if (!payload[property] && payload[property] !== 0) {
        delete payload[property];
      }
    }
    getSocialOrders(
      payload,
      (res) => {
        setLoading(false);
        if (res.success) {
          setDataSource(res.data.response);
          setTotalCount(res.data.totalCount);
        } else {
          toastError(res.message);
        }
        setRowData(null);
      },
      (res) => {
        setLoading(false);
        toastError(res.message);
      }
    );
  }, [renderMe, currentPage, searchValues, pageSize]);

  useEffect(() => {
    getUserRequestStatus(
      (res) => {
        if (res.success) {
          const options = res.data.map((ele) => {
            return {
              label: ele.name,
              value: ele.id,
            };
          });
          setUserStatusOptions(options);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        toastError(res.message);
      }
    );
    getLogisticTeams(
      (res) => {
        if (res.success) {
          const filterOptions = [];
          const options = res.data.map((ele) => {
            filterOptions.push({
              text: ele.name,
              value: ele.id,
            });
            return {
              label: ele.name,
              value: ele.id,
            };
          });
          setLogisticTeams(options);
          setLogisticTeamsFilterOptions(filterOptions);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        toastError(res.message);
      }
    );
    const ratingOptions = [];
    const newArray = Array.from({ length: ratingCount }, (_, index) => {
      ratingOptions.push({
        text: (index + 1).toString(),
        value: index + 1,
      });
      setRatingFilteringOptions(ratingOptions);
      return {
        label: (index + 1).toString(),
        value: index + 1,
      };
    });
    setRating(newArray);
  }, []);

  useEffect(() => {
    const storedCurrentPage = localStorage.getItem("currentPage");
    if (storedCurrentPage) {
      setCurrentPage(parseInt(storedCurrentPage));
    }
  }, [page]);

  return (
    <section className="users social-orders">
      <div className="users__all-contents">
        <div className="users__content">
          <div className="users__usersData">
            <div className="pb-3 mb-3">
              <MainHeader title="Social Orders" />
            </div>
            <div className="users__table grid-table mrg-y-30">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                rowId={"requestId"}
                title={"Social Orders Report"}
                allowExport={!(roles?.includes(12) || roles?.includes(14) || roles?.includes(15))}
                isExporting={isExporting}
                onExport={handleExportDataToExcel}
                totalCount={totalCount}
                onPageChange={handlePageChange}
                currentPage={currentPage}
                rowSelection={roles?.includes(12) || roles?.includes(15) ? null : rowSelection}
                onChange={handleTableChange}
                showSocialOrdersBtns={!(roles?.includes(12) || roles?.includes(15))}
                onCourierAssigning={
                  !roles?.includes(12) && !roles?.includes(15) ? handleAssigningBulk : () => { }
                }
                disableAssignCourier={selectedRows.length < 1 || !submittable}
                onUserStatusAssigning={() => {
                  if (!roles?.includes(12) && !roles?.includes(15) && selectedRows.length > 0) {
                    setIsEditStatusModalOpen(true);
                    setUserAssignMode("bulk");
                  }
                }}
                disableUserStatus={selectedRows.length < 1}
                onPageSizeChange={handlePageSizeChange}
              />
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ViewDetails
          open={isModalOpen}
          rowData={rowData}
          subHeader={
            <SubHeader
              bgColor="#589559"
              title={"Request Details"}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          onCancel={handleCancelModal}
        />
      )}
      {isAddressDetailsModalOpen && (
        <AddressDetails
          open={isAddressDetailsModalOpen}
          rowData={rowData}
          useAPI={true}
          EditAddress={true}
          subHeader={
            <SubHeader
              bgColor="#589559"
              title={"Address Details"}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          onSubmit={() => {
            handleCancelModal();
            setRenderMe(!renderMe);
          }}
          onCancel={handleCancelModal}
        />
      )}
      {isAssignModalOpen && (
        <AssignToCourierModal
          open={isAssignModalOpen}
          rowData={rowData}
          selectedRows={selectedRows}
          // assignMode={assignMode}
          onSubmit={() => {
            setIsAssignModalOpen(false);
            setRenderMe(!renderMe);
            setSelectedRowKeys([]);
            setSelectedRows([]);
            setRowData("")
          }}
          subHeader={
            <SubHeader
              bgColor="#589559"
              title={"Assign order to courier"}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          onCancel={handleCancelModal}
        />
      )}
      {isEditStatusModalOpen && (
        <EditStatus
          open={isEditStatusModalOpen}
          rowData={rowData}
          statusOptions={userStatusOptions}
          mode={"customer"}
          onSubmit={() => {
            setIsEditStatusModalOpen(false);
            setRenderMe(!renderMe);
            setSelectedRowKeys([]);
            setSelectedRows([]);
            setRowData("")
          }}
          selectedIds={selectedRowKeys}
          assignMode={userAssignMode}
          subHeader={
            <SubHeader
              bgColor="#589559"
              title={"Edit User Status"}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          onCancel={handleCancelModal}
        />
      )}
      {isAssignmentStatusModalOpen && (
        <EditStatus
          open={isAssignmentStatusModalOpen}
          rowData={rowData}
          statusOptions={assignmentStatusOptions}
          mode={"assignment"}
          onSubmit={() => {
            setIsAssignmentStatusModalOpen(false);
            setRenderMe(!renderMe);
            setRowData("")
          }}
          subHeader={
            <SubHeader
              bgColor="#589559"
              title={"Edit Assignment Status"}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          onCancel={handleCancelModal}
        />
      )}
      {isAssignLogisticOpen && (
        <AssignLogisticTeam
          open={isAssignLogisticOpen}
          rowData={rowData}
          selectedLogisticTeam={selectedLogisticTeam}
          onSubmit={() => {
            setIsAssignLogisticOpen(false);
            setRowData(null);
            setSelectedLogisticTeam(undefined);
            setRenderMe(!renderMe);
          }}
          subHeader={
            <SubHeader
              bgColor="#589559"
              title={"Assign logistic team"}
              closeButton
              onCancel={() => {
                setIsAssignLogisticOpen(false);
                setRowData(null);
                setSelectedLogisticTeam(undefined);
              }}
            />
          }
          onCancel={() => {
            setIsAssignLogisticOpen(false);
            setRowData(null);
            setSelectedLogisticTeam(undefined);
          }}
        />
      )}
      {isRatingModal && (
        <RatingModal
          open={isRatingModal}
          rowData={rowData}
          selectedRatingValue={selectedRatingValue}
          onSubmit={() => {
            setRenderMe(!renderMe);
            handleCancelModal(false);
          }}
          subHeader={
            <SubHeader
              bgColor="#589559"
              title={"Confirm Rating"}
              closeButton
              onCancel={() => {
                handleCancelModal();
              }}
            />
          }
          onCancel={() => {
            handleCancelModal();
          }}
        />
      )}
      {isAssignmentLogsModalOpen && (
        <AssignmentLogsModal
          open={isAssignmentLogsModalOpen}
          rowData={rowData}
          subHeader={
            <SubHeader
              bgColor="#589559"
              title={"Assignment Logs"}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          onCancel={handleCancelModal}
        />
      )}
      {isCallCenterModalOpen && (
        <CallCenterStatusModal
          open={isCallCenterModalOpen}
          rowData={rowData}
          onSubmit={() => {
            setRenderMe(!renderMe)
            handleCancelModal()
          }}
          subHeader={
            <SubHeader
              bgColor="#589559"
              title={"Call Center Status"}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          onCancel={handleCancelModal}
        />
      )}
    </section>
  );
};

export default SocialOrders;
