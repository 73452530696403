const SalesDetails = ({ data }) => {
    return (
        <div className="dealers__balance-details-modal__holder d-flex flex-column gap-3">
            {data ? (
                data.length > 0 ?
                    data.map((sale, indx) => (
                        <div key={sale.indx} className="dealers__balance-details-modal__check-holder p-3">
                            <h3 className="fs-6 d-flex align-items-center gap-2 justify-content-center">
                                <span>{sale.month} {sale.year}</span>
                            </h3>
                            <div className="d-flex align-items-center gap-1 mb-2">
                                <span className="dealers__balance-details-modal__check-holder__label">Quantity per ton:</span>
                                <span className="dealers__balance-details-modal__check-holder__value">{sale.quanityInTon} Ton</span>
                            </div>
                            <div className="d-flex align-items-center gap-1 mb-2">
                                <span className="dealers__balance-details-modal__check-holder__label">Total price:</span>
                                <span className="dealers__balance-details-modal__check-holder__value">{sale.totalprice} EGP</span>
                            </div>
                            <div className="d-flex align-items-center gap-1 mb-2">
                                <span className="dealers__balance-details-modal__check-holder__label">Bonus:</span>
                                <span className="dealers__balance-details-modal__check-holder__value">{sale.bonus} EGP</span>
                            </div>
                            <div className="d-flex align-items-center gap-1 mb-2">
                                <span className="dealers__balance-details-modal__check-holder__label">Total with bonus:</span>
                                <span className="dealers__balance-details-modal__check-holder__value">{sale.totalpriceWithBonus} EGP</span>
                            </div>
                        </div>
                    )) : (
                        <p className="dealers__balance-details-modal__empty-msg text-center fw-bold py-4 mb-0">
                            Merchant doesn't have any sales!
                        </p>
                    )) : (
                ""
            )}
        </div>
    );
}

export default SalesDetails;