import { useEffect, useState } from "react";
import { getSettings } from "../../apis/Network";
import { toastError } from "../../helpers/toasters";

// components
import SettingsForm from "./SettingsForm";
import MainHeader from "../SubComponents/MainHeader";

const Settings = () => {
    const [renderMe, setRenderMe] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        getSettings(
            (res) => {
                if (res.success) {
                    setData(res.data)
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                toastError(res.message)
            }
        )
    }, [renderMe])

    return (
        <section className="users settings">
            <div className="users__all-contents">
                <div className="users__content">
                    <div className="users__usersData pb-5">
                        <div className="pb-3 mb-3">
                            <MainHeader title="Settings" />
                        </div>
                        <div className="d-flex flex-column gap-4">
                            {data?.map((ele) => (
                                <div key={ele.id} className="settings__form-holder bg-white px-3 px-lg-4 py-4 rounded-2">
                                    <SettingsForm
                                        id={ele.id}
                                        title={ele.key}
                                        value={ele.id === 7 || ele.id === 10 ? ele.value : +ele.value}
                                        onSubmit={() => setRenderMe(!renderMe)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Settings;