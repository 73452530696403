import { useState, useEffect } from "react";
import { Row, Space, Modal, Form, Select } from "antd";
import { toastError, toastSuccess } from "../../helpers/toasters";
import { updateCallCenterStatus } from "../../apis/Network";

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";

const CallCenterStatusModal = ({
    open,
    onCancel,
    subHeader,
    rowData,
    onSubmit,
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm();
    const initialValues = {
        status: undefined,
    };
    const allCallCenterStatus = [
        { label: "Unreachable", value: 1 },
        { label: "Done", value: 2 },
    ]

    const handleFinish = (values) => {
        if (submitting) return;
        setSubmitting(true);
        const payload = {
            callCenterUserStatusId: values.status,
            requestId: rowData.requestId
        };
        updateCallCenterStatus(
            payload,
            (res) => {
                setSubmitting(false);
                if (res.success) {
                    toastSuccess(res.message);
                    onSubmit();
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setSubmitting(false);
                toastError(res.message);
            }
        );
    }

    useEffect(() => {
        if (rowData && rowData.callCenterUserRequestStatusId !== 0) {
            form.setFieldValue("status", rowData.callCenterUserRequestStatusId);
        }
    }, [rowData, form]);

    return (
        <Modal
            title={subHeader}
            open={open}
            className="msg-modal"
            footer={false}
            closable={false}
            centered
            destroyOnClose
            onCancel={onCancel}
        >
            <Form
                form={form}
                className="edit-actor__content pt-3"
                onFinish={handleFinish}
                initialValues={initialValues}
            >
                <div className="new-actor__name new-category mb-5">
                    <label className="users__parent-info-label mb-2 fw-bold" htmlFor="">
                        Status
                    </label>
                    <Form.Item
                        className="new-category__form-item"
                        name="status"
                        rules={[
                            {
                                required: true,
                                message: "Please, select the status",
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select status"
                            options={allCallCenterStatus}
                            getPopupContainer={(trigger) => trigger.parentElement}
                        />
                    </Form.Item>
                </div>
                <Row className="mrg-ys-20 mrg-x-auto mt-2 justify-content-center">
                    <Space size={20}>
                        <SecondaryButton buttonName={"Cancel"} onClick={onCancel} />
                        <PrimaryButton
                            buttonName={"Save"}
                            loading={submitting}
                            type="submit"
                        />
                    </Space>
                </Row>
            </Form>
        </Modal>
    );
};

export default CallCenterStatusModal;
