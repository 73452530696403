import { useEffect, useRef, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { convertSecondsToTimeInMinsAndSecs } from "helpers/helpfulFunctions";

// assets
import userPic from "assets/images/chat-user.png";
import adminPic from "assets/images/chat-logo.png";
import adminMic from "assets/icons/chat-mic-admin.svg";
import userMic from "assets/icons/chat-mic-user.svg";

// components
import MessageTimestamp from "./MessageTimestamp";

const MessageAudio = ({
    data,
    onPlaying,
    active,
    playingAudio,
    fromUser,
    timeOfMsg,
}) => {
    const [currentTime, setCurrentTime] = useState("");
    const audioRef = useRef();

    useEffect(() => {
        if (audioRef?.current && !active) {
            const audioElement = audioRef.current.audio.current;
            if (!audioElement.paused) {
                audioElement.pause();
                // audioElement.currentTime = 0;
            }
        }
    }, [active, audioRef, playingAudio]);

    return (
        <div className="chat__chat-holder__body__message__holder__audio d-flex align-items-center gap-2">
            <div className="flex-fill">
                <AudioPlayer
                    ref={audioRef}
                    src={data?.message}
                    timeFormat={"mm:ss"}
                    showSkipControls={false}
                    showJumpControls={false}
                    showFilledVolume={false}
                    customVolumeControls={[]}
                    defaultCurrentTime={"00:00"}
                    defaultDuration={<LoadingOutlined />}
                    customAdditionalControls={[]}
                    layout={"horizontal-reverse"}
                    autoPlayAfterSrcChange={false}
                    hasDefaultKeyBindings={false}
                    customProgressBarSection={[
                        RHAP_UI.PROGRESS_BAR,
                        // RHAP_UI.CURRENT_TIME,
                        // RHAP_UI.DURATION,
                    ]}
                    onListen={(e) => {
                        const time = convertSecondsToTimeInMinsAndSecs(e.target.currentTime);
                        setCurrentTime(() => time);
                    }}
                    onLoadedData={(e) => {
                        const time = convertSecondsToTimeInMinsAndSecs(e.target.duration);
                        setCurrentTime(() => time);
                    }}
                    customIcons={{
                        play: currentTime ? (
                            <PlayIcon fromUser={fromUser} />
                        ) : (
                            <LoadingOutlined />
                        ),
                        pause: <PauseIcon fromUser={fromUser} />,
                    }}
                    className={`rhap_loop--off chat__chat-holder__body__message__holder__audio__holder ${fromUser
                        ? "chat__chat-holder__body__message__holder__audio__holder--from-user"
                        : ""
                        }`}
                    onEnded={(e) => {
                        audioRef.current.audio.current.currentTime = 0;
                        const time = convertSecondsToTimeInMinsAndSecs(e.target.duration);
                        setCurrentTime(() => time);
                    }}
                    onPlay={() => onPlaying(data.message)}
                />
                <div className="d-flex align-items-center justify-content-between gap-1">
                    <span
                        className={`chat__chat-holder__body__message__holder__audio__holder__current-time ${fromUser
                            ? "chat__chat-holder__body__message__holder__audio__holder--from-user__current-time"
                            : ""
                            }`}
                    >
                        {currentTime}
                    </span>
                    <MessageTimestamp type={data?.type} time={timeOfMsg} />
                </div>
            </div>
            <div className="position-relative">
                <img
                    src={fromUser ? userPic : adminPic}
                    alt=""
                    className="chat__chat-holder__body__message__holder__audio__holder__sender-pic d-block img-fluid"
                />
                <img
                    src={userMic}
                    alt=""
                    className="chat__chat-holder__body__message__holder__audio__holder__mic-icon d-block img-fluid position-absolute bottom-0 start-0"
                />
            </div>
        </div>
    );
};

export default MessageAudio;

const PlayIcon = ({ fromUser }) => {
    return (
        <svg
            width="17"
            height="18"
            viewBox="0 0 17 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.55744 1.2321C0.55744 0.462302 1.39077 -0.018823 2.05744 0.366077L15.5573 8.16022C16.224 8.54512 16.224 9.50737 15.5573 9.89227L2.05744 17.6864C1.39077 18.0713 0.557441 17.5902 0.557441 16.8204L0.55744 1.2321Z"
                fill={fromUser ? "#747474" : "white"}
            />
        </svg>
    );
};

const PauseIcon = ({ fromUser }) => {
    return (
        <svg
            width="13"
            height="18"
            viewBox="0 0 13 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <line
                x1="2.5"
                y1="-1.09279e-07"
                x2="2.5"
                y2="18"
                stroke={fromUser ? "#747474" : "white"}
                strokeWidth="5"
            />
            <line
                x1="10.5"
                y1="-1.09279e-07"
                x2="10.5"
                y2="18"
                stroke={fromUser ? "#747474" : "white"}
                strokeWidth="5"
            />
        </svg>
    );
};