import { useEffect, useState } from "react";
import { Modal, Image } from "antd";
import { baseUrl } from "../../../apis/services";

// components
import GridTable from "../../SubComponents/GridTable/GridTable";

const OrderDetailsModal = ({ data, open, subHeader, onCancel }) => {
  const [dataSource, setDataSource] = useState([]);

  const columns = [
    {
      key: "supplyTypeImages",
      title: "IMAGES",
      dataIndex: "supplyTypeImages",
      render: (_, row) => row.supplyTypeImages?.length > 0 ? (
        <Image.PreviewGroup
          items={row.supplyTypeImages.map((imgUrl) => `${baseUrl}${imgUrl}`)}
        >
          <Image
            width={60}
            height={60}
            src={baseUrl + row.supplyTypeImages[0]}
          />
        </Image.PreviewGroup>
      ) : (
        ""
      ),
    },
    {
      key: "supplyType",
      title: "SUPPLY TYPE",
      dataIndex: "supplyType",
    },
    {
      key: "price",
      title: "UNIT PRICE PER GRAM",
      dataIndex: "price",
    },
    {
      key: "totalQuantity",
      title: "QUANTITY IN GRAMS",
      dataIndex: "totalQuantity",
    },
    {
      key: "deductionPercentage",
      title: "DEDUCTION PERCENTAGE",
      dataIndex: "deductionPercentage",
      render: (_, row) => {
        return <span>{row.deductionPercentage}%</span>;
      },
    },
    {
      key: "totalPoints",
      title: "TOTAL POINTS",
      dataIndex: "totalPoints",
    },
    {
      key: "totalpounds",
      title: "TOTAL POUNDS",
      dataIndex: "totalpounds",
    },

    {
      key: "marketplacePoints",
      title: "MARKETPLACE POINTS",
      dataIndex: "marketplacePoints",
    },
    {
      key: "marketplacePounds",
      title: "MARKETPLACE POUNDS",
      dataIndex: "marketplacePounds",
    },
  ];

  useEffect(() => {
    if (data) {
      const deepClone = structuredClone(data.cansSalesRequests || []);
      setDataSource(deepClone);
    }
  }, [data]);

  return (
    <Modal
      title={subHeader}
      open={open}
      width={window.innerWidth >= 992 ? "66%" : "90%"}
      className="msg-modal"
      footer={false}
      closable={false}
      centered
      destroyOnClose
      onCancel={onCancel}
    >
      <GridTable
        columns={columns}
        dataSource={dataSource}
        className="cans-sales-requests__view-details"
        rowId="salesRequestId"
      />
    </Modal>
  );
};

export default OrderDetailsModal;
