import { useState, useEffect } from "react";
import { Row, Space, Modal, Form, Select, DatePicker } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { toastError, toastSuccess } from "../../helpers/toasters";
import { assignOrdersToCourier, getActiveCouriers, sendCourierToPartnership } from "../../apis/Network";

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";

const AssignToCourierModal = ({
  mode,
  assignMode,
  open,
  onCancel,
  subHeader,
  rowData,
  onSubmit,
  selectedRows,
  sendToPartnership = false
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [submittable, setSubmittable] = useState(false);
  const [activeCouriers, setActiveCouriers] = useState(null);
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const initialValues = {
    courier: undefined,
    assigningDate: "",
  };

  const handleFinish = (values) => {
    if (submitting) return;
    setSubmitting(true);

    const dayjsDate = dayjs(values.assigningDate, "D MMMM YYYY");
    const formattedDate = dayjsDate.format("YYYY-MM-DDTHH:mm:ss");

    let newFormattedData;
    if (sendToPartnership) {
      newFormattedData = [{
        assiginingDate: formattedDate,
        partnerShipId: rowData.id,
        note: "",
      }]
    } else {
      newFormattedData = selectedRows.map((item) => {
        return {
          assiginingDate: formattedDate,
          requestId: item.requestId,
          note: item.note,
        };
      });
    }

    const payload = {
      courierId: values.courier,
      userAssignList: newFormattedData,
    };
    if (sendToPartnership) {
      sendCourierToPartnership(
        payload,
        (res) => {
          setSubmitting(false);
          if (res.success) {
            toastSuccess(res.message);
            onSubmit();
          } else {
            toastError(res.message);
          }
        },
        (res) => {
          setSubmitting(false);
          toastError(res.message);
        }
      );
    } else {
      assignOrdersToCourier(
        payload,
        (res) => {
          setSubmitting(false);
          if (res.success) {
            toastSuccess(res.message);
            onSubmit();
          } else {
            toastError(res.message);
          }
        },
        (res) => {
          setSubmitting(false);
          toastError(res.message);
        }
      );
    }
  }

  useEffect(() => {
    if (rowData && !sendCourierToPartnership) {
      form.setFieldValue("courier", rowData.courierId);
      form.setFieldValue(
        "assigningDate",
        dayjs(rowData.assigningDate, "D MMMM YYYY")
      );
    }
  }, [rowData, form]);

  useEffect(() => {
    if (selectedRows && activeCouriers) {
      const initialCourier = selectedRows[0].courierId;
      const initialDate = selectedRows[0].assiginingDate;
      let isSameCourier = true;
      let isSameDate = true;
      for (let i = 1; i < selectedRows.length; i++) {
        if (selectedRows[i].courierId !== initialCourier) {
          isSameCourier = false;
        }
        if (selectedRows[i].assiginingDate !== initialDate) {
          isSameDate = false;
        }
        if (!isSameDate && !isSameCourier) {
          break;
        }
      }

      if (isSameCourier && initialCourier) {
        let isCourierActive = activeCouriers.some(
          (courier) => courier?.value === initialCourier
        );
        if (isCourierActive) {
          form.setFieldValue("courier", initialCourier);
        }
      }
      if (isSameDate && initialDate) {
        form.setFieldValue("assigningDate", dayjs(initialDate, "D MMMM YYYY"));
      }
    }
  }, [selectedRows, activeCouriers]);

  useEffect(() => {
    getActiveCouriers(
      (res) => {
        if (res.success) {
          const options = [];
          res.data.forEach((courier) => {
            const obj = {
              label: courier.userName,
              value: courier.id,
            };
            options.push(obj);
          });
          setActiveCouriers(options);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        toastError(res.message);
      }
    );
  }, []);

  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
  }, [values, form]);
  
  return (
    <Modal
      title={subHeader}
      open={open}
      className="msg-modal"
      footer={false}
      closable={false}
      centered
      destroyOnClose
      onCancel={onCancel}
    >
      <Form
        form={form}
        className="edit-actor__content pt-3"
        onFinish={handleFinish}
        initialValues={initialValues}
      >
        <div className="new-actor__name new-category mb-5">
          <label className="users__parent-info-label mb-2 fw-bold" htmlFor="">
            Courier
          </label>
          <Form.Item
            className="new-category__form-item"
            name="courier"
            rules={[
              {
                required: true,
                message: "Please, select courier",
              },
            ]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              placeholder="Select courier"
              loading={activeCouriers ? false : true}
              options={activeCouriers ? activeCouriers : []}
              getPopupContainer={(trigger) => trigger.parentElement}
            />
          </Form.Item>
        </div>
        <div className="new-actor__name new-category mb-5">
          <label className="users__parent-info-label mb-2 fw-bold" htmlFor="">
            Assign Date
          </label>
          <Form.Item
            className="new-category__form-item social-orders__date-picker-holder"
            name="assigningDate"
            rules={[
              {
                required: true,
                message: "Please, select a date",
              },
            ]}
          >
            <DatePicker
              className="w-100"
              showNow={false}
              showSecond={false}
              format="D MMMM YYYY"
              disabledDate={(current) => moment().add(-1, "days") >= current}
              onKeyDown={(e) => e.preventDefault()}
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
            />
          </Form.Item>
        </div>
        {/* {assignMode === "single" ? (
                    <div className="new-actor__name new-category mb-5">
                        <label className="users__parent-info-label mb-2 fw-bold" htmlFor="">
                            Note
                        </label>
                        <Form.Item
                            className="new-category__form-item"
                            name="note"
                            validateFirst
                            rules={[
                                {
                                    required: true,
                                    message: "Please, select note",
                                },
                                {
                                    min: 3,
                                    message: "Minimum is 3 characters"
                                },
                                {
                                    max: 70,
                                    message: "Maximum is 70 characters"
                                },
                            ]}
                        >
                            <Input.TextArea
                                placeholder="Enter note"
                                maxLength={70}
                                showCount
                            />
                        </Form.Item>
                    </div>
                ) : (
                    ""
                )} */}
        <Row className="mrg-ys-20 mrg-x-auto mt-2 justify-content-center">
          <Space size={20}>
            <SecondaryButton buttonName={"Cancel"} onClick={onCancel} />
            <PrimaryButton
              buttonName={"Save"}
              loading={submitting}
              disableMe={!submittable || (!selectedRows && !sendCourierToPartnership)}
              type="submit"
            />
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};

export default AssignToCourierModal;
