import { useEffect, useState } from 'react';
import { Row, Space, InputNumber, Modal, Form, Col, Input, Select } from 'antd';
import { toastError, toastSuccess } from '../../helpers/toasters';
import { handleAllowNumbersOnly } from '../../helpers/helpfulFunctions';
import { addSalesRequestsReturn, getCategoriesForList, getMerchants } from "../../apis/Network";

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";

const ReturnModal = ({
    onSubmit,
    open,
    onCancel,
    SubHeader,
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [merchantsList, setMerchantsList] = useState(null);
    const [typesList, setTypesList] = useState(null);
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const initialValues = {
        price: "",
        quantity: "",
        note: "",
        merchant: undefined,
        type: undefined,
    }

    const handlePriceValidation = (_, value) => {
        if (!value && value !== 0) {
            return Promise.reject(new Error("Please, enter the price"))
        } else if (value <= 0) {
            return Promise.reject(new Error(`Minimum value should be greater than 0`));
        } else {
            return Promise.resolve()
        }
    }

    const handleQuantityValidation = (_, value) => {
        if (!value && value !== 0) {
            return Promise.reject(new Error("Please, enter the quantity"))
        } else if (value <= 0) {
            return Promise.reject(new Error(`Minimum value should be greater than 0`));
        } else {
            return Promise.resolve()
        }
    }

    const handleOnFinish = (values) => {
        if (submitting) return;
        setSubmitting(true);

        const payload = {
            dealerId: values.merchant,
            typeId: values.type,
            totalQuantity: values.quantity,
            note: values.note.trim(),
            perKiloPrice: values.price
        }

        addSalesRequestsReturn(
            payload,
            (res) => {
                setSubmitting(false)
                if (res.success) {
                    toastSuccess(res.message);
                    onSubmit();
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setSubmitting(false)
                toastError(res.message);
            }
        )
    }

    useEffect(() => {
        getMerchants(
            (res) => {
                if (res.success) {
                    const data = res.data.map((merchant) => {
                        return { value: merchant.id, label: merchant.name };
                    })
                    setMerchantsList(data)
                } else {
                    toastError(res.message)
                }
            },
            (res) => {
                toastError(res?.data?.error)
            })
    }, [])

    useEffect(() => {
        getCategoriesForList(
            (res) => {
                if (res.success) {
                    const data = res.data.map((type) => {
                        return { value: type.id, label: type.name };
                    })
                    setTypesList(data)
                } else {
                    toastError(res.message)
                }
            },
            (res) => {
                toastError(res?.data?.error)
            })
    }, [])

    return (
        <Modal
            title={SubHeader}
            open={open}
            className="msg-modal"
            footer={false}
            closable={false}
            centered
            destroyOnClose
            onCancel={onCancel}
        >
            <Form
                form={form}
                className="edit-actor__content"
                onFinish={handleOnFinish}
                initialValues={initialValues}
            >
                <Row>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2">
                                Merchant
                            </label>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: "Please, select a merchant",
                                    },
                                ]}
                                name="merchant"
                                className="new-category__form-item"
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="label"
                                    name="merchant"
                                    className="new-category__form-item"
                                    placeholder="Select merchant"
                                    loading={merchantsList ? false : true}
                                    options={merchantsList ? merchantsList : []}
                                    getPopupContainer={trigger => trigger.parentElement}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2">
                                Type
                            </label>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: "Please, select a type",
                                    },
                                ]}
                                name="type"
                                className="new-category__form-item"
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="label"
                                    name="type"
                                    className="new-category__form-item"
                                    placeholder="Select type"
                                    loading={typesList ? false : true}
                                    options={typesList ? typesList : []}
                                    getPopupContainer={trigger => trigger.parentElement}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <div className="lang-name wd-100 flex ">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Price Per Kilo
                                </label>
                            </div>
                            <Form.Item
                                className="new-category__form-item"
                                name="price"
                                rules={[
                                    { validator: handlePriceValidation }
                                ]}
                            >
                                <InputNumber
                                    className="w-100 d-block search__searchField py-1"
                                    placeholder="Enter price per kilo"
                                    onKeyDown={(e) => handleAllowNumbersOnly(e, true)}
                                    controls={false}
                                    keyboard={false}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <div className="lang-name wd-100 flex">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Total Quantity
                                </label>
                            </div>
                            <Form.Item
                                className="new-category__form-item"
                                name="quantity"
                                rules={[
                                    { validator: handleQuantityValidation }
                                ]}
                            >
                                <InputNumber
                                    className="w-100 d-block search__searchField py-1"
                                    placeholder="Enter total quantity"
                                    onKeyDown={(e) => handleAllowNumbersOnly(e, true)}
                                    controls={false}
                                    keyboard={false}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2" htmlFor="">
                                Note
                            </label>
                            <Form.Item
                                className="new-category__form-item"
                                name="note"
                                rules={[
                                    {
                                        whitespace: true,
                                        message: "Please, enter a valid note",
                                    },
                                ]}
                            >
                                <TextArea
                                    maxLength={100}
                                    showCount
                                    className="store-items__item-form__description"
                                    autoSize={{ minRows: 2, maxRows: 6 }}
                                    placeholder="Enter note"
                                />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row className="mrg-ys-20 mrg-x-auto pt-2 justify-content-center">
                    <Space size={20}>
                        <SecondaryButton
                            buttonName={"Cancel"}
                            onClick={onCancel}
                        />
                        <PrimaryButton
                            buttonName={"Add"}
                            loading={submitting}
                            type="submit"
                            disableMe={!merchantsList || !typesList}
                        />
                    </Space>
                </Row>
            </Form>
        </Modal>
    );
}

export default ReturnModal;