import { CloseOutlined } from "@ant-design/icons";
import { Input, Select, Space } from "antd";
import { dropdownStyle } from "../../helpers/helpfulFunctions";
import { useEffect, useState } from "react";
import PrimaryButton from "./Buttons/PrimaryButton";
import SecondaryButton from "./Buttons/SecondaryButton";

function SubHeader({
  bgColor,
  title,
  titleColor,
  closeButton,
  onCancel,
  history,
  styling,
  handleHistory,
  noPadding,
  method,
  rtl,
  secondaryBtnName,
  primaryBtnName,
  primaryBtnAction,
  secondaryBtnAction,
  isAnswerFilters = false,
  exportAsExcel = false,
  totalQuantity = 0,
  totalPrice = 0,
}) {
  const allMonths = [
    { label: "All", value: null },
    { label: "January", value: "01" },
    { label: "February", value: "02" },
    { label: "March", value: "03" },
    { label: "April", value: "04" },
    { label: "May", value: "05" },
    { label: "June", value: "06" },
    { label: "July", value: "07" },
    { label: "August", value: "08" },
    { label: "September", value: "09" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
  ];
  const [isMonthDisable, setIsMonthDisable] = useState(true);
  const [year, setYear] = useState(null);
  const [years, setYears] = useState([{ label: "All", value: null }]);
  const [month, setMonth] = useState(null);
  const [months, setMonths] = useState(allMonths);
  const trendingShowsGredientColor = {
    background: `${
      bgColor === "gradient"
        ? "linear-gradient(180deg, #B778E7 0%, #5C3C74 100%)"
        : bgColor
    }`,
  };

  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();
  let currentMonth = currentDate.getMonth() + 1;
  for (let i = currentYear; i >= 2020; i--) {
    years.push({ label: i.toString(), value: i.toString() });
  }

  useEffect(() => {
    if (+year === currentYear) {
      const m = allMonths.filter((month) => month?.value <= currentMonth);
      setMonths(m);
    } else {
      setMonths(allMonths);
    }
    //eslint-disable-next-line
  }, [year]);

  const handleCloseButton = () => {
    onCancel();
  };
  const handleChangeYears = (year) => {
    setYear(year);
    setMonth(null);
    if (year) {
      setIsMonthDisable(false);
    } else {
      setIsMonthDisable(true);
    }
  };
  const handleChangeMonths = (month) => {
    setMonth(month);
  };
  const handleFilterOnClick = () => {
    primaryBtnAction({ year, month });
  };

  return (
    <>
      {rtl ? (
        <div
          style={styling}
          className={closeButton || noPadding ? "subHeader px-0" : "subHeader"}
        >
          <div
            className="subHeader__title-rtl"
            style={{ color: `${titleColor ? titleColor : ""}` }}
          >
            {title}
          </div>
          <div
            className="subHeader__color"
            style={trendingShowsGredientColor}
          ></div>
        </div>
      ) : (
        <div
          style={styling}
          className={closeButton || noPadding ? "subHeader px-0" : "subHeader"}
        >
          <Space size={20}>
            {" "}
            <div
              className="subHeader__color"
              style={trendingShowsGredientColor}
            ></div>
            <div
              className="subHeader__title"
              style={{ color: `${titleColor ? titleColor : ""}` }}
            >
              {title}
            </div>
            {totalQuantity !== 0 && (
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "green",
                }}
              >{`Total Quantity : ${totalQuantity} KG`}</div>
            )}
            {totalPrice !== 0 && (
              <div
                style={{ fontSize: "18px", fontWeight: "bold", color: "green" }}
              >{`Total Price : ${totalPrice} LE`}</div>
            )}
          </Space>

          {closeButton && (
            <button className="subHeader__close " onClick={handleCloseButton}>
              <CloseOutlined
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#33383F",
                }}
              />
            </button>
          )}
          {history && (
            <div
              className=" flex items-center dir-row justify-around  "
              style={{ width: "200px" }}
            >
              <div className="history">Last</div>
              <div className="flex justify-center ">
                <Input
                  className="search__searchField input-error-regexp pad-y-5 wd-75"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => handleHistory(e.target.value)}
                />
              </div>

              <div className="history ">{method}</div>
            </div>
          )}
          {isAnswerFilters && exportAsExcel && (
            <Space size={20}>
              <Select
                className="select-secondary-style "
                onChange={handleChangeYears}
                dropdownStyle={dropdownStyle}
                value={year}
                options={years}
                getPopupContainer={trigger => trigger.parentElement}
                placeholder="YEAR"
              />
              <Select
                className="select-secondary-style "
                onChange={handleChangeMonths}
                dropdownStyle={dropdownStyle}
                value={month}
                options={months}
                placeholder="MONTH"
                getPopupContainer={trigger => trigger.parentElement}
                disabled={isMonthDisable}
              />
              <PrimaryButton
                buttonName={primaryBtnName}
                onClick={handleFilterOnClick}
              />
              <SecondaryButton
                buttonName={secondaryBtnName}
                onClick={secondaryBtnAction}
              />
            </Space>
          )}
        </div>
      )}
    </>
  );
}

export default SubHeader;
