import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Button, Input } from "antd";
import WebsiteTitle from "../../helpers/WebsiteTitle";
import { toastError, toastSuccess } from "../../helpers/toasters";
import { signinAdmin } from "../../apis/Network";
import ROUTES from "../../constants/NewRoutes";
import { login } from "../../store/authorization";
import { updateCurrentPath } from "../../store/dynamicData";

function SignIn() {
  const [loading, setLoading] = useState(false);
  const { token, roles } = useSelector((state) => state.auth.authorization);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function onFinish(values) {
    if (values?.userName && values?.password) {
      setLoading(true);
      const data = { ...values, ForScraption: true }
      signinAdmin(
        data,
        (success) => {
          setLoading(false);
          if (success?.success) {
            setLoading(false);
            toastSuccess(success.message);
            dispatch(login(success.data));
            // if (success.data.roles.includes(7) && !success.data.roles.includes(1)) {
            //   dispatch(updateCurrentPath("types"));
            //   navigate(ROUTES.CATEGORIES);
            // } else
            if (success.data.roles.includes(10) || success.data.roles.includes(12) || success.data.roles.includes(14)) {
              dispatch(updateCurrentPath("social-orders"));
              navigate(ROUTES.SOCIAL_ORDERS);
            } else if (success.data.roles.includes(11)) {
              dispatch(updateCurrentPath("scrappers"));
              navigate(ROUTES.DEALERS);
            } else if (success.data.roles.includes(15)) {
              dispatch(updateCurrentPath("chat"));
              navigate(ROUTES.CHAT);
            } else {
              dispatch(updateCurrentPath("scrap-sales-requests"));
              navigate(ROUTES.SCRAP_SALES_REQUESTS);
            }
          } else {
            toastError(success.message);
            setLoading(false);
          }
        },
        (fail) => {
          setLoading(false);
          toastError(fail?.data?.error);
        }
      );
    }
  }

  useEffect(() => {
    if (token && roles) {
      // if (roles.includes(7) && !roles.includes(1)) {
      //   navigate(ROUTES.CATEGORIES, { replace: true })
      // } else 
      if (roles.includes(10) || roles.includes(12) || roles.includes(14)) {
        navigate(ROUTES.SOCIAL_ORDERS, { replace: true })
      } else if (roles.includes(11)) {
        navigate(ROUTES.DEALERS, { replace: true })
      } else if (roles.includes(15)) {
        navigate(ROUTES.CHAT, { replace: true })
      } else {
        navigate(ROUTES.SCRAP_SALES_REQUESTS, { replace: true })
      }
    }
  }, [token, roles])

  return (
    <section className="sign-in">
      <WebsiteTitle title="Login" />
      <div className="content p-4">
        <div
          className="pb-4"
          style={{ borderRadius: "20px", backgroundColor: "white" }}
        >
          <div className="title text-center mrg-ye-50 py-3 mb-4">Sign In</div>
          <Row>
            <Col
              xs={{ offset: 3, span: 18 }}
              sm={{ offset: 3, span: 18 }}
              md={{ offset: 6, span: 12 }}
            >
              <Form onFinish={onFinish}>
                <Row gutter={[0, 8]}>
                  <Col span={24}>
                    <Form.Item
                      name="userName"
                      placeholder={t("User Name")}
                      rules={[
                        {
                          required: true,
                          message: "Please provide your user name",
                        },
                      ]}
                    >
                      <Input className={"form-item"} placeholder="User Name" />
                    </Form.Item>
                  </Col>
                  <Col span={24} className="mb-3">
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please provide your password ",
                        },
                      ]}
                    >
                      <Input.Password
                        className={"form-item"}
                        placeholder="Password"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ offset: 3, span: 18 }}
                    sm={{ offset: 3, span: 18 }}
                    md={{ offset: 5, span: 14 }}
                  >
                    <Button
                      loading={loading}
                      className="btn-login text-uppercase"
                      htmlType="submit"
                    >
                      Sign In
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
}

export default SignIn;
