import { PlusOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";

const MainHeader = ({
  title = null,
  info = null,
  buttonName,
  onButtonClick,
  disableBtn,
}) => {
  return (
    <header className="main-header d-flex justify-content-between align-items-center gap-3">
      {title && <h1 className="text-white mb-0 main-header__title">{title}</h1>}
      {info && (
        <>
          <strong className="text-white mt-2">{`${info?.courierName} : ${info?.courierMobile}`}</strong>
        </>
      )}

      <Space size={10}>
        {buttonName && (
          <Button
            onClick={() => {
              if (!disableBtn) onButtonClick();
            }}
            htmlType="button"
            disabled={disableBtn}
            className="main-header__plus-button border-0 outline-none shadow-none"
            icon={<PlusOutlined />}
          >
            {buttonName}
          </Button>
        )}
      </Space>
    </header>
  );
};

export default MainHeader;
