import { useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, Row, Select, Space } from "antd";
import {
  getSupplyingTypesForCansRequest,
  getQuantityUnits,
  addCansSaleRequest,
  getCollectionTypes,
} from "../../../apis/Network";
import { toastError, toastSuccess } from "../../../helpers/toasters";
import { handleAllowNumbersOnly, validatePhoneNumber } from "../../../helpers/helpfulFunctions";

// components
import SecondaryButton from "../../SubComponents/Buttons/SecondaryButton";
import PrimaryButton from "../../SubComponents/Buttons/PrimaryButton";
import FormItem from "./FormItem";

const AddRequestModal = ({ open, subHeader, onCancel, onSubmit }) => {
  const [activeSupplyingTypes, setActiveSupplyingTypes] = useState(null);
  const [activeCollectionTypes, setActiveCollectionTypes] = useState(null);
  const [quantityUnits, setQuantityUnits] = useState(null);
  const [emptyItemsError, setEmptyItemsError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [itemsCounter, setItemsCounter] = useState(0);
  const [createdItems, setCreatedItems] = useState([
    {
      id: 0,
      supplyTypeId: "",
      unitId: 1,
      quantity: "",
      deductionPercentage: 0,
    },
  ]);
  const [form] = Form.useForm();
  const initialValues = {
    mobile: "",
    supplyTypeId: "",
    unitId: 1,
    quantity: "",
    "deductionPercentage-0": 0,
    collectionTypeId: undefined,
  };

  const validateMobileNumber = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Please, Enter mobile number"));
    } else if (!validatePhoneNumber(value)) {
      return Promise.reject(new Error("Please, Enter a valid mobile number"));
    } else {
      return Promise.resolve();
    }
  };

  const handleAddNewItem = () => {
    const itemId = itemsCounter + 1;
    setItemsCounter(itemId);
    const newItem = {
      id: itemId,
      supplyTypeId: "",
      unitId: quantityUnits ? quantityUnits[0]?.value : "",
      quantity: "",
      deductionPercentage: 0,
    };
    form.setFieldValue(`deductionPercentage-${itemId}`, 0)
    const createdItemsCopy = structuredClone(createdItems);
    createdItemsCopy.push(newItem);
    setCreatedItems(createdItemsCopy);
    if (emptyItemsError) {
      setEmptyItemsError(false);
    }
  };

  const handleDeleteItem = (id) => {
    const updatedCreatedItems = createdItems.filter((ele) => ele.id !== id);
    setCreatedItems(updatedCreatedItems);
  };

  const handleUpdateItem = (id, property, value) => {
    const createdItemsCopy = structuredClone(createdItems);
    const itemIndex = createdItemsCopy.findIndex((ele) => ele.id === id);
    createdItemsCopy[itemIndex][property] = value;
    setCreatedItems(createdItemsCopy);
  };

  const validateItems = () => {
    if (createdItems.length === 0) {
      setEmptyItemsError(true);
      return;
    }
  };

  const handleSubmit = (values) => {
    if (submitting) return;
    setSubmitting(true);

    const requests = createdItems.map((item) => {
      return {
        supplyTypeId: item.supplyTypeId,
        unitId: item.unitId,
        quantity: item.quantity,
        deductionPercentage: item.deductionPercentage
      };
    });
    const payload = {
      mobile: values.mobile,
      collectionTypeId: values.collectionTypeId,
      requests,
    };
    addCansSaleRequest(
      payload,
      (res) => {
        setSubmitting(false);
        if (res.success) {
          toastSuccess(res.message);
          onSubmit();
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setSubmitting(false);
        toastError(res.message);
      }
    );
  };

  useEffect(() => {
    getQuantityUnits(
      (res) => {
        if (res.success) {
          const options = res.data.map((unit) => {
            return { label: unit.name, value: unit.id };
          });
          setQuantityUnits(options);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        toastError(res.message);
      }
    );
    getSupplyingTypesForCansRequest(
      (res) => {
        if (res.success) {
          const options = res.data.map((unit) => {
            return { label: unit.name, value: unit.id };
          });
          setActiveSupplyingTypes(options);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        toastError(res.message);
      }
    );
    getCollectionTypes(
      (res) => {
        if (res.success) {
          const options = res.data.map((unit) => {
            return { label: unit.name, value: unit.id };
          });
          setActiveCollectionTypes(options);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        toastError(res.message);
      }
    );
  }, []);

  return (
    <Modal
      title={subHeader}
      open={open}
      className="msg-modal"
      footer={false}
      closable={false}
      centered
      destroyOnClose
      onCancel={onCancel}
    >
      <Form
        className="cans-sales-requests__add-request-form pt-3"
        initialValues={initialValues}
        onFinish={handleSubmit}
        form={form}
      >
        <Row>
          <Col span={24}>
            <div className="mb--4 d-flex flex-column">
              <label className="mb-1 text--secondary-1 fw-semibold">
                Mobile Number
              </label>
              <Form.Item
                name="mobile"
                rules={[{ validator: validateMobileNumber }]}
              >
                <Input
                  type="text"
                  className="search__searchField py-1"
                  placeholder="Enter mobile number"
                  autoComplete="new-password"
                  onKeyDown={(e) => handleAllowNumbersOnly(e, false)}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={24}>
            <div className="mb-3">
              <div className="d-flex flex-column gap-3">
                {createdItems.map((ele, indx) => (
                  <FormItem
                    key={ele.id}
                    data={ele}
                    onDelete={handleDeleteItem}
                    onUpdateItem={handleUpdateItem}
                    supplyingTypes={activeSupplyingTypes}
                    quantityUnits={quantityUnits}
                    form={form}
                    order={indx}
                  />
                ))}
              </div>
              {emptyItemsError ? (
                <div className="ant-form-item-explain ant-form-item-explain-connected">
                  <p className="ant-form-item-explain-error">
                    Please, add items
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="d-flex justify-content-end">
              <Button
                className="cans-sales-requests__add-request-form__add-request-btn text-white border-0 outline-none shadow-none"
                onClick={handleAddNewItem}
              >
                +
              </Button>
            </div>
          </Col>
          <Col span={24}>
            <div className="lang-name wd-100 flex">
              <label className="text--secondary-1 mb-2 fw-semibold" htmlFor="">
                Collection Types
              </label>
            </div>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please, Select the collection type",
                },
              ]}
              name="collectionTypeId"
              className="new-category__form-item"
            >
              <Select
                name="collectionTypeId"
                className="new-category__form-item"
                placeholder="Select collection type"
                loading={activeCollectionTypes ? false : true}
                options={activeCollectionTypes ? activeCollectionTypes : []}
                getPopupContainer={(trigger) => trigger.parentElement}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mrg-ys-20 mrg-x-auto mt-2 justify-content-center">
          <Space size={20}>
            <SecondaryButton buttonName={"Cancel"} onClick={onCancel} />
            <PrimaryButton
              buttonName={"Submit"}
              loading={submitting}
              onClick={validateItems}
              type="submit"
            />
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddRequestModal;
