import { useState, useEffect, useRef } from "react";
import autoAnimate from "@formkit/auto-animate";

// components
import ChatDateSeperator from "./ChatDateSeperator";
import ChatMessage from "./ChatMessage";

const ChatBody = ({ data, searchValue }) => {
    const [playingAudio, setPlayingAudio] = useState("");

    const parent1 = useRef(null);
    const parent2 = useRef(null);

    useEffect(() => {
        parent1.current && autoAnimate(parent1.current);
    }, [parent1, parent1.current]);

    useEffect(() => {
        parent2.current && autoAnimate(parent2.current);
    }, [parent2, parent2.current]);

    return (
        <div
            ref={parent1}
            className="d-flex flex-column flex-fill justify-content-start"
        >
            {data?.messages?.length ? (
                data?.messages?.map((obj) => {
                    return (
                        <div key={obj.date}>
                            <ChatDateSeperator date={obj.date} />
                            <div ref={parent2} className='d-flex flex-column gap-2'>
                                {
                                    obj.messages.map((msg, indx, arr) => (
                                        <ChatMessage
                                            key={`${msg.adminId ? `${msg.adminId}-` : ""}${msg.message}-${msg.date}`}
                                            data={msg}
                                            userName={data?.name}
                                            searchValue={searchValue}
                                            onPlayingAudio={(url) => setPlayingAudio(url)}
                                            playingAudio={playingAudio}
                                            firstMessageOfSequence={
                                                indx === 0 ||
                                                (msg.adminId !== arr[indx - 1].adminId)
                                            }
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    )
                })
            ) : searchValue ? (
                <p className="text-center py-5">No results found!</p>
            ) : (
                ""
            )}
        </div>
    );
}

export default ChatBody;