import { utils, writeFile } from "xlsx";
import store from "../store";
import { logout } from "../store/authorization";
import { emptyStaticData } from "../store/staticData";
import { emptyDynamicData, updateCurrentPath } from "../store/dynamicData";

export const handleLogout = () => {
  store.dispatch(logout())
  store.dispatch(emptyStaticData())
  store.dispatch(emptyDynamicData())
  store.dispatch(updateCurrentPath())
};

export const handleAllowNumbersOnly = (e, allowFloat = false) => {
  const isCopyingOrPasting = e.ctrlKey && /^[cva]$/i.test(e.key)
  if (
    e.key === "Backspace" ||
    (allowFloat && e.key === ".") ||
    e.key.startsWith("ArrowLeft") ||
    e.key.startsWith("ArrowRight") ||
    isCopyingOrPasting
  ) {
    return;
  }
  if (/\D+/gi.test(e.key)) {
    e.preventDefault();
  }
};

// Checks if phone number is a valid egyptian phone number
export const validatePhoneNumber = (phone) => {
  let phoneLen = 11;
  let charLen = 2; // will validate that user enters 0 and first two numbers inputed are 01
  if (phone.startsWith("2")) {
    phoneLen = 12;
    charLen = 3; // will validate that user enters 2 and first three numbers inputed are 201
  }
  if (
    !/^\d+$/.test(phone) ||
    phone.length > phoneLen ||
    phone.length < phoneLen ||
    (phone.length >= charLen &&
      !phone.startsWith(`${charLen === 2 ? "0" : "20"}1`)) ||
    (phone.length >= charLen + 1 &&
      !phone.startsWith(`${charLen === 2 ? "0" : "20"}10`) &&
      !phone.startsWith(`${charLen === 2 ? "0" : "20"}11`) &&
      !phone.startsWith(`${charLen === 2 ? "0" : "20"}12`) &&
      !phone.startsWith(`${charLen === 2 ? "0" : "20"}15`))
  ) {
    return false;
  } else {
    return true;
  }
};

// export data to excel
export const exportDataAsExcel = (
  dataToExport,
  config,
) => {
  const sheetData = [];
  dataToExport.forEach((baseObj) => {
    // set base object
    const sheetObj = {};
    config.baseProps.forEach((probObj) => {
      if (probObj.values) {
        sheetObj[probObj.title] = probObj.values[baseObj[probObj.prop]]
      } else if (probObj.concatprop) {
        let displayedValue = "";
        baseObj[probObj.prop].forEach((ele, indx, arr) => {
          displayedValue = displayedValue.concat(ele[probObj.concatprop])
          if (indx !== arr.length - 1) {
            displayedValue = displayedValue.concat(", ")
          }
        })
        sheetObj[probObj.title] = displayedValue
      } else {
        sheetObj[probObj.title] = baseObj[probObj.prop]
      }
    })
    // set nested objects if exists
    if (config.nestedProps && baseObj[config.nestedProps.prop].length > 0) {
      baseObj[config.nestedProps.prop].forEach((nestedObj, indx) => {
        let extraObj = {}
        if (indx === 0) {
          extraObj = { ...sheetObj }
        }
        extraObj[config.nestedProps.requestTitle || "#"] = indx + 1
        config.nestedProps.data.forEach((obj) => {
          extraObj[obj.title] = nestedObj[obj.prop]
        })
        sheetData.push(extraObj)
      })
    } else {
      sheetData.push(sheetObj)
    }
  })
  if (config.totalObj) {
    sheetData.push(config.totalObj);
  }
  const ws = utils.json_to_sheet(sheetData);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, "Data");
  writeFile(wb, `${config.tab}.xlsx`);
};

export const convertBytesToSize = (bytes) => {
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
}

export const getFilePathFromDownloadURL = (url) => {
  const urlArr = url.split("/v0/")
  const base = urlArr[0].split(".com")[0]
  const filePath = `${base}.com/v0/${urlArr[1]}`;
  return filePath;
}

export const downloadFileFromBlob = (blob, name) => {
  const blobUrl = URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(blobUrl);
  link.remove();
}

export const convertSecondsToTimeInMinsAndSecs = (seconds) => {
  const mins = parseInt(seconds / 60);
  const secs = parseInt(seconds % 60);
  return `${mins <= 9 ? "0" : ""}${mins}:${secs <= 9 ? "0" : ""}${secs}`;
};

export const dropdownStyle = {
  border: " solid 1px #CEE2F6",
  borderRadius: "12px",
  boxShadow: "0px 8px 10px #0000001A ",
};
