// assets
import ArrowIcon from "../../../assets/icons/double-angles-down.svg"

const NewMessagesAlertBtn = ({ onClick }) => {
    return (
        <button
            type="button"
            onClick={onClick}
            className='chat__chat-holder__new-messages-alert position-absolute d-inline-flex align-items-center gap-1 border-0 outline-none'
        >
            New Messages
            <img src={ArrowIcon} alt="" className="img-fluid d-block" />
        </button>
    );
}

export default NewMessagesAlertBtn;