import { useState, useEffect, useRef } from "react";
import { Button, Space, Input } from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { toastError } from "../../helpers/toasters";
import { getMerchantScrapTypes } from "../../apis/Network";

// components
import SupplyModal from "./SupplyModal";
import ViewPricesList from "./ViewPricesList";
import SubHeader from '../SubComponents/SubHeader';
import GridTable from '../SubComponents/GridTable/GridTable';
import MainHeader from "../SubComponents/MainHeader";

const MerchantScrapTypes = () => {
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState("add");
  const [rowData, setRowData] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [isPriceListModalVisible, setIsPriceListModalVisible] = useState(false);
  const [isViewPricesListModalVisible, setIsViewPricesListModalVisible] = useState(false);
  const [renderMe, setRenderMe] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleOpenModal = (row) => {
    setRowData(row);
    setIsPriceListModalVisible(true);
  };

  const onCancel = () => {
    setIsPriceListModalVisible(false);
    setMode("add");
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text || text === 0 ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      key: "name",
      title: "NAME (EN)",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },
    {
      key: "nameAr",
      title: "NAME (AR)",
      dataIndex: "nameAr",
      ...getColumnSearchProps("nameAr"),
    },
    {
      key: "id",
      title: "ACTIONS",
      dataIndex: "action",
      render: (_, row) => {
        return (
          <Space size={5}>
            {row.merchamtPrices.length ? (
              <>
                <Button
                  onClick={() => {
                    handleOpenModal(row)
                    setMode("edit");
                  }}
                  className="border-0"
                  style={{ backgroundColor: "#589559", color: "white" }}
                >
                  Edit Price List
                </Button>
                <Button
                  style={{
                    backgroundColor: "white",
                    color: "#176b87",
                    border: "solid 1px #176b87 ",
                  }}
                  onClick={() => {
                    setRowData(row)
                    setIsViewPricesListModalVisible(true)
                  }}>
                  View Prices
                </Button>
              </>
            ) : (
              <Button
                className="main-header__plus-button outline-none shadow-none"
                icon={<PlusOutlined />}
                onClick={() => {
                  handleOpenModal(row)
                  setMode("add");
                }}
              >
                Add
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true)
    getMerchantScrapTypes(
      (res) => {
        setLoading(false);
        if (res.success) {
          setDataSource(res.data);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setLoading(false);
        toastError(res?.data?.error);
      }
    );
  }, [renderMe]);

  return (
    <section className="users">
      <div className="users__all-contents">
        <div className="users__content">
          <div className="users__usersData">
            <div className="pb-3 mb-3">
              <MainHeader title="Merchant Scrap Types" />
            </div>
            <div className="users__table grid-table mrg-y-30">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                rowId={"id"}
              />
            </div>
          </div>
        </div>
      </div>
      {isPriceListModalVisible && (
        <SupplyModal
          open={isPriceListModalVisible}
          mode={mode}
          rowData={rowData}
          onSubmit={() => {
            setRenderMe(!renderMe)
            onCancel()
          }}
          SubHeader={
            <SubHeader
              bgColor="#F58234"
              title={`${mode === "add" ? "Add New" : "Edit"} Price List`}
              closeButton
              onCancel={onCancel}
            />
          }
          onCancel={onCancel}
          primaryBtnName={mode === "add" ? "Add" : "Save"}
          secondaryBtnName={"Cancel"}
        />
      )}
      {isViewPricesListModalVisible && (
        <ViewPricesList
          open={isViewPricesListModalVisible}
          onCancel={() => {
            setRowData(null)
            setIsViewPricesListModalVisible(false)
          }}
          SubHeader={
            <SubHeader
              bgColor="#F58234"
              title={`View Prices List`}
              closeButton
              onCancel={() => {
                setRowData(null)
                setIsViewPricesListModalVisible(false)
              }}
            />
          }
          rowData={rowData}
        />
      )}
    </section>
  );
};

export default MerchantScrapTypes;
