import { useState, useEffect, useRef } from "react";
import { Button, Space, Input, DatePicker } from "antd";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { toastError } from "../../helpers/toasters";
import { getMerchantPeriods } from "../../apis/Network";

// components
import GridTable from "../SubComponents/GridTable/GridTable";
import SubHeader from "../SubComponents/SubHeader";
import MainHeader from "../SubComponents/MainHeader";
import PeriodModal from "./PeriodModal";

const MerchantPeriods = () => {
    const [loading, setLoading] = useState(true);
    const [mode, setMode] = useState("add");
    const [renderMe, setRenderMe] = useState(false);
    const [filteredDataSource, setFilteredDataSource] = useState([]);
    const [selectedRange, setSelectedRange] = useState([]);
    const [rowData, setRowData] = useState(null);
    const [isPeriodModalOpen, setIsPeriodModalOpen] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const { RangePicker } = DatePicker;

    const handleDateChange = (dates) => {
        setSelectedRange(dates);
        if (dates) {
            const startDate = dates[0].format("DD MMM, YYYY");
            const endDate = dates[1].format("DD MMM, YYYY");
            const dataWithingRange = dataSource.filter((ele) => {
                const comparisonDate = moment(ele.creationDate, "DD MMM, YYYY");
                return (
                    comparisonDate.isSameOrAfter(startDate) &&
                    comparisonDate.isSameOrBefore(endDate)
                );
            });
            setFilteredDataSource(dataWithingRange);
        } else {
            setFilteredDataSource(dataSource);
        }
    };
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text || text === 0 ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });
    const getColumnDateProps = () => ({
        filterDropdown: () => (
            <div
                style={{
                    padding: 8,
                }}
                className="range-picker-holder"
                onKeyDown={(e) => e.stopPropagation()}
            >
                <RangePicker
                    value={selectedRange}
                    allowClear
                    popupClassName="main-date-picker"
                    inputReadOnly={false}
                    onChange={handleDateChange}
                    onKeyDown={(e) => e.preventDefault()}
                />
            </div>
        ),
        filterIcon: (filtered) => <FilterOutlined style={selectedRange?.length > 0 ? { color: "blue" } : {}} />,
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text,
    });
    const columns = [
        {
            key: "days",
            title: "PERIOD",
            dataIndex: "days",
            ...getColumnSearchProps("days"),
        },
        {
            key: "isActive",
            title: "STATUS",
            dataIndex: "isActive",
            filters: [
                { text: "Active", value: true },
                { text: "Inactive", value: false },
            ],
            onFilter: (value, record) => record?.isActive === value,
            render: (_, row) => {
                return <span>{row.isActive ? "Active" : "Inactive"}</span>;
            },
        },
        {
            key: "creationDate",
            title: "CREATION DATE",
            dataIndex: "creationDate",
            ...getColumnDateProps("creationDate"),
        },
        {
            key: "id",
            title: "ACTIONS",
            render: (_, row) => {
                return (
                    <div>
                        <Space size={5} className="mb-2">
                            <Button
                                className="border-0"
                                onClick={() => {
                                    setRowData(row);
                                    setMode("edit");
                                    setIsPeriodModalOpen(true);
                                }}
                                style={{ backgroundColor: "#589559", color: "white" }}
                            >
                                Edit
                            </Button>
                        </Space>
                    </div>
                );
            },
        },
    ];

    const handleOpenStoreModal = () => {
        setIsPeriodModalOpen(true);
    };

    const handleCancelStoreModal = () => {
        setIsPeriodModalOpen(false);
        setRowData(null);
        setMode("add");
    };

    const handleSubmit = () => {
        handleCancelStoreModal();
        setRenderMe(!renderMe);
        setLoading(true);
    };

    useEffect(() => {
        getMerchantPeriods(
            (res) => {
                setLoading(false);
                if (res.success) {
                    setDataSource(res.data);
                    setFilteredDataSource(res.data);
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setLoading(false);
                toastError(res.message);
            }
        );
    }, [renderMe]);

    return (
        <section className="users stores">
            <div className="users__all-contents ">
                <div className="users__content">
                    <div className="users__usersData">
                        <div className="pb-3">
                            <MainHeader
                                title="All Merchant Periods"
                                buttonName="Add New Period"
                                onButtonClick={handleOpenStoreModal}
                            />
                        </div>
                        <div className="users__table grid-table mrg-ye-50 ">
                            <GridTable
                                loading={loading}
                                columns={columns}
                                dataSource={filteredDataSource}
                                rowId={"id"}
                                title={"Merchant Periods"}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {isPeriodModalOpen && (
                <PeriodModal
                    open={isPeriodModalOpen}
                    mode={mode}
                    rowData={rowData}
                    onSubmit={handleSubmit}
                    SubHeader={
                        <SubHeader
                            bgColor="#F58234"
                            title={`${mode === "add" ? "Add New" : "Edit"} Period`}
                            closeButton
                            onCancel={handleCancelStoreModal}
                        />
                    }
                    onCancel={handleCancelStoreModal}
                />
            )}
        </section>
    );
}

export default MerchantPeriods;