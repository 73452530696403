import { useEffect, useState } from "react";
import { Button } from "antd";
import { convertSecondsToTimeInMinsAndSecs } from "helpers/helpfulFunctions";

// assets
import sendIcon from "assets/icons/send-record.svg";

const VoiceNoteRecord = ({ onCancel, onSubmit }) => {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const timerId = setInterval(() => {
      setTimer((preValue) => preValue + 1);
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  return (
    <div className="chat__chat-holder__footer__voice-record-holder d-flex align-items-center justify-content-between">
      <Button
        htmlType="button"
        onClick={onCancel}
        className="chat__chat-holder__footer__voice-record-holder__cancel-btn h-auto d-block p-0 fsize--9 border-0 outline-none bg-transparent shadow-none"
      >
        Cancel
      </Button>
      <p className="text-white fw-500 fsize--9 mb-0">
        {convertSecondsToTimeInMinsAndSecs(timer)}
      </p>
        <Button
          htmlType="submit"
          onClick={onSubmit}
          className="chat__chat-holder__footer__voice-record-holder__send-btn d-flex justify-content-center align-items-center p-0 border-0 outline-none shadow-none change-direction"
        >
          <img src={sendIcon} alt="Send record" className="d-block img-fluid" />
        </Button>
    </div>
  );
};

export default VoiceNoteRecord;
