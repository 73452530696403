import { useState, useEffect, useRef } from "react";
import { Button, Space, Input, Image, DatePicker } from "antd";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { toastError } from "../../helpers/toasters";
import { getPartners } from "../../apis/Network";
import { baseUrl } from "../../apis/services";

// components
import GridTable from "../SubComponents/GridTable/GridTable";
import SubHeader from "../SubComponents/SubHeader";
import MainHeader from "../SubComponents/MainHeader";
import AddEditPartner from "./AddEditPartner";

const Partners = () => {
    const [loading, setLoading] = useState(true);
    const [mode, setMode] = useState("add");
    const [rowData, setRowData] = useState("");
    const [dataSource, setDataSource] = useState([]);
    const [isPartnerModalVisible, setIsPartnerModalVisible] = useState(false);
    const [renderMe, setRenderMe] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchValues, setSearchValues] = useState({
        name: "",
        nameEn: "",
        from: "",
        to: "",
        isActive: ""
    })
    const [selectedRange, setSelectedRange] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const { RangePicker } = DatePicker;

    const handlePageSizeChange = (current, newSize) => {
        setLoading(true)
        setPageSize(newSize)
    }

    const handleDateChange = (dates) => {
        setSelectedRange(dates);
        if (dates) {
            const startDate = dates[0].format("YYYY-MM-DD");
            const endDate = dates[1].format("YYYY-MM-DD");
            updateSearchValues("from", startDate, { property: "to", value: endDate })
        } else {
            updateSearchValues("from", "", { property: "to", value: "" })
        }
    };

    const updateSearchValues = (property, newValue, otherProperty) => {
        const copyOfSearchObj = structuredClone(searchValues)
        copyOfSearchObj[property] = newValue;
        if (otherProperty) {
            copyOfSearchObj[otherProperty.property] = otherProperty.value
        }
        setSearchValues(copyOfSearchObj)
    }

    const handleTableChange = (_, filters) => {
        if (filters.isActive) {
            if (filters.isActive.length > 1) {
                updateSearchValues("isActive", "")
            } else {
                updateSearchValues("isActive", filters.isActive[0])
            }
        } else if (searchValues.isActive !== "") {
            updateSearchValues("isActive", "")
        }
    }

    const handlePageChange = (page) => {
        setCurrentPage(page)
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        const value = selectedKeys[0]?.trim() || ""
        confirm();
        switch (dataIndex) {
            case "name":
                updateSearchValues("name", value)
                break;
            case "nameEn":
                updateSearchValues("nameEn", value)
                break;
            case "from":
                updateSearchValues("from", value)
                break;
            case "to":
                updateSearchValues("to", value)
                break;
            default:
                break;
        }
    };
    const handleReset = (clearFilters, dataIndex, confirm) => {
        clearFilters();
        setLoading(true);
        switch (dataIndex) {
            case "name":
                updateSearchValues("name", "")
                break;
            case "nameEn":
                updateSearchValues("nameEn", "")
                break;
            case "from":
                updateSearchValues("from", "")
                break;
            case "to":
                updateSearchValues("to", "")
                break;
            default:
                break;
        }
        confirm()
    };

    const handleEditBtn = (row) => {
        setMode("edit");
        setRowData(row);
        setIsPartnerModalVisible(true);
    };

    const handleAddPartner = () => {
        setIsPartnerModalVisible(true);
        setMode("add");
    };

    const handleModalCancel = () => {
        setIsPartnerModalVisible(false);
        setMode("add");
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, dataIndex, confirm)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) => true,
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const getColumnDateProps = (dataIndex) => ({
        filterDropdown: () => (
            <div
                style={{
                    padding: 8,
                }}
                className="range-picker-holder"
                onKeyDown={(e) => e.stopPropagation()}
            >
                <RangePicker
                    value={selectedRange}
                    allowClear
                    popupClassName="main-date-picker"
                    inputReadOnly={false}
                    onChange={(dates, datesStr) => {
                        setCurrentPage(1)
                        handleDateChange(dates, datesStr)
                    }}
                    onKeyDown={(e) => e.preventDefault()}
                />
            </div>
        ),
        filterIcon: (filtered) => <FilterOutlined style={selectedRange?.length > 0 ? { color: "blue" } : {}} />,
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text,
    });

    const columns = [
        {
            key: "image",
            title: "IMAGE",
            dataIndex: "image",
            render: (_, row) => (
                <Image
                    width={90}
                    height={70}
                    src={baseUrl + row.image}
                    className="d-block img-fluid object-fit-cover"
                    alt=""
                />
            ),
        },
        {
            key: "nameEn",
            title: "NAME (EN)",
            dataIndex: "nameEn",
            ...getColumnSearchProps("nameEn"),
        },
        {
            key: "name",
            title: "NAME (AR)",
            dataIndex: "name",
            ...getColumnSearchProps("name"),
        },
        {
            key: "isActive",
            title: "STATUS",
            dataIndex: "isActive",
            filters: [
                {
                    text: 'Active',
                    value: true,
                },
                {
                    text: 'Deactive',
                    value: false,
                }
            ],
            onFilter: (value, record) => true,
            render: (_, row) => (
                <span>{row.isActive ? "Active" : "Deactive"}</span>
            ),
        },
        {
            key: "creationDate",
            title: "CREATION DATE",
            dataIndex: "creationDate",
            ...getColumnDateProps("creationDate"),
        },
        {
            key: "id",
            title: "ACTIONS",
            dataIndex: "action",
            render: (_, row) => {
                return (
                    <Space size={5}>
                        <Button
                            className="border-0"
                            onClick={() => handleEditBtn(row)}
                            style={{ backgroundColor: "#589559", color: "white" }}
                        >
                            Edit
                        </Button>
                    </Space>
                );
            },
        },
    ];

    useEffect(() => {
        setLoading(true)
        const payload = {
            pageNumber: currentPage,
            perPage: pageSize,
            ...searchValues
        }
        for (let property in payload) {
            if (!payload[property] && payload[property] !== 0 && payload[property] !== false) {
                delete payload[property]
            }
        }
        getPartners(
            payload,
            (res) => {
                setLoading(false);
                if (res.success) {
                    setDataSource(res.data.response);
                    setTotalCount(res.data.totalCount);
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setLoading(false);
                if (res.data?.error) {
                    toastError(res.message);
                }
            }
        );
    }, [renderMe, searchValues, currentPage, pageSize]);

    return (
        <section className="users store-items">
            <div className="users__all-contents">
                <div className="users__content">
                    <div className="users__usersData">
                        <div className="pb-3 mb-3">
                            <MainHeader
                                title="Partners"
                                buttonName={"Add Partner"}
                                onButtonClick={handleAddPartner}
                            />
                        </div>
                        <div className="users__table store-items__data-table grid-table mrg-ye-50">
                            <GridTable
                                loading={loading}
                                columns={columns}
                                dataSource={dataSource}
                                rowId={"id"}
                                currentPage={currentPage}
                                totalCount={totalCount}
                                onPageChange={handlePageChange}
                                onChange={handleTableChange}
                                pageSize={pageSize}
                                onPageSizeChange={handlePageSizeChange}
                            />
                        </div>
                    </div>
                </div>
                {isPartnerModalVisible && (
                    <AddEditPartner
                        isPartnerModalVisible={isPartnerModalVisible}
                        mode={mode}
                        rowData={rowData}
                        SubHeader={
                            <SubHeader
                                bgColor="#F58234"
                                title={`${mode === "add" ? "Add New" : "Edit"} Partner`}
                                closeButton
                                onCancel={handleModalCancel}
                            />
                        }
                        onSubmit={() => {
                            setRenderMe(!renderMe)
                            handleModalCancel()
                        }}
                        onCancel={handleModalCancel}
                        primaryBtnName={`${mode === "add" ? "Add" : "Save"}`}
                        secondaryBtnName={"Cancel"}
                    />
                )}
            </div>
        </section>
    );
}

export default Partners;