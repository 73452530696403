import { useEffect, useState } from "react";
import { Col, Form, Input, Modal, Row, Space, Switch, Upload } from "antd";
import ImgCrop from 'antd-img-crop';
import { toastError, toastSuccess } from "../../helpers/toasters";
import { addPartner, editPartner } from "../../apis/Network";
import { arabicNameValidation } from "../../constants/RegexValidations";
import { baseUrl } from "../../apis/services";

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";

const AddEditPartner = ({
    isPartnerModalVisible,
    mode,
    rowData = {},
    SubHeader,
    primaryBtnName,
    secondaryBtnName,
    onSubmit,
    onCancel,
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [uploadedImage, setUploadedImage] = useState("");
    const [invalidImage, setInvalidImage] = useState(false)
    const [previewImage, setPreviewImage] = useState("");
    const [form] = Form.useForm();
    const initialValues = {
        image: "",
        nameEn: "",
        name: "",
        isActive: true,
    };
    const props = {
        name: 'image',
        accept: ".png,.jpg,.jpeg,.bmp,.gif,.svg",
        maxCount: 1,
        FileList: uploadedImage,
        showUploadList: false,
        onChange(info) {
            if (!info.file.type.startsWith("image")) {
                handleRemoveImage();
                form.setFields([
                    {
                        name: "image",
                        errors: ["Please, upload partner logo"],
                        value: "",
                    }
                ])
                return;
            }
            handlePreview(info.file)
        },
    };

    const handleBeforeCrop = (file) => {
        if (!file.type.startsWith("image")) {
            handleRemoveImage();
            return false;
        } else {
            return true
        }
    }

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handlePreview = async (file) => {
        setUploadedImage(file.originFileObj);
        form.setFields([{ name: "image", value: file.originFileObj, errors: [] }])
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
    };

    const handleRemoveImage = () => {
        setPreviewImage("");
        setUploadedImage("");
        setInvalidImage(true)
    };

    const handleAddPartner = (payload) => {
        addPartner(
            payload,
            (res) => {
                setSubmitting(false);
                if (res.success) {
                    toastSuccess(res.message);
                    onSubmit();
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setSubmitting(false);
                toastError(res.message);
            }
        );
    };

    const handleEditPartner = (payload) => {
        editPartner(
            payload,
            (res) => {
                setSubmitting(false);
                if (res.success) {
                    toastSuccess(res.message);
                    onSubmit();
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setSubmitting(false);
                toastError(res.message);
            }
        );
    };

    const handleOnFinish = (values) => {
        if (submitting) return;
        setSubmitting(true);

        const formData = new FormData();
        const payload = {
            Name: values.name.trim(),
            NameEn: values.nameEn.trim(),
            isActive: values.isActive,
        };
        if (uploadedImage) {
            payload.Image = uploadedImage
        }
        for (const key in payload) {
            if (payload.hasOwnProperty(key)) {
                formData.append(key, payload[key]);
            }
        }

        if (mode === "add") {
            handleAddPartner(formData);
        } else {
            formData.append("Id", rowData.id)
            handleEditPartner(formData);
        }
    };

    useEffect(() => {
        if (rowData && mode === "edit") {
            form.setFieldsValue({
                nameEn: rowData.nameEn,
                name: rowData.name,
                isActive: rowData.isActive,
                image: rowData.image
            });
        }
    }, [rowData, mode, form]);

    return (
        <Modal
            title={SubHeader}
            open={isPartnerModalVisible}
            className="msg-modal"
            footer={false}
            closable={false}
            centered
            destroyOnClose
        >
            <Form
                form={form}
                className="edit-actor__content store-items__item-form"
                onFinish={handleOnFinish}
                initialValues={initialValues}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <div className="lang-name wd-100 flex ">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Image
                                </label>
                            </div>
                            {previewImage ? (
                                <img src={previewImage} alt="" className="store-items__item-form__item-preview-img d-block img-fluid mb-2" />
                            ) : mode === "edit" && !invalidImage ? (
                                <img src={baseUrl + rowData?.image} alt="" className="store-items__item-form__item-preview-img d-block img-fluid mb-2" />
                            ) : (
                                ""
                            )}
                            <Form.Item
                                name="image"
                                rules={[
                                    { required: true, message: "Please, upload partner logo" }
                                ]}
                                className="w-100"
                            >
                                <ImgCrop beforeCrop={handleBeforeCrop} fillColor="transparent" quality={1} aspectSlider>
                                    <Upload {...props}>
                                        Upload Image +
                                    </Upload>
                                </ImgCrop>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2" htmlFor="">
                                Name (EN)
                            </label>
                            <Form.Item
                                className=" new-category__form-item"
                                name="nameEn"
                                validateFirst
                                rules={[
                                    {
                                        required: true,
                                        message: "Please, enter partner name in English",
                                    },
                                    {
                                        min: 2,
                                        message: "Minimum length is 2 characters",
                                    },
                                    {
                                        pattern:
                                            /^[a-z][a-z\s\d$!@#%^&*()_+-=\[\]/{}\\|;':",./<>?~`]*[a-z\d$!@#%^&*()_+-=\[\]/{}\\|;':",./<>?~`]$/i,
                                        message: "Please, enter a valid name",
                                    },
                                    {
                                        max: 15,
                                        message: "Maximum length is 15 characters",
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    className="search__searchField py-2"
                                    placeholder="Enter Name in English"
                                    maxLength={15}
                                    showCount
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2" htmlFor="">
                                Name (AR)
                            </label>
                            <Form.Item
                                className="new-category__form-item"
                                name="name"
                                validateFirst
                                rules={[
                                    {
                                        required: true,
                                        message: "Please, enter partner name in Arabic",
                                    },
                                    {
                                        min: 2,
                                        message: "Minimum length is 2 characters",
                                    },
                                    {
                                        pattern: arabicNameValidation,
                                        message: "Please, enter a valid name",
                                    },
                                    {
                                        max: 15,
                                        message: "Maximum length is 15 characters",
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    className="search__searchField py-2"
                                    placeholder="Enter Name in Arabic"
                                    maxLength={15}
                                    dir="rtl"
                                    showCount
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name mb-4 row mx-0 align-items-center">
                            <div className="col-4 px-0 text-start">
                                <label className="users__parent-info-label">Active</label>
                            </div>
                            <div className="col-8 px-0 d-flex">
                                <Form.Item
                                    name="isActive"
                                    valuePropName="checked"
                                    className="mb-0"
                                >
                                    <Switch />
                                </Form.Item>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mrg-ys-20 mrg-x-auto justify-content-center">
                    <Space size={20}>
                        <SecondaryButton buttonName={secondaryBtnName} onClick={onCancel} />
                        <PrimaryButton
                            buttonName={primaryBtnName}
                            loading={submitting}
                            type="submit"
                        />
                    </Space>
                </Row>
            </Form>
        </Modal>
    );
}

export default AddEditPartner;