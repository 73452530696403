import { useEffect, useRef, useState } from "react";
import { Button, Input, Space, Col } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { getCourier } from "../../apis/Network";
import { toastError } from "../../helpers/toasters";
import { exportDataAsExcel } from "../../helpers/helpfulFunctions";

// components
import GridTable from "../SubComponents/GridTable/GridTable";
import SubHeader from "../SubComponents/SubHeader";
import MainHeader from "../SubComponents/MainHeader";
import CourierModal from "./CourierModal";
import CourierLogsModal from "./CourierLogsModal";

const Courier = () => {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [mode, setMode] = useState("add");
  const [rowData, setRowData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLogsModalOpen, setIsLogsModalOpen] = useState(false);
  const [renderMe, setRenderMe] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleExportDataToExcel = (data) => {
    if (isExporting) return;
    setIsExporting(true)
    const baseProps = [
      { title: "User Name", prop: "userName" },
      { title: "Mobile Number", prop: "phone" },
      { title: "For Store", prop: "forStore", values: { true: "For Store", false: "Not For Store" } },
      { title: "Status", prop: "isActive", values: { true: "Active", false: "Deactive" } },
    ]
    const nestedProps = {
      prop: "logs",
      requestTitle: "Logs",
      data: [
        { title: "Month", prop: "month" },
        { title: "Year", prop: "year" },
        { title: "Completed Orders", prop: "completedOrdersCount" },
        { title: "Un-Completed Orders", prop: "unCompletedOrdersCount" },
        { title: "Total Orders", prop: "allOrdersCount" },
      ]
    }
    exportDataAsExcel(data, { baseProps, nestedProps, tab: "Couriers Report" })
    setIsExporting(false)
  }

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setMode("add");
  };
  const handleCancelModal = () => {
    setIsModalOpen(false);
    setMode("add");
    setRowData(null);
    setIsLogsModalOpen(false)
  };
  const handleSubmit = () => {
    handleCancelModal();
    setRenderMe(!renderMe);
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          ></Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      key: "userName",
      title: "USER NAME",
      dataIndex: "userName",
      ...getColumnSearchProps("userName"),
    },
    {
      key: "phone",
      title: "MOBILE NUMBER",
      dataIndex: "phone",
      ...getColumnSearchProps("phone"),
    },
    {
      key: "forStore",
      title: "FOR STORE",
      dataIndex: "forStore",
      filters: [
        { text: "For Store", value: true },
        { text: "Not For Store", value: false },
      ],
      onFilter: (value, record) => value === record.forStore,
      render: (_, row) => {
        return <span>{row.forStore ? "For Store" : "Not For Store"}</span>;
      },
    },
    {
      key: "isActive",
      title: "STATUS",
      dataIndex: "isActive",
      filters: [
        { text: "Active", value: true },
        { text: "Deactived", value: false },
      ],
      onFilter: (value, record) => value === record.isActive,
      render: (_, row) => {
        return <span>{row.isActive ? "Active" : "Deactived"}</span>;
      },
    },
    {
      key: "id",
      title: "ACTIONS",
      render: (_, row) => {
        return (
          <div>
            <Space size={5} className="mb-2">
              <Col span={24}>
                <Button
                  className="border-0"
                  onClick={() => {
                    setRowData(row);
                    setMode("edit");
                    setIsModalOpen(true);
                  }}
                  style={{ backgroundColor: "#589559", color: "white" }}
                >
                  Edit
                </Button>
              </Col>
              <Col span={24}>
                <Button
                  className="w-100"
                  onClick={() => {
                    setRowData(row);
                    setIsLogsModalOpen(true);
                  }}
                  style={{ backgroundColor: "rgb(233 98 44)", color: "white", border: "none" }}
                >
                  Logs
                </Button>
              </Col>
            </Space>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getCourier(
      (res) => {
        setLoading(false);
        if (res.success) {
          setDataSource(res.data);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setLoading(false);
        toastError(res.message);
      }
    );
  }, [renderMe]);

  return (
    <section className="users couriers">
      <div className="users__all-contents">
        <div className="users__content">
          <div className="users__usersData">
            <div className="pb-3 mb-3">
              <MainHeader
                title="Couriers"
                buttonName={"Add New Courier"}
                onButtonClick={handleOpenModal}
              />
            </div>
            <div className="users__table grid-table mrg-y-30">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                rowId={"id"}
                title={"Couriers"}
                allowExport={true}
                isExporting={isExporting}
                onExport={handleExportDataToExcel}
              />
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <CourierModal
          open={isModalOpen}
          onCancel={handleCancelModal}
          onSubmit={handleSubmit}
          subHeader={
            <SubHeader
              bgColor="#589559"
              title={`${mode === "add" ? "Add New" : "Edit"} Courier`}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          rowData={rowData}
          primaryBtnName={"Save"}
          mode={mode}
        />
      )}
      {isLogsModalOpen && (
        <CourierLogsModal
          open={isLogsModalOpen}
          onCancel={handleCancelModal}
          onSubmit={handleSubmit}
          subHeader={
            <SubHeader
              bgColor="#589559"
              title={`${rowData?.userName}'s Logs`}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          rowData={rowData}
        />
      )}
    </section>
  );
};

export default Courier;
