import { Button, Modal } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { convertBytesToSize } from "helpers/helpfulFunctions";
import { theFirstEncounteredLetterIsArabic } from 'helpers/RegexPatterns';

// assets
import zipIcon from "assets/icons/files-extensions/zip.png";
import rarIcon from "assets/icons/files-extensions/rar.png";
import htmlIcon from "assets/icons/files-extensions/html.png";
import pdfIcon from "assets/icons/files-extensions/pdf.png";
import docIcon from "assets/icons/files-extensions/doc.png";
import xlsIcon from "assets/icons/files-extensions/xls.png";
import pptIcon from "assets/icons/files-extensions/ppt.png";
import txtIcon from "assets/icons/files-extensions/txt.png";
import fallbackIcon from "assets/icons/files-extensions/file.png";

const PreivewUploadedDocuments = ({ onUpload, onCancel, files }) => {
  const extensionIcons = {
    zip: zipIcon,
    rar: rarIcon,
    htm: htmlIcon,
    pdf: pdfIcon,
    doc: docIcon,
    xls: xlsIcon,
    ppt: pptIcon,
    txt: txtIcon,
  };
  return (
    <Modal
      open={true}
      className="chat__chat-holder__body__message__holder__document__upload-preview"
      footer={false}
      centered
      destroyOnClose
      maskClosable={false}
      onCancel={onCancel}
      zIndex={1701}
    >
      <p className="chat__chat-holder__body__message__holder__document__upload-preview__confirmation-msg text--secondary-100 fsize--7 font--bold text-center">
        You're about to upload {files?.length}{" "}
        {files?.length !== 1 ? "files" : "file"}
      </p>
      <div
        dir={"ltr"}
        className="chat__chat-holder__body__message__holder__document__upload-preview__files-holder d-flex flex-column gap-2 pb-4 pt-3"
      >
        {files?.map((file) => {
          const fileExtension = file.name
            .split(".")[1]
            .slice(0, 3)
            .toLowerCase();
          return (
            <div
              key={file.uid}
              className="chat__chat-holder__body__message__holder__document d-flex align-items-start gap-2"
            >
              <img
                src={extensionIcons[fileExtension] || fallbackIcon}
                alt=""
                className="chat__chat-holder__body__message__holder__document__upload-preview__files-holder__icon d-block img-fluid"
              />
              <div>
                <p
                  title={file.name.length > 30 ? file.name : ""}
                  dir={
                    theFirstEncounteredLetterIsArabic.test(file.name)
                      ? "rtl"
                      : "ltr"
                  }
                  className="chat__chat-holder__body__message__holder__document__upload-preview__files-holder__name text-truncate text--secondary-100 fsize--9 mb-1"
                >
                  {file.name}
                </p>
                <p className="chat__chat-holder__body__message__holder__document__upload-preview__files-holder__size fsize--12 mb-0">
                  {convertBytesToSize(file.size)}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="d-flex justify-content-center">
        <Button
          onClick={onUpload}
          className="chat__chat-holder__footer__upload-preview-holder__preview-upload-btn bg--secondary-100 text--white fw-bold border-0 outline-none shadow-none h-auto"
        >
          <UploadOutlined />
        </Button>
      </div>
    </Modal>
  );
};

export default PreivewUploadedDocuments;
