import { useEffect, useRef, useState } from "react";
import { Button, Image, Space, DatePicker } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import moment from 'moment';
import { getHomeBanners } from "../../apis/Network";
import { toastError } from "../../helpers/toasters";
import { baseUrl } from "../../apis/services";

// components
import GridTable from "../SubComponents/GridTable/GridTable";
import SubHeader from "../SubComponents/SubHeader";
import MainHeader from "../SubComponents/MainHeader";
import BannerModal from "./BannerModal";
import DeleteModal from "./DeleteModal";

const HomeBanner = () => {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [selectedRange, setSelectedRange] = useState([]);
  const [mode, setMode] = useState("add");
  const [rowData, setRowData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [canAdminAdd, setCanAdminAdd] = useState(false);
  const [canAdminDelete, setCanAdminDelete] = useState(false);
  const [renderMe, setRenderMe] = useState(false);
  const searchInput = useRef(null);
  const { RangePicker } = DatePicker;

  const handleDateChange = (dates) => {
    setSelectedRange(dates);
    if (dates) {
      const startDate = dates[0].format("DD MMM, YYYY");
      const endDate = dates[1].format("DD MMM, YYYY");
      const dataWithingRange = dataSource.filter((ele) => {
        const comparisonDate = moment(ele.creationDate, "DD MMM, YYYY");
        return (
          comparisonDate.isSameOrAfter(startDate) &&
          comparisonDate.isSameOrBefore(endDate)
        );
      });
      setFilteredDataSource(dataWithingRange);
    } else {
      setFilteredDataSource(dataSource);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCancelModal = () => {
    setIsModalOpen(false);
    setIsDeleteModalOpen(false);
    setMode("add");
    setRowData(null);
  };
  const handleSubmit = () => {
    handleCancelModal();
    setRenderMe(!renderMe);
  };
  const getColumnDateProps = () => ({
    filterDropdown: () => (
      <div
        style={{
          padding: 8,
        }}
        className="range-picker-holder"
        onKeyDown={(e) => e.stopPropagation()}
      >
        <RangePicker
          value={selectedRange}
          allowClear
          popupClassName="main-date-picker"
          inputReadOnly={false}
          onChange={handleDateChange}
          onKeyDown={(e) => e.preventDefault()}
        />
      </div>
    ),
    filterIcon: (filtered) => <FilterOutlined style={selectedRange?.length > 0 ? { color: "blue" } : {}} />,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });
  const columns = [
    {
      key: "image",
      title: "IMAGE",
      dataIndex: "image",
      render: (_, row) => {
        return row.imageOtherLanguage ? (
          <Image.PreviewGroup>
            <Image
              width={70}
              height={50}
              src={baseUrl + row.image}
              alt=""
            />
            <Image
              width={0}
              height={0}
              className="d-none overflow-hidden"
              src={baseUrl + row.imageOtherLanguage}
              alt=""
            />
          </Image.PreviewGroup>
        ) : (
          <Image
            src={baseUrl + row.image}
            width={70}
            height={50}
            preview={{ src: baseUrl + row.image }}
          />
        );
      },
    },
    {
      key: "isActive",
      title: "STATUS",
      dataIndex: "isActive",
      filters: [
        { text: "Active", value: true },
        { text: "Deactive", value: false },
      ],
      onFilter: (value, record) => record.isActive === value,
      render: (_, row) => {
        return <span>{row.isActive ? "Active" : "Deactive"}</span>;
      },
    },
    {
      key: "creationDate",
      title: "CREATION DATE",
      dataIndex: "creationDate",
      ...getColumnDateProps("creationDate"),
    },
    {
      key: "id",
      title: "ACTIONS",
      render: (_, row) => {
        return (
          <div>
            <Space size={5} className="mb-2">
              <Button
                className="border-0"
                onClick={() => {
                  setRowData(row);
                  setMode("edit");
                  setIsModalOpen(true);
                }}
                style={{ backgroundColor: "#589559", color: "white" }}
              >
                Edit
              </Button>
              <Button
                className="border-0"
                onClick={() => {
                  setRowData(row);
                  setIsDeleteModalOpen(true);
                }}
                style={{ backgroundColor: "#E72121", color: "white" }}
              >
                Delete
              </Button>
            </Space>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getHomeBanners(
      (res) => {
        setLoading(false);
        if (res.success) {
          setDataSource(res.data);
          setFilteredDataSource(res.data);
          let activeImages = 0;
          res.data.forEach((ele) => {
            if (ele.isActive) {
              activeImages++
            }
          })
          setCanAdminAdd(activeImages < 10)
          setCanAdminDelete(activeImages > 1)
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setLoading(false);
        toastError(res.message);
      }
    );
  }, [renderMe]);

  return (
    <section className="users banners">
      <div className="users__all-contents">
        <div className="users__content">
          <div className="users__usersData">
            <div className="pb-3 mb-3">
              <MainHeader
                title="Home Banners"
                buttonName={"Add Banner"}
                onButtonClick={handleOpenModal}
                disableBtn={!canAdminAdd}
              />
            </div>
            <div className="users__table grid-table mrg-y-30">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={filteredDataSource}
                rowId={"id"}
              />
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <BannerModal
          open={isModalOpen}
          onCancel={handleCancelModal}
          onSubmit={handleSubmit}
          canAdminMakeBannerActive={canAdminAdd}
          canAdminDelete={canAdminDelete}
          subHeader={
            <SubHeader
              bgColor="#589559"
              title={`${mode === "add" ? "Add New" : "Edit"} Banner`}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          rowData={rowData}
          primaryBtnName={"Save"}
          mode={mode}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          open={isDeleteModalOpen}
          rowData={rowData}
          canAdminDelete={canAdminDelete}
          subHeader={
            <SubHeader
              bgColor="#589559"
              title={`Delete Banner`}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          onCancel={handleCancelModal}
          onSubmit={handleSubmit}
        />
      )}
    </section>
  );
};

export default HomeBanner;
