import { useState, useEffect } from "react";
import { Row, Space, Modal, Form, Col, Input, Switch, Upload } from "antd";
import ImgCrop from 'antd-img-crop';
import { toastError, toastSuccess } from "../../helpers/toasters";
import { addDoDontImage, updateDoDontImage } from "../../apis/Network";
import { baseUrl } from "../../apis/services";

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";

const AddEditModal = ({
    open,
    onCancel,
    subHeader,
    primaryBtnName,
    canAdminMakeImageActive,
    mode,
    rowData,
    onSubmit,
    canAdminDeleteValid,
    canAdminDeleteInvalid
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [uploadedImage, setUploadedImage] = useState("");
    const [invalidImage, setInvalidImage] = useState(false)
    const [previewImage, setPreviewImage] = useState("");
    const maxSize = 5000000;
    const [form] = Form.useForm();
    const initialValues = {
        name: "",
        image: "",
        isValid: true,
        isActive: true,
    };
    const props = {
        name: 'image',
        accept: ".png,.jpg,.jpeg,.bmp,.gif,.svg",
        maxCount: 1,
        showUploadList: false,
        onChange(info) {
            if (!info.file.type.startsWith("image")) {
                handleRemoveImage();
                form.setFields([
                    {
                        name: "image",
                        errors: ["Please, upload an image with a valid format"],
                        value: "",
                    }
                ])
            } else if (info.file.size > maxSize) {
                handleRemoveImage();
                form.setFields([
                    {
                        name: "image",
                        errors: ["File size exceeds the maximum limit of 5 MB. Please, upload a smaller image"],
                        value: "",
                    }
                ])
            } else {
                handlePreview(info.file)
            }
        },
    };

    const handleBeforeCrop = (file) => {
        if (!file.type.startsWith("image") || file.size > maxSize) {
            handleRemoveImage();
            return false;
        } else {
            return true
        }
    }

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handlePreview = async (file) => {
        setUploadedImage(file.originFileObj);
        form.setFields([{ name: "image", value: file.originFileObj, errors: [] }])
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
    };

    const handleRemoveImage = () => {
        setPreviewImage("");
        setUploadedImage("");
        setInvalidImage(true)
    };

    const handleOnFinish = (values) => {
        if (mode === "edit") {
            // stop admin from activating an image when there're already 10 active images
            if (!rowData.isActive && values.isActive && !canAdminMakeImageActive) {
                toastError("You can't activate more than 10 images")
                return;
            }
            // stop admin from changing to invalid when there's only one valid
            if (!canAdminDeleteValid && rowData.isValid && !values.isValid) {
                toastError("Should remain at least one valid image")
                return;
            }
            // stop admin from changing to valid when there's only one invalid
            if (!canAdminDeleteInvalid && !rowData.isValid && values.isValid) {
                toastError("Should remain at least one invalid image")
                return;
            }
            if (rowData.isActive && !values.isActive) {
                // stop admin from deactivating a valid image when there's only one
                if (!canAdminDeleteValid && values.isValid) {
                    toastError("Should remain at least one valid image")
                    return;
                }
                // stop admin from deactivating an invalid image when there's only one
                if (!canAdminDeleteInvalid && !values.isValid) {
                    toastError("Should remain at least one invalid image")
                    return;
                }
            }
        }
        if (submitting) return;
        setSubmitting(true);

        const formData = new FormData();
        const payload = {
            Name: values.name.trim(),
            IsActive: values.isActive,
            IsValid: values.isValid,
        }
        if (mode === "edit") {
            payload.Id = rowData.id;
        }
        if (uploadedImage) {
            payload.Image = uploadedImage
        }
        for (const key in payload) {
            if (payload.hasOwnProperty(key)) {
                formData.append(key, payload[key]);
            }
        }

        if (mode === "add") {
            addDoDontImage(
                formData,
                (res) => {
                    setSubmitting(false);
                    if (res.success) {
                        toastSuccess(res.message);
                        onSubmit();
                    } else {
                        toastError(res.message);
                    }
                },
                (res) => {
                    setSubmitting(false);
                    toastError(res.message);
                }
            );
        } else {
            updateDoDontImage(
                formData,
                (res) => {
                    setSubmitting(false);
                    if (res.success) {
                        toastSuccess(res.message);
                        onSubmit();
                    } else {
                        toastError(res.message);
                    }
                },
                (res) => {
                    setSubmitting(false);
                    toastError(res.message);
                }
            );
        }
    };

    useEffect(() => {
        if (mode === "edit" && rowData) {
            form.setFieldValue("image", rowData.image);
            form.setFieldValue("name", rowData.name);
            form.setFieldValue("isValid", rowData.isValid);
            form.setFieldValue("isActive", rowData.isActive);
        }
    }, [rowData, mode, form]);

    return (
        <Modal
            title={subHeader}
            open={open}
            className="msg-modal"
            footer={false}
            closable={false}
            centered
            destroyOnClose
            onCancel={onCancel}
            maskClosable={false}
        >
            <Form
                form={form}
                className="edit-actor__content pt-3 do-dont-images__add-form"
                onFinish={handleOnFinish}
                initialValues={initialValues}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <div className="lang-name wd-100 flex ">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Image
                                </label>
                            </div>
                            {previewImage ? (
                                <img src={previewImage} alt="" className="do-dont-images__add-form__preview-img d-block img-fluid mb-2" />
                            ) : mode === "edit" && !invalidImage ? (
                                <img src={baseUrl + rowData?.image} alt="" className="do-dont-images__add-form__preview-img d-block img-fluid mb-2" />
                            ) : (
                                ""
                            )}
                            <Form.Item
                                name="image"
                                rules={[
                                    { required: true, message: "Please, upload an image with a valid format" }
                                ]}
                                className="w-100"
                            >
                                <ImgCrop beforeCrop={handleBeforeCrop} fillColor="transparent" aspectSlider cropperProps={{ restrictPosition: false }} minZoom={0.5} rotationSlider>
                                    <Upload {...props}>
                                        Upload Image +
                                    </Upload>
                                </ImgCrop>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2" htmlFor="">
                                Name
                            </label>
                            <Form.Item
                                className="new-category__form-item"
                                name="name"
                                validateFirst
                                rules={[
                                    {
                                        required: true,
                                        message: "Please, enter a valid image name",
                                    },
                                    {
                                        min: 3,
                                        message: "Name should be at least 3 characters",
                                    },
                                    {
                                        pattern: /^[a-z][\w\s$!@#%^&*()_+-=[\]/{}\\|;':",./<>?~`]*[\w$!@#%^&*()_+-=[\]/{}\\|;':",./<>?~`]$/i,
                                        message: "Please, enter a valid image name",
                                    },
                                    {
                                        max: 50,
                                        message: "Name should not exceed 50 characters",
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    className="search__searchField py-2"
                                    placeholder="Enter Name"
                                    maxLength={50}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name mb-4 row mx-0 align-items-center">
                            <div className="col-4 px-0 text-start">
                                <label className="users__parent-info-label">Validation</label>
                            </div>
                            <div className="col-8 px-0 d-flex gap-2 align-items-center">
                                <span>Invalid</span>
                                <Form.Item
                                    name="isValid"
                                    valuePropName="checked"
                                    className="mb-0"
                                >
                                    <Switch />
                                </Form.Item>
                                <span>Valid</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name mb-4 row mx-0 align-items-center">
                            <div className="col-4 px-0 text-start">
                                <label className="users__parent-info-label">Status</label>
                            </div>
                            <div className="col-8 px-0 d-flex gap-2 align-items-center">
                                <span>Deactive</span>
                                <Form.Item
                                    name="isActive"
                                    valuePropName="checked"
                                    className="mb-0"
                                >
                                    <Switch />
                                </Form.Item>
                                <span>Active</span>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mrg-ys-20 mrg-x-auto mt-2 justify-content-center">
                    <Space size={20}>
                        <SecondaryButton buttonName={"Cancel"} onClick={onCancel} />
                        <PrimaryButton
                            buttonName={primaryBtnName}
                            loading={submitting}
                            type="submit"
                        />
                    </Space>
                </Row>
            </Form>
        </Modal>
    );
}

export default AddEditModal;