import { useEffect, useRef, useState } from "react";
import { Button, Form, Image, Input, InputNumber, Modal, Select, Space } from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useSelector } from "react-redux";
import { toastError, toastSuccess } from "../../../helpers/toasters";
import { baseUrl } from "../../../apis/services";
import {
  editScrapSalesRequest,
  getScrapperSaleRequestInfo,
  getSupplyingTypesForCansRequest,
} from "../../../apis/Network";
import { handleAllowNumbersOnly } from "../../../helpers/helpfulFunctions";

// comonents
import GridTable from "../../SubComponents/GridTable/GridTable";
import AddRequestSupplyType from "./AddRequestSupplyType";
import SubHeader from "../../SubComponents/SubHeader";
import DeleteRequestSupplyType from "./DeleteRequestSupplyType";

const ViewRequestDetails = ({
  data,
  open,
  subHeader,
  onCancel,
  onUpdateRequest,
  onSubmitSupplyTypes
}) => {
  const [activeSupplyingTypes, setActiveSupplyingTypes] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [editableRows, setEditableRows] = useState([]);
  const [submittingRows, setSubmittingRows] = useState([]);
  const [loadingDataRows, setLoadingDataRows] = useState([]);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [addSupplyOpen, setAddSupplyOpen] = useState(false);
  const [deleteSupplyOpen, setDeleteSupplyOpen] = useState(false);
  const [rowIdToDelete, setRowIdToDelete] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { roles } = useSelector((state) => state.auth.authorization);
  const searchInput = useRef(null);

  const handleCancelModal = () => {
    setAddSupplyOpen(false)
    setDeleteSupplyOpen(false)
    setRowIdToDelete("")
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const validateQuantity = (_, value) => {
    if (!value && value !== 0) {
      return Promise.reject("Required");
    } else if (value <= 0) {
      return Promise.reject("Invalid");
    } else {
      return Promise.resolve();
    }
  };
  const updateLoadingDataRows = (id, add) => {
    let copyOfLoadingDataRows = [...loadingDataRows];
    if (add) {
      copyOfLoadingDataRows.push(id);
    } else {
      copyOfLoadingDataRows = loadingDataRows.filter((value) => value !== id);
    }
    setLoadingDataRows(copyOfLoadingDataRows);
  };
  const updateSubmittingRows = (id, add) => {
    let copyOfSubmittingRows = [...submittingRows];
    if (add) {
      copyOfSubmittingRows.push(id);
    } else {
      copyOfSubmittingRows = submittingRows.filter((value) => value !== id);
    }
    setSubmittingRows(copyOfSubmittingRows);
  };
  const getNewRowData = (payload, recordIndex, record) => {
    updateLoadingDataRows(record.salesRequestId, true);
    getScrapperSaleRequestInfo(
      payload,
      (res) => {
        updateLoadingDataRows(record.salesRequestId, false);
        if (res.success) {
          const copyOfRecord = { ...record };
          copyOfRecord.totalPoints = res.data.points;
          copyOfRecord.price = res.data.unitPrice;
          copyOfRecord.totalpounds = res.data.totalPrice;

          const copyOfDataSource = structuredClone(dataSource);
          copyOfDataSource[recordIndex] = copyOfRecord;
          setDataSource(copyOfDataSource);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        updateLoadingDataRows(record.salesRequestId, false);
        toastError(res.message);
      }
    );
  };
  const updateRowData = (record) => {
    if (!record.totalQuantity || record.totalQuantity < 0 || invalid) return;
    updateSubmittingRows(record.salesRequestId, true);
    const payload = {
      id: record.salesRequestId,
      amountInGram: record.totalQuantity,
      supplyTypeId: record.supplyTypeId,
    };
    editScrapSalesRequest(
      payload,
      (res) => {
        updateSubmittingRows(record.salesRequestId, false);
        if (res.success) {
          toastSuccess(res.message);
          const newEditableRows = editableRows.filter(
            (id) => id !== record.salesRequestId
          );
          setEditableRows(newEditableRows);
          onUpdateRequest();
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        updateSubmittingRows(record.salesRequestId, false);
        toastError(res.message);
      }
    );
  };
  const handleUpdateRow = (record, property, value) => {
    const copyOfRecord = { ...record };
    const payload = {
      salesRequestId: record.salesRequestId,
      supplyTypeId: record.supplyTypeId,
      AmountInGrams: record.totalQuantity,
    };
    if (property === "supplyType") {
      copyOfRecord["supplyType"] = value.label;
      copyOfRecord["supplyTypeId"] = value.value;
      payload.supplyTypeId = value.value;
    } else {
      copyOfRecord[property] = value;
      payload.AmountInGrams = value;
    }
    const copyOfDataSource = structuredClone(dataSource);
    const recordIndex = copyOfDataSource.findIndex(
      (ele) => ele.salesRequestId === record.salesRequestId
    );
    copyOfDataSource[recordIndex] = copyOfRecord;

    if (copyOfRecord.totalQuantity && copyOfRecord.totalQuantity > 0) {
      setDataSource(copyOfDataSource);
      getNewRowData(payload, recordIndex, copyOfRecord);
      setInvalid(false);
    } else {
      copyOfDataSource[recordIndex].totalPoints = "_";
      copyOfDataSource[recordIndex].price = "_";
      copyOfDataSource[recordIndex].totalpounds = "_";
      setDataSource(copyOfDataSource);
      setInvalid(true);
    }
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          ></Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text, record) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : editableRows.includes(record.salesRequestId) ? (
        dataIndex === "supplyType" ? (
          <Select
            name={`supplyTypeId-${data?.id}`}
            className="new-category__form-item"
            placeholder="Select supplying type"
            onChange={(value, option) => {
              handleUpdateRow(record, "supplyType", option);
            }}
            loading={activeSupplyingTypes ? false : true}
            options={activeSupplyingTypes ? activeSupplyingTypes : []}
            value={record.supplyTypeId}
          // getPopupContainer={(trigger) => trigger.parentElement}
          />
        ) : dataIndex === "totalQuantity" ? (
          <Form initialValues={{ totalQuantity: record.totalQuantity }}>
            <Form.Item
              rules={[{ validator: validateQuantity }]}
              name={`totalQuantity`}
              className="new-category__form-item mb-0"
            >
              <InputNumber
                className="w-100 d-block search__searchField"
                placeholder="Enter quantity"
                onKeyDown={(e) => handleAllowNumbersOnly(e, true)}
                controls={false}
                keyboard={false}
                maxLength={8}
                value={record.totalQuantity}
                onChange={(value) => {
                  handleUpdateRow(record, "totalQuantity", value);
                }}
              />
            </Form.Item>
          </Form>
        ) : !invalid ? (
          text
        ) : (
          "_"
        )
      ) : (
        text
      ),
  });

  const columns = [
    {
      key: "id",
      title: "#",
      render: (_, row, index) => (
        <span>{((currentPage - 1) * pageSize) + index + 1}</span>
      )
    },
    {
      key: "supplyTypeImages",
      title: "IMAGES",
      dataIndex: "supplyTypeImages",
      render: (_, row) => row.supplyTypeImages?.length > 0 ? (
        <Image.PreviewGroup
          items={row.supplyTypeImages.map((imgUrl) => `${baseUrl}${imgUrl}`)}
        >
          <Image
            width={60}
            height={60}
            src={baseUrl + row.supplyTypeImages[0]}
          />
        </Image.PreviewGroup>
      ) : (
        ""
      ),
    },
    {
      key: "id",
      title: "NO. OF IMAGES",
      render: (_, row) => (
        <span>{row.supplyTypeImages.length}</span>
      )
    },
    {
      key: "supplyType",
      title: "SUPPLY TYPE",
      dataIndex: "supplyType",
      ...getColumnSearchProps("supplyType"),
    },
    {
      key: "totalQuantity",
      title: "QUANTITY IN GRAMS",
      dataIndex: "totalQuantity",
      ...getColumnSearchProps("totalQuantity"),
    },
    {
      key: "deductionPercentage",
      title: "DEDUCTION PERCENTAGE",
      dataIndex: "deductionPercentage",
      ...getColumnSearchProps("deductionPercentage"),
      render: (_, row) => (
        <span>{row.deductionPercentage} %</span>
      )
    },
    {
      key: "price",
      title: "UNIT PRICE PER GRAM",
      dataIndex: "price",
      ...getColumnSearchProps("price"),
    },
    {
      key: "totalpounds",
      title: "TOTAL POUNDS",
      dataIndex: "totalpounds",
      ...getColumnSearchProps("totalpounds"),
    },
    {
      key: "totalPoints",
      title: "MARKETPLACE POINTS",
      dataIndex: "totalPoints",
      ...getColumnSearchProps("totalPoints"),
    },
    {
      key: "salesRequestId",
      title: "ACTIONS",
      hidden: data?.statusId !== 1 || roles.includes(12) || roles.includes(15),
      render: (_, row) => {
        const rowIndexInArr = dataSource.findIndex((ele) => ele.salesRequestId === row.salesRequestId);
        let hideDeleteBtn = false;
        if (rowIndexInArr === dataSource.length - 1) {
          hideDeleteBtn = true
        }
        return (
          <div>
            <Space size={5} className="mb-2">
              {editableRows.includes(row.salesRequestId) ? (
                <Button
                  className="border-0"
                  disabled={loadingDataRows.includes(row.salesRequestId)}
                  loading={submittingRows.includes(row.salesRequestId)}
                  onClick={() => {
                    if (
                      loadingDataRows.includes(row.salesRequestId) ||
                      submittingRows.includes(row.salesRequestId)
                    ) {
                      return;
                    }
                    updateRowData(row);
                  }}
                  style={{ backgroundColor: "#589559", color: "white" }}
                >
                  Save
                </Button>
              ) : (
                <Space size={5} className="mb-2">
                  <Button
                    className="btn--secondary"
                    onClick={() => {
                      const updatedArr = [...editableRows];
                      updatedArr.push(row.salesRequestId);
                      setEditableRows(updatedArr);
                    }}
                  >
                    Edit
                  </Button>
                  {!hideDeleteBtn ? (
                    <Button
                      className="border-0"
                      onClick={() => {
                        setRowIdToDelete(row.salesRequestId);
                        setDeleteSupplyOpen(true);
                      }}
                      style={{ backgroundColor: "#E72121", color: "white" }
                      }
                    >
                      Delete
                    </Button>
                  ) : (
                    ""
                  )}
                </Space>
              )}
            </Space>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (data) {
      const deepClone = structuredClone(data.cansSalesRequests || []);
      setDataSource(deepClone);
    }
  }, [data]);

  useEffect(() => {
    getSupplyingTypesForCansRequest(
      (res) => {
        if (res.success) {
          const options = res.data.map((unit) => {
            return { label: unit.name, value: unit.id };
          });
          setActiveSupplyingTypes(options);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        toastError(res.message);
      }
    );
  }, []);

  return (
    <>
      <Modal
        title={subHeader}
        open={open}
        width={window.innerWidth >= 992 ? "66%" : "90%"}
        className="msg-modal"
        footer={false}
        closable={false}
        centered
        destroyOnClose
        onCancel={onCancel}
      >
        {data?.statusId === 1  && !(roles.includes(12) || roles.includes(15)) ? (
          <div className="d-flex justify-content-end py-3">
            <Button
              onClick={() => setAddSupplyOpen(true)}
              htmlType="button"
              className="border-0 outline-none shadow-none"
              icon={<PlusOutlined />}
              style={{ backgroundColor: "#589559", color: "white" }}
            >
              Add Supply Type
            </Button>
          </div>
        ) : (
          ""
        )}
        <GridTable
          columns={columns}
          dataSource={dataSource}
          className="cans-sales-requests__view-details"
          rowId={"salesRequestId"}
          currentPage={currentPage}
          onPageChange={(value) => setCurrentPage(value)}
          pageSize={pageSize}
          onPageSizeChange={(_, newSize) => {
            setPageSize(newSize)
          }}
        />
      </Modal>
      {addSupplyOpen && (
        <AddRequestSupplyType
          open={addSupplyOpen}
          SubHeader={
            <SubHeader
              bgColor="#589559"
              title={`Add Supply Type`}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          rowData={data}
          onSubmit={() => {
            setAddSupplyOpen(false)
            onSubmitSupplyTypes()
          }}
          activeSupplyingTypes={activeSupplyingTypes}
          onCancel={handleCancelModal}
        />
      )}
      {deleteSupplyOpen && (
        <DeleteRequestSupplyType
          open={deleteSupplyOpen}
          SubHeader={
            <SubHeader
              bgColor="#589559"
              title={`Delete Supply Type`}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          rowId={rowIdToDelete}
          onDelete={() => {
            handleCancelModal()
            onSubmitSupplyTypes()
          }}
          onCancel={handleCancelModal}
        />
      )}
    </>
  );
};

export default ViewRequestDetails;
