import { useState, useEffect } from 'react';
import { getBlob, ref } from 'firebase/storage';
import { Button } from 'antd';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { theFirstEncounteredLetterIsArabic } from 'helpers/RegexPatterns';
import { FBStorage } from 'apis/firebase';
import { downloadFileFromBlob, getFilePathFromDownloadURL } from 'helpers/helpfulFunctions';

// assets
import zipIcon from "assets/icons/files-extensions/zip.png"
import rarIcon from "assets/icons/files-extensions/rar.png"
import htmlIcon from "assets/icons/files-extensions/html.png"
import pdfIcon from "assets/icons/files-extensions/pdf.png"
import docIcon from "assets/icons/files-extensions/doc.png"
import xlsIcon from "assets/icons/files-extensions/xls.png"
import pptIcon from "assets/icons/files-extensions/ppt.png"
import txtIcon from "assets/icons/files-extensions/txt.png"
import fallbackIcon from "assets/icons/files-extensions/file.png"
import Highlighter from 'react-highlight-words';

const MessageDocument = ({ data, searchValue }) => {
    const [currentIcon, setCurrentIcon] = useState("");
    const [nameDir, setNameDir] = useState("ltr");
    const [isDownloading, setIsDownloading] = useState(false);

    const onDownload = () => {
        if (isDownloading) return;
        setIsDownloading(true)
        const filePath = getFilePathFromDownloadURL(data.message);
        const storageRef = ref(FBStorage, filePath);
        getBlob(storageRef)
            .then((blob) => {
                downloadFileFromBlob(blob, data.mediaName)
                setIsDownloading(false)
            })
            .catch((error) => {
                setIsDownloading(false)
                console.log('error downloading file: ', error)
            })
    }

    useEffect(() => {
        if (data) {
            let icon = ""
            switch (data.documentExtension.slice(0, 3)) {
                case "zip":
                    icon = zipIcon
                    break;
                case "rar":
                    icon = rarIcon
                    break;
                case "htm":
                    icon = htmlIcon
                    break;
                case "pdf":
                    icon = pdfIcon
                    break;
                case "doc":
                    icon = docIcon
                    break;
                case "xls":
                    icon = xlsIcon
                    break;
                case "ppt":
                    icon = pptIcon
                    break;
                case "txt":
                    icon = txtIcon
                    break;
                default:
                    icon = fallbackIcon
                    break;
            }
            setCurrentIcon(icon)
            if (theFirstEncounteredLetterIsArabic.test(data.mediaName)) {
                setNameDir("rtl");
            }
        }
    }, [data])

    return (
        <div className="chat__chat-holder__body__message__holder__document d-flex align-items-center gap-2">
            {isDownloading ? <LoadingOutlined /> : ""}
            <button
                onClick={onDownload}
                className="d-flex gap-2 h-auto bg-transparent p-0 border-0 outline-none shadow-none"
            >
                <img src={currentIcon} alt="" className="chat__chat-holder__body__message__holder__document__icon d-block img-fluid" />
                <div>
                    <p
                        dir={nameDir}
                        className={`chat__chat-holder__body__message__holder__document__name text-start ${!searchValue ? "text-truncate" : ""} fsize--9 mb-1`}
                    >
                        <Highlighter
                            highlightStyle={{
                                backgroundColor: "#ffc069",
                                padding: 0,
                            }}
                            searchWords={[searchValue]}
                            autoEscape
                            textToHighlight={data?.mediaName}
                        />
                    </p>
                    <p className="chat__chat-holder__body__message__holder__document__size fsize--11 mb-0 text-start">
                        {data?.size}
                    </p>
                </div>
            </button>
        </div>
    );
}

export default MessageDocument;