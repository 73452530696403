import { useEffect, useRef, useState } from "react";
import { Button, Image, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { toastError } from "../../helpers/toasters";
import { getMerchantSalesRequests } from "../../apis/Network";
import { baseUrl } from "../../apis/services";

// assets
import videoThumbnail from "../../assets/images/video-play.jpg";

// components
import GridTable from "../SubComponents/GridTable/GridTable";
import SubHeader from "../SubComponents/SubHeader";
import MainHeader from "../SubComponents/MainHeader";
import RequestModal from "./RequestModal";
// import RequestModal from "./RequestModal";

const MerchantSalesRequests = () => {
    const [loading, setLoading] = useState(true);
    const [dataSource, setDataSource] = useState([]);
    const [mode, setMode] = useState("");
    const [rowData, setRowData] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [renderMe, setRenderMe] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);

    const handleCancelModal = () => {
        setIsModalOpen(false);
        setMode("");
        setRowData("")
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text || text === 0 ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            key: "requestIndex",
            title: "REQUEST NUMBER",
            dataIndex: "requestIndex",
            ...getColumnSearchProps("requestIndex"),
            sorter: (a, b) => a.requestIndex - b.requestIndex,
            sortDirections: ["ascend", "descend"],
        },
        {
            key: "image",
            title: "IMAGE",
            dataIndex: "image",
            render: (_, row) => {
                const preview = {
                    destroyOnClose: true,
                }
                return (
                    <Image
                        preview={preview}
                        src={baseUrl + row.image}
                        className="sales-requests__receipt-img d-block"
                    />
                )
            },
        },
        {
            key: "dealerName",
            title: "MERHCANT NAME",
            dataIndex: "dealerName",
            ...getColumnSearchProps("dealerName"),
            sorter: (a, b) => a.dealerName.toLowerCase() < b.dealerName.toLowerCase() ? -1 : 1,
            sortDirections: ["ascend", "descend"],
        },
        {
            key: "dealerMobile",
            title: "MOBILE NUMBER",
            dataIndex: "dealerMobile",
            ...getColumnSearchProps("dealerMobile"),
        },
        {
            key: "type",
            title: "TYPE",
            dataIndex: "type",
            ...getColumnSearchProps("type"),
        },
        {
            key: "totalQuantity",
            title: "QUANTITY",
            dataIndex: "totalQuantity",
            ...getColumnSearchProps("totalQuantity"),
            sorter: (a, b) => a.totalQuantity - b.totalQuantity,
            sortDirections: ["ascend", "descend"],
        },
        {
            key: "perKiloPrice",
            title: "UNIT PRICE",
            dataIndex: "perKiloPrice",
            ...getColumnSearchProps("perKiloPrice"),
        },
        {
            key: "totalPrice",
            title: "TOTAL PRICE",
            dataIndex: "totalPrice",
            ...getColumnSearchProps("totalPrice"),
        },
        {
            key: "days",
            title: "Recieving Period",
            dataIndex: "days",
            ...getColumnSearchProps("days"),
        },
        {
            key: "description",
            title: "DESCRIPTION",
            dataIndex: "description",
            ...getColumnSearchProps("description"),
            render: (_, row) => {
                return (
                    <span style={{ maxWidth: "30ch" }} title={row.description} className="d-inline-block text-truncate">
                        {row.description}
                    </span>
                );
            },
        },
        {
            key: "store",
            title: "STORE",
            dataIndex: "store",
            ...getColumnSearchProps("store"),
        },
        {
            key: "date",
            title: "CREATION DATE",
            dataIndex: "date",
            ...getColumnSearchProps("date"),
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
            sortDirections: ["ascend", "descend"],
        },
        {
            key: "requestStatus",
            title: "STATUS",
            dataIndex: "requestStatus",
            filters: [
                { text: "Accepted", value: 2 },
                { text: "Rejected", value: 3 },
                { text: "Pending", value: 1 },
            ],
            onFilter: (value, record) => record?.requestStatusId === value,
        },
        {
            key: "salesRequestId",
            title: "ACTIONS",
            render: (_, row) => {
                return (
                    <div>
                        <Space size={5} className="mb-2">
                            <Button
                                className="border-0"
                                disabled={row.requestStatusId === 2}
                                onClick={() => {
                                    if (row.requestStatusId === 2) return
                                    setRowData(row)
                                    setMode("accept")
                                    setIsModalOpen(true)
                                }}
                                style={row.requestStatusId === 2 ? {} : { backgroundColor: "#589559", color: "white" }}>
                                Accept
                            </Button>
                            <Button
                                className="border-0"
                                disabled={row.requestStatusId === 3}
                                onClick={() => {
                                    if (row.requestStatusId === 3) return
                                    setRowData(row)
                                    setMode("reject")
                                    setIsModalOpen(true)
                                }}
                                style={row.requestStatusId === 3 ? {} : { backgroundColor: "#E72121", color: "white" }}>
                                Reject
                            </Button>
                        </Space>
                    </div >
                );
            },
        },
    ]

    useEffect(() => {
        getMerchantSalesRequests(
            (res) => {
                setLoading(false);
                if (res.success) {
                    setDataSource(res.data);
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setLoading(false);
                toastError(res.message);
            })
    }, [renderMe])

    return (
        <section className="users sales-requests">
            <div className="users__all-contents">
                <div className="users__content">
                    <div className="users__usersData">
                        <div className="pb-3 mb-3">
                            <MainHeader title="Merchant Sales Requests" />
                        </div>
                        <div className="users__table grid-table mrg-y-30">
                            <GridTable
                                loading={loading}
                                columns={columns}
                                dataSource={dataSource}
                                rowId={"salesRequestId"}
                                className="grid-table--sales-requests"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {isModalOpen &&
                <RequestModal
                    open={isModalOpen}
                    onCancel={handleCancelModal}
                    onSubmit={() => {
                        setRenderMe(!renderMe)
                        handleCancelModal()
                    }}
                    SubHeader={
                        <SubHeader
                            bgColor="#589559"
                            title={`${mode === "accept" ? "Accept " : "Reject"} Request`}
                            closeButton
                            onCancel={handleCancelModal}
                        />
                    }
                    rowData={rowData}
                    primaryBtnName={"Confirm"}
                    mode={mode}
                />
            }
        </section>
    );
}

export default MerchantSalesRequests;