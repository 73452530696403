import { useEffect, useRef, useState } from "react";
import { Button, DatePicker, Input, Space, Switch } from "antd";
import { useSelector } from "react-redux";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  getRedeemOrderStatus,
  // getCashRedeemPointsRequests,
  getStoreRedeemPointsRequests,
} from "../../apis/Network";
import { toastError } from "../../helpers/toasters";
import { exportDataAsExcel, handleAllowNumbersOnly } from "../../helpers/helpfulFunctions";

// components
import GridTable from "../SubComponents/GridTable/GridTable";
import SubHeader from "../SubComponents/SubHeader";
import ViewStoreItem from "./ViewStoreItem";
import MainHeader from "../SubComponents/MainHeader";
import ConfirmRefundModal from "./ConfirmRefundModal";
import AddressDetails from "../SocialOrders/AddressDetails";
// import ConfirmRequestModal from "./ConfirmRequestModal";
import ChangeStatusModal from "./ChangeStatusModal";
import ChangeActualTotalPriceModal from "./ChangeActualTotalPriceModal";
import ConfirmChangeAddressStatusModal from "./ConfirmChangeAddressStatusModal";

const RedeemPointsRequests = () => {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [rowData, setRowData] = useState("");
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isAddressDetailsModalOpen, setIsAddressDetailsModalOpen] = useState(false);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const [isChangeActualPriceModalOpen, setIsChangeActualPriceModalOpen] = useState(false);
  const [isChangeAddressStatusModalOpen, setIsChangeAddressStatusModalOpen] = useState(false);
  const [redeemType, setRedeemType] = useState("store");
  // const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [changeStatusModalOpen, setChangeStatusModalOpen] = useState(false);
  const [orderStatus, setOrderStatus] = useState([]);
  const [renderMe, setRenderMe] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [selectedRange, setSelectedRange] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [isExporting, setIsExporting] = useState(false);
  const [searchValues, setSearchValues] = useState({
    fullName: "",
    mobile: "",
    address: "",
    from: "",
    to: "",
    pounds: "",
    points: "",
    isDelivered: "",
    orderStatusId: "",
    hours: "",
    cashFromUser: "",
    paymentTypeId: "",
    orderTotalPaidPrice: "",
    addressSaved: ""
  });
  const [searchedColumn, setSearchedColumn] = useState("");
  const { roles } = useSelector((state) => state.auth.authorization);
  const { RangePicker } = DatePicker;
  const searchInput = useRef(null);

  const handlePageSizeChange = (current, newSize) => {
    setLoading(true);
    setPageSize(newSize);
  };

  const updateSearchValues = (property, newValue, otherProperty) => {
    let copyOfSearchObj = { ...searchValues };
    copyOfSearchObj[property] = newValue;
    if (otherProperty) {
      copyOfSearchObj[otherProperty.property] = otherProperty.value;
    }
    setSearchValues(copyOfSearchObj);
  };

  const handleCancelModal = () => {
    setIsViewModalOpen(false);
    // setIsConfirmModalOpen(false);
    setChangeStatusModalOpen(false);
    setIsRefundModalOpen(false);
    setIsAddressDetailsModalOpen(false);
    setIsChangeActualPriceModalOpen(false);
    setIsChangeAddressStatusModalOpen(false);
    setRowData("");
  };

  const handlePageChange = (page) => {
    if (page !== currentPage) {
      setLoading(true);
    }
    setCurrentPage(page);
  };

  const handleSubmitModal = () => {
    setLoading(true)
    setRenderMe(!renderMe);
    handleCancelModal();
  };

  // const handleRedeemTypeChange = (value) => {
  //   if (value) {
  //     setRedeemType("cash");
  //   } else {
  //     setRedeemType("store");
  //   }
  // };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    const value = selectedKeys[0]?.trim() || ""
    confirm();
    if (redeemType === "store") {
      switch (dataIndex) {
        case "fullName":
          updateSearchValues("fullName", value);
          break;
        case "mobile":
          updateSearchValues("mobile", value);
          break;
        case "points":
          updateSearchValues("points", value);
          break;
        case "pounds":
          updateSearchValues("pounds", value);
          break;
        case "address":
          updateSearchValues("address", value);
          break;
        case "hours":
          updateSearchValues("hours", value);
          break;
        case "cashFromUser":
          updateSearchValues("cashFromUser", value);
          break;
        case "orderTotalPaidPrice":
          updateSearchValues("orderTotalPaidPrice", value);
          break;
        default:
          break;
      }
    } else {
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    clearFilters();
    if (redeemType === "store") {
      setLoading(true);
      switch (dataIndex) {
        case "fullName":
          updateSearchValues("fullName", "");
          break;
        case "mobile":
          updateSearchValues("mobile", "");
          break;
        case "from":
          updateSearchValues("from", "");
          break;
        case "points":
          updateSearchValues("points", "");
          break;
        case "pounds":
          updateSearchValues("pounds", "");
          break;
        case "address":
          updateSearchValues("address", "");
          break;
        case "hours":
          updateSearchValues("hours", "");
          break;
        case "cashFromUser":
          updateSearchValues("cashFromUser", "");
          break;
        case "orderTotalPaidPrice":
          updateSearchValues("orderTotalPaidPrice", "");
          break;
        default:
          break;
      }
    } else {
      setSearchText("");
    }
    confirm();
  };

  const handleDateChange = (dates, dateStr) => {
    setSelectedRange(dates);
    if (dates) {
      const startDate = dates[0].format("YYYY-MM-DD");
      const endDate = dates[1].format("YYYY-MM-DD");
      updateSearchValues("from", startDate, { property: "to", value: endDate });
    } else {
      updateSearchValues("from", "", { property: "to", value: "" });
    }
  };

  const handleTableChange = (_, filters) => {
    if (filters.isDelivered) {
      if (filters.isDelivered.length > 1) {
        updateSearchValues("isDelivered", "");
      } else {
        updateSearchValues("isDelivered", filters.isDelivered[0]);
      }
    } else if (searchValues.isDelivered) {
      updateSearchValues("isDelivered", "");
    }

    if (filters.paymentTypeId) {
      if (filters.paymentTypeId.length > 1) {
        updateSearchValues("paymentTypeId", "");
      } else {
        updateSearchValues("paymentTypeId", filters.paymentTypeId[0]);
      }
    } else if (searchValues.paymentTypeId) {
      updateSearchValues("paymentTypeId", "");
    }

    if (filters.orderStatusId) {
      if (searchValues.orderStatusId) {
        const filterArrToStr = [...filters.orderStatusId].sort((x, y) => x - y).join("")
        const crrentArrToStr = [...searchValues.orderStatusId].sort((x, y) => x - y).join("")
        if (filterArrToStr !== crrentArrToStr) {
          updateSearchValues("orderStatusId", filters.orderStatusId)
        }
      } else {
        updateSearchValues("orderStatusId", filters.orderStatusId)
      }
    } else if (searchValues.orderStatusId) {
      updateSearchValues("orderStatusId", null)
    }

    if (filters.addressSaved) {
      if (filters.addressSaved.length > 1) {
        updateSearchValues("addressSaved", "");
      } else {
        updateSearchValues("addressSaved", filters.addressSaved[0]);
      }
    } else if (searchValues.addressSaved || searchValues.addressSaved === false) {
      updateSearchValues("addressSaved", "");
    }
  };

  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: () => (
      <div
        style={{
          padding: 8,
        }}
        className="range-picker-holder"
        onKeyDown={(e) => e.stopPropagation()}
      >
        <RangePicker
          value={selectedRange}
          allowClear
          popupClassName="main-date-picker"
          inputReadOnly={false}
          onChange={(dates, datesStr) => {
            setCurrentPage(1);
            handleDateChange(dates, datesStr);
          }}
          onKeyDown={(e) => e.preventDefault()}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterOutlined
        style={selectedRange?.length > 0 ? { color: "blue" } : {}}
      />
    ),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onKeyDown={(e) => {
            const acceptsNumbersOnly = [
              "pounds",
              "points",
              "hours",
              "cashFromUser",
              "orderTotalPaidPrice"
            ]
            if (acceptsNumbersOnly.includes(dataIndex)) {
              handleAllowNumbersOnly(e, true)
            }
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters, dataIndex, confirm)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          ></Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => true,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text || text === 0 ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      key: "fullName",
      title: "USER NAME",
      dataIndex: "fullName",
      fixed: "left",
      ...getColumnSearchProps("fullName"),
    },
    {
      key: "mobile",
      title: "MOBILE NUMBER",
      dataIndex: "mobile",
      fixed: "left",
      ...getColumnSearchProps("mobile"),
    },
    {
      key: "points",
      title: "NUMBER OF POINTS",
      dataIndex: "points",
      ...getColumnSearchProps("points"),
    },
    {
      key: "pounds",
      title: "POUNDS",
      dataIndex: "pounds",
      ...getColumnSearchProps("pounds"),
    },
    {
      key: "orderTotalPaidPrice",
      title: "ACTUAL TOTAL PRICE",
      dataIndex: "orderTotalPaidPrice",
      ...getColumnSearchProps("orderTotalPaidPrice"),
      render: (_, row) => {
        return (
          <span className="d-block text-center">
            {row.orderTotalPaidPrice || ""}
          </span>
        );
      },
    },
    {
      key: "isDelivered",
      title: "REQUEST STATUS",
      dataIndex: "isDelivered",
      filters: [
        { text: "Redeemed", value: true },
        { text: "Pending", value: false },
      ],
      render: (_, row) => (
        <span>{row.isDelivered ? "Redeemed" : "Pending"}</span>
      ),
    },
    {
      key: "address",
      title: "Address",
      hidden: true,
      dataIndex: "address",
      ...getColumnSearchProps("address"),
      render: (_, row) => {
        return (
          <p className="redeem-points__address mx-auto mb-0">{row.address}</p>
        );
      },
    },
    {
      key: "orderStatusId",
      title: "ORDER STATUS",
      dataIndex: "orderStatusId",
      filters: orderStatus,
      render: (_, row) => (
        <span>{row.orderStatus}</span>
      ),
    },
    {
      key: "hours",
      title: "DELIVERY PERIOD",
      dataIndex: "hours",
      ...getColumnSearchProps("hours"),
    },
    {
      key: "cashFromUser",
      title: "CASH FROM USER",
      dataIndex: "cashFromUser",
      ...getColumnSearchProps("cashFromUser"),
    },
    {
      key: "paymentTypeId",
      title: "PAYMENT METHOD",
      dataIndex: "paymentTypeId",
      filters: [
        { text: "Cash", value: 1 },
        { text: "Credit Card", value: 2 },
      ],
      render: (_, row) => {
        return (<span>{row.paymentType}</span>)
      }
    },
    {
      key: "addressSaved",
      title: "ADDRESS SAVED STATUS",
      dataIndex: "addressSaved",
      hidden: !(roles.includes(1) || roles.includes(9)),
      filters: [
        { text: "Saved", value: true },
        { text: "Unsaved", value: false },
      ],
      render: (_, row) => (
        <Switch
          checked={row.addressSaved}
          onChange={() => {
            setRowData(row);
            setIsChangeAddressStatusModalOpen(true);
          }}
        />
      ),
    },
    {
      key: "date",
      title: "REQUEST DATE",
      dataIndex: "date",
      ...getColumnDateProps("date"),
    },
    {
      key: "id",
      title: "ACTIONS",
      render: (_, row) => {
        const disabled = row.orderStatusId === 4 || row.orderStatusId === 6;
        const hideBtn = roles.includes(12) || roles.includes(15);
        return (
          <div className="action-btns-grid">
            {!hideBtn ? (
              <Button
                className="border-0 w-100"
                disabled={disabled}
                onClick={() => {
                  if (disabled) return;
                  setRowData(row);
                  // setIsConfirmModalOpen(true);
                  setChangeStatusModalOpen(true);
                }}
                style={disabled ? (
                  {}
                ) : (
                  { backgroundColor: "#589559", color: "white" }
                )}
              >
                Change Status
              </Button>
            ) : (
              ""
            )}
            {redeemType === "store" ? (
              <Button
                className="w-100"
                onClick={() => {
                  setRowData(row);
                  setIsViewModalOpen(true);
                }}
                style={{
                  color: "black",
                  border: "1px solid black",
                }}
              >
                View Details
              </Button>
            ) : (
              ""
            )}
            {!hideBtn ? (
              <Button
                onClick={() => {
                  setRowData(row);
                  setIsRefundModalOpen(true);
                }}
                className="w-100 border-0"
                style={{
                  backgroundColor: "rgb(233 98 44)",
                  color: "white",
                }}
              >
                Refund
              </Button>
            ) : (
              ""
            )}
            <Button
              className="btn--secondary w-100"
              onClick={() => {
                setRowData(row);
                setIsAddressDetailsModalOpen(true);
              }}
            >
              Address Details
            </Button>
            {roles.includes(1) || roles.includes(9) ? (
              <Button
                className="w-100"
                onClick={() => {
                  setRowData(row);
                  setIsChangeActualPriceModalOpen(true);
                }}
                style={{
                  backgroundColor: "#176b87",
                  color: "white",
                  border: "none",
                }}
              >
                Add Actual Total Price
              </Button>
            ) : (
              ""
            )}
          </div >
        );
      },
    },
  ];

  const handleExportDataToExcel = () => {
    if (isExporting) return;
    setIsExporting(true)
    const baseProps = [
      { title: "User Name", prop: "fullName" },
      { title: "User Mobile", prop: "mobile" },
      { title: "Number Of Points", prop: "points" },
      { title: "Pounds", prop: "pounds" },
      { title: "Actual Total Price", prop: "orderTotalPaidPrice" },
      { title: "Request Status", prop: "isDelivered", values: { true: "Redeemed", false: "Pending" } },
      { title: "Order Status", prop: "orderStatus" },
      { title: "Delivery Period", prop: "hours" },
      { title: "Cash From User", prop: "cashFromUser" },
      { title: "Payment Type", prop: "paymentType" },
      { title: "Request Date", prop: "date" },
      { title: "Government", prop: "goverment" },
      { title: "Area", prop: "district" },
      { title: "Address", prop: "address" },
      { title: "Street", prop: "street" },
      { title: "Nearest Landmark", prop: "landmark" },
      { title: "Building", prop: "buildingNo" },
      { title: "Floor", prop: "floorNo" },
      { title: "Apartment", prop: "apartmentNo" },
    ];
    const nestedProps = {
      prop: "storeItemInfos",
      requestTitle: "Items",
      data: [
        { title: "Item Name", prop: "name" },
        { title: "Item Quantity", prop: "quantity" },
        { title: "Item Unit Price", prop: "price" },
        { title: "Item Total Price", prop: "totalPrice" },
        { title: "Item URL", prop: "link" },
      ],
    }

    const payload = {
      isExport: true,
      pageNumber: currentPage,
      perPage: pageSize,
      ...searchValues,
    };
    for (let property in payload) {
      if (!payload[property] && payload[property] !== 0) {
        delete payload[property]
      }
    }
    getStoreRedeemPointsRequests(
      payload,
      (res) => {
        if (res.success) {
          exportDataAsExcel(
            res.data.response,
            { baseProps, nestedProps, tab: "Redeem Points Requests Report" }
          )
          setIsExporting(false)
        }
      },
      (res) => {
        setIsExporting(false)
        toastError(res?.data?.error);
      }
    );
  }

  useEffect(() => {
    setLoading(true);
    const payload = {
      pageNumber: currentPage,
      perPage: pageSize,
      ...searchValues,
    };
    for (let property in payload) {
      if (!payload[property] && payload[property] !== 0 && payload[property] !== false) {
        delete payload[property]
      }
    }
    getStoreRedeemPointsRequests(
      payload,
      (res) => {
        setLoading(false);
        if (res.success) {
          setDataSource(res.data.response);
          setTotalCount(res.data.totalCount);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setLoading(false);
        toastError(res.message);
      }
    );
  }, [renderMe, currentPage, pageSize, searchValues]);

  useEffect(() => {
    getRedeemOrderStatus(
      (res) => {
        if (res.success) {
          const list = res.data.map((ele) => ({ value: ele.id, text: ele.name }))
          list.push({ text: "Refunded", value: 5 }, { text: "Cancelled", value: 6 })
          setOrderStatus(list)
        } else {
          toastError(res.message)
        }
      },
      (res) => {
        toastError(res?.data?.error)
      }
    )
  }, [])

  return (
    <section className="users redeem-points">
      <div className="users__all-contents">
        <div className="users__content">
          <div className="users__usersData">
            <div className="pb-3 mb-3">
              <MainHeader title="Redeem Points Requests" />
            </div>
            {/* <div className="d-flex align-items-center gap-2 mb-3">
              <span className="text-white">Store</span>
              <Switch
                className="redeem-points__type-switch"
                checked={redeemType === "cash"}
                onChange={handleRedeemTypeChange}
              />
              <span className="text-white">Cash</span>
            </div> */}
            <div className="users__table grid-table mrg-y-30">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                rowId={"id"}
                currentPage={currentPage}
                totalCount={totalCount}
                pageSize={pageSize}
                onChange={handleTableChange}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                title="Redeem Points Requests"
                isExporting={isExporting}
                allowExport={!(roles.includes(12) || roles.includes(15))}
                onExport={handleExportDataToExcel}
              />
            </div>
          </div>
        </div>
      </div>
      {/* {isConfirmModalOpen && (
        <ConfirmRequestModal
          request={rowData}
          open={isConfirmModalOpen}
          redeemType={redeemType}
          subHeader={
            <SubHeader
              bgColor="#589559"
              title={`Mark As Redeemed`}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          onCancel={handleCancelModal}
          onSubmit={handleSubmitModal}
        />
      )} */}
      {changeStatusModalOpen && (
        <ChangeStatusModal
          rowData={rowData}
          open={changeStatusModalOpen}
          redeemType={redeemType}
          subHeader={
            <SubHeader
              bgColor="#589559"
              title={`Change Redeem Order Status`}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          onCancel={handleCancelModal}
          onSubmit={handleSubmitModal}
        />
      )}
      {isChangeActualPriceModalOpen && (
        <ChangeActualTotalPriceModal
          rowData={rowData}
          open={isChangeActualPriceModalOpen}
          SubHeader={
            <SubHeader
              bgColor="#589559"
              title={`Change Actual Price`}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          onCancel={handleCancelModal}
          onSubmit={handleSubmitModal}
        />
      )}
      {isChangeAddressStatusModalOpen && (
        <ConfirmChangeAddressStatusModal
          rowData={rowData}
          open={isChangeAddressStatusModalOpen}
          SubHeader={
            <SubHeader
              bgColor="#589559"
              title={`Change Address Status`}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          onCancel={handleCancelModal}
          onSubmit={handleSubmitModal}
        />
      )}
      {isRefundModalOpen && (
        <ConfirmRefundModal
          request={rowData}
          open={isRefundModalOpen}
          type={1}
          subHeader={
            <SubHeader
              bgColor="#589559"
              title={`Refund A Redeemed Request`}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          onSubmit={handleSubmitModal}
          onCancel={handleCancelModal}
        />
      )}
      {isViewModalOpen && (
        <ViewStoreItem
          rowData={rowData?.storeItemInfos}
          open={isViewModalOpen}
          onSubmit={() => {
            setRenderMe(!renderMe)
          }}
          subHeader={
            <SubHeader
              bgColor="#589559"
              title={`View Details`}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          onCancel={handleCancelModal}
        />
      )}
      {isAddressDetailsModalOpen && (
        <AddressDetails
          open={isAddressDetailsModalOpen}
          rowData={rowData}
          subHeader={
            <SubHeader
              bgColor="#589559"
              title={"Address Details"}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          onSubmit={() => {
            handleCancelModal();
            setRenderMe(!renderMe);
          }}
          onCancel={handleCancelModal}
        />
      )}
    </section>
  );
};

export default RedeemPointsRequests;
