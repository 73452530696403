import { useEffect, useState } from "react";
import { Col, Form, Input, Modal, Row, Select, Space, Switch } from "antd";
import { toastError, toastSuccess } from "../../helpers/toasters";
import {
  addStoreSubcategory,
  editStoreSubcategory,
  getActiveStoreCategories,
  getAllPartnersWithoutPagination,
  getPartnersOfCategoryForList,
} from "../../apis/Network";
import { arabicNameValidation } from "../../constants/RegexValidations";

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";

const AddEditStoreSubcategory = ({
  isSubcategoryModalVisible,
  mode,
  rowData = {},
  SubHeader,
  primaryBtnName,
  secondaryBtnName,
  onSubmit,
  onCancel,
}) => {
  const [categories, setCategories] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [partnersList, setPartnersList] = useState(null);
  const [allActiveDeactivePartners, setAllActiveDeactivePartners] = useState(null);
  const [partnersListLoading, setPartnersListLoading] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [form] = Form.useForm();
  const initialValues = {
    nameEn: "",
    nameAr: "",
    category: undefined,
    partners: undefined,
    isActive: true,
  };

  const handleAddSubcategory = (payload) => {
    addStoreSubcategory(
      payload,
      (res) => {
        setSubmitting(false);
        if (res.success) {
          toastSuccess(res.message);
          onSubmit();
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setSubmitting(false);
        toastError(res.message);
      }
    );
  };

  const handleEditSubcategory = (payload) => {
    editStoreSubcategory(
      payload,
      (success) => {
        setSubmitting(false);
        if (success.success) {
          toastSuccess(success.message);
          onSubmit();
        } else {
          toastError(success.message);
        }
      },
      (fail) => {
        setSubmitting(false);
        toastError(fail.message);
      }
    );
  };

  const handleOnFinish = (values) => {
    if (submitting || partnersListLoading) return;
    setSubmitting(true);

    const payload = {
      name: values.nameEn.trim(),
      nameAr: values.nameAr.trim(),
      categoryId: selectedCategory,
      isActive: values.isActive,
      partners: values.partners || []
    };
    if (mode === "add") {
      handleAddSubcategory(payload);
    } else {
      payload.subCategoryId = rowData.id;
      const selectedPartners = values.partners?.map((ele) => {
        let partnerOfName = allActiveDeactivePartners?.find((partner) => partner.nameEn === ele)
        if (partnerOfName) {
          return partnerOfName.id
        } else {
          return ele
        }
      })
      payload.partners = selectedPartners || []
      handleEditSubcategory(payload);
    }
  };

  useEffect(() => {
    if (rowData && mode === "edit") {
      form.setFieldsValue({
        nameEn: rowData.nameEn,
        nameAr: rowData.nameAr,
        isActive: rowData.isActive,
      });
      setSelectedCategory(rowData.categoryId)
      setPartnersListLoading(true)
    }
  }, [rowData, mode, form]);

  useEffect(() => {
    if (!selectedCategory) return
    getPartnersOfCategoryForList(
      selectedCategory,
      (res) => {
        setPartnersListLoading(false)
        if (res.success) {
          const options = res.data.map((partner) => {
            return { value: partner.id, label: partner.name };
          });
          setPartnersList(options)
        } else {
          toastError(res.message)
        }
      },
      (res) => {
        setPartnersListLoading(false)
        toastError(res.message)
      }
    )
  }, [selectedCategory])

  useEffect(() => {
    getActiveStoreCategories(
      (res) => {
        if (res.success) {
          const options = res.data.map((category) => {
            return { value: category.id, label: category.name };
          });
          setCategories(options);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        if (res.data?.error) {
          toastError(res.message);
        }
      }
    );
  }, []);

  useEffect(() => {
    if (!rowData) return;
    if (categories) {
      const categoryExists = categories.find((category) => category.value === rowData.categoryId)
      if (!categoryExists) {
        form.setFieldValue("category", rowData.category)
      } else {
        form.setFieldValue("category", rowData.categoryId)
      }
    }
  }, [rowData, categories])

  useEffect(() => {
    if (!rowData || !partnersList || mode !== "edit" || !firstRender) return;
    getAllPartnersWithoutPagination(
      (res) => {
        setFirstRender(false);
        if (res.success) {
          setAllActiveDeactivePartners(res.data)
          const selectedPartners = rowData.partners.map((partId) => {
            const isPartnerActive = partnersList.find((partner) => partner.value === partId);
            if (isPartnerActive) {
              return partId
            } else {
              const partnerName = res.data.find((partner) => partner.id === partId).nameEn
              return partnerName
            }
          })
          form.setFieldValue("partners", selectedPartners)
        } else {
          toastError(res.message)
        }
      },
      (res) => {
        toastError(res?.data?.error)
      }
    )
  }, [rowData, partnersList])

  return (
    <Modal
      title={SubHeader}
      open={isSubcategoryModalVisible}
      className="msg-modal"
      footer={false}
      closable={false}
      centered
      destroyOnClose
    >
      <Form
        form={form}
        className="edit-actor__content store-items__item-form"
        onFinish={handleOnFinish}
        initialValues={initialValues}
      >
        <Row gutter={24}>
          <Col span={24}>
            <div className="new-actor__name new-category mb-5">
              <label className="users__parent-info-label mb-2" htmlFor="">
                Name (EN)
              </label>
              <Form.Item
                className=" new-category__form-item"
                name="nameEn"
                validateFirst
                rules={[
                  {
                    required: true,
                    message: "Please, Enter Subcategory name in English",
                  },
                  {
                    min: 3,
                    message: "Minimum length is 3 characters",
                  },
                  {
                    pattern:
                      /^[a-z][a-z\s\d$!@#%^&*()_+-=\[\]/{}\\|;':",./<>?~`]*[a-z\d$!@#%^&*()_+-=\[\]/{}\\|;':",./<>?~`]$/i,
                    message: "Please, Enter Subcategory name in English",
                  },
                  {
                    max: 50,
                    message: "Maximum length is 50 characters",
                  },
                ]}
              >
                <Input
                  type="text"
                  className="search__searchField py-2"
                  placeholder="Enter Name in English"
                  maxLength={50}
                  showCount
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={24}>
            <div className="new-actor__name new-category mb-5">
              <label className="users__parent-info-label mb-2" htmlFor="">
                Name (AR)
              </label>
              <Form.Item
                className=" new-category__form-item"
                name="nameAr"
                validateFirst
                rules={[
                  {
                    required: true,
                    message: "Please, Enter Subcategory name in Arabic",
                  },
                  {
                    min: 3,
                    message: "Minimum length is 3 characters",
                  },
                  {
                    pattern: arabicNameValidation,
                    message: "Please, Enter Subcategory name in Arabic",
                  },
                  {
                    max: 50,
                    message: "Maximum length is 50 characters",
                  },
                ]}
              >
                <Input
                  type="text"
                  className="search__searchField py-2"
                  placeholder="Enter Name in Arabic"
                  maxLength={50}
                  dir="rtl"
                  showCount
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={24}>
            <div className="new-actor__name new-category mb-5">
              <label className="users__parent-info-label mb-2" htmlFor="">
                Category
              </label>
              <Form.Item
                className="new-category__form-item"
                name="category"
                rules={[
                  {
                    required: true,
                    message: "Please, select a category",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="label"
                  placeholder="Select category"
                  onChange={(value) => {
                    setSelectedCategory(value)
                    setPartnersListLoading(true)
                    form.resetFields(["partners"])
                  }}
                  loading={categories ? false : true}
                  options={categories ? categories : []}
                  getPopupContainer={(trigger) => trigger.parentElement}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={24}>
            <div className="new-actor__name new-category mb-5">
              <label className="users__parent-info-label mb-2" htmlFor="">
                Partners
              </label>
              <Form.Item
                className="new-category__form-item"
                name="partners"
              >
                <Select
                  showSearch
                  allowClear
                  mode="multiple"
                  optionFilterProp="label"
                  placeholder="Select partners"
                  loading={partnersListLoading}
                  options={partnersList ? partnersList : []}
                  getPopupContainer={(trigger) => trigger.parentElement}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={24}>
            <div className="new-actor__name mb-4 row mx-0 align-items-center">
              <div className="col-4 px-0 text-start">
                <label className="users__parent-info-label">Active</label>
              </div>
              <div className="col-8 px-0 d-flex">
                <Form.Item
                  name="isActive"
                  valuePropName="checked"
                  className="mb-0"
                >
                  <Switch />
                </Form.Item>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mrg-ys-20 mrg-x-auto justify-content-center">
          <Space size={20}>
            <SecondaryButton buttonName={secondaryBtnName} onClick={onCancel} />
            <PrimaryButton
              buttonName={primaryBtnName}
              loading={submitting}
              disableMe={partnersListLoading}
              type="submit"
            />
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddEditStoreSubcategory;
