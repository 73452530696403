const GovernmentIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10120_22755)">
                <path d="M22.2141 22.5938V15.0924H14.1349V22.5938H12.7286V13.6862H17.4714V5.66616H15.8905V2.52408H14.1016V0H12.6953V2.52408H10.9064V5.66616H9.3255V22.5938H7.91925V8.80828H1.78589V22.5938H0V24H24V22.5938H22.2141ZM14.2664 7.40203H15.6726V8.80828H14.2664V7.40203ZM14.2664 10.5441H15.6726V11.9504H14.2664V10.5441ZM5.52464 22.0423H4.11839V20.636H5.52464V22.0423ZM5.52464 18.6783H4.11839V17.272H5.52464V18.6783ZM5.52464 15.3143H4.11839V13.9081H5.52464V15.3143ZM5.52464 11.9504H4.11839V10.5441H5.52464V11.9504ZM12.5305 11.9504H11.1243V10.5441H12.5305V11.9504ZM12.5305 8.80828H11.1243V7.40203H12.5305V8.80828ZM12.6953 4.25991H14.1015V5.66616H12.6953V4.25991ZM17.2967 22.5938H15.8905V16.95H17.2967V22.5938ZM20.4585 22.5938H19.0523V16.95H20.4585V22.5938Z"  />
            </g>
            <defs>
                <clipPath id="clip0_10120_22755">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default GovernmentIcon;