import { useState, useEffect } from 'react';
import { Row, Space, InputNumber, Modal, Form, Col, Input, Switch } from 'antd';
import { toastError, toastSuccess } from '../../helpers/toasters';
import { addSupplyingTypeWithImage, editSupplyingTypeWithImage } from '../../apis/Network';
import { handleAllowNumbersOnly } from '../../helpers/helpfulFunctions';

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";
import ImageUpload from 'modules/HomeBanner/ImageUpload';

const AddEditModal = ({
    open,
    onCancel,
    SubHeader,
    primaryBtnName,
    mode,
    rowData,
    onSubmit
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [uploadedImg, setUploadedImg] = useState("");
    const [form] = Form.useForm();
    const initialValues = {
        image: "",
        nameEn: "",
        nameAr: "",
        pricePerKilo: "",
        perKiloPriceForPoints: "",
        priceForCash: "",
        priceForCheck: "",
        isActive: true,
    }

    const handleUnitPriceValidation = (_, value) => {
        if (!value && value !== 0) {
            return Promise.reject(new Error(`Please, Enter the price per kilo`));
        } else if (value <= 0) {
            return Promise.reject(new Error(`Minimum value should be greater than 0`));
        } else if (value >= 100000000) {
            return Promise.reject(new Error(`Maximum value is 8 digits`));
        } else {
            if (
                form.getFieldValue("perKiloPriceForPoints") &&
                !form.isFieldValidating("perKiloPriceForPoints")
            ) {
                form.validateFields(["perKiloPriceForPoints"]);
            }
            return Promise.resolve()
        }
    }

    const handlePriceOnStoreValidation = (_, value) => {
        if (!value && value !== 0) {
            return Promise.reject(new Error(`Please, Enter the price for marketplace`));
        } else if (value <= 0) {
            return Promise.reject(new Error(`Minimum value should be greater than 0`));
        } else if (form.getFieldValue("pricePerKilo") && value < form.getFieldValue("pricePerKilo")) {
            return Promise.reject(new Error(`Price for marketplace can't be less than price per kilo`));
        } else {
            return Promise.resolve()
        }
    }

    const handlePriceValidation = (_, value) => {
        if (!value && value !== 0) {
            return Promise.reject(new Error(`Please, Enter the price`));
        } else if (value <= 0) {
            return Promise.reject(new Error(`Minimum value should be greater than 0`));
        } else if (value >= 100000000) {
            return Promise.reject(new Error(`Maximum value is 8 digits`));
        } else {
            return Promise.resolve()
        }
    }

    const handleOnFinish = (values) => {
        if (submitting) return;
        setSubmitting(true);
        const formData = new FormData();
        const payload = {
            nameAr: values.nameAr,
            nameEN: values.nameEn,
            perKiloPrice: values.pricePerKilo,
            isActive: values.isActive,
            perKiloPriceForPoints: values.perKiloPriceForPoints,
            partnerShipCashPricePerKilo: values.priceForCash,
            partnerShipCheckPricePerKilo: values.priceForCheck,
        }
        for (const key in payload) {
            formData.append(key, payload[key]);
        }
        if (uploadedImg) {
            formData.append("image", uploadedImg)
        }
        if (mode === "add") {
            addSupplyingTypeWithImage(
                formData,
                (res) => {
                    setSubmitting(false)
                    if (res.success) {
                        toastSuccess(res.message);
                        onSubmit();
                    } else {
                        toastError(res.message);
                    }
                },
                (res) => {
                    setSubmitting(false)
                    toastError(res.message);
                }
            )
        } else {
            formData.append("id", rowData.id)
            const isImageDeleted = rowData.image && !form.getFieldValue("image")
            if(isImageDeleted) {
                formData.append("deleteImage", true)
            }
            editSupplyingTypeWithImage(
                formData,
                (res) => {
                    setSubmitting(false)
                    if (res.success) {
                        toastSuccess(res.message);
                        onSubmit();
                    } else {
                        toastError(res.message);
                    }
                },
                (res) => {
                    setSubmitting(false)
                    toastError(res.message);
                }
            )
        }
    }

    useEffect(() => {
        if (mode === "edit" && rowData) {
            form.setFieldValue("image", rowData.image)
            form.setFieldValue("pricePerKilo", rowData.perKiloPrice)
            form.setFieldValue("nameEn", rowData.nameEN)
            form.setFieldValue("nameAr", rowData.nameAr)
            form.setFieldValue("isActive", rowData.isActive)
            form.setFieldValue("perKiloPriceForPoints", rowData.perKiloPriceForPoints)
            form.setFieldValue("priceForCheck", rowData.partnerShipCheckPricePerKilo)
            form.setFieldValue("priceForCash", rowData.partnerShipCashPricePerKilo)
        }
    }, [rowData, mode, form])

    return (
        <Modal
            title={SubHeader}
            open={open}
            className="msg-modal"
            footer={false}
            closable={false}
            centered
            destroyOnClose
            onCancel={onCancel}
        >
            <Form
                form={form}
                className="edit-actor__content pt-3"
                onFinish={handleOnFinish}
                initialValues={initialValues}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <ImageUpload
                                formRef={form}
                                name="image"
                                mode={mode}
                                onUpload={(value) => setUploadedImg(value)}
                                rowDataImg={rowData?.image}
                                label={"Image"}
                                required={false}
                                deletable={true}
                            />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2" htmlFor="">
                                Name In English
                            </label>
                            <Form.Item
                                className="new-category__form-item"
                                name="nameEn"
                                validateFirst
                                rules={[
                                    {
                                        required: true,
                                        message: "Please, Enter the type name in English",
                                    },
                                    {
                                        pattern: /^[\w\s$!@#%^&*()_+-=[\]/{}\\|;':",./<>?~`]+$/,
                                        message: "Please, Enter the type name in English",
                                    },
                                    {
                                        min: 2,
                                        message: "Minimum length is 2 characters"
                                    },
                                    {
                                        max: 50,
                                        message: "Maximum length is 50 characters"
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    className="search__searchField py-2"
                                    placeholder="Enter Name in English"
                                    maxLength={50}
                                    showCount
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2" htmlFor="">
                                Name In Arabic
                            </label>
                            <Form.Item
                                className=" new-category__form-item"
                                name="nameAr"
                                validateFirst
                                rules={[
                                    {
                                        required: true,
                                        message: "Please, Enter the type name in Arabic",
                                    },
                                    {
                                        pattern: /^[\u0600-\u06FF\s\d$!@#%^&*()_+-=[\]/\\{}|;':",./<>؟~؟’~‘÷×>`]+$/,
                                        message: "Please, Enter the type name in Arabic",
                                    },
                                    {
                                        min: 2,
                                        message: "Minimum length is 2 characters"
                                    },
                                    {
                                        max: 50,
                                        message: "Maximum length is 50 characters"
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    className="search__searchField py-2"
                                    placeholder="Enter Name in Arabic"
                                    maxLength={50}
                                    showCount
                                    dir='rtl'
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <div className="lang-name wd-100 flex">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Price Per Kilo
                                </label>
                            </div>
                            <Form.Item
                                className="new-category__form-item"
                                name="pricePerKilo"
                                rules={[
                                    { validator: handleUnitPriceValidation }
                                ]}
                            >
                                <InputNumber
                                    min={0}
                                    className="w-100 d-block search__searchField py-1"
                                    placeholder="Enter the price per kilo for this type"
                                    onKeyDown={(e) => handleAllowNumbersOnly(e, true)}
                                    controls={false}
                                    keyboard={false}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <div className="lang-name wd-100 flex">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Price For Marketplace
                                </label>
                            </div>
                            <Form.Item
                                className="new-category__form-item"
                                name="perKiloPriceForPoints"
                                rules={[
                                    { validator: handlePriceOnStoreValidation }
                                ]}
                            >
                                <InputNumber
                                    min={0}
                                    className="w-100 d-block search__searchField py-1"
                                    placeholder="Enter the price for marketplace"
                                    onKeyDown={(e) => handleAllowNumbersOnly(e, true)}
                                    controls={false}
                                    keyboard={false}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <div className="lang-name wd-100 flex">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Partnership Cash
                                </label>
                            </div>
                            <Form.Item
                                className="new-category__form-item"
                                name="priceForCash"
                                rules={[
                                    { validator: handlePriceValidation }
                                ]}
                            >
                                <InputNumber
                                    min={0}
                                    className="w-100 d-block search__searchField py-1"
                                    placeholder="Enter the partnership cash"
                                    onKeyDown={(e) => handleAllowNumbersOnly(e, true)}
                                    controls={false}
                                    keyboard={false}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <div className="lang-name wd-100 flex">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Partnership Check
                                </label>
                            </div>
                            <Form.Item
                                className="new-category__form-item"
                                name="priceForCheck"
                                rules={[
                                    { validator: handlePriceValidation }
                                ]}
                            >
                                <InputNumber
                                    min={0}
                                    className="w-100 d-block search__searchField py-1"
                                    placeholder="Enter the partnership check"
                                    onKeyDown={(e) => handleAllowNumbersOnly(e, true)}
                                    controls={false}
                                    keyboard={false}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name mb-4 row mx-0 align-items-center">
                            <div className="col-4 px-0 text-start">
                                <label className="users__parent-info-label">Active</label>
                            </div>
                            <div className="col-8 px-0 d-flex">
                                <Form.Item
                                    name="isActive"
                                    valuePropName="checked"
                                    className="mb-0"
                                >
                                    <Switch />
                                </Form.Item>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mrg-ys-20 mrg-x-auto mt-2 justify-content-center">
                    <Space size={20}>
                        <SecondaryButton
                            buttonName={"Cancel"}
                            onClick={onCancel}
                        />
                        <PrimaryButton
                            buttonName={primaryBtnName}
                            loading={submitting}
                            type="submit"
                        />
                    </Space>
                </Row>
            </Form>
        </Modal>
    );
}

export default AddEditModal;