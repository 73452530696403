import { useEffect, useState } from "react";
import { Form, Modal, Row, Space, Col, Select } from "antd";
import {
  AcceptOrRejectCansSalesRequest,
  getActiveCouriers,
} from "../../apis/Network";
import { toastError, toastSuccess } from "../../helpers/toasters";

// components
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";

const AcceptRejectModal = ({
  mode,
  request,
  open,
  subHeader,
  onCancel,
  onSubmit,
  primaryBtnName,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [couriers, setCouriers] = useState(null);
  const initialValues = {
    courier: undefined,
  };

  const handleFinish = (values) => {
    if (submitting) return;
    setSubmitting(true);

    const payload = {
      salesRequsetId: request.requestId,
      isAccepted: mode === "accept",
      courierId: values.courier,
    };

    AcceptOrRejectCansSalesRequest(
      payload,
      (res) => {
        setSubmitting(false);
        if (res.success) {
          toastSuccess(res.message);
          onSubmit();
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setSubmitting(false);
        toastError(res.message);
      }
    );
  };

  useEffect(() => {
    getActiveCouriers(
      (res) => {
        if (res.success) {
          const dataArr = res.data.map((courier) => {
            return { value: courier.id, label: courier.userName };
          });
          setCouriers(dataArr);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        if (res.data?.error) {
          toastError(res.message);
        }
      }
    );
  }, []);

  return (
    <Modal
      title={subHeader}
      open={open}
      className="msg-modal"
      footer={false}
      closable={false}
      centered
      destroyOnClose
      onCancel={onCancel}
    >
      <p className="text-center pt-3 mb-3">
        You are about to {mode} this request!
      </p>
      <Form
        className="edit-actor__content"
        onFinish={handleFinish}
        initialValues={initialValues}
      >
        {/* {mode === "accept" ? (
                    <Row>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <label className="users__parent-info-label mb-2">
                                    Courier
                                </label>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please, Select a courier",
                                        },
                                    ]}
                                    name="courier"
                                    className="new-category__form-item"
                                >
                                    <Select
                                        name="courier"
                                        className="new-category__form-item"
                                        placeholder="Select Courier"
                                        loading={couriers ? false : true}
                                        options={couriers ? couriers : []}
                                        getPopupContainer={trigger => trigger.parentElement}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                ) : (
                    ""
                )} */}
        <Row className="mrg-ys-20 mrg-x-auto mt-2 justify-content-center">
          <Space size={20}>
            <SecondaryButton buttonName={"Cancel"} onClick={onCancel} />
            <PrimaryButton
              buttonName={primaryBtnName}
              loading={submitting}
              type="submit"
            />
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};

export default AcceptRejectModal;
