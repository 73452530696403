import { Image, Space, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const PreviewUploadedVideo = ({ file, onCancel, onUpload }) => {
  return (
    <Image
      src=""
      alt=""
      width={0}
      height={0}
      hidden
      className="d-none"
      rootClassName="chat__chat-holder__footer__upload-preview-holder"
      preview={{
        zIndex: 1702,
        visible: file,
        maskClosable: false,
        keyboard: false,
        onVisibleChange: onCancel,
        imageRender: () => (
          <video controls className="chat__chat-holder__footer__upload-preview-holder__video d-block img-fluid" src={URL.createObjectURL(file?.originFileObj)} />
        ),
        toolbarRender: () => (
          <Space size={12} className="toolbar-wrapper">
            <Button
              onClick={onUpload}
              className="chat__chat-holder__footer__upload-preview-holder__preview-upload-btn text--white fw-bold border-0 outline-none shadow-none h-auto"
            >
              <UploadOutlined />
            </Button>
          </Space>
        ),
      }}
    />
  );
};

export default PreviewUploadedVideo;
