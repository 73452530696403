import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Space,
} from "antd";
import { toastError, toastSuccess } from "../../helpers/toasters";
import {
  addMerchantScrapTypesPriceList,
  getMerchantPeriodsForList,
} from "../../apis/Network";

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";
import FormItem from "./FormItem";

function SupplyModal({
  open,
  mode,
  rowData = {},
  SubHeader,
  primaryBtnName,
  secondaryBtnName,
  onCancel,
  onSubmit
}) {
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [periods, setPeriods] = useState(null);
  const [itemsCounter, setItemsCounter] = useState(0);
  const [createdItems, setCreatedItems] = useState([{ id: 0, deliveryPeriodId: undefined, pricePerKilo: "", state: 1 }]);
  const [form] = Form.useForm();
  const initialValues = {
    isActive: true,
  }

  const handleAddNewItem = () => {
    const itemId = itemsCounter + 1;
    setItemsCounter(itemId);
    const newItem = {
      id: itemId,
      deliveryPeriodId: undefined,
      pricePerKilo: "",
    };
    newItem.state = 1;
    const createdItemsCopy = structuredClone(createdItems);
    createdItemsCopy.push(newItem);
    setCreatedItems(createdItemsCopy);
  };

  const handleUpdateItem = (id, property, value) => {
    const createdItemsCopy = structuredClone(createdItems);
    const itemIndex = createdItemsCopy.findIndex((ele) => ele.id === id);
    createdItemsCopy[itemIndex][property] = value;
    setCreatedItems(createdItemsCopy);
  };

  const handleDeleteItem = (id) => {
    const createdItemsCopy = structuredClone(createdItems);
    const itemIndx = createdItemsCopy.findIndex((ele) => ele.id === id);
    if (mode === "add" || createdItemsCopy[itemIndx].state === 1) {
      const updatedItemsList = createdItemsCopy.filter((ele) => ele.id !== id);
      setCreatedItems(updatedItemsList);
    } else {
      createdItemsCopy[itemIndx].state = 3
      setCreatedItems(createdItemsCopy);
    }
  };

  const handleOnCancel = () => {
    setIsModalLoading(false);
    onCancel();
  };

  const handleFinish = (values) => {
    if (isModalLoading) return;
    setIsModalLoading(true);

    const updatedData = createdItems.map((item) => {
      if (item.state === 1) {
        const { id, ...remainData } = item;
        return remainData;
      } else {
        return item;
      }
    });
    const payload = {
      scrapperTypeId: rowData.id,
      pricesDaysList: updatedData
    }
    addMerchantScrapTypesPriceList(
      payload,
      (res) => {
        setIsModalLoading(false);
        if (res.success) {
          toastSuccess(res.message);
          onSubmit()
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setIsModalLoading(false);
        toastError(res.message);
      }
    );
  };

  useEffect(() => {
    getMerchantPeriodsForList(
      (res) => {
        if (res.success) {
          const data = res.data.map((ele) => {
            return { value: ele.id, label: ele.days };
          });
          setPeriods(data);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        toastError(res.message);
      }
    );
  }, []);

  useEffect(() => {
    if (rowData && mode === "edit") {
      const itemsList = rowData.merchamtPrices.map((item) => {
        form.setFieldValue(`deliveryPeriodId-${item.id}`, item.deliveryPeriodId)
        form.setFieldValue(`pricePerKilo-${item.id}`, item.pricePerKilo)
        return { ...item, state: 2, }
      });
      setCreatedItems(itemsList);
      form.setFieldsValue({ isActive: rowData.isActive, })
    }
  }, [rowData, mode]);

  return (
    <Modal
      title={SubHeader}
      open={open}
      className="msg-modal"
      footer={false}
      closable={false}
      centered
      destroyOnClose
    >
      <Form
        className="edit-actor__content"
        initialValues={initialValues}
        onFinish={handleFinish}
        form={form}
      >
        <Row gutter={24}>
          <Col span={24}>
            <div className="d-flex justify-content-end wd-100">
              <Button className="mb-3" onClick={handleAddNewItem}>
                + Add
              </Button>
            </div>
          </Col>
          <Col span={24}>
            <div className="mb-3">
              <div
                className="d-flex flex-column gap-3"
              // style={{ maxHeight: "300px", overflowY: "auto" }}
              >
                {createdItems?.map(
                  (ele, indx) => {
                    if (ele.state !== 3) {
                      return (
                        <FormItem
                          key={ele.id}
                          data={ele}
                          order={indx}
                          onDelete={handleDeleteItem}
                          periods={periods}
                          allItems={createdItems}
                          onUpdateItem={handleUpdateItem}
                        />
                      )
                    } else {
                      return null
                    }
                  }
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Space size={20}>
            <SecondaryButton
              buttonName={secondaryBtnName}
              onClick={handleOnCancel}
            />
            <PrimaryButton
              buttonName={primaryBtnName}
              loading={isModalLoading}
              type="submit"
            />
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}

export default SupplyModal;