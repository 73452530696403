import { useEffect, useRef, useState } from "react";
import { Button, Input, Modal, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { toastError } from "helpers/toasters";
import {
  getSocialOrderRequestDetailsById,
  getSocialOrderRequestImagesById
} from "apis/Network";

// components
import GridTable from "../SubComponents/GridTable/GridTable";
import ImagesCarousel from "./ImagesCarousel";

const ViewDetails = ({ subHeader, open, rowData = {}, onCancel }) => {
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [images, setImages] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          ></Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text, record) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      key: "supplyType",
      title: "SUPPLY TYPE",
      dataIndex: "supplyType",
      ...getColumnSearchProps("supplyType"),
    },
    {
      key: "date",
      title: "REQUEST DATE",
      dataIndex: "date",
      ...getColumnSearchProps("date"),
    },
  ];

  useEffect(() => {
    if (rowData) {
      getSocialOrderRequestDetailsById(
        rowData.requestId,
        (res) => {
          setLoading(false)
          if (res.success) {
            setDataSource(res.data)
          } else {
            toastError(res.message)
          }
        },
        (res) => {
          setLoading(false)
          toastError(res?.data?.error)
        }
      )
      getSocialOrderRequestImagesById(
        rowData.requestId,
        (res) => {
          setLoading(false)
          if (res.success) {
            setImages(res.data)
          } else {
            toastError(res.message)
          }
        },
        (res) => {
          setLoading(false)
          toastError(res?.data?.error)
        }
      )
    }
  }, [rowData]);

  return (
    <Modal
      title={subHeader}
      open={open}
      width={window.innerWidth >= 992 ? "66%" : "90%"}
      className="msg-modal social-orders__view-details  cans-sales-requests__view-details"
      footer={false}
      closable={false}
      centered
      destroyOnClose
      onCancel={onCancel}
    >
      {images?.length ? (
        <div className="my-4">
          <ImagesCarousel data={images} />
        </div>
      ) : (
        ""
      )}
      <GridTable
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        rowId={"id"}
      />
    </Modal>
  );
};

export default ViewDetails;