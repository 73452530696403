import { useEffect, useRef, useState } from "react";
import { Button, Input, Space, DatePicker, Image } from "antd";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from 'moment';
import { getSupplyingTypes } from "../../apis/Network";
import { toastError } from "../../helpers/toasters";
import { baseUrl } from "apis/services";

// components
import GridTable from "../SubComponents/GridTable/GridTable";
import MainHeader from "../SubComponents/MainHeader";
import SubHeader from "./../SubComponents/SubHeader";
import AddEditModal from "./AddEditModal";
import DeleteModal from "./DeleteModal";

const SupplyingTypes = () => {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [creationDateSelectedRange, setCreationDateSelectedRange] = useState(null);
  const [updatedDateSelectedRange, setUpdatedDateSelectedRange] = useState(null);
  const [mode, setMode] = useState("add");
  const [rowData, setRowData] = useState(null);
  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [renderMe, setRenderMe] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const { RangePicker } = DatePicker;

  const filterDataByDate = (originalData, property, startDate, endDate) => {
    const dataWithingRange = originalData.filter((ele) => {
      const comparisonDate = moment(ele[property], "DD MMM, YYYY");
      return (
        comparisonDate.isSameOrAfter(startDate) &&
        comparisonDate.isSameOrBefore(endDate)
      );
    });
    return dataWithingRange;
  }

  const handleDateChange = (dates, dataIndex) => {
    if (dataIndex === "creationDate") {
      setCreationDateSelectedRange(dates);
    } else {
      setUpdatedDateSelectedRange(dates);
    }
    if (dates) {
      const startDate = dates[0].format("DD MMM, YYYY");
      const endDate = dates[1].format("DD MMM, YYYY");
      const dataWithingRange = dataSource.filter((ele) => {
        const comparisonDate = moment(ele[dataIndex], "DD MMM, YYYY");
        return (
          comparisonDate.isSameOrAfter(startDate) &&
          comparisonDate.isSameOrBefore(endDate)
        );
      });
      if (dataIndex === "creationDate" && updatedDateSelectedRange) {
        const startDate = updatedDateSelectedRange[0].format("DD MMM, YYYY");
        const endDate = updatedDateSelectedRange[1].format("DD MMM, YYYY");
        const filteredData = filterDataByDate(dataWithingRange, "updatedDate", startDate, endDate)
        setFilteredDataSource(filteredData);
      } else if (dataIndex === "updatedDate" && creationDateSelectedRange) {
        const startDate = creationDateSelectedRange[0].format("DD MMM, YYYY");
        const endDate = creationDateSelectedRange[1].format("DD MMM, YYYY");
        const filteredData = filterDataByDate(dataWithingRange, "creationDate", startDate, endDate)
        setFilteredDataSource(filteredData);
      } else {
        setFilteredDataSource(dataWithingRange);
      }
    } else {
      if (dataIndex === "creationDate" && updatedDateSelectedRange) {
        const startDate = updatedDateSelectedRange[0].format("DD MMM, YYYY");
        const endDate = updatedDateSelectedRange[1].format("DD MMM, YYYY");
        const dataWithingRange = filterDataByDate(dataSource, "updatedDate", startDate, endDate)
        setFilteredDataSource(dataWithingRange);
      } else if (dataIndex === "updatedDate" && creationDateSelectedRange) {
        const startDate = creationDateSelectedRange[0].format("DD MMM, YYYY");
        const endDate = creationDateSelectedRange[1].format("DD MMM, YYYY");
        const dataWithingRange = filterDataByDate(dataSource, "creationDate", startDate, endDate)
        setFilteredDataSource(dataWithingRange);
      } else {
        setFilteredDataSource(dataSource);
      }
    }
  };

  const handleCancelModal = () => {
    setIsAddEditModalOpen(false);
    setIsDeleteModalOpen(false);
    setMode("");
    setRowData(null);
  };
  const handleOpenAddModal = () => {
    setIsAddEditModalOpen(true);
    setMode("add");
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          ></Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text || text === 0 ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: () => (
      <div
        style={{
          padding: 8,
        }}
        className="range-picker-holder"
        onKeyDown={(e) => e.stopPropagation()}
      >
        <RangePicker
          value={dataIndex === "creationDate" ? creationDateSelectedRange : updatedDateSelectedRange}
          allowClear
          popupClassName="main-date-picker"
          inputReadOnly={false}
          onChange={(dates) => handleDateChange(dates, dataIndex)}
          onKeyDown={(e) => e.preventDefault()}
        />
      </div>
    ),
    filterIcon: (filtered) => <FilterOutlined style={
      dataIndex === "creationDate" ? (
        creationDateSelectedRange?.length > 0 ? { color: "blue" } : {}
      ) : (
        updatedDateSelectedRange?.length > 0 ? { color: "blue" } : {}
      )
    } />,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });

  const columns = [
    {
      key: "image",
      title: "IMAGE",
      dataIndex: "image",
      render: (_, row) => {
        return row.image ? (
          <Image
            src={baseUrl + row.image}
            width={70}
            height={50}
            preview={{ src: baseUrl + row.image }}
          />
        ) : (
          ""
        )
      }
    },
    {
      key: "nameEN",
      title: "NAME(EN)",
      dataIndex: "nameEN",
      fixed: "left",
      ...getColumnSearchProps("nameEN"),
    },
    {
      key: "nameAr",
      title: "NAME(AR)",
      dataIndex: "nameAr",
      ...getColumnSearchProps("nameAr"),
    },
    {
      key: "perKiloPrice",
      title: "PRICE PER KILO",
      dataIndex: "perKiloPrice",
      ...getColumnSearchProps("perKiloPrice"),
    },
    {
      key: "perKiloPriceForPoints",
      title: "PRICE FOR MARKETPLACE",
      dataIndex: "perKiloPriceForPoints",
      ...getColumnSearchProps("perKiloPriceForPoints"),
    },
    {
      key: "partnerShipCashPricePerKilo",
      title: "PARTNERSHIP CASH",
      dataIndex: "partnerShipCashPricePerKilo",
      ...getColumnSearchProps("partnerShipCashPricePerKilo"),
    },
    {
      key: "partnerShipCheckPricePerKilo",
      title: "PARTNERSHIP CHECK",
      dataIndex: "partnerShipCheckPricePerKilo",
      ...getColumnSearchProps("partnerShipCheckPricePerKilo"),
    },
    {
      key: "isActive",
      title: "STATUS",
      dataIndex: "isActive",
      filters: [
        { text: "Active", value: true },
        { text: "Inactive", value: false },
      ],
      onFilter: (value, record) => record?.isActive === value,
      render: (_, row) => {
        return <span>{row.isActive ? "Active" : "Inactive"}</span>;
      },
    },
    {
      key: "creationDate",
      title: "CREATION DATE",
      dataIndex: "creationDate",
      ...getColumnDateProps("creationDate"),
    },
    {
      key: "updatedDate",
      title: "UPDATE DATE",
      dataIndex: "updatedDate",
      ...getColumnDateProps("updatedDate"),
    },
    {
      key: "id",
      title: "ACTION",
      fixed: "right",
      render: (_, row) => {
        return (
          <Space size={5}>
            <Button
              className="border-0"
              style={{ backgroundColor: "#589559", color: "white" }}
              onClick={() => {
                setRowData(row);
                setMode("edit");
                setIsAddEditModalOpen(true);
              }}
            >
              Edit
            </Button>
            <Button
              className="border-0"
              onClick={() => {
                setRowData(row);
                setIsDeleteModalOpen(true);
              }}
              style={{ backgroundColor: "#E72121", color: "white" }}
            >
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true)
    getSupplyingTypes(
      (res) => {
        setLoading(false);
        if (res.success) {
          setDataSource(res.data);
          setFilteredDataSource(res.data);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setLoading(false);
        toastError(res.message);
      }
    );
  }, [renderMe]);

  return (
    <section className="users">
      <div className="users__all-contents">
        <div className="users__content">
          <div className="users__usersData">
            <div className="pb-3 mb-3">
              <MainHeader
                title="Supplying Types"
                buttonName={"Add New Type"}
                onButtonClick={handleOpenAddModal}
              />
            </div>
            <div className="users__table grid-table mrg-y-30">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={filteredDataSource}
                rowId={"id"}
              />
            </div>
          </div>
        </div>
      </div>
      {isAddEditModalOpen && (
        <AddEditModal
          open={isAddEditModalOpen}
          onCancel={handleCancelModal}
          onSubmit={() => {
            setRenderMe(!renderMe);
            handleCancelModal();
          }}
          SubHeader={
            <SubHeader
              bgColor="#589559"
              title={`${mode === "add" ? "Add New" : "Edit"} Type`}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          rowData={rowData}
          primaryBtnName={mode === "edit" ? "Update" : "Add"}
          mode={mode}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          rowData={rowData}
          open={isDeleteModalOpen}
          onCancel={handleCancelModal}
          onDelete={() => {
            setRenderMe(!renderMe);
            handleCancelModal();
          }}
          SubHeader={
            <SubHeader
              bgColor="#589559"
              title={`Delete Type`}
              closeButton
              onCancel={handleCancelModal}
            />
          }
        />
      )}
    </section>
  );
};

export default SupplyingTypes;
