import { useEffect, useRef, useState } from "react";
import { Button, Input, Space, DatePicker } from "antd";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
// import moment from "moment";
import { getAcceptedRequestsReport, getCollectionTypes } from "../../apis/Network";
import { toastError } from "../../helpers/toasters";
import { exportDataAsExcel, handleAllowNumbersOnly } from "../../helpers/helpfulFunctions";

// components
import GridTable from "../SubComponents/GridTable/GridTable";
import MainHeader from "../SubComponents/MainHeader";

const AcceptedRequestsReport = () => {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectedRange, setSelectedRange] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isExporting, setIsExporting] = useState(false);
  const [collectionTypes, setCollectionTypes] = useState([]);
  const [searchValues, setSearchValues] = useState({
    fullName: "",
    mobile: "",
    from: "",
    to: "",
    pounds: "",
    points: "",
    grams: "",
    type: "",
    acceptedBy: "",
    deductionPercentage: "",
    storePounds: "",
  });
  const [totalCount, setTotalCount] = useState(1);
  const { RangePicker } = DatePicker;
  const searchInput = useRef(null);

  const handlePageSizeChange = (current, newSize) => {
    setLoading(true);
    setPageSize(newSize);
  };
  const handlePageChange = (page) => {
    setLoading(true);
    setCurrentPage(page);
  };

  const updateSearchValues = (property, newValue, otherProperty) => {
    const copyOfSearchObj = { ...searchValues };
    copyOfSearchObj[property] = newValue;
    if (otherProperty) {
      copyOfSearchObj[otherProperty.property] = otherProperty.value;
    }
    setSearchValues(copyOfSearchObj);
  };
  const handleTableChange = (_, filters) => {
    if (filters.collectionType) {
      if (searchValues.collectionType) {
        const filterArrToStr = [...filters.collectionType].sort((x, y) => x - y).join("")
        const crrentArrToStr = [...searchValues.collectionType].sort((x, y) => x - y).join("")
        if (filterArrToStr !== crrentArrToStr) {
          updateSearchValues("collectionType", filters.collectionType)
        }
      } else {
        updateSearchValues("collectionType", filters.collectionType)
      }
    } else if (searchValues.collectionType) {
      updateSearchValues("collectionType", null)
    }
  }
  const handleDateChange = (dates, dateStr) => {
    setSelectedRange(dates);
    if (dates) {
      const startDate = dates[0].format("YYYY-MM-DD");
      const endDate = dates[1].format("YYYY-MM-DD");
      updateSearchValues("from", startDate, { property: "to", value: endDate });
    } else {
      updateSearchValues("from", "", { property: "to", value: "" });
    }
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    const value = selectedKeys[0]?.trim() || "";
    confirm();
    switch (dataIndex) {
      case "userName":
        updateSearchValues("fullName", value);
        break;
      case "userPhone":
        updateSearchValues("mobile", value);
        break;
      case "type":
        updateSearchValues("type", value);
        break;
      case "totalQuantityInGrams":
        updateSearchValues("grams", value);
        break;
      case "totalPoints":
        updateSearchValues("points", value);
        break;
      case "totalPounds":
        updateSearchValues("pounds", value);
        break;
      case "acceptedBy":
        updateSearchValues("acceptedBy", value);
        break;
      case "deductionPercentage":
        updateSearchValues("deductionPercentage", value);
        break;
      case "storePounds":
        updateSearchValues("storePounds", value);
        break;
      default:
        break;
    }
  };
  const handleReset = (clearFilters, dataIndex, confirm) => {
    // setSearchText("");
    clearFilters();
    setLoading(true);
    switch (dataIndex) {
      case "userName":
        updateSearchValues("fullName", "");
        break;
      case "userPhone":
        updateSearchValues("mobile", "");
        break;
      case "type":
        updateSearchValues("type", "");
        break;
      case "totalQuantityInGrams":
        updateSearchValues("grams", "");
        break;
      case "totalPoints":
        updateSearchValues("points", "");
        break;
      case "totalPounds":
        updateSearchValues("pounds", "");
        break;
      case "acceptedBy":
        updateSearchValues("acceptedBy", "");
        break;
      case "deductionPercentage":
        updateSearchValues("deductionPercentage", "");
        break;
      case "storePounds":
        updateSearchValues("storePounds", "");
        break;
      default:
        break;
    }
    confirm();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onKeyDown={(e) => {
            const acceptsNumbersOnly = [
              "storePounds",
              "deductionPercentage",
              "totalPounds",
              "totalPoints",
              "totalQuantityInGrams",
              "userPhone"
            ];
            if (acceptsNumbersOnly.includes(dataIndex)) {
              handleAllowNumbersOnly(e, true);
            }
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters, dataIndex, confirm)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          ></Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => true,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: () => (
      <div
        style={{
          padding: 8,
        }}
        className="range-picker-holder"
        onKeyDown={(e) => e.stopPropagation()}
      >
        <RangePicker
          value={selectedRange}
          allowClear
          popupClassName="main-date-picker"
          inputReadOnly={false}
          onChange={(dates, datesStr) => {
            setCurrentPage(1);
            handleDateChange(dates, datesStr);
          }}
          onKeyDown={(e) => e.preventDefault()}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterOutlined
        style={selectedRange?.length > 0 ? { color: "blue" } : {}}
      />
    ),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });
  const columns = [
    {
      key: "userName",
      title: "USERNAME",
      dataIndex: "userName",
      fixed: "left",
      ...getColumnSearchProps("userName"),
    },
    {
      key: "userPhone",
      title: "MOBILE NUMBER",
      dataIndex: "userPhone",
      fixed: "left",
      ...getColumnSearchProps("userPhone"),
    },
    {
      key: "type",
      title: "SUPPLY TYPE",
      dataIndex: "type",
      ...getColumnSearchProps("type"),
    },
    {
      key: "collectionType",
      title: "COLLECTION TYPE",
      dataIndex: "collectionType",
      filters: collectionTypes,
      onFilter: (value, record) => {
        // record.collectionType === value
        return true
      },
    },
    {
      key: "totalQuantityInGrams",
      title: "QUANTITY IN GRAMS",
      dataIndex: "totalQuantityInGrams",
      ...getColumnSearchProps("totalQuantityInGrams"),
    },
    {
      key: "totalPoints",
      title: "MARKETPLACE POINTS",
      dataIndex: "totalPoints",
      ...getColumnSearchProps("totalPoints"),
    },
    {
      key: "storePounds",
      title: "MARKETPLACE POUNDS",
      dataIndex: "storePounds",
      ...getColumnSearchProps("storePounds"),
    },
    {
      key: "totalPounds",
      title: "TOTAL POUNDS",
      dataIndex: "totalPounds",
      ...getColumnSearchProps("totalPounds"),
    },
    {
      key: "deductionPercentage",
      title: "DEDUCTION PERCENTAGE",
      dataIndex: "deductionPercentage",
      ...getColumnSearchProps("deductionPercentage"),
      render: (_, row) => {
        return <span>{row.deductionPercentage}%</span>;
      },
    },
    {
      key: "acceptedBy",
      title: "ACCEPTED BY",
      dataIndex: "acceptedBy",
      ...getColumnSearchProps("acceptedBy"),
    },
    {
      key: "date",
      title: "REQUEST DATE",
      dataIndex: "date",
      ...getColumnDateProps("date"),
    },
  ];

  const handleExportDataToExcel = () => {
    if (isExporting) return;
    setIsExporting(true)
    const baseProps = [
      { title: "Username", prop: "userName" },
      { title: "Mobile Number", prop: "userPhone" },
      { title: "Supply Type", prop: "type" },
      { title: "Collection Type", prop: "collectionType" },
      { title: "Quantity In Grams", prop: "totalQuantityInGrams" },
      { title: "Marketplace Points", prop: "totalPoints" },
      { title: "Marketplace Pounds", prop: "storePounds" },
      { title: "Total Pounds", prop: "totalPounds" },
      { title: "Deduction Percentage", prop: "deductionPercentage" },
      { title: "Accepted By", prop: "acceptedBy" },
      { title: "Date", prop: "date" },
    ]
    const payload = {
      isExport: true,
      pageNumber: currentPage,
      perPage: pageSize,
      ...searchValues,
    };
    for (let property in payload) {
      if (!payload[property] && payload[property] !== 0) {
        delete payload[property]
      }
    }
    getAcceptedRequestsReport(
      payload,
      (res) => {
        if (res.success) {
          exportDataAsExcel(res.data.response, { baseProps, tab: "Accepted Requests Report" })
          setIsExporting(false)
        }
      },
      (res) => {
        setIsExporting(false)
        toastError(res?.data?.error);
      }
    );
  }

  useEffect(() => {
    const payload = {
      pageNumber: currentPage,
      perPage: pageSize,
      ...searchValues,
    };
    for (let property in payload) {
      if (!payload[property] && payload[property] !== 0) {
        delete payload[property]
      }
    }
    getAcceptedRequestsReport(
      payload,
      (res) => {
        setLoading(false);
        if (res.success) {
          setDataSource(res.data.response);
          setTotalCount(res.data.totalCount);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setLoading(false);
        toastError(res.message);
      }
    );
  }, [searchValues, currentPage, pageSize]);

  useEffect(() => {
    getCollectionTypes(
      (res) => {
        const filterArr = res.data.map((obj) => (
          { text: obj.name, value: obj.id }
        ))
        setCollectionTypes(filterArr)
      },
      (res) => {
        toastError(res?.data?.error)
      })
  }, [])

  return (
    <section className="users">
      <div className="users__all-contents">
        <div className="users__content">
          <div className="users__usersData">
            <div className="pb-3 mb-3">
              <MainHeader title="Accepted Requests Report" />
            </div>
            <div className="users__table grid-table mrg-y-30">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                rowId={"requestId"}
                title={"Accepted Requests Report"}
                allowExport
                isExporting={isExporting}
                onExport={handleExportDataToExcel}
                currentPage={currentPage}
                totalCount={totalCount}
                pageSize={pageSize}
                onPageSizeChange={handlePageSizeChange}
                onPageChange={handlePageChange}
                onChange={handleTableChange}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AcceptedRequestsReport;
