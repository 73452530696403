const StoreItemsIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1418_14133)">
                <path d="M1 1C0.734784 1 0.48043 1.10536 0.292893 1.29289C0.105357 1.48043 0 1.73478 0 2C0 2.26522 0.105357 2.51957 0.292893 2.70711C0.48043 2.89464 0.734784 3 1 3H2.78C2.92536 3.00025 3.06696 3.04614 3.18484 3.13118C3.30272 3.21623 3.39092 3.33614 3.437 3.474L6.734 13.367C6.881 13.807 6.899 14.279 6.787 14.729L6.516 15.816C6.117 17.41 7.358 19 9 19H21C21.2652 19 21.5196 18.8946 21.7071 18.7071C21.8946 18.5196 22 18.2652 22 18C22 17.7348 21.8946 17.4804 21.7071 17.2929C21.5196 17.1054 21.2652 17 21 17H9C8.61 17 8.36 16.68 8.455 16.303L8.66 15.485C8.69454 15.3466 8.7743 15.2238 8.88662 15.1359C8.99894 15.048 9.13739 15.0002 9.28 15H20C20.2101 15.0003 20.415 14.9343 20.5855 14.8115C20.7561 14.6888 20.8836 14.5154 20.95 14.316L23.615 6.316C23.6651 6.16567 23.6787 6.00559 23.6548 5.84895C23.6308 5.69232 23.57 5.54362 23.4773 5.4151C23.3847 5.28657 23.2628 5.18192 23.1217 5.10975C22.9806 5.03758 22.8245 4.99996 22.666 5H6.555C6.40947 4.99996 6.26764 4.95417 6.14956 4.86911C6.03149 4.78405 5.94313 4.66402 5.897 4.526L4.949 1.684C4.88265 1.48479 4.75528 1.31153 4.58495 1.18876C4.41462 1.06599 4.20997 0.999949 4 1H1ZM8 20C7.46957 20 6.96086 20.2107 6.58579 20.5858C6.21071 20.9609 6 21.4696 6 22C6 22.5304 6.21071 23.0391 6.58579 23.4142C6.96086 23.7893 7.46957 24 8 24C8.53043 24 9.03914 23.7893 9.41421 23.4142C9.78929 23.0391 10 22.5304 10 22C10 21.4696 9.78929 20.9609 9.41421 20.5858C9.03914 20.2107 8.53043 20 8 20ZM20 20C19.4696 20 18.9609 20.2107 18.5858 20.5858C18.2107 20.9609 18 21.4696 18 22C18 22.5304 18.2107 23.0391 18.5858 23.4142C18.9609 23.7893 19.4696 24 20 24C20.5304 24 21.0391 23.7893 21.4142 23.4142C21.7893 23.0391 22 22.5304 22 22C22 21.4696 21.7893 20.9609 21.4142 20.5858C21.0391 20.2107 20.5304 20 20 20Z" />
            </g>
            <defs>
                <clipPath id="clip0_1418_14133">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default StoreItemsIcon;