import { useState, useEffect } from "react";
import { Row, Space, Modal, Form, Select, Input, DatePicker } from "antd";
import dayjs from 'dayjs';
import moment from "moment";
import { toastError, toastSuccess } from "../../helpers/toasters";
import {
  bulkAssignUserStatus,
  getActiveCouriers,
  updateAssignmentRequestStatus,
  updateUserRequestStatus,
} from "../../apis/Network";
// import { handleAllowNumbersOnly, validatePhoneNumber } from "../../helpers/helpfulFunctions";

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";

const EditStatus = ({
  open,
  onCancel,
  subHeader,
  rowData,
  onSubmit,
  statusOptions,
  mode,
  assignMode,
  selectedIds
}) => {
  const [activeCouriers, setActiveCouriers] = useState(null);
  const [showCourierSelect, setShowCourierSelect] = useState(false);
  // const [showAnotherAccountField, setShowAnotherAccountField] = useState(false);
  const [selectedUserStatus, setSelectedUserStatus] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();
  const initialValues = {
    userStatus: undefined,
    courier: undefined,
    assigningDate: undefined,
    note: "",
    // anotherAccountMobile: "",
  };

  // const validateMobileNumber = (_, value) => {
  //   if (!value) {
  //     return Promise.reject(new Error("Please, Enter mobile number"));
  //   } else if (!validatePhoneNumber(value)) {
  //     return Promise.reject(new Error("Please, Enter a valid mobile number"));
  //   } else {
  //     return Promise.resolve();
  //   }
  // };

  const handleOnFinish = (values) => {
    if (submitting) return;
    setSubmitting(true);

    if (mode === "customer") {
      const payload = {
        userRequestSTatusId: values.userStatus,
        userStatusReason: values.note?.trim(),
      }
      if (assignMode === "bulk") {
        payload.requestsId = selectedIds;
        bulkAssignUserStatus(
          payload,
          (res) => {
            setSubmitting(false);
            if (res.success) {
              toastSuccess(res.message);
              onSubmit();
            } else {
              toastError(res.message);
            }
          },
          (res) => {
            setSubmitting(false);
            toastError(res.message);
          }
        );
      } else {
        payload.requestId = rowData.requestId;
        updateUserRequestStatus(
          payload,
          (res) => {
            setSubmitting(false);
            if (res.success) {
              toastSuccess(res.message);
              onSubmit();
            } else {
              toastError(res.message);
            }
          },
          (res) => {
            setSubmitting(false);
            toastError(res.message);
          }
        );
      }
    } else {
      const payload = {
        requestId: rowData.requestId,
        assigninStatusId: values.userStatus,
      };
      if (showCourierSelect) {
        payload.courierId = values.courier
        payload.assiginingDate = dayjs(values.assigningDate).format("YYYY-MM-DD")
      } 
      // else if (showAnotherAccountField) {
      //   payload.anotherAccountMobile = `${values.anotherAccountMobile.length === 11 ? "2" : ""}${values.anotherAccountMobile}`
      // }
      updateAssignmentRequestStatus(
        payload,
        (res) => {
          setSubmitting(false);
          if (res.success) {
            toastSuccess(res.message);
            onSubmit();
          } else {
            toastError(res.message);
          }
        },
        (res) => {
          setSubmitting(false);
          toastError(res.message);
        }
      );
    }
  };

  useEffect(() => {
    if (rowData && mode) {
      if (mode === "customer") {
        form.setFieldsValue({
          userStatus: rowData.userRequestStatusId,
          note: rowData.userRequestStatusReason,
        });
      } else {
        setSelectedUserStatus(rowData.assignedStatusId)
        form.setFieldsValue({
          userStatus: rowData.assignedStatusId,
        })
      }
    }
  }, [rowData, form, mode]);

  useEffect(() => {
    if (rowData && selectedUserStatus && mode === "assignment") {
      if (selectedUserStatus === 2) {
        setShowCourierSelect(true)
        const initialValues = {
          assigningDate: rowData.assigningDate ? dayjs(rowData.assigningDate, "D MMMM YYYY") : "",
        }
        if (activeCouriers) {
          initialValues.courier = rowData.courierId
        }
        form.setFieldsValue(initialValues);
        // setShowAnotherAccountField(false);
      } else if (selectedUserStatus === 6) {
        // setShowAnotherAccountField(true)
        // form.setFieldsValue({
        //   anotherAccountMobile: rowData.anotherAccount,
        // });
        setShowCourierSelect(false)
      } else {
        form.setFieldsValue({
          courier: undefined,
          assigningDate: "",
          // anotherAccountMobile: ""
        })
        // setShowAnotherAccountField(false)
        setShowCourierSelect(false)
        setSelectedUserStatus("")
      }
    }
  }, [rowData, selectedUserStatus, mode])

  useEffect(() => {
    if (rowData) {
      getActiveCouriers(
        (res) => {
          if (res.success) {
            const options = [];
            res.data.forEach((courier) => {
              const obj = {
                label: courier.userName,
                value: courier.id,
              };
              options.push(obj);
            });
            setActiveCouriers(options);
            form.setFieldsValue({
              courier: rowData.courierId,
            });
          } else {
            toastError(res.message);
          }
        },
        (res) => {
          toastError(res.message);
        }
      );
    }
  }, [rowData])

  return (
    <Modal
      title={subHeader}
      open={open}
      className="msg-modal"
      footer={false}
      closable={false}
      centered
      destroyOnClose
      onCancel={onCancel}
    >
      <Form
        form={form}
        className="edit-actor__content pt-3"
        onFinish={handleOnFinish}
        initialValues={initialValues}
      >
        <div className="new-actor__name new-category mb-5">
          <label className="users__parent-info-label mb-2 fw-bold" htmlFor="">
            {mode === "customer" ? "User Status" : "Assignment Status"}
          </label>
          <Form.Item
            className="new-category__form-item"
            name="userStatus"
            rules={[
              {
                required: true,
                message: "Please, select status",
              },
            ]}
          >
            <Select
              placeholder="Select status"
              loading={statusOptions ? false : true}
              onChange={(value) => setSelectedUserStatus(value)}
              options={statusOptions ? statusOptions : []}
              getPopupContainer={(trigger) => trigger.parentElement}
            />
          </Form.Item>
        </div>
        {mode === "assignment" ? (
          showCourierSelect ? (
            <>
              <div className="new-actor__name new-category mb-5">
                <label className="users__parent-info-label mb-2 fw-bold" htmlFor="">
                  Courier
                </label>
                <Form.Item
                  className="new-category__form-item"
                  name="courier"
                  rules={[
                    {
                      required: true,
                      message: "Please, select courier",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    placeholder="Select courier"
                    loading={activeCouriers ? false : true}
                    options={activeCouriers ? activeCouriers : []}
                    getPopupContainer={(trigger) => trigger.parentElement}
                  />
                </Form.Item>
              </div>
              <div className="new-actor__name new-category mb-5">
                <label className="users__parent-info-label mb-2 fw-bold" htmlFor="">
                  Assign date
                </label>
                <Form.Item
                  className="new-category__form-item social-orders__date-picker-holder"
                  name="assigningDate"
                  rules={[
                    {
                      required: true,
                      message: "Please, select a date",
                    },
                  ]}
                >
                  <DatePicker
                    className="w-100"
                    showNow={false}
                    showSecond={false}
                    format="DD MMMM YYYY"
                    disabledDate={(current) => moment().add(-1, "days") >= current}
                    onKeyDown={(e) => e.preventDefault()}
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                  />
                </Form.Item>
              </div>
            </>
          ) 
          // : showAnotherAccountField ? (
          //   <div className="new-actor__name new-category mb-5">
          //     <label className="users__parent-info-label mb-2 fw-bold" htmlFor="">
          //       Phone Number
          //     </label>
          //     <Form.Item
          //       name="anotherAccountMobile"
          //       className="new-category__form-item"
          //       rules={[{ validator: validateMobileNumber }]}
          //     >
          //       <Input
          //         type="text"
          //         placeholder="Enter mobile number"
          //         onKeyDown={(e) => handleAllowNumbersOnly(e, false)}
          //       />
          //     </Form.Item>
          //   </div>
          // ) 
          : (
            ""
          )
        ) : (
          <div className="new-actor__name new-category mb-5">
            <label className="users__parent-info-label mb-2 fw-bold" htmlFor="">
              Reason
            </label>
            <Form.Item
              className="new-category__form-item"
              name="note"
              validateFirst
              rules={[
                {
                  whitespace: true,
                  message: "Please, Enter a valid reason",
                },
                {
                  min: 3,
                  message: "Minimum is 3 characters",
                },
                {
                  max: 70,
                  message: "Maximum is 70 characters",
                },
              ]}
            >
              <Input.TextArea
                placeholder="Enter reason"
                maxLength={70}
                showCount
              />
            </Form.Item>
          </div>
        )}
        <Row className="mrg-ys-20 mrg-x-auto mt-2 justify-content-center">
          <Space size={20}>
            <SecondaryButton buttonName={"Cancel"} onClick={onCancel} />
            <PrimaryButton
              buttonName={"Save"}
              loading={submitting}
              type="submit"
            />
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};

export default EditStatus;
