import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Form, Input, Modal, Row, Select, Space, Switch } from "antd";
import { toastError, toastSuccess } from "../../helpers/toasters";
import { addAdminApi, editAdmin, getAdminRoles } from "../../apis/Network";
import { handleAllowNumbersOnly, handleLogout, validatePhoneNumber } from "../../helpers/helpfulFunctions";
import { adminNameValidation } from "../../constants/RegexValidations";

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";

function AddEditModal({
    open,
    mode,
    rowData = {},
    SubHeader,
    primaryBtnName,
    secondaryBtnName,
    onCancel,
    onSubmit
}) {
    const [submitting, setSubmitting] = useState(false);
    const [adminsRoles, setAdminsRoles] = useState([]);
    const [isPasswordRequired, setIsPasswordRequired] = useState(mode !== "edit");
    const { authorization } = useSelector((state) => state.auth);
    const [form] = Form.useForm();
    const initialValues = {
        adminName: "",
        adminPassword: "",
        confirmPassword: "",
        mobile: "",
        isActive: true,
        roles: [],
    }

    const handlePasswordRequire = (value, otherField) => {
        if (mode !== "edit") return;
        if (value) {
            setIsPasswordRequired(true)
        } else {
            if (!form.getFieldValue(otherField)) {
                setIsPasswordRequired(false)
                form.setFields([{
                    name: [otherField],
                    value: "",
                    touched: false,
                    validating: false,
                    errors: [],
                    warnings: []
                }])
            } else {
                setIsPasswordRequired(true)
            }
        }
    }

    const handlePasswordValidation = (_, value) => {
        if (!value.trim()) {
            return Promise.reject(new Error("Please, enter the password"));
        } else if (value.trim().length < 6) {
            return Promise.reject(new Error("Minimum length is 6 characters"));
        }
        if (
            form.getFieldValue("confirmPassword") &&
            !form.isFieldValidating("confirmPassword")
        ) {
            form.validateFields(["confirmPassword"]);
        }
        return Promise.resolve();
    };

    const handlePhoneValidation = (_, value) => {
        if (!value) {
            return Promise.reject(new Error("Please, enter the phone number"));
        } else if (!validatePhoneNumber(value)) {
            return Promise.reject(new Error("Please, enter a valid phone number"));
        } else {
            return Promise.resolve();
        }
    };

    const handleOnCancel = () => {
        setSubmitting(false);
        onCancel();
    };

    const checkIfNoDataHasBeenModified = (values) => {
        let isModified = false;
        for (let key in values) {
            if (key.includes("assword")) {
                if (values[key]) {
                    isModified = true;
                    break;
                }
            } else if (key === "roles") {
                let newRoles = values.roles;
                let oldRoles = rowData.roles;
                if (typeof newRoles === "object") {
                    newRoles = newRoles.sort().join("")
                }
                if (typeof oldRoles === "object") {
                    oldRoles = oldRoles.sort().join("")
                }
                if (newRoles !== oldRoles) {
                    isModified = true;
                    break;
                }
            } else if (!Object.keys(rowData).includes(key)) {
                isModified = true;
                break;
            } else if (values[key] !== rowData[key]) {
                isModified = true;
                break;
            }
        }
        return isModified;
    }

    const handleFinish = (values) => {
        if (submitting) return;
        setSubmitting(true);
        if (mode === "edit") {
            const isModified = checkIfNoDataHasBeenModified(values);
            if (!isModified) {
                setSubmitting(false);
                toastError("No changes!");
                handleOnCancel();
                return;
            }
        }

        const data = {
            userName: values.adminName.trim(),
            password: values.adminPassword ? values.adminPassword.trim() : null,
            mobile: values.mobile.length > 11 ? values.mobile : `2${values.mobile}`,
            isActive: values.isActive,
            roles: typeof values.roles === "object" ? values.roles : [values.roles],
        };

        if (mode === "add") {
            addAdminApi(
                data,
                (success) => {
                    if (success.success) {
                        toastSuccess(success.message);
                        onSubmit();
                    } else {
                        toastError(success.message);
                    }
                    setSubmitting(false);
                },
                (res) => {
                    toastError(res?.data?.error);
                    setSubmitting(false);
                }
            );
        } else {
            data.id = rowData?.adminId;
            delete data.userName;
            if (rowData.adminId === authorization.id) {
                data.isActive = rowData?.isActive;
            }
            editAdmin(
                data,
                (res) => {
                    setSubmitting(false);
                    if (res.success) {
                        toastSuccess(res.message);
                        onSubmit();
                        if (rowData.adminId === authorization.id && values.roles !== rowData.roles[0]) {
                            handleLogout()
                        }
                    } else {
                        toastError(res.message);
                    }
                }, (res) => {
                    toastError(res.message);
                    setSubmitting(false);
                }
            )
        }
    };

    useEffect(() => {
        getAdminRoles(
            (res) => {
                if (res.success) {
                    const data = res.data.map((ele) => {
                        return { value: ele.id, label: ele.name };
                    });
                    setAdminsRoles(data);
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                toastError(res.message);
            }
        );
    }, []);

    useEffect(() => {
        if (rowData && mode === "edit") {
            form.setFieldsValue({
                adminName: rowData.userName,
                isActive: rowData.isActive,
                mobile: rowData.mobile,
                roles: rowData.roles,
            });
        }
    }, [rowData, mode, form]);

    return (
        open && (
            <Modal
                title={SubHeader}
                open={open}
                className="msg-modal"
                footer={false}
                closable={false}
                destroyOnClose
                centered
            >
                <Form
                    form={form}
                    className="edit-actor__content"
                    onFinish={handleFinish}
                    initialValues={initialValues}
                    autoComplete="off"
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <label className="users__parent-info-label mb-2">
                                    Name
                                </label>
                                <Form.Item
                                    className="new-category__form-item"
                                    name="adminName"
                                    validateFirst
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: "Please, enter the name of the admin",
                                        },
                                        {
                                            pattern: /^[\u0600-\u06FFa-z]/i,
                                            message: "Please, enter a valid name",
                                        },
                                        {
                                            min: 2,
                                            message: "Minimum length is 2 characters"
                                        },
                                        {
                                            pattern: adminNameValidation,
                                            message: "Please, enter a valid name",
                                        },
                                        {
                                            max: 50,
                                            message: "Maximum length is 50 characters"
                                        }
                                    ]}
                                >
                                    <Input
                                        type="text"
                                        disabled={mode === "edit"}
                                        className="search__searchField py-2"
                                        placeholder="Enter admin name"
                                        showCount
                                        maxLength={50}
                                        role="presentation"
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <label className="users__parent-info-label mb-2">
                                    Password
                                </label>
                                <Form.Item
                                    className="new-category__form-item"
                                    name="adminPassword"
                                    rules={isPasswordRequired ? [
                                        { validator: handlePasswordValidation }
                                    ] : []}
                                >
                                    <Input.Password
                                        placeholder={"Enter password"}
                                        className="search__searchField py-2"
                                        autoComplete="new-password"
                                        onChange={(e) => handlePasswordRequire(e.target.value, "confirmPassword")}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <label className="users__parent-info-label mb-2">
                                    Confirm Password
                                </label>
                                <Form.Item
                                    className="new-category__form-item"
                                    name="confirmPassword"
                                    rules={isPasswordRequired ? [
                                        {
                                            required: true,
                                            message: "Please, enter the confirm password",
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue("adminPassword") === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    new Error("Password and Confirm Password do not match")
                                                );
                                            },
                                        }),
                                    ] : []}
                                >
                                    <Input.Password
                                        placeholder={"Enter confirm password"}
                                        className="search__searchField py-2"
                                        onChange={(e) => handlePasswordRequire(e.target.value, "password")}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <label className="users__parent-info-label mb-2">
                                    Phone Number
                                </label>
                                <Form.Item
                                    className="new-category__form-item"
                                    name="mobile"
                                    rules={[{ validator: handlePhoneValidation }]}
                                >
                                    <Input
                                        type="text"
                                        className="search__searchField py-2"
                                        placeholder="Enter phone number"
                                        onKeyDown={(e) => handleAllowNumbersOnly(e, false)}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name mb-4 row mx-0 align-items-center">
                                <div className="col-4 px-0 text-start">
                                    <label className="users__parent-info-label">Status</label>
                                </div>
                                <div className="col-8 px-0 d-flex">
                                    <Form.Item
                                        name="isActive"
                                        valuePropName="checked"
                                        className="mb-0"
                                    >
                                        <Switch disabled={submitting || authorization?.id === rowData?.adminId} />
                                    </Form.Item>
                                </div>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name mb-4 row mx-0 align-items-start">
                                <div className="col-3 px-0 text-start">
                                    <label className="users__parent-info-label">Role</label>
                                </div>
                                <div className="col-9 px-0 d-flex">
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please, select a role",
                                            },
                                        ]}
                                        name="roles"
                                        className="mb-0 w-100"
                                    >
                                        <Select
                                            className="new-category__form-item"
                                            name="roles"
                                            placeholder="Select role"
                                            loading={adminsRoles ? false : true}
                                            options={adminsRoles ? adminsRoles : []}
                                            allowClear
                                            getPopupContainer={trigger => trigger.parentElement}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mrg-ys-20 mrg-x-auto justify-content-center">
                        <Space size={20}>
                            <SecondaryButton
                                buttonName={secondaryBtnName}
                                onClick={handleOnCancel}
                            />
                            <PrimaryButton
                                buttonName={primaryBtnName}
                                loading={submitting}
                                type="submit"
                            />
                        </Space>
                    </Row>
                </Form>
            </Modal>
        )
    );
}

export default AddEditModal;
