import { useEffect, useState } from "react";
import { Button, Upload } from "antd";

// assets
import uploadDocumentsIcon from "assets/icons/upload-documents.svg";
import uploadImagesIcon from "assets/icons/upload-images.svg";
import uploadVideoIcon from "assets/icons/upload-video.svg";

const UploadOptions = ({
  show,
  onErrorMSG,
  uploadedImagesList,
  uploadedDocumentsList,
  onUploadingImages,
  onUploadingDocuments,
  onUploadingVideo,
  uploadingMedia,
}) => {
  const [slideUp, setSlideUp] = useState(false);
  const [showHolder, setShowHolder] = useState(false);
  const imageProps = {
    name: "images",
    multiple: true,
    maxCount: 10,
    showUploadList: false,
    fileList: uploadedImagesList,
    accept: ".png,.jpg,.jpeg,.bmp,.svg,.gif",
    beforeUpload() {
      return false;
    },
    onChange(info) {
      if (uploadingMedia) return;
      const maxSize = 16 * 1024 * 1024;
      let areImagesValid = true;
      const validImagesArr = [];
      for (let i = 0; i < info.fileList.length; i++) {
        if (info.fileList[i].size > maxSize) {
          areImagesValid = false;
          onErrorMSG(`Maximum file size is 16 MB`);
          onUploadingImages([]);
          break;
        } else if (!info.fileList[i].type.startsWith("image")) {
          areImagesValid = false;
          onErrorMSG("Please, upload valid images");
          onUploadingImages([]);
          break;
        }
        validImagesArr.push(info.fileList[i]);
      }
      onUploadingImages(validImagesArr);
      if (areImagesValid) {
        onErrorMSG("");
      }
    },
  };
  const videoProps = {
    name: "video",
    showUploadList: false,
    maxCount: 1,
    accept: ".mp4,.avi,.wmv,.webm,.flv,.mkv,.ogg,.vob,.avchd,.mov,.m4a",
    beforeUpload() {
      return false;
    },
    onChange(info) {
      if (uploadingMedia) return;
      const maxSize = 100 * 1024 * 1024;
      let isVideoValid = true;
      if (info.file.size > maxSize) {
        isVideoValid = false;
        onErrorMSG(`Maximum file size is 100 MB`);
      } else if (!info.file.type.startsWith("video")) {
        isVideoValid = false;
        onErrorMSG("Please, upload a valid video");
      }
      if (isVideoValid) {
        onUploadingVideo(info.fileList[0]);
        onErrorMSG("");
      }
    },
  };
  const docProps = {
    name: "documents",
    showUploadList: false,
    maxCount: 10,
    multiple: true,
    fileList: uploadedDocumentsList,
    beforeUpload() {
      return false;
    },
    onChange(info) {
      console.log(info)
      if (uploadingMedia) return;
      const maxSize = 16 * 1024 * 1024;
      let areDocumentsValid = true;
      const validDocsArr = [];
      for (let i = 0; i < info.fileList.length; i++) {
        if (info.fileList[i].size > maxSize) {
          areDocumentsValid = false;
          onErrorMSG(`Maximum file size is 16 MB`);
          onUploadingImages([]);
          break;
        }
        validDocsArr.push(info.fileList[i]);
      }
      onUploadingDocuments(validDocsArr);
      if (areDocumentsValid) {
        onErrorMSG("");
      }
    },
  };
  const options = [
    {
      id: 0,
      props: imageProps,
      title: "Image",
      icon: uploadImagesIcon,
      alt: "upload image",
    },
    {
      id: 1,
      props: videoProps,
      title: "Video",
      icon: uploadVideoIcon,
      alt: "upload video",
    },
    {
      id: 2,
      props: docProps,
      title: "Document",
      icon: uploadDocumentsIcon,
      alt: "upload document",
    },
  ];

  useEffect(() => {
    if (show) {
      setShowHolder(true);
    } else {
      setSlideUp(false);
    }
  }, [show]);

  useEffect(() => {
    let timerId;
    if (showHolder) {
      timerId = setTimeout(() => {
        setSlideUp(true);
      }, 0);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [showHolder]);

  useEffect(() => {
    let timerId;
    if (!slideUp) {
      timerId = setTimeout(() => {
        setShowHolder(false);
      }, 300);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [slideUp]);

  return showHolder ? (
    <div
      data-show={slideUp}
      className="chat__chat-holder__footer__upload-options position-absolute d-flex flex-wrap"
    >
      {options?.map((option) => (
        <Upload
          key={option.id}
          disabled={uploadingMedia}
          className="lh-1"
          {...option.props}
        >
          <Button className="d-flex flex-column align-items-center border-0 outline-none bg-transparent p-0 h-auto">
            <img
              src={option.icon}
              alt={option.alt}
              className="d-block img-fluid"
            />
            <span className="fsize--10 font--semi-bold text--secondary-80">
              {option.title}
            </span>
          </Button>
        </Upload>
      ))}
    </div>
  ) : (
    ""
  );
};

export default UploadOptions;
