const AdminsIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_644_8758)">
                <path d="M14.9016 22.9547C14.8219 22.9079 14.7375 22.861 14.6578 22.8141L14.1656 23.0251C13.2 23.4376 12.0797 23.0626 11.5547 22.1532L10.7109 20.6907C10.1859 19.7813 10.425 18.6235 11.2641 17.9907L11.6906 17.6719C11.6906 17.6251 11.6906 17.5782 11.6906 17.5313C11.6906 17.4844 11.6906 17.4376 11.6906 17.3907L11.2641 17.0719C10.425 16.4391 10.1906 15.2813 10.7109 14.3719L11.5547 12.9094C11.8078 12.4735 12.2062 12.1454 12.6797 11.986C11.5125 11.4516 10.2422 11.1751 8.95781 11.1797C4.0125 11.1751 0 15.1876 0 20.1329V22.7297C0 23.4329 0.567188 24.0001 1.27031 24.0001H15.0938C15.0281 23.836 14.9859 23.6626 14.9625 23.4844L14.9016 22.9547Z"  />
                <path d="M8.95772 9.83438C11.6734 9.83438 13.8749 7.63288 13.8749 4.91719C13.8749 2.2015 11.6734 0 8.95772 0C6.24203 0 4.04053 2.2015 4.04053 4.91719C4.04053 7.63288 6.24203 9.83438 8.95772 9.83438Z"  />
                <path d="M23.6955 19.0406L22.6877 18.2859C22.7674 17.789 22.7674 17.2781 22.6877 16.7812L23.6955 16.0265C24.0049 15.7968 24.0893 15.3703 23.8971 15.0328L23.0533 13.5703C22.8611 13.2375 22.4486 13.0968 22.0924 13.2515L20.9346 13.7437C20.5408 13.425 20.1002 13.1718 19.6314 12.989L19.4814 11.7375C19.4346 11.3531 19.1111 11.0671 18.7221 11.0671H17.0299C16.6455 11.0671 16.3174 11.3578 16.2705 11.7375L16.1205 12.989C15.6471 13.1718 15.2111 13.425 14.8174 13.7437L13.6596 13.2515C13.3033 13.1015 12.8908 13.2375 12.6986 13.5703L11.8549 15.0328C11.6627 15.3656 11.7471 15.7921 12.0564 16.0265L13.0643 16.7812C12.9846 17.2781 12.9846 17.789 13.0643 18.2859L12.0564 19.0406C11.7471 19.275 11.6627 19.7015 11.8549 20.0343L12.6986 21.4968C12.8908 21.8296 13.3033 21.9703 13.6596 21.8203L14.8174 21.3234C15.2111 21.6421 15.6518 21.8953 16.1205 22.0781L16.2705 23.3296C16.3174 23.714 16.6408 24 17.0299 24H18.7221C19.1064 24 19.4346 23.7093 19.4814 23.3296L19.6314 22.0781C20.1002 21.8953 20.5408 21.6421 20.9346 21.3234L22.0924 21.8156C22.4486 21.9656 22.8611 21.8296 23.0533 21.4921L23.8971 20.0296C24.0893 19.7015 24.0049 19.275 23.6955 19.0406ZM17.8736 19.3593C16.8658 19.3593 16.0455 18.539 16.0455 17.5312C16.0455 16.5234 16.8658 15.7031 17.8736 15.7031C18.8814 15.7031 19.7018 16.5234 19.7018 17.5312C19.7018 18.539 18.8814 19.3593 17.8736 19.3593Z"  />
            </g>
            <defs>
                <clipPath id="clip0_644_8758">
                    <rect width="24" height="24" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default AdminsIcon;