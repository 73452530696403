import { useEffect, useState } from "react";
import { Col, Form, Modal, Row, Space, Switch, Input, Checkbox } from "antd";
import { addCourier, editCourier } from "../../apis/Network";
import { toastError, toastSuccess } from "../../helpers/toasters";
import { handleAllowNumbersOnly, validatePhoneNumber } from "../../helpers/helpfulFunctions";

// components
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";

// assets
import editIcon from "../../assets/icons/edit-pen.svg"

const CourierModal = ({
    mode,
    rowData,
    open,
    subHeader,
    onCancel,
    onSubmit,
    primaryBtnName
}) => {
    const [changePassword, setChangePassword] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [nameDir, setNameDir] = useState("ltr");
    const [form] = Form.useForm();
    const initialValues = {
        courierName: "",
        phone: "",
        courierPassword: "",
        courierConfirmPassword: "",
        isActive: true,
        forStore: false
    };

    const handleNameChange = (e) => {
        if (e.target.value && /^[\u0600-\u06FF]$/.test(e.target.value[0])) {
            setNameDir("rtl");
        } else {
            setNameDir("ltr");
        }
    };

    const handlePhoneValidation = (_, value) => {
        if (!value) {
            return Promise.reject(new Error("This field is required. Please enter your phone number."));
        } else if (!validatePhoneNumber(value)) {
            return Promise.reject(new Error("Invalid phone number format. Please enter a valid phone number."));
        } else {
            return Promise.resolve();
        }
    }

    const handlePasswordValidation = (_, value) => {
        if (!value) {
            return Promise.reject(new Error("This field is required. Please enter your password."));
        } else if (value && value.length < 8) {
            return Promise.reject(new Error("Password must be at least 8 characters."));
        }
        if (
            form.getFieldValue("courierConfirmPassword") &&
            !form.isFieldValidating("courierConfirmPassword")
        ) {
            form.validateFields(["courierConfirmPassword"]);
        }
        return Promise.resolve();
    };

    const handleFinish = (values) => {
        if (submitting) return;
        setSubmitting(true);

        const payload = {
            userName: values.courierName,
            password: values.courierPassword,
            phone: values.phone,
            isActive: values.isActive,
            forStore: values.forStore,
        }
        if (mode === "add") {
            addCourier(
                payload,
                (res) => {
                    setSubmitting(false);
                    if (res.success) {
                        toastSuccess(res.message);
                        onSubmit()
                    } else {
                        toastError(res.message);
                    }
                },
                (res) => {
                    setSubmitting(false);
                    toastError(res.message);
                })
        } else {
            payload.id = rowData.id
            editCourier(
                payload,
                (res) => {
                    setSubmitting(false);
                    if (res.success) {
                        toastSuccess(res.message);
                        onSubmit()
                    } else {
                        toastError(res.message);
                    }
                },
                (res) => {
                    setSubmitting(false);
                    toastError(res.message);
                })
        }
    }

    useEffect(() => {
        if (rowData && mode === "edit") {
            form.setFieldsValue({
                courierName: rowData.userName,
                phone: rowData.phone,
                isActive: rowData.isActive,
                forStore: rowData.forStore,
            })
        }
    }, [mode, rowData])

    return (
        <Modal
            title={subHeader}
            open={open}
            className="msg-modal"
            footer={false}
            closable={false}
            centered
            destroyOnClose
            onCancel={onCancel}
        >
            <Form
                form={form}
                className="edit-actor__content couriers__form pt-3"
                onFinish={handleFinish}
                initialValues={initialValues}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <label className="users__parent-info-label mb-2" htmlFor="">
                            Username
                        </label>
                        <Form.Item
                            name="courierName"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required, please enter your username.",
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                dir={nameDir}
                                onChange={handleNameChange}
                                className="py-2"
                                placeholder="User Name"
                                maxLength={100}
                                autoComplete="new-password"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <label className="users__parent-info-label mb-2" htmlFor="">
                            Mobile Number
                        </label>
                        <Form.Item
                            name="phone"
                            rules={[
                                { validator: handlePhoneValidation },
                            ]}
                        >
                            <Input
                                type="text"
                                onChange={handleNameChange}
                                className="py-2"
                                placeholder="Mobile Number"
                                onKeyDown={handleAllowNumbersOnly}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        {mode === "edit" ? (
                            <div className="mb-4">
                                <button
                                    className="couriers__form__edit-password-btn d-flex align-items-center justify-content-between gap-3 p-0 bg-transparent w-100 border-0 outline-0 shadow-none"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#change-password"
                                    aria-expanded="false"
                                    aria-controls="change-password"
                                    onClick={() => setChangePassword(!changePassword)}
                                >
                                    <span className="text--red-5 fsize--3">Edit Password</span>
                                    <span className=" d-inline-flex justify-content-center align-items-center rounded-circle">
                                        <img src={editIcon} alt="" className="d-block img-fluid" />
                                    </span>
                                </button>
                            </div>
                        ) : (
                            ""
                        )}
                        <div>
                            <div
                                className={`collapse ${mode !== "edit" ? "show" : ""}`}
                                id="change-password"
                            >
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Password
                                </label>
                                <Form.Item
                                    name="courierPassword"
                                    rules={
                                        mode === "edit" && !changePassword
                                            ? []
                                            : [{ validator: handlePasswordValidation }]
                                    }
                                >
                                    <Input.Password
                                        maxLength={20}
                                        className="py-2"
                                        autoComplete="new-password"
                                        placeholder={`${mode === "edit" ? "New " : ""}Password`}
                                    />
                                </Form.Item>
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Confim Password
                                </label>
                                <Form.Item
                                    name="courierConfirmPassword"
                                    rules={
                                        mode === "edit" && !changePassword
                                            ? []
                                            : [
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value) {
                                                            return Promise.reject(
                                                                new Error(
                                                                    "This field is required. Please enter your confirmed password."
                                                                )
                                                            );
                                                        } else if (getFieldValue("courierPassword") === value) {
                                                            return Promise.resolve();
                                                        } else if (getFieldValue("courierPassword") !== value) {
                                                            return Promise.reject(
                                                                new Error(
                                                                    "Confirm password does not match with password"
                                                                )
                                                            );
                                                        }
                                                    },
                                                }),
                                            ]
                                    }
                                >
                                    <Input.Password
                                        maxLength={20}
                                        className="py-2"
                                        autoComplete="new-password"
                                        placeholder={`Confirm ${mode === "edit" ? "New " : ""}Password`}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name">
                            <Form.Item name="forStore" valuePropName="checked">
                                <Checkbox>For Store</Checkbox>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name mb-4 row mx-0 align-items-center">
                            <div className="col-3 px-0 text-start">
                                <label className="users__parent-info-label">Active</label>
                            </div>
                            <div className="col-8 px-0 d-flex">
                                <Form.Item
                                    name="isActive"
                                    valuePropName="checked"
                                    className="mb-0"
                                >
                                    <Switch />
                                </Form.Item>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mrg-ys-20 mrg-x-auto mt-2 justify-content-center">
                    <Space size={20}>
                        <SecondaryButton
                            buttonName={"Cancel"}
                            onClick={onCancel}
                        />
                        <PrimaryButton
                            buttonName={primaryBtnName}
                            loading={submitting}
                            type="submit"
                        />
                    </Space>
                </Row>
            </Form>
        </Modal>
    );
}

export default CourierModal;