const StoreCategoriesIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_11_5)">
                <path d="M3.60009 0C1.6267 0 0.00586047 1.63256 0.00586047 3.60595V7.20023C0.00586047 9.17372 1.62666 10.8038 3.60014 10.8038H7.19442C9.16786 10.8038 10.8004 9.17367 10.8004 7.20023V3.60595C10.8003 1.63256 9.16781 0 7.19438 0H3.60009ZM16.7939 0C14.8205 0 13.1996 1.63256 13.1996 3.60595V7.20023C13.1996 9.17372 14.8204 10.8038 16.7939 10.8038H20.3999C22.3733 10.8038 23.9941 9.17367 23.9941 7.20023V3.60595C23.9941 1.63252 22.3733 0 20.3999 0H16.7939ZM3.60009 13.2055C1.62666 13.2055 0.0058136 14.8263 0.0058136 16.7998V20.3964C0.0058136 22.3698 1.62661 24 3.60009 24H7.19438C9.16781 24 10.8003 22.3698 10.8003 20.3964V16.7998C10.8003 14.8263 9.16781 13.2055 7.19438 13.2055H3.60009ZM16.7939 13.2055C14.8205 13.2055 13.1996 14.8263 13.1996 16.7998V20.3964C13.1996 22.3698 14.8204 24 16.7939 24H20.3999C22.3733 24 23.9941 22.3698 23.9941 20.3964V16.7998C23.9941 14.8263 22.3733 13.2055 20.3999 13.2055H16.7939Z" />
            </g>
            <defs>
                <clipPath id="clip0_11_5">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
}

export default StoreCategoriesIcon;