import { Form } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import ImgCrop from "antd-img-crop";
import { baseUrl } from "../../../apis/services";

const OtherImages = ({
  mode,
  previewFiles,
  formRef,
  images,
  onRemoveImagePath,
  onPreviewFilesChange,
  uploadedImages,
  onUploadedImagesChange,
}) => {
  const maxSize = 5000000
  const props = {
    name: "images",
    showUploadList: false,
    // maxCount: 2,
    // fileList: uploadedImages,
    accept: ".png,.jpg,.jpeg,.bmp,.svg",
    onChange(info) {
      if (!info.file.type.startsWith("image")) {
        formRef.setFields([
          {
            name: "images",
            errors: ["Please, upload a valid image"],
            values: ""
          }
        ])
        return;
      } else if (info.file.size > maxSize) {
        formRef.setFields([
          {
            name: "images",
            errors: ["The image file size should not exceed 5MB"],
            values: ""
          }
        ])
        return;
      }
      return handlePreview(info.file, info.fileList);
    },
  };

  const handleBeforeCrop = (file) => {
    if (!file.type.startsWith("image") || file.size > maxSize) {
      formRef.resetFields(["images"])
      return false;
    } else {
      return true
    }
  }

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file, files) => {
    if (uploadedImages.length === 2 || file.status === "uploading") return
    if (!file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    onPreviewFilesChange((arr) => [...arr, file]);
    onUploadedImagesChange((arr) => [...arr, file.originFileObj]);
    formRef.setFields([{ name: "images", errors: [], value: [...uploadedImages, file.originFileObj] }])
  };

  const handleRemoveFile = (fileId) => {
    const filteredPreviewFiles = previewFiles.filter(
      (file) => file.uid !== fileId
    );
    onPreviewFilesChange(filteredPreviewFiles);
    const filteredUploadedImages = uploadedImages.filter(
      (file) => file.uid !== fileId
    );
    onUploadedImagesChange(filteredUploadedImages);
    if (filteredUploadedImages.length === 0) {
      formRef.resetFields(["images"])
    } else {
      formRef.setFieldValue("images", filteredUploadedImages);
    }
  };

  return (
    <div className="d-flex align-items-start flex-row-reverse gap-3">
      <Form.Item
        name={"images"}
        className="position-relative store-items__item-form__images__input"
      >
        <ImgCrop cropperProps={{ restrictPosition: false }} beforeCrop={handleBeforeCrop} fillColor="transparent" quality={1} rotationSlider aspectSlider minZoom={0.5}>
          <Dragger
            {...props}
            className="main-input--upload"
            disabled={
              previewFiles?.length === 2 ||
              images?.length === 2 ||
              (mode === "edit" && (previewFiles?.length + images?.length === 2))
            }
          >
            <span className="fsize--6 text--blue-3 mb--3">Upload More Images</span>
          </Dragger>
        </ImgCrop>
      </Form.Item>
      <div className="d-flex gap-2">
      {mode === "edit" && images && images.length > 0 ? (
          images.map((path, indx) => (
            <div key={path} className="position-relative">
              <img
                src={baseUrl + path}
                alt=""
                className="store-items__item-form__images__preview-img d-block object-fit-cover"
              />
              <button
                type="button"
                onClick={() => onRemoveImagePath(path)}
                className="main-input--upload__edit-btn d-block mx-auto border-0 outline-0 shadow-none p-0 fsize--5 fw-bold text--blue-3 border-bottom"
              >
                <CloseOutlined style={{ fontSize: "13px" }} />
              </button>
            </div>
          ))
        ) : (
          ""
        )}
        {previewFiles.length > 0 ? (
          previewFiles.map((file) => (
            <div key={file.uid} className="position-relative">
              <img
                src={file.preview}
                alt=""
                className="store-items__item-form__images__preview-img d-block object-fit-cover"
              />
              <button
                type="button"
                onClick={() => handleRemoveFile(file.uid)}
                className="main-input--upload__edit-btn d-block mx-auto border-0 outline-0 shadow-none p-0 fsize--5 fw-bold text--blue-3 border-bottom"
              >
                <CloseOutlined style={{ fontSize: "13px" }} />
              </button>
            </div>
          ))
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default OtherImages;
