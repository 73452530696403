import { useEffect, useState } from "react";
import { Col, Form, Modal, Row, Space, InputNumber } from "antd";
import { toastError, toastSuccess } from "../../helpers/toasters";
import { updateRedeemOrderPriceAndAddress } from "../../apis/Network";
import { handleAllowNumbersOnly } from "../../helpers/helpfulFunctions";

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";

const ChangeActualTotalPriceModal = ({
    open,
    rowData = {},
    SubHeader,
    onSubmit,
    onCancel,
}) => {
    const [submittable, setSubmittable] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm();
    const values = Form.useWatch([], form);
    const initialValues = {
        totalPrice: "",
    };

    const handleOnFinish = (values) => {
        if (submitting) return;
        setSubmitting(true);

        const payload = {
            orderId: rowData.id,
            orderTotalPaidPrice: values.totalPrice,
            addressSaved: rowData.addressSaved
        };
        updateRedeemOrderPriceAndAddress(
            payload,
            (res) => {
                setSubmitting(false);
                if (res.success) {
                    toastSuccess(res.message);
                    onSubmit();
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setSubmitting(false);
                toastError(res?.data?.error);
            }
        );
    };

    const handlePriceValidation = (_, value) => {
        if (!value && value !== 0) {
            return Promise.reject(new Error("Please, Enter the total price"))
        } else if (value < 0) {
            return Promise.reject(new Error(`Please, Enter a valid price`));
        } else {
            return Promise.resolve()
        }
    }

    useEffect(() => {
        if (rowData && rowData.orderTotalPaidPrice) {
            form.setFieldsValue({
                totalPrice: rowData.orderTotalPaidPrice,
            });
        }
    }, [rowData, form]);

    useEffect(() => {
        form
            .validateFields({
                validateOnly: true,
            })
            .then(
                (info) => {
                    setSubmittable(true);
                },
                (info) => {
                    if (info.errorFields.length === 0) {
                        setSubmittable(true);
                    } else {
                        setSubmittable(false);
                    }
                }
            );
    }, [values, form]);

    return (
        <Modal
            title={SubHeader}
            open={open}
            className="msg-modal"
            footer={false}
            closable={false}
            centered
            destroyOnClose
        >
            <Form
                form={form}
                className="edit-actor__content store-items__item-form"
                onFinish={handleOnFinish}
                initialValues={initialValues}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <div className="lang-name wd-100 flex ">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Total Price
                                </label>
                            </div>
                            <Form.Item
                                className="new-category__form-item"
                                name="totalPrice"
                                rules={[
                                    { validator: handlePriceValidation }
                                ]}
                            >
                                <InputNumber
                                    className="w-100 d-block search__searchField py-1"
                                    placeholder="Enter total price"
                                    onKeyDown={(e) => handleAllowNumbersOnly(e, true)}
                                    controls={false}
                                    keyboard={false}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row className="mrg-ys-20 mrg-x-auto justify-content-center">
                    <Space size={20}>
                        <SecondaryButton buttonName={"Cancel"} onClick={onCancel} />
                        <PrimaryButton
                            buttonName={rowData?.orderTotalPaidPrice ? "Update" : "Add"}
                            loading={submitting}
                            type="submit"
                            disableMe={!submittable}
                        />
                    </Space>
                </Row>
            </Form>
        </Modal>
    );
}

export default ChangeActualTotalPriceModal;