import { useState, useEffect, useRef } from "react";
import { Button, Space, Input, DatePicker } from "antd";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { toastError } from "../../helpers/toasters";
import { getPartnership } from "../../apis/Network";
import { handleAllowNumbersOnly } from "../../helpers/helpfulFunctions";

// components
import GridTable from "../SubComponents/GridTable/GridTable";
import SubHeader from "../SubComponents/SubHeader";
import MainHeader from "../SubComponents/MainHeader";
import AddEditPartnership from "./AddEditPartnership";
import AddressDetails from "../SocialOrders/AddressDetails";
import AssignToCourierModal from "../SocialOrders/AssignToCourierModal";

const Partnership = () => {
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState("add");
  const [rowData, setRowData] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [selectedRange, setSelectedRange] = useState([]);
  const [isPartnershipModalOpen, setIsPartnershipModalOpen] = useState(false);
  const [isAddressDetailsModalOpen, setIsAddressDetailsModalOpen] = useState(false);
  const [isSendCourierModalOpen, setIsSendCourierModalOpen] = useState(false);
  const [renderMe, setRenderMe] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const { RangePicker } = DatePicker;

  const handleEditBtn = (row) => {
    setMode("edit");
    setRowData(row);
    setIsPartnershipModalOpen(true);
  };

  const handleAddPartnership = () => {
    setIsPartnershipModalOpen(true);
    setMode("add");
  };

  const handlePartnershipModalCancel = () => {
    setIsPartnershipModalOpen(false);
    setMode("add");
  };

  const handleDateChange = (dates) => {
    setSelectedRange(dates);
    if (dates) {
      const startDate = dates[0].format("DD MMM, YYYY");
      const endDate = dates[1].format("DD MMM, YYYY");
      const dataWithingRange = dataSource.filter((ele) => {
        const comparisonDate = moment(ele.creationDate, "DD MMM, YYYY");
        return (
          comparisonDate.isSameOrAfter(startDate) &&
          comparisonDate.isSameOrBefore(endDate)
        );
      });
      setFilteredDataSource(dataWithingRange);
    } else {
      setFilteredDataSource(dataSource);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          onKeyDown={(e) => {
            const acceptsNumbersOnly = ["mobile", "noOfRequests"]
            if (acceptsNumbersOnly.includes(dataIndex)) {
              handleAllowNumbersOnly(e, true)
            }
          }}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          ></Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text || text === 0 ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getColumnDateProps = () => ({
    filterDropdown: () => (
      <div
        style={{
          padding: 8,
        }}
        className="range-picker-holder"
        onKeyDown={(e) => e.stopPropagation()}
      >
        <RangePicker
          value={selectedRange}
          allowClear
          popupClassName="main-date-picker"
          inputReadOnly={false}
          onChange={handleDateChange}
          onKeyDown={(e) => e.preventDefault()}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterOutlined
        style={selectedRange?.length > 0 ? { color: "blue" } : {}}
      />
    ),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });

  const columns = [
    {
      key: "name",
      title: "NAME",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
      render: (_, row) => (
        <span style={{ display: "block", maxWidth: "25ch" }}>
          {row.name}
        </span>
      ),
    },
    {
      key: "mobile",
      title: "MOBILE NUMBER",
      dataIndex: "mobile",
      ...getColumnSearchProps("mobile"),
    },
    {
      key: "creationDate",
      title: "CREATION DATE",
      dataIndex: "creationDate",
      ...getColumnDateProps("creationDate"),
    },
    {
      key: "noOfRequests",
      title: "NUMBER OF REQUESTS",
      dataIndex: "noOfRequests",
      ...getColumnSearchProps("noOfRequests"),
    },
    {
      key: "isActive",
      title: "STATUS",
      dataIndex: "isActive",
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        }
      ],
      onFilter: (value, record) => value === record.isActive,
      render: (_, row) => (
        <span>{row.isActive ? "Active" : "Inactive"}</span>
      ),
    },
    {
      key: "id",
      title: "ACTIONS",
      dataIndex: "action",
      render: (_, row) => {
        return (
          <>
            <div className="d-flex justify-content-center gap-2 mb-2">
              <Button
                className="border-0"
                onClick={() => {
                  handleEditBtn(row)
                }}
                style={{ backgroundColor: "#589559", color: "white" }}
              >
                Edit
              </Button>
              <Button
                className="btn--secondary w-100 border-0"
                disabled={!row.isActive}
                onClick={() => {
                  if (!row.isActive) return
                  setRowData(row);
                  setIsSendCourierModalOpen(true);
                }}
              >
                Send Courier
              </Button>
            </div>
            <div className="d-flex justify-content-center">
              <Button
                onClick={() => {
                  setRowData(row);
                  setIsAddressDetailsModalOpen(true);
                }}
                style={{
                  backgroundColor: "white",
                  color: "#176b87",
                  border: "solid 1px #176b87 ",
                }}
                className="w-100"
              >
                Address Details
              </Button>
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    getPartnership(
      (res) => {
        setLoading(false);
        if (res.success) {
          setDataSource(res.data);
          setFilteredDataSource(res.data);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setLoading(false);
        if (res.data?.error) {
          toastError(res.message);
        }
      }
    );
  }, [renderMe]);

  return (
    <section className="users store-items">
      <div className="users__all-contents">
        <div className="users__content">
          <div className="users__usersData">
            <div className="pb-3 mb-3">
              <MainHeader
                title="Partnership"
                buttonName={"Add Partnership"}
                onButtonClick={handleAddPartnership}
              />
            </div>
            <div className="users__table store-items__data-table grid-table mrg-ye-50">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={filteredDataSource}
                rowId={"id"}
              />
            </div>
          </div>
        </div>
        {isPartnershipModalOpen && (
          <AddEditPartnership
            open={isPartnershipModalOpen}
            mode={mode}
            rowData={rowData}
            SubHeader={
              <SubHeader
                bgColor="#F58234"
                title={`${mode === "add" ? "Add New" : "Edit"} Partnership`}
                closeButton
                onCancel={handlePartnershipModalCancel}
              />
            }
            onSubmit={() => {
              setRenderMe(!renderMe);
              handlePartnershipModalCancel();
            }}
            onCancel={handlePartnershipModalCancel}
            primaryBtnName={`${mode === "add" ? "Add" : "Update"}`}
            secondaryBtnName={"Cancel"}
          />
        )}
        {isAddressDetailsModalOpen && (
          <AddressDetails
            open={isAddressDetailsModalOpen}
            rowData={rowData}
            EditAddress={false}
            partnershipAddress={true}
            subHeader={
              <SubHeader
                bgColor="#589559"
                title={"Address Details"}
                closeButton
                onCancel={() => {
                  setIsAddressDetailsModalOpen(false);
                  setRowData(null);
                }}
              />
            }
          />
        )}
        {isSendCourierModalOpen && (
          <AssignToCourierModal
            open={isSendCourierModalOpen}
            sendToPartnership={true}
            rowData={rowData}
            onSubmit={() => {
              setIsSendCourierModalOpen(false);
              setRenderMe(!renderMe);
              setRowData(null);
            }}
            subHeader={
              <SubHeader
                bgColor="#589559"
                title={"Send Courier"}
                closeButton
                onCancel={() => {
                  setRowData(null);
                  setIsSendCourierModalOpen(false);
                }}
              />
            }
            onCancel={() => {
              setRowData(null);
              setIsSendCourierModalOpen(false);
            }}
          />
        )}
      </div>
    </section>
  );
};

export default Partnership;
