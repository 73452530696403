import { useState } from "react";
import { Row, Space, Modal, Form } from "antd";
import { toastError, toastSuccess } from "../../helpers/toasters";
import { RateOrder } from "../../apis/Network";

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";

const RatingModal = ({
  open,
  onCancel,
  subHeader,
  rowData,
  onSubmit,
  selectedRatingValue,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = () => {
    if (submitting) return;
    setSubmitting(true);

    const payload = {
      rating: selectedRatingValue,
      requestId: rowData.requestId,
    };

    RateOrder(
      payload,
      (res) => {
        setSubmitting(false);
        if (res.success) {
          toastSuccess(res.message);
          onSubmit();
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setSubmitting(false);
        toastError(res.message);
      }
    );
  };

  return (
    <Modal
      title={subHeader}
      open={open}
      className="msg-modal"
      footer={false}
      closable={false}
      centered
      destroyOnClose
      onCancel={onCancel}
    >
      <Form className="edit-actor__content pt-3" onFinish={handleSubmit}>
        <p className="text-center">
          Are you sure you want to rate this order by{" "}
          <strong style={{ fontWeight: "bold" }}>{selectedRatingValue}</strong>{" "}
          ?
        </p>
        <Row className="mrg-ys-20 mrg-x-auto mt-2 justify-content-center">
          <Space size={20}>
            <SecondaryButton buttonName={"Cancel"} onClick={onCancel} />
            <PrimaryButton
              buttonName={"Confirm"}
              loading={submitting}
              type="submit"
            />
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};

export default RatingModal;
