const MarketplaceBannerIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 295 223" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4731_28988)">
                <path d="M215.035 0H79.4144C77.8932 0 76.4344 0.610615 75.3588 1.69752C74.2832 2.78442 73.6789 4.25857 73.6789 5.79568V217.293C73.7248 218.799 74.3493 220.228 75.4199 221.277C76.4906 222.325 77.9233 222.912 79.4144 222.911H215.035C215.777 222.911 216.511 222.763 217.196 222.476C217.881 222.19 218.504 221.769 219.028 221.239C219.553 220.71 219.969 220.08 220.253 219.388C220.536 218.696 220.682 217.954 220.682 217.204V5.79568C220.683 4.2739 220.09 2.81309 219.034 1.72872C217.977 0.644347 216.541 0.0234121 215.035 0ZM202.947 205.078H91.5029V17.8329H202.947V205.078ZM52.9431 29.7809H5.73611C4.97544 29.7808 4.22235 29.9336 3.52068 30.2304C2.81901 30.5272 2.18278 30.9621 1.64903 31.5098C1.11528 32.0575 0.694662 32.7069 0.411674 33.4204C0.128686 34.1339 -0.0110238 34.8972 0.000678841 35.6657V187.245C0.000678841 188.782 0.604946 190.256 1.68055 191.343C2.75615 192.43 4.21498 193.041 5.73611 193.041H52.9431C53.7183 193.077 54.4927 192.954 55.2194 192.679C55.9461 192.404 56.61 191.983 57.1709 191.441C57.7319 190.899 58.1782 190.248 58.4828 189.527C58.7874 188.805 58.9441 188.029 58.9433 187.245V35.6657C58.9562 34.8741 58.8084 34.0882 58.5091 33.3566C58.2099 32.6249 57.7654 31.9629 57.2032 31.4115C56.641 30.8601 55.9729 30.4309 55.2402 30.1505C54.5076 29.87 53.7258 29.7442 52.9431 29.7809ZM288.625 29.7809H241.242C239.736 29.7807 238.29 30.3791 237.217 31.4468C236.144 32.5145 235.529 33.9658 235.506 35.4874V187.245C235.506 188.782 236.111 190.256 237.186 191.343C238.262 192.43 239.721 193.041 241.242 193.041H288.625C290.131 193.041 291.577 192.443 292.65 191.375C293.723 190.307 294.338 188.856 294.361 187.334V35.6657C294.372 34.8972 294.233 34.1339 293.95 33.4204C293.667 32.7069 293.246 32.0575 292.712 31.5098C292.179 30.9621 291.542 30.5272 290.841 30.2304C290.139 29.9336 289.386 29.7808 288.625 29.7809Z" />
            </g>
            <defs>
                <clipPath id="clip0_4731_28988">
                    <rect width="294.36" height="223" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default MarketplaceBannerIcon;