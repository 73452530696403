import { useEffect, useState } from "react";
import { Col, Form, InputNumber, Modal, Row, Select, Space } from "antd";
import { changeRedeemOrderStatus, getRedeemOrderStatus } from "../../apis/Network";
import { toastError, toastSuccess } from "../../helpers/toasters";
import { handleAllowNumbersOnly } from "../../helpers/helpfulFunctions";

// components
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";

const ChangeStatusModal = ({
    rowData,
    open,
    subHeader,
    onCancel,
    onSubmit,
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [allStatus, setAllStatus] = useState(null);
    const [showHoursField, setShowHourField] = useState(false)
    const [form] = Form.useForm();
    const initialValues = {
        statusId: undefined,
        hours: "",
    }

    const validateHours = (_, value) => {
        if (!value && value !== 0) {
            return Promise.reject("Please, Enter number of hours")
        } else if (value !== parseInt(value)) {
            return Promise.reject("Please, Enter a valid number")
        } else if (value < 1) {
            return Promise.reject("Minimum value is 1 hour")
        } else if (value > 240) {
            return Promise.reject("Maximum value is 240 hours")
        } else {
            return Promise.resolve()
        }
    }

    const handleFinish = (values) => {
        if (submitting) return;
        setSubmitting(true);

        const payload = {
            orderId: rowData.id,
            statusId: values.statusId
        }
        if (payload.statusId === 3) {
            payload.numberOfHoursForDelivery = values.hours
        }

        changeRedeemOrderStatus(
            payload,
            (res) => {
                setSubmitting(false);
                if (res.success) {
                    toastSuccess(res.message);
                    onSubmit();
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setSubmitting(false);
                toastError(res.message);
            }
        );
    };

    useEffect(() => {
        getRedeemOrderStatus(
            (res) => {
                if (res.success) {
                    const list = res.data.map((ele) => ({ value: ele.id, label: ele.name }))
                    setAllStatus(list)
                } else {
                    toastError(res.message)
                }
            },
            (res) => {
                toastError(res?.data?.error)
            }
        )
    }, [])

    useEffect(() => {
        if (allStatus && rowData) {
            form.setFieldsValue({
                statusId: rowData.orderStatusId,
            })
            if (rowData.orderStatusId === 3) {
                setShowHourField(true)
                form.setFieldValue("hours", rowData.hours)
            }
        }
    }, [allStatus, rowData])

    return (
        <Modal
            title={subHeader}
            open={open}
            className="msg-modal"
            footer={false}
            closable={false}
            centered
            destroyOnClose
            onCancel={onCancel}
        >
            <Form
                form={form}
                onFinish={handleFinish}
                initialValues={initialValues}
                className="edit-actor__content pt-3"
            >
                <Row>
                    <Col span={24}>
                        <div className="lang-name wd-100 flex">
                            <label className="text--secondary-1 mb-2 fw-semibold" htmlFor="">
                                Order Status
                            </label>
                        </div>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Please, select status",
                                },
                            ]}
                            name="statusId"
                            className="new-category__form-item"
                        >
                            <Select
                                name="statusId"
                                className="new-category__form-item"
                                placeholder="Select status"
                                loading={allStatus ? false : true}
                                options={allStatus ? allStatus : []}
                                onChange={(value) => {
                                    if (value === 3) {
                                        setShowHourField(true)
                                        if (rowData?.hours) {
                                            form.setFieldValue("hours", rowData.hours)
                                        }
                                    } else {
                                        setShowHourField(false)
                                        form.resetFields(["hours"])
                                    }
                                }}
                                getPopupContainer={(trigger) => trigger.parentElement}
                            />
                        </Form.Item>
                    </Col>
                    {showHoursField ? (
                        <Col span={24}>
                            <div className="lang-name wd-100 flex">
                                <label className="text--secondary-1 mb-2 fw-semibold" htmlFor="">
                                    Delivery Period
                                </label>
                            </div>
                            <Form.Item
                                className="new-category__form-item w-100"
                                name="hours"
                                rules={[
                                    { validator: validateHours }
                                ]}
                            >
                                <InputNumber
                                    className="w-100 search__searchField"
                                    placeholder="Enter delivery period"
                                    onKeyDown={(e) => handleAllowNumbersOnly(e, false)}
                                    controls={false}
                                    keyboard={false}
                                    suffix={"Hour"}
                                />
                            </Form.Item>
                        </Col>
                    ) : (
                        ""
                    )}
                </Row>
                <Row className="mrg-ys-20 mrg-x-auto mt-2 justify-content-center">
                    <Space size={20}>
                        <SecondaryButton buttonName={"Cancel"} onClick={onCancel} />
                        <PrimaryButton
                            buttonName={"Change"}
                            loading={submitting}
                            type="submit"
                        />
                    </Space>
                </Row>
            </Form>
        </Modal>
    );
}

export default ChangeStatusModal;