import notFoundIMage from "../../assets/images/notFound.svg";

function Notfound() {
  return (
    <section className="users">
      <div className="users__all-contents ">
        <div
          className="users__usersData"
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <img src={notFoundIMage} alt="Not Found" />
        </div>
      </div>
    </section>
  );
}

export default Notfound;
