import { useEffect, useRef, useState } from "react";
import { Button, Input, Space, DatePicker, Table } from "antd";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import Highlighter from "react-highlight-words";
import { getCansSalesRequests, getCollectionTypes } from "../../apis/Network";
import { toastError } from "../../helpers/toasters";
import { exportDataAsExcel, handleAllowNumbersOnly } from "../../helpers/helpfulFunctions";

// components
import GridTable from "../SubComponents/GridTable/GridTable";
import AcceptRejectModal from "./AcceptRejectModal";
import SubHeader from "../SubComponents/SubHeader";
import MainHeader from "../SubComponents/MainHeader";
import ViewRequestDetails from "./ViewRequestDetails";
import AddRequestModal from "./AddRequestModal";
import RefundPointsModal from "./RefundPointsModal";

const CansSalesRequests = () => {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [mode, setMode] = useState("");
  const [rowData, setRowData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isAddRequestModalOpen, setIsAddRequestModalOpen] = useState(false);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [renderMe, setRenderMe] = useState(false);
  const [collectionTypes, setCollectionTypes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchValues, setSearchValues] = useState({
    fullName: "",
    mobile: "",
    totalQuantity: "",
    totalPounds: "",
    totalPoints: "",
    totalMarketplacePounds: "",
    pointStatus: null,
    from: "",
    to: "",
    address: "",
    courierName: "",
    courierMobile: "",
    collectionType: null,
    cashToUser: "",
    pointPercentage: ""
  })
  const [selectedRange, setSelectedRange] = useState([]);
  const [columnsTotal, setColumnsTotal] = useState({ totalQuantity: "", totalPounds: "", totalPoints: "", totalMarketplacePounds: "" });
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const { roles } = useSelector((state) => state.auth.authorization);
  const { RangePicker } = DatePicker;
  const searchInput = useRef(null);

  const handlePageSizeChange = (current, newSize) => {
    setLoading(true)
    setPageSize(newSize)
  }

  const handleDateChange = (dates, dateStr) => {
    setSelectedRange(dates);
    if (dates) {
      const startDate = dates[0].format("YYYY-MM-DD");
      const endDate = dates[1].format("YYYY-MM-DD");
      updateSearchValues("from", startDate, { property: "to", value: endDate })
    } else {
      updateSearchValues("from", "", { property: "to", value: "" })
    }
  };
  const handleCancelModal = () => {
    setIsModalOpen(false);
    setIsViewModalOpen(false);
    setIsAddRequestModalOpen(false);
    setIsRefundModalOpen(false);
    setMode("");
    setRowData(null);
  };

  const handleSubmitModal = () => {
    handleCancelModal();
    setRenderMe(!renderMe);
  };
  const handleOpenAddRequestModal = () => {
    setIsAddRequestModalOpen(true);
  };
  const handleSubmit = () => {
    handleCancelModal();
    setRenderMe(!renderMe);
  };
  const updateSearchValues = (property, newValue, otherProperty) => {
    const copyOfSearchObj = structuredClone(searchValues)
    copyOfSearchObj[property] = newValue;
    if (otherProperty) {
      copyOfSearchObj[otherProperty.property] = otherProperty.value
    }
    setSearchValues(copyOfSearchObj)
  }
  const handleTableChange = (_, filters) => {
    if (filters.statusId) {
      if (searchValues.pointStatus) {
        const filterArrToStr = [...filters.statusId].sort((x, y) => x - y).join("")
        const crrentArrToStr = [...searchValues.pointStatus].sort((x, y) => x - y).join("")
        if (filterArrToStr !== crrentArrToStr) {
          updateSearchValues("pointStatus", filters.statusId)
        }
      } else {
        updateSearchValues("pointStatus", filters.statusId)
      }
    } else if (searchValues.pointStatus) {
      updateSearchValues("pointStatus", null)
    }
    if (filters.collectionType) {
      if (searchValues.collectionType) {
        const filterArrToStr = [...filters.collectionType].sort((x, y) => x - y).join("")
        const crrentArrToStr = [...searchValues.collectionType].sort((x, y) => x - y).join("")
        if (filterArrToStr !== crrentArrToStr) {
          updateSearchValues("collectionType", filters.collectionType)
        }
      } else {
        updateSearchValues("collectionType", filters.collectionType)
      }
    } else if (searchValues.collectionType) {
      updateSearchValues("collectionType", null)
    }
  }
  const handlePageChange = (page) => {
    setLoading(true)
    setCurrentPage(page)
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    const value = selectedKeys[0]?.trim() || ""
    confirm();
    switch (dataIndex) {
      case "userName":
        updateSearchValues("fullName", value)
        break;
      case "userPhone":
        updateSearchValues("mobile", value)
        break;
      case "courierName":
        updateSearchValues("courierName", value)
        break;
      case "courierMobile":
        updateSearchValues("courierMobile", value)
        break;
      case "totalQuantity":
        updateSearchValues("totalQuantity", value)
        break;
      case "totalPoints":
        updateSearchValues("totalPoints", value)
        break;
      case "totalPounds":
        updateSearchValues("totalPounds", value)
        break;
      case "pointsPercentage":
        updateSearchValues("pointPercentage", value)
        break;
      case "totalMarketplacePoundss":
        updateSearchValues("totalMarketplacePounds", value)
        break;
      case "cashToUser":
        updateSearchValues("cashToUser", value)
        break;
      default:
        break;
    }
  };
  const handleReset = (clearFilters, dataIndex, confirm) => {
    clearFilters();
    setLoading(true);
    switch (dataIndex) {
      case "userName":
        updateSearchValues("fullName", "")
        break;
      case "userPhone":
        updateSearchValues("mobile", "")
        break;
      case "courierName":
        updateSearchValues("courierName", "")
        break;
      case "courierMobile":
        updateSearchValues("courierMobile", "")
        break;
      case "totalQuantity":
        updateSearchValues("totalQuantity", "")
        break;
      case "totalPounds":
        updateSearchValues("totalPounds", "")
        break;
      case "totalPoints":
        updateSearchValues("totalPoints", "")
        break;
      case "pointsPercentage":
        updateSearchValues("pointPercentage", "")
        break;
      case "totalMarketplacePoundss":
        updateSearchValues("totalMarketplacePounds", "")
        break;
      case "cashToUser":
        updateSearchValues("cashToUser", "")
        break;
      default:
        break;
    }
    confirm()
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onKeyDown={(e) => {
            const acceptsNumbersOnly = ["pointsPercentage", "totalPounds", "totalPoints", "totalQuantity", "cashToUser"]
            if (acceptsNumbersOnly.includes(dataIndex)) {
              handleAllowNumbersOnly(e, true)
            }
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, dataIndex, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          ></Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => true,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: () => (
      <div
        style={{
          padding: 8,
        }}
        className="range-picker-holder"
        onKeyDown={(e) => e.stopPropagation()}
      >
        <RangePicker
          value={selectedRange}
          allowClear
          popupClassName="main-date-picker"
          inputReadOnly={false}
          onChange={(dates, datesStr) => {
            setCurrentPage(1)
            handleDateChange(dates, datesStr)
          }}
          onKeyDown={(e) => e.preventDefault()}
        />
      </div>
    ),
    filterIcon: (filtered) => <FilterOutlined style={selectedRange?.length > 0 ? { color: "blue" } : {}} />,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });
  const columns = [
    {
      key: "userName",
      title: "USER NAME",
      dataIndex: "userName",
      fixed: "left",
      ...getColumnSearchProps("userName"),
    },
    {
      key: "userPhone",
      title: "USER MOBILE",
      dataIndex: "userPhone",
      // fixed: "left",
      ...getColumnSearchProps("userPhone"),
    },
    {
      key: "courierName",
      title: "COURIER NAME",
      dataIndex: "courierName",
      ...getColumnSearchProps("courierName"),
    },
    {
      key: "courierMobile",
      title: "COURIER MOBILE",
      dataIndex: "courierMobile",
      ...getColumnSearchProps("courierMobile"),
    },
    {
      key: "collectionType",
      title: "COLLECTION TYPE",
      dataIndex: "collectionType",
      filters: collectionTypes,
      onFilter: (value, record) => {
        // record.collectionType === value
        return true
      },
    },
    {
      key: "totalQuantity",
      title: "QUANTITY IN GRAMS",
      dataIndex: "totalQuantity",
      ...getColumnSearchProps("totalQuantity"),
    },
    {
      key: "totalPounds",
      title: "TOTAL POUNDS",
      dataIndex: "totalPounds",
      ...getColumnSearchProps("totalPounds"),
    },
    {
      key: "totalPoints",
      title: "POINTS",
      dataIndex: "totalPoints",
      ...getColumnSearchProps("totalPoints"),
    },
    {
      key: "pointsPercentage",
      title: "POINTS PERCENTAGE",
      dataIndex: "pointsPercentage",
      ...getColumnSearchProps("pointsPercentage"),
      render: (_, row) => {
        return <span>{`${row?.pointsPercentage} %`}</span>;
      },
    },
    {
      key: "totalMarketplacePoundss",
      title: "MARKETPLACE POUNDS",
      dataIndex: "totalMarketplacePoundss",
      ...getColumnSearchProps("totalMarketplacePoundss"),
    },
    {
      key: "cashToUser",
      title: "CASH VALUE",
      dataIndex: "cashToUser",
      ...getColumnSearchProps("cashToUser"),
    },
    {
      key: "statusId",
      title: "POINTS STATUS",
      dataIndex: "statusId",
      filters: [
        { text: "Collected", value: 2 },
        { text: "Rejected", value: 3 },
        { text: "Pending", value: 1 },
      ],
      onFilter: (value, record) => {
        return true
        // return record.statusId === value
      },
      filterResetToDefaultFilteredValue: true,
      render: (_, row) => {
        return <span>{`${row?.statusId === 1 ? "Pending" : row?.statusId === 2 ? "Collected" : "Rejected"}`}</span>;
      },
    },
    {
      key: "date",
      title: "REQUEST DATE",
      dataIndex: "date",
      ...getColumnDateProps("date"),
    },
    {
      key: "salesRequestId",
      title: "ACTIONS",
      render: (_, row) => {
        return (
          <div>
            <Space size={5} className="mb-2">
              <Button
                className="btn--secondary"
                onClick={() => {
                  setRowData(row);
                  setIsViewModalOpen(true);
                }}
              >
                View Details
              </Button>
              {!(roles.includes(12) || roles.includes(15)) ? (
                <>
                  <Button
                    className="border-0"
                    disabled={row.statusId !== 1}
                    onClick={() => {
                      if (row.statusId !== 1) return;
                      setRowData(row);
                      setMode("accept");
                      setIsModalOpen(true);
                    }}
                    style={
                      row.statusId === 2
                        ? {}
                        : { backgroundColor: "#589559", color: "white" }
                    }
                  >
                    Accept
                  </Button>
                  <Button
                    className="border-0"
                    disabled={row.statusId !== 1}
                    onClick={() => {
                      if (row.statusId !== 1) return;
                      setRowData(row);
                      setMode("reject");
                      setIsModalOpen(true);
                    }}
                    style={
                      row.statusId === 3
                        ? {}
                        : { backgroundColor: "#E72121", color: "white" }
                    }
                  >
                    Reject
                  </Button>
                </>
              ) : (
                ""
              )}
              {/* <Button
                onClick={() => {
                  setRowData(row);
                  setIsRefundModalOpen(true);
                }}
                style={
                  row.statusId === 2
                    ? {
                        backgroundColor: "rgb(233 98 44)",
                        color: "white",
                      }
                    : {}
                }
                disabled={row.statusId !== 2}
              >
                Refund{" "}
              </Button> */}
            </Space>
          </div>
        );
      },
    },
  ];

  const handleExportDataToExcel = () => {
    if (isExporting) return;
    setIsExporting(true)
    const baseProps = [
      { title: "User Name", prop: "userName" },
      { title: "Mobile Number", prop: "userPhone" },
      { title: "Couier Name", prop: "courierName" },
      { title: "Courier Mobile", prop: "courierMobile" },
      { title: "Collection Type", prop: "collectionType" },
      { title: "Quantity In Grams", prop: "totalQuantity" },
      { title: "Total Pounds", prop: "totalPounds" },
      { title: "Points", prop: "totalPoints" },
      { title: "Points Percentage", prop: "pointsPercentage" },
      { title: "Marketplace Pounds", prop: "totalMarketplacePoundss" },
      { title: "Cash Value", prop: "cashToUser" },
      { title: "Points Status", prop: "statusName" },
      { title: "Request Date", prop: "date" },
    ]
    const nestedProps = {
      prop: "cansSalesRequests",
      requestTitle: "Request Number",
      data: [
        { title: "Request Supply Type", prop: "supplyType" },
        { title: "Request Quantity In Grams", prop: "totalQuantity" },
        { title: "Request Deduction Percentage", prop: "deductionPercentage" },
        { title: "Request Unit Price Per Gram", prop: "price" },
        { title: "Request Total Pounds", prop: "totalpounds" },
        { title: "Request Marketplace Points", prop: "totalPoints" },
      ]
    }
    const payload = {
      isExport: true,
      ...searchValues,
    };
    for (let property in payload) {
      if (!payload[property] && payload[property] !== 0) {
        delete payload[property];
      }
    }
    getCansSalesRequests(
      payload,
      (res) => {
        setIsExporting(false)
        if (res.success) {
          const totalObj = {
            "User Name": "Sum",
            "Quantity In Grams": res.data.totalQuantity,
            "Total Pounds": res.data.totalPounds,
            "Points": res.data.totalMarketplacePoints,
            "Marketplace Pounds": res.data.totalMarketplacePounds
          }
          exportDataAsExcel(
            res.data.response,
            { baseProps, nestedProps, tab: "Scrap Sales Requests Report", totalObj },
          )
        } else {
          toastError(res.message)
        }
      },
      (res) => {
        setIsExporting(false)
        toastError(res?.data?.error);
      }
    );
  }

  useEffect(() => {
    const payload = {
      pageNumber: currentPage,
      perPage: pageSize,
      ...searchValues
    }
    for (let property in payload) {
      if (!payload[property] && payload[property] !== 0) {
        delete payload[property]
      }
    }
    getCansSalesRequests(
      payload,
      (res) => {
        setLoading(false);
        if (res.success) {
          setDataSource(res.data.response);
          setTotalCount(res.data.totalCount);
          const totalObj = {
            totalQuantity: res.data.totalQuantity,
            totalPounds: res.data.totalPounds,
            totalPoints: res.data.totalMarketplacePoints,
            totalMarketplacePounds: res.data.totalMarketplacePounds
          }
          if (rowData) {
            const updatedRowData = res.data.response.find((ele) => ele.requestId === rowData.requestId)
            setRowData(updatedRowData)
          }
          setColumnsTotal(totalObj)
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setLoading(false);
        toastError(res.message);
      }
    );
  }, [renderMe, searchValues, currentPage, pageSize]);

  useEffect(() => {
    getCollectionTypes(
      (res) => {
        const filterArr = res.data.map((obj) => (
          { text: obj.name, value: obj.id }
        ))
        setCollectionTypes(filterArr)
      },
      (res) => {
        toastError(res?.data?.error)
      })
  }, [])

  return (
    <section className="users cans-sales-requests">
      <div className="users__all-contents">
        <div className="users__content">
          <div className="users__usersData">
            <div className="pb-3 mb-3">
              <MainHeader
                title="Scrap Sales Requests"
                buttonName={!(roles.includes(12) || roles.includes(15)) ? "Add Request" : ""}
                onButtonClick={handleOpenAddRequestModal}
              />
            </div>
            <div className="users__table grid-table mrg-y-30">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                rowId={"requestId"}
                allowExport={!(roles.includes(12) || roles.includes(15))}
                isExporting={isExporting}
                onExport={handleExportDataToExcel}
                currentPage={currentPage}
                totalCount={totalCount}
                onPageChange={handlePageChange}
                onChange={handleTableChange}
                pageSize={pageSize}
                onPageSizeChange={handlePageSizeChange}
                summary={() => (
                  <Table.Summary>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} className="fw-bold text-center text--primary-1">Sum</Table.Summary.Cell>
                      <Table.Summary.Cell index={1}></Table.Summary.Cell>
                      <Table.Summary.Cell index={2}></Table.Summary.Cell>
                      <Table.Summary.Cell index={3}></Table.Summary.Cell>
                      <Table.Summary.Cell index={4}></Table.Summary.Cell>
                      <Table.Summary.Cell index={5} className="text-center text--primary-1">{columnsTotal.totalQuantity}</Table.Summary.Cell>
                      <Table.Summary.Cell index={6} className="text-center text--primary-1">{columnsTotal.totalPounds}</Table.Summary.Cell>
                      <Table.Summary.Cell index={7} className="text-center text--primary-1">{columnsTotal.totalPoints}</Table.Summary.Cell>
                      <Table.Summary.Cell index={8}></Table.Summary.Cell>
                      <Table.Summary.Cell index={10} className="text-center text--primary-1">{columnsTotal.totalMarketplacePounds}</Table.Summary.Cell>
                      <Table.Summary.Cell index={11}></Table.Summary.Cell>
                      <Table.Summary.Cell index={12}></Table.Summary.Cell>
                      <Table.Summary.Cell index={13}></Table.Summary.Cell>
                      <Table.Summary.Cell index={14}></Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                )}
              />
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <AcceptRejectModal
          mode={mode}
          request={rowData}
          open={isModalOpen}
          subHeader={
            <SubHeader
              bgColor="#589559"
              title={`${mode === "accept" ? "Accept" : "Reject"} Request`}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          onCancel={handleCancelModal}
          onSubmit={handleSubmit}
          primaryBtnName={mode}
        />
      )}
      {isViewModalOpen && (
        <ViewRequestDetails
          data={rowData}
          open={isViewModalOpen}
          subHeader={
            <SubHeader
              bgColor="#589559"
              title={`View Details`}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          onSubmitSupplyTypes={() => setRenderMe(!renderMe)}
          onUpdateRequest={() => setRenderMe(!renderMe)}
          onCancel={handleCancelModal}
        />
      )}
      {isAddRequestModalOpen && (
        <AddRequestModal
          open={isAddRequestModalOpen}
          subHeader={
            <SubHeader
              bgColor="#589559"
              title={`Add Request`}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          onSubmit={handleSubmit}
          onCancel={handleCancelModal}
        />
      )}
      {isRefundModalOpen && (
        <RefundPointsModal
          request={rowData}
          open={isRefundModalOpen}
          subHeader={
            <SubHeader
              bgColor="#589559"
              title={`Refund a Redeemed Points`}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          onSubmit={handleSubmitModal}
          onCancel={handleCancelModal}
        />
      )}
    </section>
  );
};

export default CansSalesRequests;
