import { useState, useEffect } from 'react';
import { Modal, Spin, Radio } from 'antd';
import { getDealerBalanceApi } from '../../../apis/Network';
import { toastError } from '../../../helpers/toasters';

// components
import SalesDetails from './SalesDetails';
import ChecksDetails from './ChecksDetails';
import ReturnsDetails from './ReturnsDetails';

const BalanceDetails = ({ SubHeader, open, rowData }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentShownData, setCurrentShownData] = useState(1);
    const options = [
        { label: "Sales", value: 1 },
        { label: "Checks", value: 2 },
        { label: "Returns", value: 3 },
    ]
    const totals = [
        { id: 0, label: "Total Balance", value: data?.dealerBalance },
        { id: 1, label: "Total Sales Amount", value: data?.totalSalesAmount },
        { id: 2, label: "Total Checks Amount", value: data?.totalChecksAmount },
        { id: 3, label: "Total Returns", value: data?.totalReturnsAmount },
    ]

    useEffect(() => {
        if (rowData) {
            getDealerBalanceApi(
                rowData.dealerId,
                (res) => {
                    setLoading(false)
                    if (res.success) {
                        setData(res.data)
                    } else {
                        toastError(res.message)
                    }
                },
                (res) => {
                    setLoading(false)
                    toastError(res.message)
                }
            )
        }
    }, [rowData])

    return (
        <Modal
            title={SubHeader}
            open={open}
            className="msg-modal dealers__balance-details-modal"
            footer={false}
            centered
            destroyOnClose
            closable={false}
        >
            {loading ? (
                <div className='py-5 d-flex justify-content-center'>
                    <Spin spinning={loading} />
                </div>
            ) : (
                <div>
                    <div className="d-flex justify-content-center mb-3">
                        <Radio.Group
                            options={options}
                            className="dealers__balance-details-modal__data-toggler"
                            onChange={(e) => setCurrentShownData(e.target.value)}
                            value={currentShownData}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </div>
                    {currentShownData === 1 ? (
                        <SalesDetails data={data?.sales} />
                    ) : currentShownData === 2 ? (
                        <ChecksDetails data={data?.checks} />
                    ) : (
                        <ReturnsDetails data={data?.returns} />
                    )}
                    <div className='pt-3 d-flex flex-column row-gap-2'>
                        {totals?.map((row) => (
                            <div key={row.id} className="d-flex align-items-center gap-2">
                                <span className='dealers__balance-details-modal__total-details__label'>{row.label}:</span>
                                <span className='dealers__balance-details-modal__total-details__value'>{row.value} EGP</span>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </Modal>
    )
}

export default BalanceDetails;
