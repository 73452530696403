const MessageTimestamp = ({ type, time }) => {
  return (
    <p
      className="chat__chat-holder__body__message__holder__timestamp text-end fsize--11 align-self-end mb-0"
      data-media={type !== 1}
      data-audio={type === 3}
    >
      {time}
    </p>
  );
};

export default MessageTimestamp;
