import { useState } from 'react';
import { Row, Input, Col, Space, Form } from 'antd';
import {
    arabicWithEmojisNoRestriction,
    englishWithEmojisNoRestriction
} from '../../constants/RegexValidations';
import { sendNotification } from '../../apis/Network';
import { toastSuccess, toastError } from '../../helpers/toasters';

// components
import MainHeader from "../SubComponents/MainHeader";
import PrimaryButton from '../SubComponents/Buttons/PrimaryButton';

const Notification = () => {
    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm();
    const initialValues = {
        titleEn: "",
        titleAr: "",
        contentEn: "",
        contentAr: "",
    }

    const handleFinish = (values) => {
        if (submitting) return;
        setSubmitting(true);

        const payload = {
            titleEn: values.titleEn.trim(),
            titleOtherLanguage: values.titleAr.trim(),
            contentEn: values.contentEn.trim(),
            contentOtherLanguage: values.contentAr.trim()
        }

        sendNotification(
            payload,
            (res) => {
                setSubmitting(false)
                if (res.success) {
                    toastSuccess(res.message)
                    form.resetFields()
                } else {
                    toastError(res.message)
                }
            },
            (res) => {
                setSubmitting(false)
                toastError(res.message)
            }
        )
    };

    return (
        <section className="users notification">
            <div className="users__all-contents">
                <div className="users__content">
                    <div className="users__usersData pb-5">
                        <div className="pb-3 mb-3">
                            <MainHeader title="Notification" />
                        </div>
                        <div>
                            <Form
                                form={form}
                                className="edit-actor__content notification__form p-4"
                                onFinish={handleFinish}
                                initialValues={initialValues}
                            >
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <div className="new-actor__name new-category mb-5">
                                            <label className="users__parent-info-label mb-2" htmlFor="">
                                                Title (EN)
                                            </label>
                                            <Form.Item
                                                className="new-category__form-item"
                                                name="titleEn"
                                                validateFirst
                                                rules={[
                                                    {
                                                        required: true,
                                                        whiteSpace: true,
                                                        message: "Please, enter the title in English",
                                                    },
                                                    {
                                                        pattern: englishWithEmojisNoRestriction,
                                                        message: "Please, enter a valid title",
                                                    },
                                                    {
                                                        min: 3,
                                                        message: "Minimum length is 3 characters"
                                                    },
                                                    {
                                                        max: 50,
                                                        message: "Maximum length is 50 characters"
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="search__searchField py-2"
                                                    placeholder="Enter title in English"
                                                    maxLength={50}
                                                    showCount
                                                />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <div className="new-actor__name new-category mb-5">
                                            <label className="users__parent-info-label mb-2" htmlFor="">
                                                Title (AR)
                                            </label>
                                            <Form.Item
                                                className=" new-category__form-item"
                                                name="titleAr"
                                                validateFirst
                                                rules={[
                                                    {
                                                        required: true,
                                                        whitespace: true,
                                                        message: "Please, enter the title in Arabic",
                                                    },
                                                    {
                                                        pattern: arabicWithEmojisNoRestriction,
                                                        message: "Please, enter a valid title",
                                                    },
                                                    {
                                                        min: 3,
                                                        message: "Minimum length is 3 characters"
                                                    },
                                                    {
                                                        max: 50,
                                                        message: "Maximum length is 50 characters"
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="search__searchField py-2"
                                                    placeholder="Enter title in Arabic"
                                                    maxLength={50}
                                                    showCount
                                                    dir="rtl"
                                                />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <div className="new-actor__name new-category mb-5">
                                            <label className="users__parent-info-label mb-2" htmlFor="">
                                                Content (EN)
                                            </label>
                                            <Form.Item
                                                className="new-category__form-item"
                                                name="contentEn"
                                                validateFirst
                                                rules={[
                                                    {
                                                        required: true,
                                                        whitespace: true,
                                                        message: "Please, enter the content in English",
                                                    },
                                                    {
                                                        pattern: englishWithEmojisNoRestriction,
                                                        message: "Please, enter a valid content",
                                                    },
                                                    {
                                                        min: 3,
                                                        message: "Minimum length is 3 characters"
                                                    },
                                                    {
                                                        max: 500,
                                                        message: "Maximum length is 500 characters"
                                                    },
                                                ]}
                                            >
                                                <Input.TextArea
                                                    maxLength={500}
                                                    showCount
                                                    className="store-items__item-form__description"
                                                    autoSize={{ minRows: 2, maxRows: 6 }}
                                                    placeholder="Enter content in English"
                                                />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <div className="new-actor__name new-category mb-5">
                                            <label className="users__parent-info-label mb-2" htmlFor="">
                                                Content (AR)
                                            </label>
                                            <Form.Item
                                                className="new-category__form-item"
                                                name="contentAr"
                                                validateFirst
                                                rules={[
                                                    {
                                                        required: true,
                                                        whitespace: true,
                                                        message: "Please, enter the content in Arabic",
                                                    },
                                                    {
                                                        pattern: arabicWithEmojisNoRestriction,
                                                        message: "Please, enter a valid content",
                                                    },
                                                    {
                                                        min: 3,
                                                        message: "Minimum length is 3 characters"
                                                    },
                                                    {
                                                        max: 500,
                                                        message: "Maximum length is 500 characters"
                                                    },
                                                ]}
                                            >
                                                <Input.TextArea
                                                    maxLength={500}
                                                    showCount
                                                    dir="rtl"
                                                    className="store-items__item-form__description"
                                                    autoSize={{ minRows: 2, maxRows: 6 }}
                                                    placeholder="Enter content in Arabic"
                                                />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mrg-ys-20 mrg-x-auto">
                                    <Space size={20}>
                                        <PrimaryButton
                                            buttonName={"Send"}
                                            loading={submitting}
                                            type="submit"
                                        />
                                    </Space>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Notification;