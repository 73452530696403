import { useEffect, useState } from "react";
import { Modal, Collapse, Spin } from "antd";
// import { getMonthlyCourierOrders } from "../../Network";
// import { toastError } from "../../helpers/toasters";

const CourierLogsModal = ({
    rowData,
    open,
    subHeader,
    onCancel,
}) => {
    const [dataSource, setDataSource] = useState(null);
    const [defaultActiveKeys, setDefaultActiveKeys] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (rowData) {
            // getMonthlyCourierOrders(
            //     rowData.id,
            //     (res) => {
            //         setLoading(false)
            //         if (res.success) {
            //             const items = res.data.map((ele) => {
            //                 return {
            //                     key: ele.month,
            //                     label: <span className="fw-bold text--primary-1 fs-6">{ele.month}</span>,
            //                     children: (
            //                         <div>
            //                             <p className="mb-2 text--secondary-1">Completed Orders: {ele.completedOrdersCount}</p>
            //                             <p className="mb-2 text--secondary-1">Un-Completed Orders: {ele.unCompletedOrdersCount}</p>
            //                             <p className="mb-0 text--secondary-1">Total Orders: {ele.allOrdersCount}</p>
            //                         </div>
            //                     )
            //                 }
            //             })
            //             const defaultKeys = res.data.map((ele) => ele.month)
            //             setDefaultActiveKeys(defaultKeys)
            //             setDataSource(items)
            //         } else {
            //             toastError(res.message)
            //         }
            //     },
            //     (res) => {
            //         setLoading(false)
            //         toastError(res?.data?.error)
            //     }
            // )
            const items = rowData.logs.map((ele) => {
                return {
                    key: ele.month,
                    label: <span className="fw-bold text--primary-1 fs-6">{ele.month}</span>,
                    children: (
                        <div>
                            <p className="mb-2 text--secondary-1">Completed Orders: {ele.completedOrdersCount}</p>
                            <p className="mb-2 text--secondary-1">Un-Completed Orders: {ele.unCompletedOrdersCount}</p>
                            <p className="mb-0 text--secondary-1">Total Orders: {ele.allOrdersCount}</p>
                        </div>
                    )
                }
            })
            const defaultKeys = rowData.logs.map((ele) => ele.month)
            setDefaultActiveKeys(defaultKeys)
            setDataSource(items)
            setLoading(false)
        }
    }, [rowData])

    return (
        <Modal
            title={subHeader}
            open={open}
            className="msg-modal couriers__logs-modal"
            footer={false}
            closable={false}
            centered
            destroyOnClose
            onCancel={onCancel}
        >
            <div className="my-3">
                <p className="mb-1 text--secondary-1">Courier Name: {rowData?.userName}</p>
                <p className="text--secondary-1">Courier Mobile: {rowData?.phone}</p>
            </div>
            {loading ? (
                <div className="d-flex justify-content-center">
                    <Spin />
                </div>
            ) : dataSource?.length > 0 ? (
                <div className="couriers__logs-modal__holder">
                    <Collapse items={dataSource} defaultActiveKey={defaultActiveKeys} />
                </div>
            ) : (
                <p className="text-center mb-0 fw-bold text--secondary-1">There are no orders</p>
            )}
        </Modal>
    );
}

export default CourierLogsModal;