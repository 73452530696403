import { useEffect } from "react";
import { useMap } from "react-leaflet";

const ChangeView = ({ center, zoom, onMarkerChange, disableControllers }) => {
    const map = useMap();

    useEffect(() => {
        if (!disableControllers) {
            map.on("click", (e) => {
                if (disableControllers) return
                onMarkerChange(e.latlng, map.getZoom())
            })

            return () => map.off()
        }
    }, [map, disableControllers])

    useEffect(() => {
        map.setView(center, zoom);
    }, [map, center, zoom])

    return null;
}

export default ChangeView;