import { useEffect, useState } from "react";
import { Col, Form, Modal, Row, Space, Switch } from "antd";
import { addHomeBanner, updateHomeBanner } from "../../apis/Network";
import { toastError, toastSuccess } from "../../helpers/toasters";

// components
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import ImageUpload from "./ImageUpload";

const BannerModal = ({
    mode,
    rowData,
    open,
    subHeader,
    onCancel,
    onSubmit,
    canAdminMakeBannerActive,
    canAdminDelete
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [uploadedImage, setUploadedImage] = useState("");
    const [uploadedImageAr, setUploadedImageAr] = useState("");
    const [form] = Form.useForm();
    const initialValues = {
        image: "",
        imageOtherLanguage: "",
        isActive: true,
    }

    const handleFinish = (values) => {
        if (mode === "edit") {
            // stop admin from activating a banner when there're already 10 active banners
            if (!rowData.isActive && values.isActive && !canAdminMakeBannerActive) {
                toastError("The maximum number for active home banners is 10")
                return;
            } else if (rowData.isActive && !values.isActive && !canAdminDelete) {
                // stop admin from deactivating a banner when there's only 1 active banner
                toastError("The minimum number for deactive home banners is 1")
                return;
            }
        }
        if (submitting) return;
        setSubmitting(true);

        const payload = new FormData()
        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                if (
                    (key === ("image") && !uploadedImage) ||
                    (key === ("imageOtherLanguage") && !uploadedImageAr)
                ) {
                    continue
                }
                payload.append(key, values[key]);
            }
        }
        if (mode === "add") {
            addHomeBanner(
                payload,
                (res) => {
                    setSubmitting(false);
                    if (res.success) {
                        toastSuccess(res.message);
                        onSubmit()
                    } else {
                        toastError(res.message);
                    }
                },
                (res) => {
                    setSubmitting(false);
                    toastError(res.message);
                })
        } else {
            payload.append("id", rowData.id);
            updateHomeBanner(
                payload,
                (res) => {
                    setSubmitting(false);
                    if (res.success) {
                        toastSuccess(res.message);
                        onSubmit()
                    } else {
                        toastError(res.message);
                    }
                },
                (res) => {
                    setSubmitting(false);
                    toastError(res.message);
                })
        }
    }

    useEffect(() => {
        if (rowData && mode === "edit") {
            form.setFieldsValue({
                image: rowData.image,
                isActive: rowData.isActive
            })
        }
    }, [mode, rowData, form])

    return (
        <Modal
            title={subHeader}
            open={open}
            className="msg-modal"
            footer={false}
            closable={false}
            centered
            destroyOnClose
            maskClosable={false}
            onCancel={onCancel}
        >
            <Form
                form={form}
                className="edit-actor__content pt-3"
                onFinish={handleFinish}
                initialValues={initialValues}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <ImageUpload
                            formRef={form}
                            name={"image"}
                            label={"Image (EN)"}
                            mode={mode}
                            required={true}
                            rowDataImg={rowData?.image}
                            onUpload={(value) => setUploadedImage(value)}
                        />
                    </Col>
                    <Col span={24}>
                        <ImageUpload
                            formRef={form}
                            name={"imageOtherLanguage"}
                            label={"Image (AR)"}
                            mode={mode}
                            required={false}
                            rowDataImg={rowData?.imageOtherLanguage}
                            onUpload={(value) => setUploadedImageAr(value)}
                        />
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name mb-4 row mx-0 align-items-center">
                            <div className="col-4 px-0 text-start">
                                <label className="users__parent-info-label">Status</label>
                            </div>
                            <div className="col-8 px-0 d-flex gap-2 align-items-center">
                                <span>Deactive</span>
                                <Form.Item
                                    name="isActive"
                                    valuePropName="checked"
                                    className="mb-0"
                                >
                                    <Switch />
                                </Form.Item>
                                <span>Active</span>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mrg-ys-20 mrg-x-auto mt-2 justify-content-center">
                    <Space size={20}>
                        <SecondaryButton
                            buttonName={"Cancel"}
                            onClick={onCancel}
                        />
                        <PrimaryButton
                            buttonName={"Save"}
                            loading={submitting}
                            type="submit"
                        />
                    </Space>
                </Row>
            </Form>
        </Modal>
    );
}

export default BannerModal;