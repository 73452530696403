import { useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import { Button, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useNavigate, useOutletContext } from "react-router-dom";
import { getCouriersWhoHaveDailyOrders } from "../../../apis/Network";
import { toastError } from "../../../helpers/toasters";
import ROUTES from "../../../constants/NewRoutes";
import { exportDataAsExcel } from "../../../helpers/helpfulFunctions";

// components
import GridTable from "../../SubComponents/GridTable/GridTable";
import MainHeader from "../../SubComponents/MainHeader";
import SubHeader from "../../SubComponents/SubHeader";
import ViewOrderDetailsModal from "./ViewOrderDetailsmodal";
import ViewCarImages from "./ViewCarImages";

const DailyCouriers = () => {
  const [loading, setLoading] = useState(true);
  const [orderDetailsModalOpen, setIsOrderDetailsModalOpen] = useState(false);
  const [carImagesModalOpen, setCarImagesModalOpen] = useState(false);
  const [rowData, setRowData] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [selectedDates, setSelectedDates] = useOutletContext();
  const [isExporting, setIsExporting] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const { roles } = useSelector((state) => state.auth.authorization);
  const searchInput = useRef(null);
  const navigate = useNavigate();

  const handleExportDataToExcel = (data) => {
    if (isExporting) return;
    setIsExporting(true)
    const baseProps = [
      { title: "Courier Name", prop: "courierName" },
      { title: "Courier Mobile", prop: "courierMobile" },
      { title: "Completed Orders", prop: "numberOfCompletedOrders" },
      { title: "Uncompleted Orders", prop: "numberOfUnCompletedOrders" },
      { title: "Total Orders", prop: "totalNumberOfOrder" },
    ];
    exportDataAsExcel(data, { baseProps, tab: "Daily Couriers Report" })
    setIsExporting(false)
  }

  const handleDateChange = (dates) => {
    setSelectedDates(dates);
    setLoading(true)
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleViewAllOrders = (row) => {
    setIsOrderDetailsModalOpen(true);
    setRowData(row);
  };

  const handleCancelModal = () => {
    setIsOrderDetailsModalOpen(false);
    setRowData(null);
    setCarImagesModalOpen(false);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          ></Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text || text === 0 ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      key: "courierName",
      title: "COURIER NAME",
      dataIndex: "courierName",
      ...getColumnSearchProps("courierName"),
    },
    {
      key: "courierMobile",
      title: "COURIER MOBILE",
      dataIndex: "courierMobile",
      ...getColumnSearchProps("courierMobile"),
    },
    {
      key: "numberOfCompletedOrders",
      title: "COMPLETED ORDERS (NUM)",
      dataIndex: "numberOfCompletedOrders",
      ...getColumnSearchProps("numberOfCompletedOrders"),
    },
    {
      key: "numberOfUnCompletedOrders",
      title: "UN-COMPLETED ORDERS (NUM)",
      dataIndex: "numberOfUnCompletedOrders",
      ...getColumnSearchProps("numberOfUnCompletedOrders"),
    },
    {
      key: "totalNumberOfOrder",
      title: "TOTAL ORDERS (NUM)",
      dataIndex: "totalNumberOfOrder",
      ...getColumnSearchProps("totalNumberOfOrder"),
    },
    {
      key: "ACTION",
      title: "ACTION",
      dataIndex: "ACTION",
      render: (_, row) => {
        return (
          <div className="d-flex flex-column gap-2">
            {row?.carImages?.length ? (
              <Button
                className="d-block border-0"
                style={{
                  backgroundColor: "#176b87",
                  color: "white",
                }}
                onClick={() => {
                  setRowData(row)
                  setCarImagesModalOpen(true)
                }}
              >
                Car Images
              </Button>
            ) : (
              ""
            )}
            <Button
              className="border-0"
              onClick={() => {
                const dates = selectedDates?.map((date) => date.format("YYYY-MM-DD"))
                navigate(`${ROUTES.DAILY_COURIES}${ROUTES.COMPLETED_ORDERS}`, { state: { data: row, selectedDate: dates } });
              }}
              style={{ backgroundColor: "#589559", color: "white" }}
            >
              View Completed Orders
            </Button>
            <Button
              className="border-0 btn--secondary"
              onClick={() => handleViewAllOrders(row)}
            >
              View All Orders status
            </Button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const payload = {
      from: selectedDates ? selectedDates[0].format("YYYY-MM-DD") : "",
      to: selectedDates ? selectedDates[1].format("YYYY-MM-DD") : ""
    }
    getCouriersWhoHaveDailyOrders(
      payload,
      (res) => {
        setLoading(false);
        if (res.success) {
          setDataSource(res.data);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setLoading(false);
        toastError(res.message);
      }
    );
  }, [selectedDates]);

  return (
    <section className="users">
      <div className="users__all-contents">
        <div className="users__content">
          <div className="users__usersData">
            <div className="pb-3 mb-3">
              <MainHeader title="Daily Couriers Who have orders" />
            </div>
            <div className="users__table grid-table mrg-y-30">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                rowId={"courierId"}
                title="Today Courier Orders"
                showTableDateFilter={true}
                onDateFilter={handleDateChange}
                dateFilterValue={selectedDates}
                isExporting={isExporting}
                allowExport={!roles?.includes(14)}
                onExport={handleExportDataToExcel}
              />
            </div>
          </div>
        </div>
      </div>
      {orderDetailsModalOpen && (
        <ViewOrderDetailsModal
          rowData={rowData}
          open={orderDetailsModalOpen}
          subHeader={
            <SubHeader
              bgColor="#589559"
              title={`View All Orders`}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          onCancel={handleCancelModal}
        />
      )}
      {carImagesModalOpen && (
        <ViewCarImages
          data={rowData?.carImages}
          open={carImagesModalOpen}
          onCancel={handleCancelModal}
        />
      )}
    </section>
  );
};

export default DailyCouriers;