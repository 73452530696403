import { useState } from "react";
import { useSelector } from 'react-redux';
import { Modal } from "antd";
import { exportDataAsExcel } from "../../../helpers/helpfulFunctions";

// components
import GridTable from "../../SubComponents/GridTable/GridTable";

const ViewOrderDetailsModal = ({ rowData, open, subHeader, onCancel }) => {
  const [isExporting, setIsExporting] = useState(false);
  const { roles } = useSelector((state) => state.auth.authorization);

  const columns = [
    {
      key: "name",
      title: "NAME",
      dataIndex: "name",
    },
    {
      key: "mobile",
      title: "MOBILE",
      dataIndex: "mobile",
    },
    {
      key: "assigninStatus",
      title: "ASSIGNED STATUS",
      dataIndex: "assigninStatus",
    },
  ];

  const handleExportDataToExcel = (dataToExport) => {
    if (isExporting) return;
    setIsExporting(true);
    const baseProps = [
      { title: "Name", prop: "name" },
      { title: "Mobile", prop: "mobile" },
      { title: "Assigned Status", prop: "assigninStatus" },
    ];
    exportDataAsExcel(dataToExport, { baseProps, tab: `${rowData.courierName}'s Orders Status Report` })
    setIsExporting(false)
  }
  return (
    <Modal
      title={subHeader}
      open={open}
      width={window.innerWidth >= 992 ? "66%" : "90%"}
      className="msg-modal"
      footer={false}
      closable={false}
      centered
      destroyOnClose
      onCancel={onCancel}
    >
      <GridTable
        columns={columns}
        dataSource={rowData?.users}
        className="cans-sales-requests__view-details"
        rowId={"mobile"}
        isExporting={isExporting}
        allowExport={!roles?.includes(14)}
        onExport={handleExportDataToExcel}
      />
    </Modal>
  );
};

export default ViewOrderDetailsModal;
