import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import { QueryClient, QueryClientProvider } from "react-query";
import { checkAdminStatus } from "./apis/Network";
import i18next from "./localization/i18next";
import General from "./modules/GeneralComponent/General";
import ROUTES from "./constants/NewRoutes";
import "render-smooth-image-react/build/style.css";
import "react-toastify/dist/ReactToastify.css";
import "leaflet-draw/dist/leaflet.draw.css"

const queryClient = new QueryClient();

function App() {
  const { lang, dir } = useSelector((state) => state.lang);
  const { authorization } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    i18next.init({
      lng: lang,
      fallbackLng: lang,
    });
  }, [lang, dir]);

  useEffect(() => {
    if (authorization && authorization.token) {
      checkAdminStatus((res) => {
        if (res.success) {
          if (!res.data) {
            navigate(ROUTES.LOGOUT, { replace: true })
          }
        }
      }, (res) => { })
    }
  }, [location, navigate, authorization]);

  return (
    <QueryClientProvider client={queryClient}>
      <>
        <Helmet>
          <html lang={lang} dir={dir}></html>
        </Helmet>
        <ToastContainer />
        <General />
      </>
    </QueryClientProvider>
  );
}

export default App;
