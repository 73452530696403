import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Image } from "antd";
import { baseUrl } from "../../apis/services";

const ImagesCarousel = ({ data }) => {
  // React multi-carousel breakpoints
  const responsive = {
    all: {
      breakpoint: { max: 4000, min: 0 },
      items: 3,
    },
  };

  return (

    <Image.PreviewGroup
      preview={{
        rootClassName: "social-orders__view-details__images-preview",
        maskClosable: false,
        keyboard: false,
      }}
    >
      <Carousel
        swipeable
        keyBoardControl
        arrows={true}
        draggable={false}
        slidesToSlide={1}
        minimumTouchDrag={60}
        responsive={responsive}
        infinite={false}
        centerMode={true}
        partialVisible={false}
      >
        {data?.map((ele) => (
          <div key={ele} className="px-2">
            <Image
              src={baseUrl + ele}
              alt=""
              height={100}
              width={"100%"}
              className="d-block img-fluid w-100 object-fit-cover"
            />
          </div>
        ))}
      </Carousel>
    </Image.PreviewGroup >
  );
};

export default ImagesCarousel;
