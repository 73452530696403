const SocialOrdersIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5 12.5C15.5 11.5717 15.1313 10.6815 14.4749 10.0251C13.8185 9.36875 12.9283 9 12 9C11.0717 9 10.1815 9.36875 9.52513 10.0251C8.86875 10.6815 8.5 11.5717 8.5 12.5V13H11.065C11.1769 12.572 11.4275 12.1932 11.7776 11.9228C12.1277 11.6524 12.5576 11.5057 13 11.5057C13.4424 11.5057 13.8723 11.6524 14.2224 11.9228C14.5725 12.1932 14.8231 12.572 14.935 13H15.5V12.5Z" />
            <path d="M7.65 20.205C7.64868 19.9483 7.69818 19.6939 7.79565 19.4565C7.89311 19.219 8.03662 19.0032 8.21789 18.8214C8.39916 18.6397 8.61462 18.4956 8.85183 18.3976C9.08904 18.2995 9.34332 18.2493 9.6 18.25C10.0845 18.2887 10.5583 18.4122 11 18.615V14H7.5C7.36739 14 7.24022 13.9473 7.14645 13.8536C7.05268 13.7598 7 13.6326 7 13.5C7 13.3674 7.05268 13.2402 7.14645 13.1464C7.24022 13.0527 7.36739 13 7.5 13V12.5C7.50053 11.3935 7.90873 10.3259 8.64661 9.50134C9.38449 8.67676 10.4003 8.15295 11.5 8.03V7.5C11.5 7.36739 11.5527 7.24021 11.6464 7.14645C11.7402 7.05268 11.8674 7 12 7C12.1326 7 12.2598 7.05268 12.3536 7.14645C12.4473 7.24021 12.5 7.36739 12.5 7.5V8.03C13.5997 8.15295 14.6155 8.67676 15.3534 9.50134C16.0913 10.3259 16.4995 11.3935 16.5 12.5V13C16.6326 13 16.7598 13.0527 16.8536 13.1464C16.9473 13.2402 17 13.3674 17 13.5C17 13.6326 16.9473 13.7598 16.8536 13.8536C16.7598 13.9473 16.6326 14 16.5 14H15V15C15.2631 15.0004 15.5235 15.0528 15.7663 15.154C16.0091 15.2553 16.2295 15.4034 16.415 15.59C16.7624 15.4839 17.132 15.4744 17.4844 15.5625C17.8367 15.6505 18.1585 15.8329 18.415 16.09C18.54 16.05 18.5 17.09 18.5 2.5C18.5 1.96957 18.2893 1.46086 17.9142 1.08579C17.5391 0.710714 17.0304 0.5 16.5 0.5H16V1.5C16 2.03043 15.7893 2.53914 15.4142 2.91421C15.0391 3.28929 14.5304 3.5 14 3.5H10C9.46957 3.5 8.96086 3.28929 8.58579 2.91421C8.21071 2.53914 8 2.03043 8 1.5V0.5H7.5C6.96957 0.5 6.46086 0.710714 6.08579 1.08579C5.71071 1.46086 5.5 1.96957 5.5 2.5V20.5C5.5 21.0304 5.71071 21.5391 6.08579 21.9142C6.46086 22.2893 6.96957 22.5 7.5 22.5H9.29C8.415 21.755 7.65 21.3 7.65 20.205Z" />
            <path d="M10.0001 2.5H14.0001C14.2654 2.5 14.5197 2.39464 14.7072 2.20711C14.8948 2.01957 15.0001 1.76522 15.0001 1.5V0.5H9.00014V1.5C9.00014 1.76522 9.1055 2.01957 9.29303 2.20711C9.48057 2.39464 9.73492 2.5 10.0001 2.5ZM19.7051 17.295C19.5653 17.156 19.3875 17.0614 19.1941 17.0232C19.0006 16.9849 18.8002 17.0047 18.618 17.0801C18.4358 17.1554 18.2799 17.2829 18.17 17.4466C18.0601 17.6103 18.001 17.8028 18.0001 18V17.5C18.0001 17.2348 17.8948 16.9804 17.7072 16.7929C17.5197 16.6054 17.2654 16.5 17.0001 16.5C16.7349 16.5 16.4806 16.6054 16.293 16.7929C16.1055 16.9804 16.0001 17.2348 16.0001 17.5V17C16.0001 16.7348 15.8948 16.4804 15.7072 16.2929C15.5197 16.1054 15.2654 16 15.0001 16C14.7349 16 14.4806 16.1054 14.293 16.2929C14.1055 16.4804 14.0001 16.7348 14.0001 17V13.5C13.9993 13.3028 13.9402 13.1103 13.8303 12.9466C13.7203 12.7829 13.5645 12.6554 13.3823 12.5801C13.2001 12.5047 12.9996 12.4849 12.8062 12.5232C12.6128 12.5614 12.435 12.656 12.2951 12.795C11.8901 13.19 12.0001 13.065 12.0001 20L9.90014 19.3C9.69434 19.2417 9.47491 19.2546 9.27735 19.3366C9.0798 19.4186 8.91568 19.5648 8.81156 19.7516C8.70745 19.9385 8.66942 20.155 8.70365 20.3661C8.73787 20.5772 8.84234 20.7706 9.00014 20.915L12.0001 23.5H20.0001C20.0001 17.69 20.1151 17.695 19.7051 17.295Z" />
        </svg>

    );
}

export default SocialOrdersIcon;