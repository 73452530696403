// components
import CoverImg from "./CoverImg";
import OtherImages from "./OtherImages";

const UploadImages = ({
  mode,
  formRef,
  data,
  onRemoveImagePath,
  imagesPaths,
  previewCover,
  onPreviewCoverChange,
  previewFiles,
  onPreviewFilesChange,
  uploadedImages,
  onUploadedImagesChange
}) => {
  return (
    <div className="store-items__item-form__images d-flex justify-content-center gap-3">
      <CoverImg
        mode={mode}
        image={data?.image}
        formRef={formRef}
        previewImage={previewCover}
        onPreviewCoverChange={onPreviewCoverChange}
      />
      <OtherImages
        mode={mode}
        formRef={formRef}
        images={imagesPaths}
        onRemoveImagePath={onRemoveImagePath}
        previewFiles={previewFiles}
        onPreviewFilesChange={onPreviewFilesChange}
        uploadedImages={uploadedImages}
        onUploadedImagesChange={onUploadedImagesChange}
      />
    </div>
  );
};

export default UploadImages;
