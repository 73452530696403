import { useEffect, useState } from "react";
import { Button, Modal, Select, Space, Input, Form } from "antd";
import { useSelector } from "react-redux";
import {
  editSocialOrderAddress,
  getCitiesForList,
  getGovernmentsForList,
  getSocialOrderAddressDetailsById,
} from "../../apis/Network";
import { toastError, toastSuccess } from "../../helpers/toasters";

// components
import GridTable from "../SubComponents/GridTable/GridTable";

const AddressDetails = ({
  subHeader,
  open,
  rowData = {},
  onCancel,
  onSubmit,
  useAPI,
  EditAddress = false,
  partnershipAddress = false
}) => {
  const [editRow, setEditRow] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [cities, setCities] = useState(null);
  const [governments, setGovernments] = useState(null);
  const [selectedGovernmentId, setSelectdGovernmentId] = useState("");
  const [loadingCities, setLoadingCities] = useState(false);
  const [selectedCityId, setSelectdCityId] = useState("");
  const [submittable, setSubmittable] = useState(false);
  const [loading, setLoading] = useState(useAPI);
  const [dataSource, setDataSource] = useState([]);
  const { roles } = useSelector((state) => state.auth.authorization);
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const initialValues = {
    government: undefined,
    district: undefined,
    address: "",
    street: "",
    landmark: "",
    buildingNo: "",
    floorNo: "",
    apartmentNo: "",
  };

  const handleSubmit = () => {
    if (submitting) return;
    setSubmitting(true);

    const values = form.getFieldsValue(true);

    const payload = {
      requestId: rowData.requestId,
      districtId: selectedCityId,
      street: values.street,
      buildingNo: `${values.buildingNo}`,
      floorNo: `${values.floorNo}`,
      apartmentNo: `${values.apartmentNo}`,
      landmark: values.landmark,
    };

    editSocialOrderAddress(
      payload,
      (res) => {
        setSubmitting(false);
        if (res.success) {
          toastSuccess(res.message);
          onSubmit();
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setSubmitting(false);
        toastError(res.message);
      }
    );
  };

  const columns = [
    {
      title: "FULL ADDRESS",
      children: [
        {
          key: "goverment",
          title: "GOVERNMENT",
          dataIndex: "goverment",
          render: (text, row) => {
            return editRow ? (
              <Form.Item
                name="government"
                rules={[
                  { required: true, message: "Please, select a government" },
                ]}
              >
                <Select
                  className="new-category__form-item"
                  placeholder="Select government"
                  options={governments ? governments : []}
                  onChange={(value, option) => {
                    setSelectdCityId("");
                    form.resetFields(["district"]);
                    setSelectdGovernmentId(option.value);
                    setLoadingCities(true);
                  }}
                  loading={governments ? false : true}
                  value={selectedGovernmentId || undefined}
                />
              </Form.Item>
            ) : (
              <span>{partnershipAddress ? row.governmentName : row.goverment}</span>
            );
          },
        },
        {
          key: "district",
          title: "CITY",
          dataIndex: "district",
          render: (text, row) => {
            return editRow ? (
              <Form.Item
                name="district"
                rules={[{ required: true, message: "Please, select a city" }]}
              >
                <Select
                  name="district"
                  className="new-category__form-item"
                  placeholder="Select city"
                  onChange={(value, option) => {
                    setSelectdCityId(option.value);
                  }}
                  loading={loadingCities || !governments}
                  options={cities ? cities : []}
                />
              </Form.Item>
            ) : (
              <span>{partnershipAddress ? row.districtName : row.district}</span>
            );
          },
        },
        {
          key: "address",
          title: "ADDRESS",
          children: [
            {
              key: "address",
              title: "ADDRESS",
              dataIndex: "address",
            },
            {
              key: "street",
              title: "STREET",
              dataIndex: "street",
              render: (text, row) => {
                return editRow ? (
                  <Form.Item
                    name="street"
                    rules={[
                      { required: true, message: "Please, enter street" },
                    ]}
                  >
                    <Input.TextArea
                      name="street"
                      className="new-category__form-item social-orders__view-details__address-textarea"
                      placeholder="Enter Street"
                      rows={2}
                    />
                  </Form.Item>
                ) : (
                  <span>{row.street}</span>
                );
              },
            },
            {
              key: "landmark",
              title: "NEAREST LANDMARK",
              dataIndex: "landmark",
              render: (text, row) => {
                return editRow ? (
                  <Form.Item name="landmark">
                    <Input.TextArea
                      name="landmark"
                      className="new-category__form-item social-orders__view-details__address-textarea"
                      placeholder="Enter landmark"
                      rows={2}
                    />
                  </Form.Item>
                ) : (
                  <span>{row.landmark}</span>
                );
              },
            },
          ],
        },
        {
          key: "buildingNo",
          title: "BLOCK",
          children: [
            {
              key: "buildingNo",
              title: "BUILDING (NUM)",
              dataIndex: "buildingNo",
              render: (text, row) => {
                return editRow ? (
                  <Form.Item
                    name="buildingNo"
                  // rules={[
                  //   { validator: handleNumberValidation }
                  // ]}
                  >
                    <Input
                      name="buildingNo"
                      placeholder="Enter building"
                      className="w-100 d-block search__searchField social-orders__view-details__text-input"
                    />
                  </Form.Item>
                ) : (
                  <span>{row.buildingNo}</span>
                );
              },
            },
            {
              key: "floorNo",
              title: "FLOOR (NUM)",
              dataIndex: "floorNo",
              render: (text, row) => {
                return editRow ? (
                  <Form.Item name="floorNo">
                    <Input
                      name="floorNo"
                      placeholder="Enter floor"
                      className="w-100 d-block search__searchField social-orders__view-details__text-input"
                    />
                  </Form.Item>
                ) : (
                  <span>{row.floorNo}</span>
                );
              },
            },
            {
              key: "apartmentNo",
              title: "APARTMENT (NUM)",
              dataIndex: "apartmentNo",
              render: (text, row) => {
                return editRow ? (
                  <Form.Item name="apartmentNo">
                    <Input
                      name="apartmentNo"
                      placeholder="Enter apartment"
                      className="w-100 d-block search__searchField social-orders__view-details__text-input"
                    />
                  </Form.Item>
                ) : (
                  <span>{row.apartmentNo}</span>
                );
              },
            },
          ],
        },
        {
          key: "id",
          title: "ACTION",
          hidden: !EditAddress || roles?.includes(12) || roles?.includes(15),
          render: (_, row) => {
            return (
              <div>
                <Space size={5} className="mb-2">
                  {editRow ? (
                    <Button
                      className="border-0"
                      disabled={
                        submitting ||
                        !submittable ||
                        !selectedCityId ||
                        !selectedGovernmentId
                      }
                      loading={submitting}
                      onClick={() => {
                        if (submittable && !submitting) {
                          handleSubmit();
                        }
                      }}
                      style={{ backgroundColor: "#589559", color: "white" }}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button
                      className="btn--secondary"
                      onClick={() => {
                        setEditRow(true);
                      }}
                    >
                      Edit
                    </Button>
                  )}
                </Space>
              </div>
            );
          },
        },
      ],
    },
  ].filter((ele) => !ele.hidden);

  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        (info) => {
          setSubmittable(true);
        },
        (info) => {
          if (info.errorFields.length === 0) {
            setSubmittable(true);
          } else {
            setSubmittable(false);
          }
        }
      );
  }, [values, form]);

  useEffect(() => {
    if (!selectedGovernmentId) return;
    getCitiesForList(
      selectedGovernmentId,
      (res) => {
        setLoadingCities(false);
        if (res.success) {
          setSelectdCityId(rowData.districtId);
          const options = res.data.map((ele) => {
            return {
              label: ele.name,
              value: ele.id,
            };
          });
          setCities(options);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setLoadingCities(false);
        toastError(res.message);
      }
    );
  }, [selectedGovernmentId]);

  useEffect(() => {
    // if (rowData && !rowData.districtId) {
    if (rowData) {
      setSelectdGovernmentId(rowData.governmentId);
      getGovernmentsForList(
        (res) => {
          if (res.success) {
            const options = res.data.map((ele) => {
              return {
                label: ele.name,
                value: ele.id,
              };
            });
            setGovernments(options);
          } else {
            toastError(res.message);
          }
        },
        (res) => {
          toastError(res.message);
        }
      );
      if (useAPI) {
        getSocialOrderAddressDetailsById(
          rowData.requestId,
          (res) => {
            setLoading(false)
            if (res.success) {
              setDataSource(res.data)
              form.setFieldsValue({
                government: res.data.governmentId,
                district: res.data.districtId,
                address: res.data.address,
                street: res.data.street,
                landmark: res.data.landmark,
                buildingNo: res.data.buildingNo,
                floorNo: res.data.floorNo,
                apartmentNo: res.data.apartmentNo,
              });
            } else {
              toastError(res.message)
            }
          },
          (res) => {
            setLoading(false)
            toastError(res?.data?.error)
          }
        )
      } else {
        const details = {
          government: rowData.governmentId,
          district: rowData.districtId,
          address: rowData.address,
          street: rowData.street,
          landmark: rowData.landmark,
          buildingNo: rowData.buildingNo,
          floorNo: rowData.floorNo,
          apartmentNo: rowData.apartmentNo,
        }
        setDataSource(rowData)
        form.setFieldsValue(details);
      }
    }
  }, [rowData]);

  return (
    <Modal
      title={subHeader}
      open={open}
      width={window.innerWidth >= 992 ? "90%" : "98%"}
      className="msg-modal social-orders__view-details"
      footer={false}
      closable={false}
      centered
      destroyOnClose
      onCancel={onCancel}
    >
      <Form
        initialValues={initialValues}
        // onFinish={handleSubmit}
        form={form}
      >
        <GridTable
          columns={columns}
          dataSource={[dataSource]}
          rowId={"requestId"}
          bordered={true}
          loading={loading}
        />
      </Form>
    </Modal>
  );
};

export default AddressDetails;
