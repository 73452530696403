import { useEffect, useState } from "react";
import { Col, Form, Input, InputNumber, Modal, Row, Select, Space, Switch } from "antd";
import parseMax, { isValidPhoneNumber } from "libphonenumber-js/max";
import { toastError, toastSuccess } from "../../helpers/toasters";
import { addPartnership, editPartnership, getCitiesForList, getGovernmentsForList } from "../../apis/Network";
import { handleAllowNumbersOnly } from "../../helpers/helpfulFunctions";

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";
import TextArea from "antd/es/input/TextArea";

const AddEditPartnership = ({
    open,
    mode,
    rowData = {},
    SubHeader,
    primaryBtnName,
    secondaryBtnName,
    onSubmit,
    onCancel,
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [governments, setGovernments] = useState(null);
    const [cities, setCities] = useState(null);
    const [selectedGovernment, setSelectedGovernment] = useState("");
    const [firstRender, setFirstRender] = useState(true);
    const [form] = Form.useForm();
    const initialValues = {
        name: "",
        mobile: "",
        government: undefined,
        area: undefined,
        street: "",
        building: "",
        floor: "",
        apartment: "",
        landmark: "",
        isActive: true
    };

    const handlePhoneValidation = (_, value) => {
        if (!value) {
            return Promise.reject(new Error("Please, enter a valid mobile number."));
        } else if (
            !/^\d+$/.test(value) ||
            value.length > 12 ||
            !isValidPhoneNumber(value, "EG")
        ) {
            return Promise.reject(new Error("Please, enter a valid mobile number."));
        } else if (parseMax(value, "EG").getType() !== "MOBILE") {
            return Promise.reject(new Error("Please, enter a valid mobile number."));
        }
        return Promise.resolve();
    }

    const handleAddPartnership = (payload) => {
        addPartnership(
            payload,
            (res) => {
                setSubmitting(false);
                if (res.success) {
                    toastSuccess(res.message);
                    onSubmit();
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setSubmitting(false);
                toastError(res?.data?.error);
            }
        );
    };

    const handleEditPartnership = (payload) => {
        editPartnership(
            payload,
            (res) => {
                setSubmitting(false);
                if (res.success) {
                    toastSuccess(res.message);
                    onSubmit();
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setSubmitting(false);
                toastError(res?.data?.error);
            }
        );
    };

    const handleFinish = (values) => {
        if (submitting) return;
        setSubmitting(true);

        const payload = {
            name: values.name.trim(),
            mobile: values.mobile,
            districtId: values.area,
            street: values.street.trim(),
            buildingNo: values.building.trim(),
            floorNo: `${values.floor}`,
            apartmentNo: `${values.apartment}`,
            landmark: values.landmark.trim(),
            isActive: values.isActive
        };

        if (mode === "add") {
            handleAddPartnership(payload);
        } else {
            payload.id = rowData.id;
            handleEditPartnership(payload);
        }
    };

    useEffect(() => {
        if (mode === "edit" && !rowData) return
        getGovernmentsForList((res) => {
            if (res.success) {
                const list = res.data.map((ele) => ({ value: ele.id, label: ele.name }))
                setGovernments(list)
                if (mode === "edit") {
                    form.setFieldValue("government", rowData.governmentId)
                }
            } else {
                toastError(res.message)
            }
        }, (res) => {
            toastError(res?.data?.error)
        })
    }, [])

    useEffect(() => {
        if (!selectedGovernment) return
        getCitiesForList(
            selectedGovernment,
            (res) => {
                if (res.success) {
                    const list = res.data.map((ele) => ({ value: ele.id, label: ele.name }))
                    setCities(list)
                    if (firstRender && mode === "edit" && !form.getFieldValue("area")) {
                        form.setFieldValue("area", rowData.districtId)
                        setFirstRender(false)
                    }
                } else {
                    toastError(res.message)
                }
            }, (res) => {
                toastError(res?.data?.error)
            })
    }, [selectedGovernment])

    useEffect(() => {
        if (rowData && mode === "edit") {
            form.setFieldsValue({
                name: rowData.name,
                mobile: rowData.mobile,
                // government: rowData.governmentId,
                // area: rowData.districtId,
                street: rowData.street,
                building: rowData.buildingNo,
                floor: rowData.floorNo,
                apartment: rowData.apartmentNo,
                landmark: rowData.landmark,
                isActive: rowData.isActive,
            });
            setSelectedGovernment(rowData.governmentId)
        }
    }, [rowData, mode, form]);

    return (
        <Modal
            title={SubHeader}
            open={open}
            className="msg-modal"
            footer={false}
            closable={false}
            centered
            destroyOnClose
        >
            <Form
                form={form}
                className="edit-actor__content"
                onFinish={handleFinish}
                initialValues={initialValues}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2">
                                Company Name
                            </label>
                            <Form.Item
                                className=" new-category__form-item"
                                name="name"
                                validateFirst
                                rules={[
                                    {
                                        required: true,
                                        message: "Please, enter a company name.",
                                    },
                                    {
                                        pattern: /^[a-z\d]/i,
                                        message: "Please, enter a valid name",
                                    },
                                    {
                                        min: 2,
                                        message: "Minimum length is 2 characters",
                                    },
                                    {
                                        pattern: /^[a-z\s\d]+[a-z\d]$/i,
                                        message: "Please, enter a valid name",
                                    },
                                    // {
                                    //     max: 10,
                                    //     message: "Maximum length is 10 characters",
                                    // },
                                ]}
                            >
                                <Input
                                    type="text"
                                    className="search__searchField py-2"
                                    placeholder="Enter company name"
                                // maxLength={10}
                                // showCount
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2">
                                Mobile Number
                            </label>
                            <Form.Item
                                className="new-category__form-item"
                                name="mobile"
                                validateFirst
                                rules={[
                                    { validator: handlePhoneValidation },
                                ]}
                            >
                                <Input
                                    type="text"
                                    className="search__searchField py-2"
                                    placeholder="Enter mobile number"
                                    onKeyDown={handleAllowNumbersOnly}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2">
                                Government
                            </label>
                            <Form.Item
                                className="new-category__form-item"
                                name="government"
                                rules={[
                                    { required: true, message: "Please, select a government." }
                                ]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="label"
                                    name="government"
                                    className="new-category__form-item"
                                    placeholder="Select government"
                                    onChange={(value) => {
                                        setCities(null)
                                        setSelectedGovernment(value)
                                        form.resetFields(["area"])
                                    }}
                                    loading={governments ? false : true}
                                    options={governments ? governments : []}
                                    getPopupContainer={trigger => trigger.parentElement}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2">
                                Area
                            </label>
                            <Form.Item
                                className="new-category__form-item"
                                name="area"
                                rules={selectedGovernment ? [
                                    { required: true, message: "Please, select a city." }
                                ] : []}
                            >
                                <Select
                                    showSearch
                                    disabled={!selectedGovernment}
                                    optionFilterProp="label"
                                    name="area"
                                    className="new-category__form-item"
                                    placeholder="Select city"
                                    loading={cities || !selectedGovernment ? false : true}
                                    options={cities ? cities : []}
                                    getPopupContainer={trigger => trigger.parentElement}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2">
                                Street
                            </label>
                            <Form.Item
                                className="new-category__form-item"
                                name="street"
                                validateFirst
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: "Please, enter your street name.",
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    className="search__searchField py-2"
                                    placeholder="Enter street"
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2">
                                Building No.
                            </label>
                            <Form.Item
                                className="new-category__form-item"
                                name="building"
                                validateFirst
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: "Please, enter your building number.",
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    className="search__searchField py-2"
                                    placeholder="Enter building"
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2">
                                Floor No.
                            </label>
                            <Form.Item
                                className="new-category__form-item"
                                name="floor"
                                validateFirst
                                rules={[
                                    {
                                        whitespace: true,
                                        pattern: /\d+/,
                                        message: "Please, enter a valid number",
                                    },
                                ]}
                            >
                                <InputNumber
                                    className="w-100 d-block search__searchField py-1"
                                    placeholder="Enter floor"
                                    onKeyDown={(e) => handleAllowNumbersOnly(e, false)}
                                    controls={false}
                                    keyboard={false}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2">
                                Apartment No.
                            </label>
                            <Form.Item
                                className="new-category__form-item"
                                name="apartment"
                                validateFirst
                                rules={[
                                    {
                                        whitespace: true,
                                        pattern: /\d+/,
                                        message: "Please, enter a valid number",
                                    },
                                ]}
                            >
                                <InputNumber
                                    className="w-100 d-block search__searchField py-1"
                                    placeholder="Enter apartment"
                                    onKeyDown={(e) => handleAllowNumbersOnly(e, false)}
                                    controls={false}
                                    keyboard={false}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <label className="users__parent-info-label mb-2">
                                Nearest Landmark
                            </label>
                            <Form.Item
                                className="new-category__form-item"
                                name="landmark"
                                validateFirst
                                rules={[
                                    {
                                        whitespace: true,
                                        message: "Please, enter a valid landmark",
                                    },
                                ]}
                            >
                                <TextArea
                                    autoSize={{ minRows: 2, maxRows: 6 }}
                                    placeholder="Enter landmark"
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name mb-4 row mx-0 align-items-center">
                            <div className="col-4 px-0 text-start">
                                <label className="users__parent-info-label">Status</label>
                            </div>
                            <div className="col-8 px-0 d-flex">
                                <Form.Item
                                    name="isActive"
                                    valuePropName="checked"
                                    className="mb-0"
                                >
                                    <Switch />
                                </Form.Item>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mrg-ys-20 mrg-x-auto justify-content-center">
                    <Space size={20}>
                        <SecondaryButton buttonName={secondaryBtnName} onClick={onCancel} />
                        <PrimaryButton
                            buttonName={primaryBtnName}
                            loading={submitting}
                            type="submit"
                        />
                    </Space>
                </Row>
            </Form>
        </Modal>
    );
}

export default AddEditPartnership;