import { useEffect, useState } from "react";
import moment from "moment";

const ChatDateSeperator = ({ date }) => {
    const [shownDate, setShownDate] = useState("");

    useEffect(() => {
        if (date) {
            const now = moment();
            const end = moment(date, "YYYY-MM-DDTHH:mm:ssZZ");
            const yesterdayDate = now.clone().subtract(1, 'days').startOf('day')
            const isYesterday = end.isSame(yesterdayDate, "d");
            const diffInDays = now.clone().diff(end, "days")
            let shownTime = ""
            if (diffInDays > 0 && !isYesterday) {
                shownTime = moment(date, "YYYY-MM-DDTHH:mm:ssZZ").format("DD-MM-YYYY");
            } else if (isYesterday) {
                shownTime = "Yesterday"
            } else {
                shownTime = "Today";
            }
            setShownDate(shownTime)
        }
    }, [date])

    return (
        <p className='chat__chat-holder__body__day-date text-center mx-auto'>
            {shownDate}
        </p>
    );
}

export default ChatDateSeperator;