import { Button } from "antd";

function PrimaryButton({ buttonName, loading, onClick, type, disableMe }) {
  return (
    <Button
      htmlType={type ? type : "button"}
      loading={loading}
      onClick={onClick}
      className="primary-popup-button"
      disabled={disableMe}
    >
      {buttonName}
    </Button>
  );
}

export default PrimaryButton;
