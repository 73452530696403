import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import ROUTES from "../../constants/NewRoutes";
import { ProtectedRoutes } from "./ProtectedRoutes";

// import Orders from "../Orders/Orders";
// import Items from "../Items/Items";
// import PendingOrders from "../Orders/PendingOrders";
// import ScrappersChecks from "../ScrappersChecks";
// import ScrappersSalesRequests from './../ScrappersSalesRequests/index';
// import RobabekiaSalesRequests from './../RobabekiaSalesRequests/index';
import SignIn from "../Authentications/SignIn";
// import Logout from "../Authentications/Logout";
import Admins from "../Admins/Admins";
import Notfound from "../Notfound/Notfound";
import NotHaveAccess from "../NotHaveAccess/Notfound";
import Stores from "../Stores";
import Settings from "../Settings";
import RedeemPointsRequests from "../RedeemPointsRequests";
import Scrappers from "./../Scrappers/index";
import Types from "./../Categories/index";
import CansSalesRequests from "../CansSalesRequests";
import SupplyingTypes from "../SupplyingTypes";
import AcceptedRequestsReport from "../AcceptedRequestsReport";
import HomeBanner from "../HomeBanner";
import SocialOrders from "../SocialOrders";
import Courier from "../Courier";
import CourierReport from "../CourierReport";
import Government from "../Government";
import City from "../City";
import DoDontImages from "../DoDontImages";
import StoreProducts from "../StoreItems";
import StoreCategories from './../StoreCategories/index';
import StoreSubcategories from './../StoreSubcategories/index';
import Notification from "../Notification";
import Partners from "../Partners";
import MarketplaceBanner from "../MarketplaceBanner";
// import DefineArea from "../DefineArea";
import TodayCourierOrders from "../TodayCourierOrders/index";
import DailyCouriers from './../TodayCourierOrders/DailyCouriers';
import CourierCompletedOrders from "../TodayCourierOrders/CourierCompletedOrders";
import Partnership from "../Partnership";
import Chat from './../Chat';
import MerchantSalesRequests from "../MerchantSalesRequests";
import SalesRequestsReturns from '../SalesRequestsReturns';
import MerchantPeriods from "../MerchantPeriods";
import MerchantScrapTypes from "../MerchantScrapTypes";

function RoutesFile() {
  const { isAuthorized } = useAuth();

  return (
    <Routes>
      <Route path={ROUTES.SIGNIN} element={<SignIn />} />
      <Route element={<ProtectedRoutes allowedRoles={[1, 9]} />}>
        {/* <Route path={ROUTES.ITEMS} element={<Items />} /> */}
        {/* <Route path={ROUTES.PENDINGORDERS} element={<PendingOrders />} /> */}
        {/* <Route path={ROUTES.Orders} element={<Orders />} /> */}
        {/* <Route path={ROUTES.DEALERSCHECKS} element={<ScrappersChecks />} /> */}
        <Route path={ROUTES.ADMINS} element={<Admins />} />
        <Route path={ROUTES.STORES} element={<Stores />} />
        <Route path={ROUTES.SETTINGS} element={<Settings />} />
        <Route path={ROUTES.SUPPLYING_TYPES} element={<SupplyingTypes />} />
        <Route path={ROUTES.HOME_BANNER} element={<HomeBanner />} />
        <Route path={ROUTES.COURIER} element={<Courier />} />
        <Route path={ROUTES.GOVERNMENT} element={<Government />} />
        <Route path={ROUTES.CTIY} element={<City />} />
        <Route path={ROUTES.DO_DONT_IMAGES} element={<DoDontImages />} />
        <Route path={ROUTES.STORE_PRODUCTS} element={<StoreProducts />} />
        <Route path={ROUTES.STORE_CATEGORIES} element={<StoreCategories />} />
        <Route path={ROUTES.STORE_SUBCATEGORIES} element={<StoreSubcategories />} />
        <Route path={ROUTES.MARKETPLACE_BANNER} element={<MarketplaceBanner />} />
        <Route path={ROUTES.PARTNERS} element={<Partners />} />
        <Route path={ROUTES.NOTIFICATION} element={<Notification />} />
        <Route path={ROUTES.MERCHANT_SALES_REQUESTS} element={<MerchantSalesRequests />} />
        <Route path={ROUTES.SALES_REQUESTS_RETURNS} element={<SalesRequestsReturns />} />
        <Route path={ROUTES.MERCHANT_PERIODS} element={<MerchantPeriods />} />
        <Route path={ROUTES.MERCHANT_SCRAP_TYPES} element={<MerchantScrapTypes />} />
        {/* <Route path={ROUTES.DEFINE_AREA} element={<DefineArea />} /> */}
        {/* <Route path={ROUTES.DEFINEAREA} element={<DefineArea />} /> */}
      </Route>
      <Route element={<ProtectedRoutes allowedRoles={[1, 7, 9]} />}>
        {/* <Route path={ROUTES.SALESREQUESTS} element={<ScrappersSalesRequests />} /> */}
        {/* <Route path={ROUTES.SCRAPPERSSALESREQUESTS} element={<RobabekiaSalesRequests />} /> */}
        {/* <Route path={ROUTES.CATEGORIES} element={<Types />} /> */}
      </Route>
      <Route element={<ProtectedRoutes allowedRoles={[1, 9, 12, 15]} />}>
        <Route path={ROUTES.REDEEM_POINTS_REQUESTS} element={<RedeemPointsRequests />} />
      </Route>
      <Route element={<ProtectedRoutes allowedRoles={[1, 9, 10, 12, 14, 15]} />}>
        <Route path={ROUTES.SOCIAL_ORDERS} element={<SocialOrders />} />
      </Route>
      <Route element={<ProtectedRoutes allowedRoles={[1, 9, 10, 14]} />}>
        <Route path={ROUTES.DAILY_COURIES} element={<TodayCourierOrders />}>
          <Route path="" element={<DailyCouriers />} />
          <Route path={`${ROUTES.DAILY_COURIES}${ROUTES.COMPLETED_ORDERS}`} element={<CourierCompletedOrders />} />
        </Route>
      </Route>
      <Route element={<ProtectedRoutes allowedRoles={[1, 9, 11, 12, 15]} />}>
        <Route
          path={ROUTES.SCRAP_SALES_REQUESTS}
          element={<CansSalesRequests />}
        />
      </Route>
      <Route element={<ProtectedRoutes allowedRoles={[1, 9, 11]} />}>
        <Route path={ROUTES.DEALERS} element={<Scrappers />} />
        <Route
          path={ROUTES.ACCEPTED_REQUESTS_REPORT}
          element={<AcceptedRequestsReport />}
        />
        <Route path={ROUTES.COURIERREPORT} element={<CourierReport />} />
      </Route>
      <Route element={<ProtectedRoutes allowedRoles={[1, 9, 14]} />}>
        <Route path={ROUTES.PARTNERSHIP} element={<Partnership />} />
      </Route>
      <Route element={<ProtectedRoutes allowedRoles={[1, 9, 15]} />}>
        <Route path={ROUTES.CHAT} element={<Chat />} />
      </Route>
      <Route path={ROUTES.NOT_HAVE_ACCESS} element={<NotHaveAccess />} />
      <Route
        path={ROUTES.NOT_FOUND}
        element={isAuthorized ? <Notfound /> : <Navigate to={ROUTES.SIGNIN} />}
      />
      {/* <Route path={ROUTES.LOGOUT} element={<Logout />} /> */}
    </Routes>
  );
}

export default RoutesFile;
