import { useEffect, useRef, useState } from "react";
import { Button, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { getAllCitiesForAdmin } from "../../apis/Network";
import { toastError } from "../../helpers/toasters";

// components
import GridTable from "../SubComponents/GridTable/GridTable";
import MainHeader from "../SubComponents/MainHeader";
import SubHeader from "../SubComponents/SubHeader";
import AddEditModal from "./AddEditModal";

const City = () => {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [rowData, setRowData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [renderMe, setRenderMe] = useState(false);
  const [mode, setMode] = useState("add");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setMode("add");
  };
  const handleCancelModal = () => {
    setIsModalOpen(false);
    setRowData(null);
  };
  const handleSubmit = () => {
    setRowData(null);
    setIsModalOpen(false);
    setMode("add");
    setRenderMe(!renderMe);
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          ></Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      key: "govenmentName",
      title: "GOVERNMENT",
      dataIndex: "govenmentName",
      ...getColumnSearchProps("govenmentName"),
    },
    {
      key: "nameEn",
      title: "AREA NAME(EN)",
      dataIndex: "nameEn",
      ...getColumnSearchProps("nameEn"),
    },
    {
      key: "nameAr",
      title: "AREA NAME(AR)",
      dataIndex: "nameAr",
      ...getColumnSearchProps("nameAr"),
    },
    {
      key: "isActive",
      title: "STATUS",
      dataIndex: "isActive",
      filters: [
        { text: "Active", value: true },
        { text: "Inactive", value: false },
      ],
      onFilter: (value, record) => record?.isActive === value,
      render: (_, row) => {
        return <span>{row.isActive ? "Active" : "Inactive"}</span>;
      },
    },
    {
      key: "requestId",
      title: "ACTIONS",
      fixed: "right",
      render: (_, row) => {
        return (
          <div>
            <Space size={5} className="mb-2">
              <Button
                className="border-0"
                onClick={() => {
                  setMode("edit");
                  console.log("row", row);
                  setRowData(row);
                  setIsModalOpen(true);
                }}
                style={{ backgroundColor: "#589559", color: "white" }}
              >
                Edit
              </Button>
            </Space>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getAllCitiesForAdmin(
      (res) => {
        setLoading(false);
        if (res.success) {
          setDataSource(res.data);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setLoading(false);
        toastError(res.message);
      }
    );
  }, [renderMe]);

  return (
    <section className="users">
      <div className="users__all-contents">
        <div className="users__content">
          <div className="users__usersData">
            <div className="pb-3 mb-3">
              <MainHeader
                title="Areas"
                buttonName={"Add Area"}
                onButtonClick={handleOpenModal}
              />
            </div>
            <div className="users__table grid-table mrg-y-30">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                rowId={"requestId"}
                // allowExport
              />
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <AddEditModal
          open={isModalOpen}
          rowData={rowData}
          mode={mode}
          primaryBtnName={mode === "add" ? "Add" : "Save"}
          subHeader={
            <SubHeader
              bgColor="#589559"
              title={`${mode === "add" ? "Add" : "Edit"} Area`}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          onSubmit={handleSubmit}
          onCancel={handleCancelModal}
        />
      )}
    </section>
  );
};

export default City;
