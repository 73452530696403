import { useState, useEffect } from "react";
import { Col, Form, InputNumber, Input, Modal, Row, Space, DatePicker } from "antd";
import moment from "moment";
import { addCheck } from "../../apis/Network";
import { toastError, toastSuccess } from "../../helpers/toasters";

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";

const AddCheckModal = ({
    open,
    SubHeader,
    primaryBtnName,
    secondaryBtnName,
    onCancel,
    action,
    rowData,
    onAdd
}) => {
    const [submittable, setSubmittable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [minCollectioDate, setMinCollectionDate] = useState(moment().add(1, "day"))
    const [form] = Form.useForm();
    const values = Form.useWatch([], form);
    const initialValues = {
        checkNumber: "",
        deliveredDate: "",
        collectionDate: "",
        checkValue: "",
    }

    const handleOnCancel = () => {
        setLoading(false);
        onCancel();
    };

    const handlePreventLetters = (e) => {
        if (e.key === "Backspace" || (e.key.startsWith("Arrow") && (e.key.startsWith("ArrowLeft") || e.key.startsWith("ArrowRight")))) return;
        if (/\D+/gi.test(e.key)) {
            e.preventDefault();
        }
    };

    const handlePreventSpecialChars = (e) => {
        if (e.key === "Backspace") return;
        if (/[\W_]+/gi.test(e.key)) {
            e.preventDefault();
        }
    }

    const handleDeliveredDate = (date) => {
        setMinCollectionDate(date)
        if (date.isAfter(minCollectioDate)) {
            form.resetFields(["collectionDate"])
        }
    }

    const handleOnFinish = (values) => {
        if (loading || !submittable) return;
        setLoading(true);

        const payload = {
            dealerId: rowData.dealerId,
            collectionDate: moment(values.collectionDate.toString()).toISOString(true).split(".")[0],
            receivingDate: moment(values.deliveredDate.toString()).toISOString(true).split(".")[0],
            checkValue: values.checkValue,
            checkNumber: values.checkNumber,
        }

        addCheck(
            payload,
            (res) => {
                setLoading(false);
                if (res.success) {
                    onAdd();
                    toastSuccess(res.message)
                } else {
                    toastError(res.message)
                }
            }, (res) => {
                setLoading(false);
                toastError(res.message)
            }
        )
    }

    useEffect(() => {
        form
            .validateFields({
                validateOnly: true,
            })
            .then(
                () => {
                    setSubmittable(true);
                },
                () => {
                    setSubmittable(false);
                }
            );
    }, [values, form]);

    return (
         (
            <Modal
                title={SubHeader}
                open={open}
                className="msg-modal"
                footer={false}
                centered
                destroyOnClose
                closable={false}
            >
                <Form
                    form={form}
                    className="edit-actor__content"
                    onFinish={handleOnFinish}
                    initialValues={initialValues}
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <div className="lang-name wd-100 flex ">
                                    <label className="users__parent-info-label mb-2" htmlFor="">
                                        Check Number
                                    </label>
                                </div>
                                <Form.Item
                                    className="new-category__form-item"
                                    name="checkNumber"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Check Number is required",
                                        },
                                        {
                                            pattern: /^[a-z0-9]+$/gi,
                                            message: "Invalid Check Number"
                                        }
                                    ]}
                                >
                                    <Input
                                        className="w-100 d-block search__searchField py-2"
                                        placeholder="Enter Check Number"
                                        onKeyDown={handlePreventSpecialChars}
                                        controls={false}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <div className="lang-name wd-100 flex ">
                                    <label className="users__parent-info-label mb-2" htmlFor="">
                                        Delivered Date
                                    </label>
                                </div>
                                <Form.Item
                                    className="new-category__form-item  d-flex justify-content-start mb-2"
                                    name="deliveredDate"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Delivered Date is required",
                                        }
                                    ]}
                                >
                                    <DatePicker
                                        getPopupContainer={(triggerNode) => {
                                            return triggerNode.parentNode;
                                        }}
                                        onChange={handleDeliveredDate}
                                        onKeyDown={(e) => e.preventDefault()}
                                        disabledDate={(current) => current.isBefore(moment().subtract(1, "day"))}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <div className="lang-name wd-100 flex ">
                                    <label className="users__parent-info-label mb-2" htmlFor="">
                                        Collection Date
                                    </label>
                                </div>
                                <Form.Item
                                    className="new-category__form-item"
                                    name="collectionDate"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Collection Date is required",
                                        }
                                    ]}
                                >
                                    <DatePicker
                                        getPopupContainer={(triggerNode) => {
                                            return triggerNode.parentNode;
                                        }}
                                        disabledDate={(current) => current.isBefore(minCollectioDate)}
                                        onKeyDown={(e) => e.preventDefault()}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <div className="lang-name wd-100 flex ">
                                    <label className="users__parent-info-label mb-2" htmlFor="">
                                        Check Value
                                    </label>
                                </div>
                                <Form.Item
                                    className="new-category__form-item"
                                    name="checkValue"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Check Value is required",
                                        },
                                        {
                                            pattern: /^[0-9]+$/gi,
                                            message: "Invalid Check Value"
                                        }
                                    ]}
                                >
                                    <InputNumber
                                        className="w-100 d-block search__searchField py-2"
                                        placeholder="Enter Check Value"
                                        onKeyDown={handlePreventLetters}
                                        controls={false}
                                        keyboard={false}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mrg-ys-20 mrg-x-auto mt-4 justify-content-center">
                        <Space size={20}>
                            <SecondaryButton
                                buttonName={secondaryBtnName}
                                onClick={handleOnCancel}
                            />
                            <PrimaryButton
                                buttonName={primaryBtnName}
                                loading={loading}
                                onClick={action}
                                type="submit"
                                disableMe={!submittable || loading}
                            />
                        </Space>
                    </Row>
                </Form>
            </Modal>
        )
    );
}

export default AddCheckModal;