import { useSelector } from "react-redux";

export const useAuth = () => {
  // const adminRoles = [1]
  const {
    token: isAuthorized,
    roles: adminRoles,
  } = useSelector((state) => state.auth.authorization);

  return { isAuthorized, adminRoles };
};
