import { useEffect, useMemo, useRef, useState } from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import { toastError } from '../../../helpers/toasters';

// components
import ChangeView from "./ChangeView";

const MapPicker = ({ onAddressChange, mode, initialCoords, disableControllers }) => {
    const [markerPosition, setMarkerPosition] = useState({ lat: "", lng: "" })
    const [center, setCenter] = useState(["", ""])
    const [zoom, setZoom] = useState(!disableControllers ? 13 : 15)
    const markerRef = useRef(null)
    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    setMarkerPosition(marker.getLatLng())
                }
            }
        }), [])

    useEffect(() => {
        if (!disableControllers) {
            if (markerPosition.lat || markerPosition.lat === 0) {
                if ((markerPosition?.lat >= -90 && markerPosition?.lat <= 90) && (markerPosition?.lng >= -180 && markerPosition?.lng <= 180)) {
                    onAddressChange(markerPosition)
                } else {
                    toastError("Please, select a valid location")
                }
            }
        }
    }, [markerPosition])

    useEffect(() => {
        const timerId = setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 1000);
        return () => clearTimeout(timerId)
    }, []);

    useEffect(() => {
        if (mode === "add") {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        setMarkerPosition({ lat: position.coords.latitude, lng: position.coords.longitude })
                        setCenter([position.coords.latitude, position.coords.longitude])
                    },
                    (error) => {
                        toastError(error.message)
                    },
                    {
                        enableHighAccuracy: true,
                        maximumAge: 2000,
                        timeout: 5000
                    }
                )
            }
        } else if (initialCoords) {
            setMarkerPosition(initialCoords)
            setCenter([initialCoords.lat, initialCoords.lng])
        }
    }, [initialCoords, mode])

    return (
        <MapContainer
            style={{ height: "200px" }}
            center={center}
            zoom={!disableControllers ? zoom : 15}
            scrollWheelZoom={!disableControllers ? true : false}
            worldCopyJump={true}
            zoomControl={!disableControllers}
            dragging={!disableControllers}
        >
            <ChangeView
                center={center}
                zoom={zoom}
                disableControllers={disableControllers}
                onMarkerChange={(latLng, zoomValue) => {
                    setCenter([latLng.lat, latLng.lng])
                    setMarkerPosition(latLng)
                    setZoom(!disableControllers ? zoomValue : 15)
                }}
            />
            <TileLayer
                // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                noWrap
            />
            <Marker
                draggable={!disableControllers}
                eventHandlers={eventHandlers}
                position={markerPosition}
                ref={markerRef}
            />
        </MapContainer>
    );
}

export default MapPicker;