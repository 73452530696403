const ChecksDetails = ({ data }) => {
    return (
        <div className="dealers__balance-details-modal__holder d-flex flex-column gap-3">
            {data ? (
                data.length > 0 ?
                    data.map((check) => (
                        <div key={check.checkNo} className="dealers__balance-details-modal__check-holder p-3">
                            <h3 className="fs-6 d-flex align-items-center gap-2 justify-content-center">
                                <span>Check Number:</span>
                                <span>{check.checkNo}</span>
                            </h3>
                            <div className="d-flex align-items-center gap-1 mb-2">
                                <span className="dealers__balance-details-modal__check-holder__label">Check value:</span>
                                <span className="dealers__balance-details-modal__check-holder__value">{check.checkValue} EGP</span>
                            </div>
                            <div className="d-flex align-items-center gap-1 mb-2">
                                <span className="dealers__balance-details-modal__check-holder__label">Delivered date:</span>
                                <span className="dealers__balance-details-modal__check-holder__value">{check.recievingDate}</span>
                            </div>
                            <div className="d-flex align-items-center gap-1 mb-2">
                                <span className="dealers__balance-details-modal__check-holder__label">Collection date:</span>
                                <span className="dealers__balance-details-modal__check-holder__value">{check.collectionDate}</span>
                            </div>
                        </div>
                    )) : (
                        <p className="dealers__balance-details-modal__empty-msg text-center fw-bold py-4 mb-0">
                            Merchant doesn't have any checks!
                        </p>
                    )) : (
                ""
            )}
        </div>
    );
}

export default ChecksDetails;