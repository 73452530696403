import { useEffect, useState } from "react";
import { Button, Image, Space } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const PreviewUploadedImages = ({ files, onUpload, onCancel }) => {
  const [preparePreviews, setPreparePreviews] = useState(false);
  const [previewImages, setPreviewImages] = useState(null);

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    return file.url || file.preview;
  };

  useEffect(() => {
    if (files?.length) {
      setPreparePreviews(true);
    }
  }, [files]);

  // Upload input calls onChange as many times as the number of files being uploaded
  // so uploaded images state would be updated many times hence files prop here will
  // be updated as many times as well, that's why I wrote code this way
  // Now preparing the preview files will happen one time only
  useEffect(() => {
    if (preparePreviews) {
      (async () => {
        const previewsArr = [];
        for (let i = 0; i < files.length; i++) {
          let preview = await handlePreview(files[i]);
          previewsArr.push(preview);
        }
        setPreviewImages(previewsArr);
      })();
    }
  }, [preparePreviews]);

  return (
    <Image.PreviewGroup
      preview={{
        zIndex: 1702,
        rootClassName: "chat__chat-holder__footer__upload-preview-holder",
        visible: true,
        maskClosable: false,
        keyboard: false,
        onVisibleChange: onCancel,
        toolbarRender: () => (
          <Space size={12} className="toolbar-wrapper">
            <Button
              onClick={onUpload}
              className="chat__chat-holder__footer__upload-preview-holder__preview-upload-btn bg--secondary-100 text--white fw-bold border-0 outline-none shadow-none h-auto"
            >
              <UploadOutlined />
            </Button>
          </Space>
        ),
      }}
    >
      {previewImages?.map((ele) => (
        <Image key={ele} width={0} height={0} src={ele} alt="" />
      ))}
    </Image.PreviewGroup>
  );
};

export default PreviewUploadedImages;
