import { Image, Modal } from "antd";
import dayjs from "dayjs";
import { baseUrl } from "../../../apis/services";

// components
import GridTable from "../../SubComponents/GridTable/GridTable";
import SubHeader from "../../SubComponents/SubHeader";

const ViewCarImages = ({ data, open, onCancel }) => {
    const columns = [
        {
            key: "image",
            title: "IMAGE",
            dataIndex: "image",
            render: (_, row) => {
                return (
                    <Image
                        src={baseUrl + row.image}
                        width={70}
                        height={50}
                        preview={{ src: baseUrl + row.image }}
                    />
                )
            },
        },
        {
            key: "date",
            title: "DATE",
            dataIndex: "date",
            render: (_, row) => {
                return (
                    new dayjs(row.date).format("YYYY-MM-DD HH:mm A")
                )
            },
        },
    ];

    return (
        <Modal
            title={
                <SubHeader
                    bgColor="#589559"
                    title={`View All Car Images`}
                    closeButton
                    onCancel={onCancel}
                />
            }
            open={open}
            width={window.innerWidth >= 992 ? "66%" : "90%"}
            className="msg-modal"
            footer={false}
            closable={false}
            centered
            destroyOnClose
            onCancel={onCancel}
        >
            <GridTable
                columns={columns}
                dataSource={data}
                rowId={"date"}
            />
        </Modal>
    );
}

export default ViewCarImages;