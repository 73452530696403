import { useEffect, useState } from "react";
import { Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

// components
import RoomsSearch from "../ChatsAside/RoomsSearch";

// assets
import userIcon from "assets/images/default-user.png"

const ChatHeader = ({
    data,
    onSearch,
    form,
    searchValue
}) => {
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [uncollapse, setUncollapse] = useState(false);

    useEffect(() => {
        if (showSearchBar) {
            const timerId = setTimeout(() => {
                setUncollapse(true)
            }, 0)
            return () => {
                clearTimeout(timerId)
            }
        }
    }, [showSearchBar])

    useEffect(() => {
        if (!uncollapse) {
            const timerId = setTimeout(() => {
                setShowSearchBar(false)
            }, 200)
            return () => {
                clearTimeout(timerId)
            }
        }
    }, [uncollapse])

    return (
        <div className="chat__chat-holder__header px-2 px-md-3 py-2">
            <div className={`d-flex align-items-center justify-content-between gap-2 ${showSearchBar ? "mb-2" : ""}`}>
                <div className="d-flex align-items-center gap-2">
                    <img
                        src={userIcon}
                        alt=""
                        className="chat__chat-holder__header__user-icon d-block img-fluid"
                    />
                    <span className="fw-bold">
                        {data ? `${data.name} - ${data.mobile}` : ""}
                    </span>
                </div>
                <Button
                    htmlType="button"
                    onClick={() => {
                        if (!showSearchBar) {
                            setShowSearchBar(true)
                        } else {
                            setUncollapse(false)
                        }
                    }}
                    className="d-block bg-transparent border-0 outline-none shadow-none p-0 lh-1 h-auto"
                >
                    <SearchOutlined />
                </Button>
            </div>
            {showSearchBar ? (
                <div className={`chat__chat-holder__header__search-bar flex-fill ${uncollapse ? "show" : ""}`}>
                    <RoomsSearch
                        form={form}
                        focusCursor={true}
                        onSearch={onSearch}
                        searchValue={searchValue}
                        placeholder="Search..."
                    />
                </div>
            ) : (
                ""
            )}
        </div>
    );
}

export default ChatHeader;