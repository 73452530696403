import { useEffect, useState } from "react";
import { Col, Form, Modal, Row, Space, Switch, Select, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { addMarketplaceBanner, getActiveStoreCategories, editMarketplaceBanner } from "../../apis/Network";
import { toastError, toastSuccess } from "../../helpers/toasters";
import { baseUrl } from "../../apis/services";

// components
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";

const BannerModal = ({
    mode,
    rowData,
    open,
    subHeader,
    onCancel,
    onSubmit,
    primaryBtnName,
    canAdminMakeBannerActive,
    canAdminDelete
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [activeCategories, setActiveCategories] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [previewImage, setPreviewImage] = useState("");
    const [uploadedImage, setUploadedImage] = useState("");
    const [invalidImage, setInvalidImage] = useState(false);
    const [form] = Form.useForm();
    const maxSize = 5000000;
    const initialValues = {
        image: "",
        categoryId: undefined,
        isActive: true,
    }
    const props = {
        name: 'image',
        accept: ".png,.jpg,.jpeg,.bmp,.gif,.svg",
        maxCount: 1,
        showUploadList: false,
        onChange(info) {
            if (!info.file.type.startsWith("image")) {
                handleRemoveImage();
                form.setFields([
                    {
                        name: "image",
                        errors: ["Please upload a valid image file (e.g., .jpg, .png)"],
                        value: "",
                    }
                ])
            } else if (info.file.size > maxSize) {
                handleRemoveImage();
                form.setFields([
                    {
                        name: "image",
                        errors: ["The image file size should not exceed 5MB"],
                        value: "",
                    }
                ])
            } else {
                handlePreview(info.file)
            }
        },
    };

    const handleBeforeCrop = (file) => {
        if (!file.type.startsWith("image") || file.size > maxSize) {
            handleRemoveImage();
            return false;
        } else {
            return true
        }
    }

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handlePreview = async (file) => {
        setUploadedImage(file.originFileObj);
        form.setFields([{ name: "image", value: file.originFileObj, errors: [] }])
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
    };

    const handleRemoveImage = () => {
        setPreviewImage("");
        setUploadedImage("");
        setInvalidImage(true)
    };

    const handleFinish = (values) => {
        if (mode === "edit") {
            // stop admin from activating a banner when there're already 10 active banners
            if (!rowData.isActive && values.isActive && !canAdminMakeBannerActive) {
                toastError("The maximum number for active marketplace banners is 10")
                return;
            } else if (rowData.isActive && !values.isActive && !canAdminDelete) {
                // stop admin from deactivating a banner when there's only 1 active banner
                toastError("The minimum number for deactive marketplace banners is 1")
                return;
            }
        }
        if (submitting) return;
        setSubmitting(true);

        const payload = new FormData()
        const copyOfValues = { ...values };
        copyOfValues.categoryId = selectedCategory;
        for (const key in copyOfValues) {
            if (copyOfValues.hasOwnProperty(key)) {
                if (key === "image" && !uploadedImage) continue;
                payload.append(key, copyOfValues[key]);
            }
        }

        if (mode === "add") {
            addMarketplaceBanner(
                payload,
                (res) => {
                    setSubmitting(false);
                    if (res.success) {
                        toastSuccess(res.message);
                        onSubmit()
                    } else {
                        toastError(res.message);
                    }
                },
                (res) => {
                    setSubmitting(false);
                    toastError(res.message);
                })
        } else {
            payload.append("id", rowData.id);
            editMarketplaceBanner(
                payload,
                (res) => {
                    setSubmitting(false);
                    if (res.success) {
                        toastSuccess(res.message);
                        onSubmit()
                    } else {
                        toastError(res.message);
                    }
                },
                (res) => {
                    setSubmitting(false);
                    toastError(res.message);
                })
        }
    }

    useEffect(() => {
        getActiveStoreCategories((res) => {
            if (res.success) {
                const optionsArr = res.data.map((ele) => {
                    return {
                        label: ele.name,
                        value: ele.id
                    }
                })
                setActiveCategories(optionsArr);
            } else {
                toastError(res.message);
            }
        }, (res) => {
            toastError(res.message);
        })
    }, []);

    useEffect(() => {
        if (rowData && mode === "edit") {
            form.setFieldsValue({
                image: rowData.image,
                isActive: rowData.isActive,
            })
            setSelectedCategory(rowData.categoryId)
        }
    }, [mode, rowData])

    useEffect(() => {
        if (!rowData) return;
        if (activeCategories) {
            const categoryExists = activeCategories.find((category) => category.value === rowData.categoryId)
            if (!categoryExists) {
                form.setFieldValue("categoryId", rowData.category)
            } else {
                form.setFieldValue("categoryId", rowData.categoryId)
            }
        }
    }, [rowData, activeCategories])

    return (
        <Modal
            title={subHeader}
            open={open}
            className="msg-modal"
            footer={false}
            closable={false}
            centered
            destroyOnClose
            onCancel={onCancel}
        >
            <Form
                form={form}
                className="edit-actor__content pt-3"
                onFinish={handleFinish}
                initialValues={initialValues}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <div className="new-actor__name new-category mb-5">
                            <div className="lang-name wd-100 flex ">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Image
                                </label>
                            </div>
                            {previewImage ? (
                                <img src={previewImage} alt="" className="do-dont-images__add-form__preview-img d-block img-fluid mb-2" />
                            ) : mode === "edit" && !invalidImage ? (
                                <img src={baseUrl + rowData?.image} alt="" className="do-dont-images__add-form__preview-img d-block img-fluid mb-2" />
                            ) : (
                                ""
                            )}
                            <Form.Item
                                name="image"
                                rules={[
                                    { required: true, message: "Image is required, please upload a valid image" }
                                ]}
                                className="w-100"
                            >
                                <ImgCrop beforeCrop={handleBeforeCrop} fillColor="transparent" aspectSlider cropperProps={{ restrictPosition: false }} minZoom={0.5} rotationSlider>
                                    <Upload {...props}>
                                        Upload Image +
                                    </Upload>
                                </ImgCrop>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name mb-1 row mx-0 align-items-start">
                            <div className="col-4 px-0 text-start">
                                <label className="users__parent-info-label">Category</label>
                            </div>
                            <div className="col-8 px-0 d-flex">
                                <Form.Item
                                    name="categoryId"
                                    className="w-100"
                                    rules={[{ required: true, message: "Category is required, please select a category." }]}
                                >
                                    <Select
                                        className="new-category__form-item"
                                        name="category"
                                        placeholder="Select Category"
                                        loading={activeCategories ? false : true}
                                        options={activeCategories ? activeCategories : []}
                                        allowClear
                                        onChange={(value) => {
                                            setSelectedCategory(value)
                                        }}
                                        getPopupContainer={trigger => trigger.parentElement}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="new-actor__name mb-4 row mx-0 align-items-center">
                            <div className="col-4 px-0 text-start">
                                <label className="users__parent-info-label">Status</label>
                            </div>
                            <div className="col-8 px-0 d-flex">
                                <Form.Item
                                    name="isActive"
                                    valuePropName="checked"
                                    className="mb-0"
                                >
                                    <Switch />
                                </Form.Item>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mrg-ys-20 mrg-x-auto mt-2 justify-content-center">
                    <Space size={20}>
                        <SecondaryButton
                            buttonName={"Cancel"}
                            onClick={onCancel}
                        />
                        <PrimaryButton
                            buttonName={primaryBtnName}
                            loading={submitting}
                            type="submit"
                        />
                    </Space>
                </Row>
            </Form>
        </Modal>
    );
}

export default BannerModal;