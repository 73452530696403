import { useState, useEffect, useRef } from "react";
import { Button, Space, Input, DatePicker } from "antd";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from 'moment';
import { toastError } from "../../helpers/toasters";
import { getStoreSubcategories } from "../../apis/Network";

// components
import GridTable from "../SubComponents/GridTable/GridTable";
import SubHeader from "../SubComponents/SubHeader";
import AddEditStoreSubcategory from "./AddEditStoreSubcategory";
import MainHeader from "../SubComponents/MainHeader";

const StoreSubcategories = () => {
    const [loading, setLoading] = useState(true);
    const [mode, setMode] = useState("add");
    const [rowData, setRowData] = useState("");
    const [dataSource, setDataSource] = useState([]);
    const [filteredDataSource, setFilteredDataSource] = useState([]);
    const [selectedRange, setSelectedRange] = useState([]);
    const [categoriesFilterList, setCategoriesFilterList] = useState([]);
    const [isSubcategoryModalVisible, setIsSubcategoryModalVisible] = useState(false);
    const [renderMe, setRenderMe] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const { RangePicker } = DatePicker;

    const handleDateChange = (dates) => {
        setSelectedRange(dates);
        if (dates) {
            const startDate = dates[0].format("DD MMM, YYYY");
            const endDate = dates[1].format("DD MMM, YYYY");
            const dataWithingRange = dataSource.filter((ele) => {
                const comparisonDate = moment(ele.creationDate, "DD MMM, YYYY");
                return (
                    comparisonDate.isSameOrAfter(startDate) &&
                    comparisonDate.isSameOrBefore(endDate)
                );
            });
            setFilteredDataSource(dataWithingRange);
        } else {
            setFilteredDataSource(dataSource);
        }
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });
    const getColumnDateProps = () => ({
        filterDropdown: () => (
            <div
                style={{
                    padding: 8,
                }}
                className="range-picker-holder"
                onKeyDown={(e) => e.stopPropagation()}
            >
                <RangePicker
                    value={selectedRange}
                    allowClear
                    popupClassName="main-date-picker"
                    inputReadOnly={false}
                    onChange={handleDateChange}
                    onKeyDown={(e) => e.preventDefault()}
                />
            </div>
        ),
        filterIcon: (filtered) => <FilterOutlined style={selectedRange?.length > 0 ? { color: "blue" } : {}} />,
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text,
    });
    const columns = [
        {
            key: "nameEn",
            title: "NAME (EN)",
            dataIndex: "nameEn",
            ...getColumnSearchProps("nameEn"),
        },
        {
            key: "nameAr",
            title: "NAME (AR)",
            dataIndex: "nameAr",
            ...getColumnSearchProps("nameAr"),
        },
        {
            key: "category",
            title: "CATEGORY",
            dataIndex: "category",
            filters: categoriesFilterList,
            onFilter: (value, record) => value === record.categoryId,
        },
        {
            key: "isActive",
            title: "STATUS",
            dataIndex: "isActive",
            filters: [
                {
                    text: 'Active',
                    value: true,
                },
                {
                    text: 'Deactive',
                    value: false,
                }
            ],
            onFilter: (value, record) => value === record.isActive,
            render: (_, row) => (
                <span>{row.isActive ? "Active" : "Deactive"}</span>
            ),
        },
        {
            key: "creationDate",
            title: "CREATION DATE",
            dataIndex: "creationDate",
            ...getColumnDateProps("creationDate"),
        },
        {
            key: "id",
            title: "ACTIONS",
            dataIndex: "action",
            render: (_, row) => {
                return (
                    <Space size={5}>
                        <Button
                            className="border-0"
                            onClick={() => handleEditBtn(row)}
                            style={{ backgroundColor: "#589559", color: "white" }}
                        >
                            Edit
                        </Button>
                    </Space>
                );
            },
        },
    ];

    const handleEditBtn = (row) => {
        setMode("edit");
        setRowData(row);
        setIsSubcategoryModalVisible(true);
    };

    const handleAddSubCategory = () => {
        setIsSubcategoryModalVisible(true);
        setMode("add");
    };

    const handleModalCancel = () => {
        setIsSubcategoryModalVisible(false);
        setMode("add");
        setRowData(null)
    };

    useEffect(() => {
        getStoreSubcategories(
            (res) => {
                setLoading(false);
                if (res.success) {
                    setDataSource(res.data);
                    setFilteredDataSource(res.data);
                    // set category options
                    const categoriesOptions = [];
                    res.data.forEach((sub) => {
                        const alreadyInArr = categoriesOptions.find((ele) => ele.value === sub.categoryId);
                        if (!alreadyInArr) {
                            const obj = {
                                value: sub.categoryId,
                                text: sub.category
                            }
                            categoriesOptions.push(obj);
                        }
                    })
                    setCategoriesFilterList(categoriesOptions);
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setLoading(false);
                if (res.data?.error) {
                    toastError(res.message);
                }
            }
        );
    }, [renderMe]);

    return (
        <section className="users store-items">
            <div className="users__all-contents">
                <div className="users__content">
                    <div className="users__usersData">
                        <div className="pb-3 mb-3">
                            <MainHeader
                                title="Marketplace Subcategories"
                                buttonName={"Add Subcategory"}
                                onButtonClick={handleAddSubCategory}
                            />
                        </div>
                        <div className="users__table store-items__data-table grid-table mrg-ye-50 ">
                            <GridTable
                                loading={loading}
                                columns={columns}
                                dataSource={filteredDataSource}
                                rowId={"id"}
                            />
                        </div>
                    </div>
                </div>
                {isSubcategoryModalVisible && (
                    <AddEditStoreSubcategory
                        isSubcategoryModalVisible={isSubcategoryModalVisible}
                        mode={mode}
                        rowData={rowData}
                        SubHeader={
                            <SubHeader
                                bgColor="#F58234"
                                title={`${mode === "add" ? "Add New Subcategory" : "Edit Subcategory"}`}
                                closeButton
                                onCancel={handleModalCancel}
                            />
                        }
                        onSubmit={() => {
                            setRenderMe(!renderMe)
                            handleModalCancel()
                        }}
                        onCancel={handleModalCancel}
                        primaryBtnName={`${mode === "add" ? "Add" : "Save"}`}
                        secondaryBtnName={"Cancel"}
                    />
                )}
            </div>
        </section>
    );
}

export default StoreSubcategories;