import { useEffect, useRef, useState } from "react";
import { Button, Input, Space, DatePicker, Image } from "antd";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { getMarketplaceBanners } from "../../apis/Network";
import { toastError } from '../../helpers/toasters';
import { baseUrl } from '../../apis/services';

// components
import GridTable from "../SubComponents/GridTable/GridTable";
import SubHeader from "../SubComponents/SubHeader";
import MainHeader from "../SubComponents/MainHeader";
import BannerModal from "./BannerModal";
import DeleteModal from "./DeleteModal";

const MarketplaceBanner = () => {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [mode, setMode] = useState("add");
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [selectedRange, setSelectedRange] = useState([]);
  const [rowData, setRowData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [canAdminAdd, setCanAdminAdd] = useState(false);
  const [canAdminDelete, setCanAdminDelete] = useState(false);
  const [renderMe, setRenderMe] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const { RangePicker } = DatePicker;
  const searchInput = useRef(null);

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCancelModal = () => {
    setIsModalOpen(false);
    setIsDeleteModalOpen(false)
    setMode("add");
    setRowData(null)
  }

  const handleDateChange = (dates, dateStr) => {
    setSelectedRange(dates);
    if (dates) {
      const startDate = dates[0].format("DD MMM, YYYY");
      const endDate = dates[1].format("DD MMM, YYYY");
      const dataWithingRange = dataSource.filter((ele) => {
        const comparisonDate = moment(ele.creationDate, "DD MMM, YYYY");
        return comparisonDate.isSameOrAfter(startDate) &&
          comparisonDate.isSameOrBefore(endDate)
      })
      setFilteredDataSource(dataWithingRange)
    } else {
      setFilteredDataSource(dataSource)
    }
  }

  const handleSubmit = () => {
    setRenderMe(!renderMe);
    setLoading(true);
    handleCancelModal();
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const getColumnDateProps = () => ({
    filterDropdown: () => (
      <div
        style={{
          padding: 8,
        }}
        className="range-picker-holder"
        onKeyDown={(e) => e.stopPropagation()}
      >
        <RangePicker
          value={selectedRange}
          allowClear
          popupClassName="main-date-picker"
          inputReadOnly={false}
          onChange={handleDateChange}
          onKeyDown={(e) => e.preventDefault()}
        />
      </div>
    ),
    filterIcon: (filtered) => <FilterOutlined style={selectedRange?.length > 0 ? { color: "blue" } : {}} />,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });
  const columns = [
    {
      key: "image",
      title: "IMAGE",
      dataIndex: "image",
      render: (_, row) => {
        return (
          <Image
            width={90}
            height={70}
            src={baseUrl + row.image}
            className="d-block img-fluid object-fit-cover"
            alt=""
          />
        );
      },
    },
    {
      key: "category",
      title: "CATEGORY",
      dataIndex: "category",
      ...getColumnSearchProps("category"),
    },
    {
      key: "isActive",
      title: "STATUS",
      dataIndex: "isActive",
      filters: [
        { text: "Active", value: true },
        { text: "Inactive", value: false },
      ],
      onFilter: (value, record) => record.isActive === value,
      render: (_, row) => {
        return (
          <span>{row.isActive ? "Active" : "Inactive"}</span>
        );
      },
    },
    {
      key: "creationDate",
      title: "CREATION DATE",
      dataIndex: "creationDate",
      ...getColumnDateProps("creationDate"),
    },
    {
      key: "id",
      title: "ACTIONS",
      render: (_, row) => {
        return (
          <div>
            <Space size={5} className="mb-2">
              <Button
                className="border-0"
                onClick={() => {
                  setRowData(row)
                  setMode("edit")
                  setIsModalOpen(true)
                }}
                style={{ backgroundColor: "#589559", color: "white" }}
              >
                Edit
              </Button>
              <Button
                className="border-0"
                onClick={() => {
                  setRowData(row)
                  setIsDeleteModalOpen(true)
                }}
                style={{ backgroundColor: "#E72121", color: "white" }}>
                Delete
              </Button>
            </Space>
          </div >
        );
      },
    },
  ];

  useEffect(() => {
    getMarketplaceBanners(
      (res) => {
        setLoading(false);
        if (res.success) {
          setDataSource(res.data);
          setFilteredDataSource(res.data);
          let activeImages = 0;
          res.data.forEach((ele) => {
            if (ele.isActive) {
              activeImages++
            }
          })
          setCanAdminAdd(activeImages < 10)
          setCanAdminDelete(activeImages > 1)
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setLoading(false);
        toastError(res.message);
      })
  }, [renderMe])

  return (
    <section className="users banners">
      <div className="users__all-contents">
        <div className="users__content">
          <div className="users__usersData">
            <div className="pb-3 mb-3">
              <MainHeader
                title="Marketplace Banners"
                buttonName={"Add New Banner"}
                onButtonClick={handleOpenModal}
                disableBtn={!canAdminAdd}
              />
            </div>
            <div className="users__table grid-table mrg-y-30">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={filteredDataSource}
                rowId={"id"}
              />
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <BannerModal
          open={isModalOpen}
          onSubmit={handleSubmit}
          onCancel={handleCancelModal}
          canAdminMakeBannerActive={canAdminAdd}
          canAdminDelete={canAdminDelete}
          subHeader={
            <SubHeader
              bgColor="#589559"
              title={`${mode === "add" ? "Add New" : "Edit"} Banner`}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          rowData={rowData}
          primaryBtnName={mode === "add" ? "Add" : "Save"}
          mode={mode}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          open={isDeleteModalOpen}
          rowData={rowData}
          canAdminDelete={canAdminDelete}
          subHeader={
            <SubHeader
              bgColor="#589559"
              title={`Delete Banner`}
              closeButton
              onCancel={handleCancelModal}
            />
          }
          onCancel={handleCancelModal}
          onSubmit={handleSubmit}
        />
      )}
    </section>
  );
}

export default MarketplaceBanner;