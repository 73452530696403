const CityIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10123_22766)">
                <path d="M21.9529 16.8273C21.6125 16.6403 21.185 16.7647 20.9981 17.105C20.8111 17.4453 20.9355 17.8728 21.2758 18.0598C22.1134 18.5199 22.5938 19.03 22.5938 19.4593C22.5938 19.9843 21.8498 20.7981 19.7619 21.5074C17.6992 22.2083 14.9426 22.5942 12 22.5942C9.05742 22.5942 6.30084 22.2083 4.23811 21.5074C2.15016 20.7981 1.40625 19.9843 1.40625 19.4593C1.40625 19.03 1.88663 18.5199 2.72419 18.0597C3.06455 17.8728 3.18886 17.4453 3.00188 17.1049C2.81489 16.7646 2.38753 16.6403 2.04708 16.8272C1.11323 17.3403 0 18.2134 0 19.4593C0 20.4143 0.656813 21.7759 3.78572 22.839C5.99034 23.588 8.90756 24.0005 12 24.0005C15.0924 24.0005 18.0097 23.588 20.2143 22.839C23.3432 21.7759 24 20.4143 24 19.4593C24 18.2134 22.8868 17.3403 21.9529 16.8273Z" />
                <path d="M6.69123 20.491C8.11857 20.8942 10.0038 21.1163 11.9997 21.1163C13.9956 21.1163 15.8809 20.8942 17.3082 20.491C19.0545 19.9977 19.9399 19.2836 19.9399 18.3687C19.9399 17.4537 19.0545 16.7397 17.3082 16.2465C16.9206 16.137 16.499 16.0409 16.0508 15.959C15.7989 16.3938 15.5439 16.8268 15.2856 17.2578C15.7817 17.3322 16.2475 17.4235 16.6705 17.5313C17.9557 17.8586 18.4252 18.2271 18.5217 18.3687C18.4251 18.5104 17.9557 18.8789 16.6706 19.2062C15.4542 19.516 13.8893 19.6927 12.241 19.7085C12.1607 19.7146 12.0802 19.7176 11.9997 19.7178C11.9187 19.7178 11.8383 19.7144 11.7584 19.7085C10.1101 19.6927 8.54518 19.516 7.32882 19.2062C6.0437 18.8789 5.57424 18.5104 5.47773 18.3687C5.57424 18.2271 6.04374 17.8586 7.32887 17.5313C7.75187 17.4235 8.21766 17.3322 8.71384 17.2578C8.44716 16.8125 8.19212 16.3795 7.9487 15.959C7.50048 16.041 7.07884 16.137 6.69123 16.2465C4.94499 16.7397 4.05957 17.4538 4.05957 18.3687C4.05957 19.2836 4.94499 19.9976 6.69123 20.491Z" />
                <path d="M12.0001 18.311C12.626 18.311 13.1946 17.992 13.5211 17.4578C15.8091 13.7145 18.5364 8.78109 18.5364 6.5362C18.5364 2.93212 15.6042 0 12.0001 0C8.39599 0 5.46387 2.93212 5.46387 6.5362C5.46387 8.78109 8.1912 13.7145 10.4791 17.4578C10.8056 17.992 11.3742 18.311 12.0001 18.311ZM9.37291 6.08742C9.37291 4.63884 10.5515 3.46031 12.0001 3.46031C13.4487 3.46031 14.6273 4.63884 14.6273 6.08742C14.6273 7.53605 13.4487 8.71458 12.0001 8.71458C10.5515 8.71458 9.37291 7.53609 9.37291 6.08742Z" />
            </g>
            <defs>
                <clipPath id="clip0_10123_22766">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
}

export default CityIcon;