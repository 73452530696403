import { useState, useEffect, useRef } from "react";
import { Button, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { toastError } from "../../helpers/toasters";
import { getAdminsApi } from "../../apis/Network";

// assets
import searchIcon from "../../assets/icons/search.svg";

// components
import GridTable from "../SubComponents/GridTable/GridTable";
import MainHeader from "../SubComponents/MainHeader";
import AddEditModal from "./AddEditModal";
import SubHeader from "../SubComponents/SubHeader";

function Admins() {
  const [allDataSource, setAllDataSource] = useState([]);
  const [renderMe, setRenderMe] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rowData, setRowData] = useState(null);
  const [mode, setMode] = useState("add");
  const [addEditModalOpen, setAddEditModalOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <img src={searchIcon} alt="" />,
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text || text === 0 ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      key: "userName",
      title: "NAME",
      dataIndex: "userName",
      ...getColumnSearchProps("userName"),
    },
    {
      key: "mobile",
      title: "PHONE NUMBER",
      dataIndex: "mobile",
      ...getColumnSearchProps("mobile"),
    },
    {
      key: "adminTypename",
      title: "ROLE",
      dataIndex: "adminTypename",
      ...getColumnSearchProps("adminTypename"),
    },
    {
      key: "isActive",
      title: "STATUS",
      dataIndex: "isActive",
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Deactive',
          value: false,
        }
      ],
      onFilter: (value, record) => value === record.isActive,
      render: (_, record) => {
        return <span>{record.isActive ? "Active" : "Deactive"}</span>;
      },
    },
    {
      key: "id",
      title: "ACTION",
      dataIndex: "action",
      render: (_, row) => {
        return (
          <Space size={5}>
            <Button
              className="border-0"
              onClick={() => {
                setRowData(row)
                setAddEditModalOpen(true)
                setMode("edit")
              }}
              style={{ backgroundColor: "#589559", color: "white" }}
            >
              Edit
            </Button>
          </Space>
        );
      },
    },
  ];

  const handleModalCancel = () => {
    setAddEditModalOpen(false)
    setRowData(null)
    setMode("add")
  }

  useEffect(() => {
    getAdminsApi(
      (res) => {
        setLoading(false);
        if (res.success) {
          setAllDataSource(res.data);
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setLoading(false);
        toastError(res.message);
      }
    );
  }, [renderMe]);

  return (
    <section className="users">
      <div className="users__all-contents">
        <div className="users__content">
          <div className="users__usersData">
            <div className="pb-3 mb-3">
              <MainHeader
                title="All Admins"
                buttonName={"Add Admin"}
                onButtonClick={() => {
                  setAddEditModalOpen(true)
                }}
              />
            </div>
            <div className="users__table grid-table mrg-y-30">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={allDataSource}
                rowId={"adminId"}
              />
            </div>
          </div>
        </div>
      </div>
      {addEditModalOpen && (
        <AddEditModal
          open={addEditModalOpen}
          mode={mode}
          rowData={rowData}
          SubHeader={
            <SubHeader
              closeButton
              bgColor="#589559"
              onCancel={handleModalCancel}
              title={`${mode === "add" ? "Add New" : "Edit"} Admin`}
            />
          }
          onSubmit={() => {
            setRenderMe(!renderMe);
            setLoading(true);
            handleModalCancel();
          }}
          onCancel={handleModalCancel}
          primaryBtnName={`${mode === "add" ? "Add" : "Save"}`}
          secondaryBtnName={"Cancel"}
        />
      )}
    </section>
  );
}

export default Admins;
