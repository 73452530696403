import { useState } from "react";
import { Form, Modal, Row, Space } from "antd";
import { toastError, toastSuccess } from "../../helpers/toasters";
import { updateRedeemOrderPriceAndAddress } from "../../apis/Network";

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";

const ConfirmChangeAddressStatusModal = ({
    open,
    rowData = {},
    SubHeader,
    onSubmit,
    onCancel,
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm();

    const handleOnFinish = () => {
        if (submitting) return;
        setSubmitting(true);

        const payload = {
            orderId: rowData.id,
            addressSaved: !rowData.addressSaved,
            orderTotalPaidPrice: rowData.orderTotalPaidPrice,
        };
        updateRedeemOrderPriceAndAddress(
            payload,
            (res) => {
                setSubmitting(false);
                if (res.success) {
                    toastSuccess(res.message);
                    onSubmit();
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setSubmitting(false);
                toastError(res.message);
            }
        );
    };

    return (
        <Modal
            title={SubHeader}
            open={open}
            className="msg-modal"
            footer={false}
            closable={false}
            centered
            destroyOnClose
        >
            <Form
                form={form}
                className="edit-actor__content store-items__item-form"
                onFinish={handleOnFinish}
            >
                <p className="text-center my-4">Are you sure you want to set this address as {`${rowData.addressSaved ? "un" : ""}saved`}?</p>
                <Row className="mrg-ys-20 mrg-x-auto justify-content-center">
                    <Space size={20}>
                        <SecondaryButton buttonName={"Cancel"} onClick={onCancel} />
                        <PrimaryButton
                            buttonName={"OK"}
                            loading={submitting}
                            type="submit"
                        />
                    </Space>
                </Row>
            </Form>
        </Modal>
    );
}

export default ConfirmChangeAddressStatusModal;