import { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { Button, Input, Modal, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { getRequestAssignmentLogs } from "../../apis/Network";
import { toastError } from "../../helpers/toasters";

// components
import GridTable from "../SubComponents/GridTable/GridTable";

const AssignmentLogsModal = ({
    open,
    onCancel,
    subHeader,
    rowData = {},
}) => {
    const [logsData, setLogsData] = useState(null)
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    ></Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text, record) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            key: "date",
            title: "UPDATED DATE",
            dataIndex: "date",
            ...getColumnSearchProps("date"),
        },
        {
            key: "assigningDate",
            title: "ASSIGNING DATE",
            dataIndex: "assigningDate",
            ...getColumnSearchProps("assigningDate"),
        },
        {
            key: "courier",
            title: "COURIER NAME",
            dataIndex: "courier",
            ...getColumnSearchProps("courier"),
        },
        {
            key: "admin",
            title: "ADMIN NAME",
            dataIndex: "admin",
            ...getColumnSearchProps("admin"),
        },
    ];

    useEffect(() => {
        if (rowData) {
            getRequestAssignmentLogs(
                rowData.requestId,
                (res) => {
                    if (res.success) {
                        setLogsData(res.data)
                    } else {
                        toastError(res.message)
                    }
                },
                (res) => {
                    toastError(res?.data?.error)
                }
            )
        }
    }, [rowData])

    return (
        <Modal
            title={subHeader}
            open={open}
            width={window.innerWidth >= 992 ? "66%" : "90%"}
            className="msg-modal social-orders__view-details  cans-sales-requests__view-details"
            footer={false}
            closable={false}
            centered
            destroyOnClose
            onCancel={onCancel}
        >
            <GridTable
                columns={columns}
                dataSource={logsData}
                loading={!logsData}
                rowId={"id"}
            />
        </Modal>
    );
}

export default AssignmentLogsModal;