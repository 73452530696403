import { useState, useEffect } from 'react';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { theFirstEncounteredLetterIsArabic } from 'helpers/RegexPatterns';

// assets
import imageIcon from "assets/icons/image.svg";
import videoIcon from "assets/icons/video--grey.svg";
import locationIcon from "assets/icons/location-dot.svg";
import microphoneIcon from "assets/icons/microphone--grey.svg";
import documentIcon from "assets/icons/file--grey.svg";

const ChatRoom = ({
    data,
    active,
    unreadNotification,
    onActiveChange,
    searchValue
}) => {
    const [lastUpdateTime, setLastUpdateTime] = useState("");
    const [messageDir, setMessageDir] = useState("ltr");

    useEffect(() => {
        if (data) {
            // set last message date
            const now = moment();
            const end = moment(data.lastMessageDate, "YYYY-MM-DDTHH:mm:ssZZ");
            const yesterdayDate = now.clone().subtract(1, 'days').startOf('day')
            const isYesterday = end.isSame(yesterdayDate, "d");
            const diffInDays = now.clone().diff(end, "days")
            let shownTime = ""
            if (diffInDays > 0 && !isYesterday) {
                shownTime = moment(data.lastMessageDate, "YYYY-MM-DDTHH:mm:ssZZ").format("DD-MM-YYYY");
            } else if (isYesterday) {
                shownTime = "Yesterday"
            } else {
                shownTime = moment(data.lastMessageDate, "YYYY-MM-DDTHH:mm:ssZZ").format("HH:mm");
            }
            setLastUpdateTime(shownTime)

            // set last message direction 
            if (theFirstEncounteredLetterIsArabic.test(data.lastMessage) && data.lastMessageType === 1) {
                setMessageDir("rtl");
            } else {
                setMessageDir("ltr")
            }
        }
    }, [data])

    return (
        <li onClick={() => onActiveChange(data?.id)}>
            <div className={`chat__rooms__holder__room ${active ? "chat__rooms__holder__room--active" : ""} ${unreadNotification ? "chat__rooms__holder__room--with-unread-messages" : ""}`}>
                <span className='d-flex align-items-center gap-2 justify-content-between mb-1'>
                    <span className="chat__rooms__holder__room__username d-block fsize--9">
                        <Highlighter
                            highlightStyle={{
                                backgroundColor: "#ffc069",
                                padding: 0,
                            }}
                            searchWords={[searchValue]}
                            caseSensitive
                            textToHighlight={data?.name}
                        />
                        {" - "}
                        <Highlighter
                            highlightStyle={{
                                backgroundColor: "#ffc069",
                                padding: 0,
                            }}
                            searchWords={[searchValue]}
                            caseSensitive
                            textToHighlight={data?.mobile}
                        />
                    </span>
                    {unreadNotification ? (
                        <span className='chat__rooms__holder__room__notifications d-inline-flex justify-content-center align-items-center'>
                            {data?.unReadMessagesCountFromUser > 99 ? "99+" : data?.unReadMessagesCountFromUser}
                        </span>
                    ) : (
                        ""
                    )}
                </span>
                <p dir={messageDir} className={`chat__rooms__holder__room__message text-truncate mb-1 fsize--9 ${messageDir === "ltr" ? "text-start" : "text-end ms-auto"} ${data?.lastMessageType !== 1 ? "d-flex align-items-center gap-1" : ""}`}>
                    {data?.lastMessageType === 1 ? (
                        data?.lastMessage
                    ) : (
                        <>
                            <img
                                src={data?.lastMessageType === 2 ? imageIcon :
                                    data?.lastMessageType === 3 ? microphoneIcon :
                                        data?.lastMessageType === 4 ? documentIcon :
                                            data?.lastMessageType === 5 ? videoIcon : locationIcon}
                                alt=""
                                className='chat__rooms__holder__room__message__icon d-block img-fluid'
                            />
                            <span>
                                {data?.lastMessageType === 2 ? "Image" :
                                    data?.lastMessageType === 3 ? "audio" :
                                        data?.lastMessageType === 4 ? "Document" :
                                            data?.lastMessageType === 5 ? "Video" : "Location"}
                            </span>
                        </>
                    )}
                </p>
                <span className="chat__rooms__holder__room__time d-block w-100 text-end fsize--11">
                    {lastUpdateTime}
                </span>
            </div>
        </li>
    );
}

export default ChatRoom;