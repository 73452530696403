import { useState, useEffect } from "react";
import { Row, Space, Modal, Form, Col, Input, Switch } from "antd";
import { toastError, toastSuccess } from "../../helpers/toasters";
import { addGovernment, editGovernment } from "../../apis/Network";

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";

const AddEditModal = ({
  open,
  onCancel,
  subHeader,
  primaryBtnName,
  mode,
  rowData = {},
  onSubmit,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();
  const initialValues = {
    nameEn: "",
    nameAr: "",
    isActive: true,
  };

  const handleOnFinish = (values) => {
    if (submitting) return;
    setSubmitting(true);
    const payload = {
      nameAr: values.nameAr,
      nameEn: values.nameEn,
      isActive: values.isActive,
    };
    if (mode === "add") {
      addGovernment(
        payload,
        (res) => {
          setSubmitting(false);
          if (res.success) {
            toastSuccess(res.message);
            onSubmit();
          } else {
            toastError(res.message);
          }
        },
        (res) => {
          setSubmitting(false);
          toastError(res.message);
        }
      );
    } else {
      payload.id = rowData.id;
      editGovernment(
        payload,
        (res) => {
          setSubmitting(false);
          if (res.success) {
            toastSuccess(res.message);
            onSubmit();
          } else {
            toastError(res.message);
          }
        },
        (res) => {
          setSubmitting(false);
          toastError(res.message);
        }
      );
    }
  };

  useEffect(() => {
    if (mode === "edit" && rowData) {
      form.setFieldValue("nameEn", rowData?.nameEn);
      form.setFieldValue("nameAr", rowData?.nameAr);
      form.setFieldValue("isActive", rowData?.isActive);
    }
  }, [rowData, mode, form]);
  console.log("rowData", rowData);
  return (
    <Modal
      title={subHeader}
      open={open}
      className="msg-modal"
      footer={false}
      closable={false}
      centered
      destroyOnClose
      onCancel={onCancel}
    >
      <Form
        form={form}
        className="edit-actor__content pt-3"
        onFinish={handleOnFinish}
        initialValues={initialValues}
      >
        <Row gutter={24}>
          <Col span={24}>
            <div className="new-actor__name new-category mb-5">
              <label className="users__parent-info-label mb-2" htmlFor="">
                Government Name in English
              </label>
              <Form.Item
                className="new-category__form-item"
                name="nameEn"
                validateFirst
                rules={[
                  {
                    required: true,
                    message: "Please, Enter the government name in English",
                  },
                  {
                    pattern: /^[\w\s$!@#%^&*()_+-=[\]/{}\\|;':",./<>?~`]+$/,
                    message: "Please, Enter the government name in English",
                  },
                  {
                    min: 3,
                    message: "Name should be at least 3 characters",
                  },
                  {
                    max: 50,
                    message: "Name should not exceed 50 characters.",
                  },
                ]}
              >
                <Input
                  type="text"
                  className="search__searchField py-2"
                  placeholder="Enter Name in English"
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={24}>
            <div className="new-actor__name new-category mb-5">
              <label className="users__parent-info-label mb-2" htmlFor="">
                Government Name in Arabic
              </label>
              <Form.Item
                className=" new-category__form-item"
                name="nameAr"
                validateFirst
                rules={[
                  {
                    required: true,
                    message: "Please, Enter the government name in Arabic",
                  },
                  {
                    pattern:
                      /^[\u0600-\u06FF\s\d$!@#%^&*()_+-=[\]/\\{}|;':",./<>؟~؟’~‘÷×>`]+$/,
                    message: "Please, Enter the government name in Arabic",
                  },
                  {
                    min: 3,
                    message: "Name should be at least 3 characters",
                  },
                  {
                    max: 50,
                    message: "Name should not exceed 50 characters.",
                  },
                ]}
              >
                <Input
                  type="text"
                  className="search__searchField py-2"
                  placeholder="Enter Name in Arabic"
                  dir="rtl"
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={24}>
            <div className="new-actor__name mb-4 row mx-0 align-items-center">
              <div className="col-4 px-0 text-start">
                <label className="users__parent-info-label">Active</label>
              </div>
              <div className="col-8 px-0 d-flex">
                <Form.Item
                  name="isActive"
                  valuePropName="checked"
                  className="mb-0"
                >
                  <Switch />
                </Form.Item>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mrg-ys-20 mrg-x-auto mt-2 justify-content-center">
          <Space size={20}>
            <SecondaryButton buttonName={"Cancel"} onClick={onCancel} />
            <PrimaryButton
              buttonName={primaryBtnName}
              loading={submitting}
              type="submit"
            />
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddEditModal;
