import { useEffect, useState } from "react";
import { Button, DatePicker, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";

function GridTable({
  loading,
  columns,
  dataSource,
  rowId,
  allowExport = false,
  onExport,
  isExporting,
  title,
  onChange,
  currentPage,
  totalCount,
  onPageChange,
  rowSelection,
  className = "",
  scroll = { x: 100 },
  bordered = false,
  onPageSizeChange,
  pageSize,
  showSocialOrdersBtns,
  onCourierAssigning,
  disableAssignCourier,
  onUserStatusAssigning,
  disableUserStatus,
  showTableDateFilter,
  onDateFilter,
  dateFilterValue,
  summary,
  showMarketplaceBtns
}) {
  const [filteredData, setFilteredData] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const { RangePicker } = DatePicker;

  useEffect(() => {
    if (title === "Orders" && dataSource) {
      const arr = filteredData.length > 0 ? filteredData : dataSource;
      const totalPrice = arr.reduce(
        (pre, curr) => pre + parseFloat(curr.price),
        0
      );
      setTotalPrice(totalPrice.toFixed(2));
    }
  }, [filteredData, dataSource, title]);

  return (
    <>
      <div className="d-flex justify-content-end align-items-start gap-2">
        {showSocialOrdersBtns ? (
          <>
            <Button
              onClick={onCourierAssigning}
              htmlType="button"
              disabled={disableAssignCourier}
              className="main-header__plus-button border-0 outline-none shadow-none mb-3"
              icon={<PlusOutlined />}
            >
              Assign To Courier
            </Button>
            <Button
              onClick={onUserStatusAssigning}
              htmlType="button"
              disabled={disableUserStatus}
              className="main-header__plus-button border-0 outline-none shadow-none mb-3"
              icon={<PlusOutlined />}
            >
              Bulk Change Customer Status
            </Button>
          </>
        ) : showMarketplaceBtns ? (
          <Button
            onClick={showMarketplaceBtns.onOpenChangeStatusModal}
            htmlType="button"
            disabled={showMarketplaceBtns.disableBtn}
            className="main-header__plus-button border-0 outline-none shadow-none mb-3"
          >
            Change Status
          </Button>
        ) : (
          ""
        )}
        {allowExport ? (
          <Button
            onClick={() => {
              const dataToExport = filteredData.length > 0 ? filteredData : dataSource
              onExport(dataToExport)
            }}
            loading={isExporting}
            className="mb-3 d-block export-btn border-0 outline-none"
          >
            Export To Excel
          </Button>
        ) : (
          ""
        )}
      </div>
      {title === "Orders" ? (
        <p className="text-white">
          <span className="fw-bold">Total Price:</span> {totalPrice} EGP
        </p>
      ) : (
        ""
      )}
      {showTableDateFilter ? (
        <div className="d-flex justify-content-end">
          <RangePicker
            value={dateFilterValue}
            allowClear
            popupClassName="main-date-picker"
            inputReadOnly={false}
            onChange={(dates) => {
              // setCurrentPage(1);
              onDateFilter(dates);
            }}
            onKeyDown={(e) => e.preventDefault()}
          />
        </div>
      ) : (
        ""
      )}
      <Table
        summary={summary}
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        rowKey={(row) => row[rowId]}
        scroll={scroll}
        bordered={bordered}
        size="small"
        className={`pb-3 ${className}`}
        rowClassName={(record, index) =>
          index % 2 === 0 && "background-even-records"
        }
        tableLayout="auto"
        rowSelection={rowSelection}
        onChange={(pagination, filters, sorter, extra) => {
          if (extra.action === "filter") {
            setFilteredData(extra.currentDataSource);
          }
          if (onChange) {
            onChange(pagination, filters, sorter, extra);
          }
        }}
        pagination={{
          responsive: true,
          current: currentPage,
          total: totalCount,
          defaultPageSize: 10,
          pageSize: pageSize,
          onChange: onPageChange,
          onShowSizeChange: onPageSizeChange,
        }}
      />
    </>
  );
}

export default GridTable;
