import { useState } from "react";
import { Col, Form, Input, InputNumber, Row } from "antd";
import { updateSettings } from "../../apis/Network";
import { toastError, toastSuccess } from "../../helpers/toasters";
import { handleAllowNumbersOnly, validatePhoneNumber } from "../../helpers/helpfulFunctions";

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";

const SettingsForm = ({ value, id, onSubmit, title }) => {
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();

  const handleValidation1 = (value) => {
    if (!value && value !== 0) {
      return Promise.reject(
        new Error(
          `Please, Enter the equivalent number of points for 1 kilo of cans`
        )
      );
    } else if (value <= 0) {
      return Promise.reject(
        new Error(`The minimum value should be greater than 0`)
      );
    } else if (value > 10000) {
      return Promise.reject(
        new Error(
          `The maximum value should be less than or equal to 10,000 points`
        )
      );
    } else {
      return Promise.resolve();
    }
  };

  const handleValidation2 = (value) => {
    if (!value && value !== 0) {
      return Promise.reject(
        new Error(`Please, Enter the equivalent number of pounds for 1 point`)
      );
    } else if (value <= 0) {
      return Promise.reject(
        new Error(`The minimum value should be greater than 0`)
      );
    } else if (value > 10000) {
      return Promise.reject(
        new Error(
          `The maximum value should be less than or equal to 10,000 EGP`
        )
      );
    } else {
      return Promise.resolve();
    }
  };

  const handleValidation3 = (value) => {
    if (!value && value !== 0) {
      return Promise.reject(new Error(`Please, Enter the period`));
    } else if (value < 1) {
      return Promise.reject(new Error(`The minimum value should be 1`));
    } else if (value > 365) {
      return Promise.reject(new Error(`The maximum value should be 365 days`));
    } else {
      return Promise.resolve();
    }
  };

  const handleValidation4 = (value) => {
    if (!value && value !== 0) {
      return Promise.reject(
        new Error(`Please, Enter the equivalent number of pounds for 1 point`)
      );
    } else if (value <= 0) {
      return Promise.reject(
        new Error(`The minimum value should be greater than 0`)
      );
    } else if (value > 10000) {
      return Promise.reject(
        new Error(
          `The maximum value should be less than or equal to 10,000 EGP`
        )
      );
    } else {
      return Promise.resolve();
    }
  };

  const handleValidation5 = (value) => {
    if (!value && value !== 0) {
      return Promise.reject(new Error(`Please, Enter the percentage`));
    } else if (value <= 0) {
      return Promise.reject(
        new Error(`The minimum value should be greater than 0`)
      );
    } else if (value > 100) {
      return Promise.reject(
        new Error(`The maximum value should be less than or equal to 100`)
      );
    } else {
      return Promise.resolve();
    }
  };

  const handleValidation7 = (value) => {
    if (!value) {
      return Promise.reject(new Error(`Please, Enter the WhatsApp number`));
    } else if (!validatePhoneNumber(value)) {
      return Promise.reject(new Error("Please, enter a valid WhatsApp number"));
    } else {
      return Promise.resolve();
    }
  };

  const handleValidation8 = (value) => {
    if (!value && value !== 0) {
      return Promise.reject(new Error(`Please, Enter the delivery period`));
    } else if (value <= 0) {
      return Promise.reject(
        new Error(`Minimum value should be greater than 0`)
      );
    } else {
      return Promise.resolve();
    }
  };

  const handleValidation9 = (value) => {
    if (!value && value !== 0) {
      return Promise.reject(new Error(`Please, Enter the percentage`));
    } else if (value <= 0) {
      return Promise.reject(
        new Error(`The minimum value should be greater than 0`)
      );
    } else if (value > 100) {
      return Promise.reject(
        new Error(`The maximum value should be less than or equal to 100`)
      );
    } else {
      return Promise.resolve();
    }
  };

  const handleValidation10 = (value) => {
    if (!value) {
      return Promise.reject(new Error("Please, enter the phone number"));
    } else if (!validatePhoneNumber(value)) {
      return Promise.reject(new Error("Please, enter a valid phone number"));
    } else {
      return Promise.resolve();
    }
  };

  const handleValidation11 = (value) => {
    if (!value && value !== 0) {
      return Promise.reject(new Error("Please, Enter the bonus"));
    } else if (!/\d+/.test(value)) {
      return Promise.reject(new Error("Please, Enter a valid bonus"));
    } else if (value < 0) {
      return Promise.reject(new Error("Minimum value should be 0"));
    } else {
      return Promise.resolve();
    }
  };

  const handleValidation = (_, value) => {
    if (id === 1) {
      return handleValidation1(value);
    } else if (id === 2) {
      return handleValidation2(value);
    } else if (id === 3) {
      return handleValidation3(value);
    } else if (id === 4) {
      return handleValidation4(value);
    } else if (id === 5 || id === 6) {
      return handleValidation5(value);
    } else if (id === 7) {
      return handleValidation7(value);
    } else if (id === 8) {
      return handleValidation8(value);
    } else if (id === 9) {
      return handleValidation9(value);
    } else if (id === 10) {
      return handleValidation10(value);
    } else if (id === 11 || id === 12) {
      return handleValidation11(value);
    }
  };

  const handleSubmit = (values) => {
    if (submitting) return;
    setSubmitting(true);

    const payload = {
      id,
      value: `${values.value}`,
    };

    updateSettings(
      payload,
      (res) => {
        setSubmitting(false);
        if (res.success) {
          toastSuccess(res.message);
          onSubmit();
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setSubmitting(false);
        toastError(res.message);
      }
    );
  };

  return (
    <>
      <h2 className="fw-bold fs-5">{title}</h2>
      <Form
        form={form}
        className="edit-actor__content"
        onFinish={handleSubmit}
        initialValues={{
          value,
        }}
      >
        <Row gutter={24}>
          <Col span={24}>
            <div className="new-actor__name new-category mb-5 pt-3">
              <div className="lang-name wd-100 flex">
                <label className="users__parent-info-label mb-2" htmlFor="">
                  {id === 1
                    ? "Number of points"
                    : id === 2
                      ? "Number of pounds"
                      : id === 3
                        ? "Period"
                        : id === 4
                          ? "Number of pounds"
                          : id === 5 || id === 6
                            ? "Percentage"
                            : id === 7
                              ? "Number"
                              : id === 8
                                ? "Period"
                                : id === 9
                                  ? "Percentage"
                                  : id === 10
                                    ? "Phone number"
                                    : id === 11 || id === 12
                                      ? "Bonus"
                                      : ""
                  }
                </label>
              </div>
              <Form.Item
                className="new-category__form-item"
                name="value"
                rules={[{ validator: handleValidation }]}
              >
                {id === 10 || id === 7 ? (
                  <Input
                    type="text"
                    placeholder="Enter mobile number"
                    className="w-100 d-block search__searchField py-1"
                    onKeyDown={(e) => handleAllowNumbersOnly(e, false)}
                  />
                ) : (
                  <InputNumber
                    min={0}
                    maxLength={id === 8 ? 2 : undefined}
                    suffix={id === 5 || id === 6 || id === 9 ? <span>%</span> : null}
                    className="w-100 search__searchField"
                    placeholder={
                      id === 1
                        ? "Enter the equivalent value for 1 kilo of cans"
                        : id === 2
                          ? "Enter the equivalent value for 1 point"
                          : id === 3
                            ? "Enter the period"
                            : id === 4
                              ? "Enter the equivalent value for 1 point"
                              : id === 5 || id === 6 || id === 9
                                ? "Enter the percentage"
                                : id === 8
                                  ? "Enter the period"
                                  : id === 11 || id === 12
                                    ? "Enter the bonus"
                                    : ""
                    }
                    onKeyDown={(e) => {
                      if (id === 3 || id === 8 || id === 11 || id === 12) {
                        handleAllowNumbersOnly(e, false);
                      } else {
                        handleAllowNumbersOnly(e);
                      }
                    }}
                    controls={false}
                    keyboard={false}
                  />
                )}
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row>
          <PrimaryButton
            buttonName={"Save"}
            loading={submitting}
            type="submit"
          />
        </Row>
      </Form>
    </>
  );
};

export default SettingsForm;
