import { useState, useEffect } from 'react';
import { Row, Space, InputNumber, Modal, Form, Col } from 'antd';
import { toastError, toastSuccess } from '../../helpers/toasters';
import { AcceptOrRejectMerchantSaleRequest } from '../../apis/Network';
import { handleAllowNumbersOnly } from '../../helpers/helpfulFunctions';

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";

const RequestModal = ({
    open,
    onCancel,
    SubHeader,
    primaryBtnName,
    mode,
    rowData,
    onSubmit
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm();

    const handleOnFinish = (values) => {
        if (submitting) return;
        setSubmitting(true);

        const payload = {
            dealerSaleRequestId: rowData.salesRequestId,
            isAccepted: mode === "accept" || mode === "edit",
        }

        if (mode === "accept") {
            payload.totalQuantity = values.totalQuantity
        }

        AcceptOrRejectMerchantSaleRequest(
            payload,
            (res) => {
                setSubmitting(false)
                if (res.success) {
                    toastSuccess(res.message);
                    onSubmit();
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setSubmitting(false)
                toastError(res.message);
            }
        )
    }

    const handleTotalQuantityValidation = (_, value) => {
        if (!value && value !== 0) {
            return Promise.reject(new Error(`Please, Enter the quantity`));
        } else if (value !== parseInt(value)) {
            return Promise.reject(new Error(`Please, Enter a valid quantity`));
        } else if (value <= 0) {
            return Promise.reject(new Error(`Minimum value should be greater than 0`));
        } else {
            return Promise.resolve()
        }
    }

    useEffect(() => {
        if (mode === "edit" && rowData) {
            form.setFieldValue("totalQuantity", rowData.totalQuantity)
        }
    }, [rowData, mode, form])

    return (
        <Modal
            title={SubHeader}
            open={open}
            className="msg-modal"
            footer={false}
            closable={false}
            centered
            destroyOnClose
            onCancel={onCancel}
        >
            <Form
                form={form}
                className="edit-actor__content"
                onFinish={handleOnFinish}
                initialValues={{
                    totalQuantity: ""
                }}
            >
                {mode !== "reject" ? (
                    <Row gutter={24}>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5 pt-3">
                                <div className="lang-name wd-100 flex">
                                    <label className="users__parent-info-label mb-2" htmlFor="">
                                        Quantity
                                    </label>
                                </div>
                                <Form.Item
                                    className="new-category__form-item"
                                    name="totalQuantity"
                                    rules={[
                                        { validator: handleTotalQuantityValidation }
                                    ]}
                                >
                                    <InputNumber
                                        min={0}
                                        className="w-100 d-block search__searchField py-2"
                                        placeholder="Enter the quantity for this type"
                                        onKeyDown={handleAllowNumbersOnly}
                                        controls={false}
                                        keyboard={false}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <p className='text-center pt-3 mb-3'>Are you sure you want to reject this request?</p>
                )}

                <Row className="mrg-ys-20 mrg-x-auto pt-2 justify-content-center">
                    <Space size={20}>
                        <SecondaryButton
                            buttonName={"Cancel"}
                            onClick={onCancel}
                        />
                        <PrimaryButton
                            buttonName={primaryBtnName}
                            loading={submitting}
                            type="submit"
                        />
                    </Space>
                </Row>
            </Form>
        </Modal>
    );
}

export default RequestModal;