import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Button, Input, Space, Switch } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { toastError } from "../../helpers/toasters";
import { getDealersApi } from "../../apis/Network";
import { exportDataAsExcel, handleAllowNumbersOnly } from "../../helpers/helpfulFunctions";

// assets
import searchIcon from "../../assets/icons/search.svg";

// components
import GridTable from "../SubComponents/GridTable/GridTable";
import MainHeader from "../SubComponents/MainHeader";
import SubHeader from "../SubComponents/SubHeader";
import BalanceDetails from "./BalanceDetails";
import AddCheckModal from "./AddCheckModal";
import ConfirmTogglingMerchantStatusModal from "./ConfirmTogglingMerchantStatusModal";

function Scrappers() {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBalanceModalOpen, setIsBalanceModalOpen] = useState(false);
  const [rowData, setRowData] = useState(true);
  const [renderMe, setRenderMe] = useState(false);
  // const [columns, setColumns] = useState([]);
  const [merchantStatusModalOpen, setMerchantStatusModalOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(1);
  const [isExporting, setIsExporting] = useState(false);
  const [searchValues, setSearchValues] = useState({
    fullName: "",
    mobile: "",
    deservedAmount: "",
    isMerchant: ""
  });
  const { authorization } = useSelector((state) => state.auth);
  const searchInput = useRef(null);

  const handlePageSizeChange = (current, newSize) => {
    setLoading(true);
    setPageSize(newSize);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onKeyDown={(e) => {
            const acceptsNumbersOnly = [
              "mobile",
            ];
            if (acceptsNumbersOnly.includes(dataIndex)) {
              handleAllowNumbersOnly(e, true);
            }
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters, dataIndex, confirm)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <img src={searchIcon} alt="" />,
    onFilter: (value, record) => true,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleTableChange = (_, filters) => {
    if (filters.isMerchant) {
      if (filters.isMerchant.length > 1) {
        updateSearchValues("isMerchant", "")
      } else {
        updateSearchValues("isMerchant", filters.isMerchant[0])
      }
    } else if (searchValues.isMerchant !== "") {
      updateSearchValues("isMerchant", "")
    }
  }

  const updateSearchValues = (property, newValue, otherProperty) => {
    const copyOfSearchObj = { ...searchValues };
    copyOfSearchObj[property] = newValue;
    if (otherProperty) {
      copyOfSearchObj[otherProperty.property] = otherProperty.value;
    }
    setSearchValues(copyOfSearchObj);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    const value = selectedKeys[0]?.trim() || ""
    confirm();
    switch (dataIndex) {
      case "name":
        updateSearchValues("fullName", value)
        break;
      case "mobile":
        updateSearchValues("mobile", value)
        break;
      case "deservedAmount":
        updateSearchValues("deservedAmount", value)
        break;
      default:
        break;
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    // setSearchText("");
    switch (dataIndex) {
      case "name":
        updateSearchValues("fullName", "");
        break;
      case "mobile":
        updateSearchValues("mobile", "");
        break;
      case "deservedAmount":
        updateSearchValues("deservedAmount", "");
        break;
      default:
        break;
    }
    clearFilters();
    confirm();
  };

  const handleCancel = () => {
    setRowData(null);
    setIsModalOpen(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columns = [
    {
      key: "name",
      title: "NAME",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },
    {
      key: "mobile",
      title: "MOBILE NUMBER",
      dataIndex: "mobile",
      ...getColumnSearchProps("mobile"),
    },
    {
      key: "isMerchant",
      title: "IS MERCHANT",
      dataIndex: "isMerchant",
      filters: [
        { text: "Merchant", value: true },
        { text: "Scrapper", value: false },
      ],
      onFilter: (value, record) => true,
      render: (_, row) => {
        return (
          <Switch
            checked={row.isMerchant}
            onChange={() => {
              setRowData(row)
              setMerchantStatusModalOpen(true)
            }}
          />
        );
      },
    },
    {
      key: "dealerId",
      title: "ACTION",
      hidden: !(authorization?.roles.includes(9) || authorization?.roles.includes(1)),
      render: (_, row) => {
        return (
          row.isMerchant ? (
            <Space size={5}>
              <Button
                className="border-0"
                style={{ backgroundColor: "#589559", color: "white" }}
                onClick={() => {
                  setRowData(row);
                  setIsModalOpen(true);
                }}
              >
                Add Check
              </Button>
              <Button
                className="btn--secondary"
                onClick={() => {
                  setRowData(row);
                  setIsBalanceModalOpen(true);
                }}
              >
                Balance Details
              </Button>
            </Space>
          ) : (
            ""
          )
        );
      },
    },
  ].filter((ele) => !ele.hidden);

  const handleExportDataToExcel = () => {
    if (isExporting) return;
    setIsExporting(true)
    const baseProps = [
      { title: "Name", prop: "name" },
      { title: "Mobile Number", prop: "mobile" },
      // { title: "Balance", prop: "deservedAmount" },
      { title: "Is Merchant", prop: "isMerchant", values: { true: "Merchant", false: "Scrapper" } },
    ]
    getDealersApi(
      {
        isExport: true,
        currentPage,
        perPage: pageSize,
        ...searchValues
      },
      (res) => {
        if (res.success) {
          exportDataAsExcel(res.data.dealers, { baseProps, tab: "Dealers" })
          setIsExporting(false)
        }
      },
      (res) => {
        setIsExporting(false)
        toastError(res?.data?.error);
      }
    );
  }

  useEffect(() => {
    setLoading(true);
    getDealersApi(
      { currentPage, perPage: pageSize, ...searchValues },
      (res) => {
        setLoading(false);
        if (res.success) {
          setDataSource(res.data.dealers);
          setTotalCount(res.data.totalCount);
        } else {
          toastError(res.message)
        }
      },
      (fail) => {
        setLoading(false);
        toastError(fail.err_msg);
      }
    );
  }, [renderMe, currentPage, searchValues, pageSize]);

  return (
    <section className="users">
      <div className="users__all-contents">
        <div className="users__content">
          <div className="users__usersData">
            <div className="pb-3">
              <MainHeader title="All Scrappers" />
            </div>
            <div className="users__table grid-table">
              <GridTable
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                rowId={"dealerId"}
                title={"Dealers"}
                allowExport={true}
                isExporting={isExporting}
                onExport={handleExportDataToExcel}
                currentPage={currentPage}
                totalCount={totalCount}
                onChange={handleTableChange}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
              />
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <AddCheckModal
          open={isModalOpen}
          rowData={rowData}
          SubHeader={
            <SubHeader
              bgColor="#589559"
              title={"Add Check"}
              closeButton
              onCancel={handleCancel}
            />
          }
          onAdd={() => {
            setRenderMe(!renderMe);
            handleCancel();
          }}
          onCancel={handleCancel}
          primaryBtnName={"Add"}
          secondaryBtnName={"Cancel"}
        />
      )}
      {isBalanceModalOpen && (
        <BalanceDetails
          open={isBalanceModalOpen}
          rowData={rowData}
          SubHeader={
            <SubHeader
              bgColor="#589559"
              title={"Balance Details"}
              closeButton
              onCancel={() => {
                setRowData("");
                setIsBalanceModalOpen(false);
              }}
            />
          }
        />
      )}
      {merchantStatusModalOpen && (
        <ConfirmTogglingMerchantStatusModal
          open={merchantStatusModalOpen}
          rowData={rowData}
          onSubmit={() => {
            setRowData(null)
            setMerchantStatusModalOpen(false)
            setRenderMe(!renderMe)
          }}
          SubHeader={
            <SubHeader
              bgColor="#F58234"
              title={`Change Merchant Status`}
              closeButton
              onCancel={() => {
                setRowData(null)
                setMerchantStatusModalOpen(false)
              }}
            />
          }
          onCancel={() => {
            setRowData(null)
            setMerchantStatusModalOpen(false)
          }}
        />
      )}
    </section>
  );
}

export default Scrappers;
