import { useEffect, useState } from "react";
import { Checkbox, Col, Form, Input, InputNumber, Modal, Row, Select, Space, Switch } from "antd";
import { toastError, toastSuccess } from "../../helpers/toasters";
import {
    addStoreItem,
    deleteItemImage,
    editStoreItem,
    getActiveStoreCategories,
    getActiveStoreSubcategories,
    getAllPartnersWithoutPagination,
    getPartnersOfCategoryForList,
    getPartnersOfSubcategoryForList,
    uploadStoreItemImage
} from "../../apis/Network";
import { arabicNameValidation } from "../../constants/RegexValidations";

// components
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";
import UploadImages from "./UploadImages";

const AddEditStoreProduct = ({
    isItemModalVisible,
    mode,
    rowData = {},
    SubHeader,
    primaryBtnName,
    secondaryBtnName,
    onSubmit,
    onCancel
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [previewCover, setPreviewCover] = useState("");
    const [previewImages, setPreviewImages] = useState([]);
    const [uploadedImages, setUploadedImages] = useState([]);
    const [imagesPaths, setImagesPaths] = useState([])
    const [activeStoreCategories, setActiveStoreCategories] = useState(null);
    const [activeStoreSubcategories, setActiveStoreSubcategories] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedSubcategory, setSelectedSubcategory] = useState("");
    const [partnersList, setPartnersList] = useState(null);
    const [partnersListLoading, setPartnersListLoading] = useState(false);
    const [allActiveDeactivePartners, setAllActiveDeactivePartners] = useState(null);
    const [itemHasOffer, setItemHasOffer] = useState(false);
    const [firstRender, setFirestRender] = useState(true);
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const initialValues = {
        nameEn: "",
        nameAr: "",
        image: "",
        images: "",
        price: "",
        quantity: "",
        descriptionEn: "",
        descriptionAr: "",
        shortDescriptionEn: "",
        shortDescriptionAr: "",
        url: "",
        category: undefined,
        subcategory: undefined,
        isActive: true,
        hasOffer: false,
        offerPercentage: "",
        partners: undefined
    }

    const sendDeleteImgAPI = (imagePath) => {
        const payload = {
            imagePath,
            itemId: rowData.itemId
        }
        deleteItemImage(
            payload,
            (res) => {
                if (!res.success) {
                    toastError(res.message)
                }
            },
            (res) => {
                toastError(res.message)
            }
        )
    }

    const handleRemoveImagePath = (path) => {
        const newPaths = [...imagesPaths].filter((imagePath) => imagePath !== path);
        setImagesPaths(newPaths);
    };

    const handleUploadedImagesChange = (value) => {
        setUploadedImages(value);
    };

    const handlePreviewCoverChange = (value) => {
        setPreviewCover(value);
    };

    const handlePreviewFilesChange = (value) => {
        setPreviewImages(value);
    };

    const handlePriceValidation = (_, value) => {
        if (!value && value !== 0) {
            return Promise.reject(new Error("Please, enter the product price"))
        } else if (value <= 0) {
            return Promise.reject(new Error(`Minimum value should be greater than 0 EGP`));
        } else if (value > 1000000) {
            return Promise.reject(new Error(`The price should not exceed 1M`));
        } else {
            return Promise.resolve()
        }
    }

    const handleQuantityValidation = (_, value) => {
        const integerPart = parseInt(value);
        if (!value && value !== 0) {
            return Promise.reject(new Error(`Please, enter the available product quantity`));
        } else if ((mode === "add" && integerPart === 0) || (integerPart && (isNaN(integerPart) || integerPart < 1))) {
            return Promise.reject(new Error(`Minimum value is 1`));
        } else if (value !== integerPart) {
            return Promise.reject(new Error(`Please, enter a valid quantity value`));
        } else if (integerPart > 1000000) {
            return Promise.reject(new Error(`The quantity should not exceed 1M`));
        } else {
            return Promise.resolve()
        }
    }

    const handleOfferPercentageValidation = (_, value) => {
        const intgerPart = parseInt(value)
        if (!value && value !== 0) {
            return Promise.reject(new Error(`Please, enter the percentage of the offer.`));
        } else if (value <= 0) {
            return Promise.reject(new Error(`Minimum value should be greater than 0`));
        } else if (intgerPart !== value) {
            return Promise.reject(new Error(`Please, enter a valid percentage value`));
        } else if (value > 100) {
            return Promise.reject(new Error(`The discount percentage should not exceed 100%.`));
        } else {
            return Promise.resolve()
        }
    }

    const handlePreventLetters = (e, allowFloat = true) => {
        if (e.key === "Backspace" ||
            (allowFloat && e.key === ".") ||
            (e.key.startsWith("Arrow") && (e.key.startsWith("ArrowLeft") || e.key.startsWith("ArrowRight")))
        ) {
            return;
        }
        if (/\D+/gi.test(e.key)) {
            e.preventDefault();
        }
    };

    const addItem = (payload) => {
        addStoreItem(
            payload,
            (res) => {
                setSubmitting(false);
                if (res.success) {
                    toastSuccess(res.message);
                    onSubmit();
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setSubmitting(false);
                toastError(res.message);
            }
        );
    }

    const editItem = async (payload) => {
        await rowData.images.forEach(async (original) => {
            let notDeleted = payload.images ? payload.images.find((sent) => sent === original) : false
            if (!notDeleted) {
                await sendDeleteImgAPI(original)
            }
        })
        editStoreItem(
            payload,
            (success) => {
                setSubmitting(false);
                if (success.success) {
                    toastSuccess(success.message);
                    onSubmit();
                } else {
                    toastError(success.message);
                }
            },
            (fail) => {
                setSubmitting(false);
                toastError(fail.message);
            }
        );
    }

    const getImagePath = (payload, itemData, type, nextItems) => {
        uploadStoreItemImage(
            payload,
            (res) => {
                if (res.success) {
                    if (type === "cover") {
                        itemData.image = res.data
                    } else {
                        itemData.images = [...itemData.images, res.data]
                    }
                    if (nextItems.length > 0) {
                        const formData = new FormData();
                        formData.set("image", nextItems[0])
                        const updatedNextItems = nextItems.length > 1 ? [nextItems[1]] : []
                        getImagePath(formData, itemData, "other", updatedNextItems)
                    } else {
                        if (itemData.images.length === 0) {
                            delete itemData.images
                        }
                        if (mode === "add") {
                            addItem(itemData)
                        } else {
                            editItem(itemData)
                        }
                    }
                } else {
                    setSubmitting(false);
                    toastError(res.message);
                }
            },
            (res) => {
                setSubmitting(false);
                toastError(res?.data?.error);
            }
        );
    }

    const uploadItemImages = async (mainImage, otherImages, itemData) => {
        const formData = new FormData();
        if (mainImage) {
            formData.set("image", mainImage)
            const nextItems = otherImages.length > 0 ? otherImages : []
            getImagePath(formData, itemData, "cover", nextItems)
        } else {
            formData.set("image", otherImages[0])
            const updatedNextItems = otherImages.length > 1 ? [otherImages[1]] : []
            getImagePath(formData, itemData, "other", updatedNextItems)
        }
    };

    const handleOnFinish = (values) => {
        const imagesError = form.getFieldError("images")
        if (submitting || imagesError.length > 0 || partnersListLoading) return;
        setSubmitting(true);

        const payload = {
            Name: values.nameEn,
            NameAr: values.nameAr,
            Description: values.descriptionEn,
            DescriptionAr: values.descriptionAr,
            shortDescription: values.shortDescriptionEn,
            shortDescriptionAr: values.shortDescriptionAr,
            link: values.url,
            Quantity: values.quantity,
            CategoryId: selectedCategory,
            Price: parseFloat(values.price.toFixed(2)),
            IsActive: values.isActive,
            images: mode === "add" ? [] : imagesPaths,
            image: mode === "add" ? "" : rowData.image,
            forGreenMatter: false,
            forscrapper: true,
            partners: values.partners || []
        }
        if (values.subcategory) {
            payload.sUbCategoryId = selectedSubcategory
        }
        if (itemHasOffer) {
            payload.DiscountPercentage = values.offerPercentage
        }
        if (mode === "add") {
            uploadItemImages(values.image, uploadedImages, payload)
        } else {
            payload.Id = rowData.itemId;
            const selectedPartners = values.partners?.map((ele) => {
                let partnerOfName = allActiveDeactivePartners?.find((partner) => partner.nameEn === ele)
                if (partnerOfName) {
                    return partnerOfName.id
                } else {
                    return ele
                }
            })
            payload.partners = selectedPartners || []
            if (previewCover || uploadedImages.length > 0) {
                uploadItemImages(previewCover ? values.image : "", uploadedImages, payload)
            } else {
                if (payload.images.length === 0) {
                    delete payload.images
                }
                editItem(payload)
            }
        }
    };

    useEffect(() => {
        if (rowData && mode === "edit") {
            if (rowData.discountPercentage) {
                setItemHasOffer(true)
            }
            setImagesPaths(rowData.images)
            form.setFieldsValue({
                nameEn: rowData.name,
                nameAr: rowData.nameAr,
                image: rowData.image,
                price: rowData.price,
                quantity: rowData.quantity,
                descriptionEn: rowData.description,
                descriptionAr: rowData.descriptionAr,
                shortDescriptionEn: rowData.shortDescription,
                shortDescriptionAr: rowData.shortDescriptionAr,
                url: rowData.link,
                isActive: rowData.isActive,
                hasOffer: rowData.discountPercentage > 0,
                offerPercentage: rowData.discountPercentage > 0 ? rowData.discountPercentage : "",
                partners: rowData.partners,
            });
            setSelectedCategory(rowData.categoryId)
            setSelectedSubcategory(rowData.subCategoryId)
        }
    }, [rowData, mode, form]);

    useEffect(() => {
        getActiveStoreCategories(
            (res) => {
                if (res.success) {
                    const dataArr = res.data.map((category) => {
                        return { value: category.id, label: category.name };
                    })
                    setActiveStoreCategories(dataArr);
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                if (res.data?.error) {
                    toastError(res.message);
                }
            }
        );
    }, [])

    useEffect(() => {
        if (!selectedCategory) return;
        getActiveStoreSubcategories(
            selectedCategory,
            (res) => {
                if (res.success) {
                    const dataArr = res.data.map((category) => {
                        return { value: category.id, label: category.name };
                    })
                    setActiveStoreSubcategories(dataArr);
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                if (res.data?.error) {
                    toastError(res.message);
                }
            }
        );
    }, [selectedCategory])

    useEffect(() => {
        if (!rowData) return;
        if (activeStoreCategories && firstRender) {
            const categoryExists = activeStoreCategories.find((category) => category.value === rowData.categoryId)
            if (!categoryExists) {
                form.setFieldValue("category", rowData.categoryName)
            } else {
                form.setFieldValue("category", rowData.categoryId)
            }
        }
        if (activeStoreSubcategories && firstRender) {
            const subcategoryExists = activeStoreSubcategories.find((subcategory) => subcategory.value === rowData.subCategoryId)
            if (!subcategoryExists) {
                form.setFieldValue("subcategory", rowData.subCategoryName)
            } else if (rowData.subCategoryId) {
                form.setFieldValue("subcategory", rowData.subCategoryId)
            }
            // if (mode !== "edit" || (mode === "edit" && activeStoreCategories)) {
            //     setFirestRender(false)
            // }
        }
    }, [rowData, activeStoreCategories, activeStoreSubcategories])

    useEffect(() => {
        if (!selectedCategory && !selectedSubcategory) return
        if (selectedSubcategory) {
            getPartnersOfSubcategoryForList(
                selectedSubcategory,
                (res) => {
                    setPartnersListLoading(false)
                    if (res.success) {
                        const options = res.data.map((partner) => {
                            return { value: partner.id, label: partner.name };
                        });
                        setPartnersList(options)
                    } else {
                        toastError(res.message)
                    }
                },
                (res) => {
                    setPartnersListLoading(false)
                    toastError(res.message)
                }
            )
        } else {
            getPartnersOfCategoryForList(
                selectedCategory,
                (res) => {
                    setPartnersListLoading(false)
                    if (res.success) {
                        const options = res.data.map((partner) => {
                            return { value: partner.id, label: partner.name };
                        });
                        setPartnersList(options)
                    } else {
                        toastError(res.message)
                    }
                },
                (res) => {
                    setPartnersListLoading(false)
                    toastError(res.message)
                }
            )
        }
    }, [selectedCategory, selectedSubcategory])

    useEffect(() => {
        if (!rowData || !partnersList || mode !== "edit" || !firstRender) return;
        getAllPartnersWithoutPagination(
            (res) => {
                setFirestRender(false)
                if (res.success) {
                    setAllActiveDeactivePartners(res.data)
                    const selectedPartners = rowData.partners.map((partId) => {
                        const isPartnerActive = partnersList.find((partner) => partner.value === partId);
                        if (isPartnerActive) {
                            return partId
                        } else {
                            const partnerName = res.data.find((partner) => partner.id === partId).nameEn
                            return partnerName
                        }
                    })
                    form.setFieldValue("partners", selectedPartners)
                } else {
                    toastError(res.message)
                }
            },
            (res) => {
                toastError(res?.data?.error)
            }
        )
    }, [rowData, partnersList])

    return (
        isItemModalVisible && (
            <Modal
                title={SubHeader}
                open={isItemModalVisible}
                className="msg-modal"
                footer={false}
                closable={false}
                centered
                destroyOnClose
            >
                <Form
                    form={form}
                    className="edit-actor__content store-items__item-form"
                    onFinish={handleOnFinish}
                    initialValues={initialValues}
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <div className="lang-name wd-100 flex ">
                                    <label className="users__parent-info-label mb-2" htmlFor="">
                                        Image
                                    </label>
                                </div>
                                <UploadImages
                                    mode={mode}
                                    formRef={form}
                                    data={rowData}
                                    onRemoveImagePath={handleRemoveImagePath}
                                    imagesPaths={imagesPaths}
                                    previewCover={previewCover}
                                    onPreviewCoverChange={handlePreviewCoverChange}
                                    previewFiles={previewImages}
                                    onPreviewFilesChange={handlePreviewFilesChange}
                                    uploadedImages={uploadedImages}
                                    onUploadedImagesChange={handleUploadedImagesChange}
                                />
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Name (EN)
                                </label>
                                <Form.Item
                                    className=" new-category__form-item"
                                    name="nameEn"
                                    validateFirst
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please, enter the product name in English",
                                        },
                                        {
                                            pattern: /^[a-z][\w\s$!@#%^&*()_+-=[\]/{}\\|;':",./<>?~`]*[\w$!@#%^&*()_+-=[\]/{}\\|;':",./<>?~`]*$/i,
                                            message: "Please, enter the product name in English",
                                        },
                                        {
                                            min: 3,
                                            message: "Minimum length is 3 characters"
                                        },
                                        {
                                            max: 50,
                                            message: "Maximum length is 50 characters"
                                        },
                                    ]}
                                >
                                    <Input
                                        type="text"
                                        className="search__searchField py-2"
                                        placeholder="Enter Name in English"
                                        maxLength={50}
                                        showCount
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Name (AR)
                                </label>
                                <Form.Item
                                    className=" new-category__form-item"
                                    name="nameAr"
                                    validateFirst
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please, enter the product name in Arabic",
                                        },
                                        {
                                            pattern: arabicNameValidation,
                                            message: "Please, enter the product name in Arabic",
                                        },
                                        {
                                            max: 50,
                                            message: "Maximum length is 50 characters"
                                        },
                                        {
                                            min: 3,
                                            message: "Minimum length is 3 characters"
                                        },
                                    ]}
                                >
                                    <Input
                                        type="text"
                                        className="search__searchField py-2"
                                        placeholder="Enter Name in Arabic"
                                        maxLength={50}
                                        showCount
                                        dir="rtl"
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <div className="lang-name wd-100 flex ">
                                    <label className="users__parent-info-label mb-2" htmlFor="">
                                        Price
                                    </label>
                                </div>
                                <Form.Item
                                    className="new-category__form-item"
                                    name="price"
                                    rules={[
                                        { validator: handlePriceValidation }
                                    ]}
                                >
                                    <InputNumber
                                        className="w-100 d-block search__searchField py-1"
                                        placeholder="Enter Price"
                                        onKeyDown={handlePreventLetters}
                                        controls={false}
                                        keyboard={false}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <div className="lang-name wd-100 flex">
                                    <label className="users__parent-info-label mb-2" htmlFor="">
                                        Available Quantity
                                    </label>
                                </div>
                                <Form.Item
                                    className="new-category__form-item"
                                    name="quantity"
                                    rules={[
                                        { validator: handleQuantityValidation }
                                    ]}
                                >
                                    <InputNumber
                                        className="w-100 d-block search__searchField py-1"
                                        placeholder="Enter available quantity"
                                        onKeyDown={(e) => handlePreventLetters(e, false)}
                                        controls={false}
                                        keyboard={false}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Description in English
                                </label>
                                <Form.Item
                                    className="new-category__form-item"
                                    name="descriptionEn"
                                    validateFirst
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please, enter the product description in English",
                                        },
                                        {
                                            pattern: /^[a-z][\w\s$!@#%^&*()_+-=[\]/{}\\|;':",./<>?~`]*[\w$!@#%^&*()_+-=[\]/{}\\|;':",./<>?~`]*$/i,
                                            message: "Please, enter the product description in English",
                                        },
                                        {
                                            min: 3,
                                            message: "Minimum length is 3 characters"
                                        },
                                    ]}
                                >
                                    <TextArea
                                        maxLength={100}
                                        showCount
                                        className="store-items__item-form__description"
                                        autoSize={{ minRows: 2, maxRows: 6 }}
                                        placeholder="enter description in English"
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Description in Arabic
                                </label>
                                <Form.Item
                                    className="new-category__form-item"
                                    name="descriptionAr"
                                    validateFirst
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please, enter the product description in Arabic",
                                        },
                                        {
                                            pattern: arabicNameValidation,
                                            message: "Please, enter the product description in Arabic",
                                        },
                                        {
                                            min: 3,
                                            message: "Minimum length is 3 characters"
                                        },
                                    ]}
                                >
                                    <TextArea
                                        maxLength={100}
                                        showCount
                                        dir="rtl"
                                        className="store-items__item-form__description"
                                        autoSize={{ minRows: 2, maxRows: 6 }}
                                        placeholder="Enter description in Arabic"
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Short Description in English
                                </label>
                                <Form.Item
                                    className="new-category__form-item"
                                    name="shortDescriptionEn"
                                    validateFirst
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please, enter the product short description in English",
                                        },
                                        {
                                            pattern: /^[a-z][\w\s$!@#%^&*()_+-=[\]/{}\\|;':",./<>?~`]*[\w$!@#%^&*()_+-=[\]/{}\\|;':",./<>?~`]*$/i,
                                            message: "Please, enter the product short description in English",
                                        },
                                        { min: 3, message: "Minimum length is 3 characters" },
                                    ]}
                                >
                                    <TextArea
                                        maxLength={50}
                                        showCount
                                        className="store-items__item-form__description"
                                        autoSize={{ minRows: 2, maxRows: 6 }}
                                        placeholder="Enter short description in English"
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Short Description in Arabic
                                </label>
                                <Form.Item
                                    className="new-category__form-item"
                                    name="shortDescriptionAr"
                                    validateFirst
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please, enter the product short description in Arabic",
                                        },
                                        {
                                            pattern: arabicNameValidation,
                                            message: "Please, enter the product short description in Arabic",
                                        },
                                        { min: 3, message: "Minimum length is 3 characters" },
                                    ]}
                                >
                                    <TextArea
                                        maxLength={50}
                                        showCount
                                        dir="rtl"
                                        className="store-items__item-form__description"
                                        autoSize={{ minRows: 2, maxRows: 6 }}
                                        placeholder="Enter short description in Arabic"
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Link
                                </label>
                                <Form.Item
                                    className="new-category__form-item"
                                    name="url"
                                    rules={[
                                        { type: "url", message: "Please, enter a valid product link" }
                                    ]}
                                >
                                    <Input
                                        type="text"
                                        className="search__searchField py-2"
                                        placeholder="Enter link"
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <label className="users__parent-info-label mb-2">
                                    Category
                                </label>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please, select a category",
                                        },
                                    ]}
                                    name="category"
                                    className="new-category__form-item"
                                >
                                    <Select
                                        showSearch
                                        optionFilterProp="label"
                                        name="category"
                                        className="new-category__form-item"
                                        placeholder="Select Category"
                                        onChange={(value) => {
                                            setSelectedSubcategory("")
                                            setSelectedCategory(value)
                                            form.resetFields(["subcategory"])
                                            form.resetFields(["partners"])
                                            setPartnersListLoading(true)
                                        }}
                                        loading={activeStoreCategories ? false : true}
                                        options={activeStoreCategories ? activeStoreCategories : []}
                                        getPopupContainer={trigger => trigger.parentElement}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <label className="users__parent-info-label mb-2">
                                    Subcategory
                                </label>
                                <Form.Item
                                    rules={activeStoreSubcategories?.length > 0 ? (
                                        [{
                                            required: true,
                                            message: "Please, select a sub-category",
                                        }]
                                    ) : (
                                        []
                                    )}
                                    name="subcategory"
                                    className="new-category__form-item"
                                >
                                    <Select
                                        showSearch
                                        optionFilterProp="label"
                                        name="subcategory"
                                        className="new-category__form-item"
                                        placeholder="Select Subcategory"
                                        loading={activeStoreSubcategories ? false : true}
                                        options={activeStoreSubcategories ? activeStoreSubcategories : []}
                                        getPopupContainer={trigger => trigger.parentElement}
                                        onChange={(value) => {
                                            setSelectedSubcategory(value)
                                            setPartnersListLoading(true)
                                            form.resetFields(["partners"])
                                        }}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name new-category mb-5">
                                <label className="users__parent-info-label mb-2" htmlFor="">
                                    Partners
                                </label>
                                <Form.Item
                                    className="new-category__form-item"
                                    name="partners"
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        mode="multiple"
                                        optionFilterProp="label"
                                        placeholder="Select partners"
                                        loading={partnersListLoading}
                                        options={partnersList ? partnersList : []}
                                        getPopupContainer={(trigger) => trigger.parentElement}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="new-actor__name mb-4">
                                <Form.Item
                                    name="hasOffer"
                                    valuePropName="checked"
                                    className="mb-0"
                                >
                                    <Checkbox
                                        onChange={(e) => setItemHasOffer(e.target.checked)}
                                    >
                                        Has Offer
                                    </Checkbox>
                                </Form.Item>
                            </div>
                        </Col>
                        {itemHasOffer && (
                            <Col span={24}>
                                <div className="new-actor__name new-category mb-5">
                                    <div className="lang-name wd-100 flex">
                                        <label className="users__parent-info-label mb-2" htmlFor="">
                                            Percentage
                                        </label>
                                    </div>
                                    <Form.Item
                                        className="new-category__form-item"
                                        name="offerPercentage"
                                        rules={[{ validator: handleOfferPercentageValidation }]}
                                    >
                                        <InputNumber
                                            className="w-100 d-block search__searchField py-1"
                                            placeholder="Enter percentage"
                                            onKeyDown={(e) => handlePreventLetters(e, false)}
                                            controls={false}
                                            keyboard={false}
                                        />
                                    </Form.Item>
                                </div>
                            </Col>
                        )}
                        <Col span={24}>
                            <div className="new-actor__name mb-4 row mx-0 align-items-center">
                                <div className="col-4 px-0 text-start">
                                    <label className="users__parent-info-label">Status</label>
                                </div>
                                <div className="col-8 px-0 d-flex">
                                    <Form.Item
                                        name="isActive"
                                        valuePropName="checked"
                                        className="mb-0"
                                    >
                                        <Switch />
                                    </Form.Item>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mrg-ys-20 mrg-x-auto">
                        <Space size={20}>
                            <SecondaryButton
                                buttonName={secondaryBtnName}
                                onClick={onCancel}
                            />
                            <PrimaryButton
                                buttonName={primaryBtnName}
                                loading={submitting}
                                type="submit"
                                disableMe={partnersListLoading}
                            />
                        </Space>
                    </Row>
                </Form>
            </Modal >
        ));
}

export default AddEditStoreProduct;