import {
    collection,
    doc,
    endBefore,
    getDocs,
    limit,
    limitToLast,
    onSnapshot,
    orderBy,
    query,
    startAfter,
    where,
} from "firebase/firestore";
import db from "./firebase";
import store from "store/index";
import { updateNotifications } from "store/chatSlice";

export const listenToUserChat = (userId, callback) => {
    const collectionName = process.env.REACT_APP_FB_Collection;
    const userDoc = doc(db, collectionName, userId);
    const unsubscribe = onSnapshot(userDoc, (doc) => {
        if (doc.exists()) {
            callback(doc.data());
        }
    });
    return unsubscribe;
};

export const updateChatNotifications = async () => {
    const collectionName = process.env.REACT_APP_FB_Collection;
    const q = query(collection(db, collectionName), where("unReadMessagesCountFromUser", ">", 0));
    const querySnapshot = await getDocs(q);
    let counter = 0;
    querySnapshot.forEach((doc) => {
        counter += doc.data().unReadMessagesCountFromUser
    });
    store.dispatch(updateNotifications(counter))
}

export const listenToChatRoomsWithPagiantion = (
    callback,
    lastDoc,
    after,
    searchValue,
) => {
    const collectionName = process.env.REACT_APP_FB_Collection;
    const filterArr = [
        collection(db, collectionName),
        orderBy('lastMessageDate', "desc"),
        orderBy('mobile', "desc"),
        where("mobile", ">=", searchValue),
        where("mobile", "<=", searchValue + "\uf8ff"),
        startAfter(lastDoc),
        limit(10),
        endBefore(lastDoc),
        limitToLast(10),
    ].filter((ele, indx) => {
        let hideOrderBy = searchValue;
        let hideWhere = !searchValue;
        if (indx === 1) {
            if (hideOrderBy) {
                return false
            } else {
                return true
            }
        }
        else if (indx >= 2 && indx <= 4) {
            if (hideWhere) {
                return false
            } else {
                return true
            }
        }
        else if (indx === 5) {
            if (!after || !lastDoc) {
                return false
            } else {
                return true
            }
        } else if (indx >= 7) {
            if (after || !lastDoc) {
                return false
            } else {
                return true
            }
        }
        return true;
    })
    let q = query(...filterArr)
    const unsubscribe = onSnapshot(q, (snapshot) => {
        updateChatNotifications();
        const chats = [];
        snapshot.docs.forEach((doc) => {
            chats.push({ id: doc.id, ...doc.data(), docSnapShot: doc });
        });
        callback(chats);
    });
    return unsubscribe;
};
