const ReturnsDetails = ({ data }) => {
    return (
        <div className="dealers__balance-details-modal__holder d-flex flex-column gap-3">
            {data ? (
                data.length > 0 ?
                    data.map((ele) => (
                        <div key={ele.typeId} className="dealers__balance-details-modal__check-holder p-3">
                            <h3 className="fs-6 d-flex align-items-center gap-2 justify-content-center">
                                <span>{ele.typeName}</span>
                            </h3>
                            <div className="d-flex align-items-center gap-1 mb-2">
                                <span className="dealers__balance-details-modal__check-holder__label">Quantity:</span>
                                <span className="dealers__balance-details-modal__check-holder__value">{ele.quantity} Kg</span>
                            </div>
                            <div className="d-flex align-items-center gap-1 mb-2">
                                <span className="dealers__balance-details-modal__check-holder__label">Price:</span>
                                <span className="dealers__balance-details-modal__check-holder__value">{ele.perKiloPrice} EGP</span>
                            </div>
                            <div className="d-flex align-items-center gap-1 mb-2">
                                <span className="dealers__balance-details-modal__check-holder__label">Date:</span>
                                <span className="dealers__balance-details-modal__check-holder__value">{ele.date}</span>
                            </div>
                        </div>
                    )) : (
                        <p className="dealers__balance-details-modal__empty-msg text-center fw-bold py-4 mb-0">
                            Merchant doesn't have any returns!
                        </p>
                    )) : (
                ""
            )}
        </div>
    );
}

export default ReturnsDetails;