import { useState } from "react";
import { Button, Form, Upload } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import { baseUrl } from "../../apis/services";

const ImageUpload = ({
    formRef,
    name,
    mode,
    onUpload,
    rowDataImg,
    label,
    required,
    deletable
}) => {
    const [invalidImage, setInvalidImage] = useState(false)
    const [previewImage, setPreviewImage] = useState("");
    const maxSize = 25 * 1024 * 1024;
    const props = {
        name,
        accept: ".png,.jpg,.jpeg,.bmp,.gif,.svg",
        maxCount: 1,
        showUploadList: false,
        onChange(info) {
            if (!info.file.type.startsWith("image")) {
                handleRemoveImage();
                formRef.setFields([
                    {
                        name,
                        errors: ["Please upload a valid image file (e.g., .jpg, .png)"],
                        value: "",
                    }
                ])
            } else if (info.file.size > maxSize) {
                handleRemoveImage();
                formRef.setFields([
                    {
                        name,
                        errors: ["The image file size should not exceed 25MB"],
                        value: "",
                    }
                ])
            } else {
                handlePreview(info.file)
            }
        },
    };

    const handleBeforeCrop = (file) => {
        if (!file.type.startsWith("image") || file.size > maxSize) {
            handleRemoveImage();
            return false;
        } else {
            return true
        }
    }

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handlePreview = async (file) => {
        onUpload(file.originFileObj);
        formRef.setFields([{ name, value: file.originFileObj, errors: [] }])
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
    };

    const handleRemoveImage = () => {
        setPreviewImage("");
        onUpload("");
        formRef.resetFields(["image"])
        setInvalidImage(true)
    };

    return (
        <div className="new-actor__name new-category mb-5">
            <div className="lang-name wd-100 flex ">
                <label className="users__parent-info-label mb-2" htmlFor="">
                    {label}
                </label>
            </div>
            <div className="position-relative p-2">
                {previewImage ? (
                    <img
                        src={previewImage}
                        alt=""
                        className="do-dont-images__add-form__preview-img d-block img-fluid mb-2"
                    />
                ) : mode === "edit" && !invalidImage && rowDataImg ? (
                    <img
                        src={baseUrl + rowDataImg}
                        alt=""
                        className="do-dont-images__add-form__preview-img d-block img-fluid mb-2"
                    />
                ) : (
                    ""
                )}
                {deletable && (previewImage ||
                    (mode === "edit" && !invalidImage && rowDataImg)) ? (
                    <Button
                        htmlType="button"
                        onClick={handleRemoveImage}
                        className="d-block border-0 outline-0 shadow-none position-absolute top-0 end-0 bg-transparent p-0 lh-1 h-auto"
                    >
                        <CloseCircleFilled />
                    </Button>
                ) : (
                    ""
                )}
            </div>
            <Form.Item
                name={name}
                rules={
                    required ? (
                        [
                            {
                                required: true,
                                message: "Image is required, please upload a valid image"
                            }
                        ]
                    ) : (
                        []
                    )}
                className="w-100"
            >
                <ImgCrop
                    beforeCrop={handleBeforeCrop}
                    fillColor="transparent"
                    aspectSlider
                    cropperProps={{ restrictPosition: false }}
                    minZoom={0.5}
                    rotationSlider
                >
                    <Upload {...props}>
                        Upload Image +
                    </Upload>
                </ImgCrop>
            </Form.Item>
        </div >
    );
}

export default ImageUpload;